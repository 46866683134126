import React from 'react';
import projectIcon from '../../../../Assets/target_icon.png';
import sprintIcon from '../../../../Assets/arrow_circle.png';
import teamIcon from '../../../../Assets/team_icon.png';
import barrierIcon from '../../../../Assets/barrier_icon.png';
import checkIcon from '../../../../Assets/check_icon.png';
import schoolIcon from '../../../../Assets/school_icon.png';
import thophyIcon from '../../../../Assets/trophy_icon.png';
import './OverviewCard.scss';

import { useTranslation } from "react-i18next"

/**
 * Renders an overview card with a quantity and label.
 *
 * @param {Object} props - The properties for the component.
 * @param {number} props.quantity - The quantity to display.
 * @param {string} props.key_card - The key of the card to display.
 * @param {boolean} [props.isSmallText=false] - Whether to display small text.
 * @return {JSX.Element} The rendered overview card.
 */
const OverviewCard = ({ quantity, key_card, isSmallText = false }) => {

  const [t] = useTranslation()

  const imageAndLabels = {
    projects: { img: projectIcon, label: 'Projects' },
    sprints: { img: sprintIcon, label: t('commons.interventions') },
    team_members: { img: teamIcon, label: 'Team Members' },
    early_wins: { img: thophyIcon, label: 'Early Wins' },
    completions: { img: checkIcon, label: 'Completions' },
    barriers: { img: barrierIcon, label: 'Barriers' },
    lessons_learned: { img: schoolIcon, label: 'Lessons Learned' },
  };

  return (
    <div className='OverviewCard'>
      <img src={imageAndLabels[key_card]?.img} alt={key_card} />
      <div className='text-center flex-1'>
        <p className='text-xxl quantity-color'>{quantity}</p>
        <p className={`${isSmallText ? 'text-md' : 'text-lg'}`}>
          {imageAndLabels[key_card]?.label}
        </p>
      </div>
    </div>
  );
};

export default OverviewCard;
