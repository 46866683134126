import React, { useState } from "react"
import { Alert, Button, Modal, Tabs } from "antd"

import { useTranslation } from "react-i18next"

import { postFeedback } from "../../Services/Feedback.service"
import FeedbackStep1 from "./Steps/Step1"
import FeedbackStep2 from "./Steps/Step2"
import FeedbackStep3 from "./Steps/Step3"
import FeedbackStep4 from "./Steps/Step4"
import FeedbackStep5 from "./Steps/Step5"
import FeedbackStep6 from "./Steps/Step6"
import FeedbackStep7 from "./Steps/Step7"
import FeedbackStep8 from "./Steps/Step8"

import "./Feedback.scss"

const { TabPane } = Tabs

/**
 * Renders a feedback component that allows users to submit feedback.
 *
 * @returns {JSX.Element} The feedback component.
 */
const Feedback = () => {
  const [showForm, setshowForm] = useState(false)
  const [isSubmited, setIsSubmited] = useState(false)
  const [step, setStep] = useState(1)
  const [step1Value, setStep1Value] = useState("")
  const [step2BugValue, setStep2BugValue] = useState("")
  const [step3BugValue, setStep3BugValue] = useState("")
  const [step3FeatureValue, setStep3FeatureValue] = useState("")
  const [step3RequestValue, setStep3RequestValue] = useState("")
  const [step4BugValue, setStep4BugValue] = useState("")
  const [step4FeatureValue, setStep4FeatureValue] = useState("")
  const [step4RequestValue, setStep4RequestValue] = useState("")
  const [step5BugValue, setStep5BugValue] = useState("")
  const [step5FeatureValue, setStep5FeatureValue] = useState("")
  const [step5RequestValue, setStep5RequestValue] = useState("")
  const [step6FeatureValue, setStep6FeatureValue] = useState("")
  const [step6RequestValue, setStep6RequestValue] = useState("")
  const [step7FeatureValue, setStep7FeatureValue] = useState("")
  const [step8FeatureValue, setStep8FeatureValue] = useState("")

  // i18n translation hook
  const [t] = useTranslation()

  const handleInitForm = () => {
    setStep(1)
    setStep1Value("")
    setStep2BugValue("")
    setStep3BugValue("")
    setStep3FeatureValue("")
    setStep3RequestValue("")
    setStep4BugValue("0")
    setStep4FeatureValue("0")
    setStep4RequestValue("")
    setStep5BugValue("")
    setStep5FeatureValue("")
    setStep5RequestValue("4")
    setStep6FeatureValue("")
    setStep6RequestValue("")
    setStep7FeatureValue("4")
    setStep8FeatureValue("")
    setshowForm(true)
    setIsSubmited(false)
  }

  /**
   * Handles the action of continuing or submitting the form.
   *
   * @return {Promise<void>} Promise that resolves when the action is complete.
   */
  const handleContinueOrSubmit = async () => {
    if (handleContinueSubmitText() === t("Submit")) {
      try {
        let data = {}
        if (step1Value === "bug") {
          data = {
            "category": "BUG",
            "bug_description": step2BugValue,
            "screenshot": step3BugValue,
            "rating": step4BugValue,
            "additional_info": step5BugValue,
          }
        } else if (step1Value === "feature") {
          data = {
            "category": "FEATURE",
            "feature_info": step3FeatureValue,
            "screenshot": step4FeatureValue,
            "like_about_feature": step5FeatureValue,
            "feature_frustrations": step6FeatureValue,
            "rating": step7FeatureValue,
            "future_of_feature": step8FeatureValue,
          }
        } else if (step1Value === "request") {
          data = {
            "category": "REQUEST",
            "request_type": step3RequestValue,
            "request_info": step4RequestValue,
            "rating": step5RequestValue,
            "additional_info": step6RequestValue,
          }
        }
        await postFeedback(data).call
        setIsSubmited(true)
        setshowForm(false)
      } catch (error) {

      }
      return
    }
    setStep((currentStep) => currentStep + 1)
  }

  const handleCancel = () => setshowForm(false)

  const handleCancelOrBack = () => {
    if (step === 1) handleCancel()
    else setStep((currentStep) => currentStep - 1)
  }

  /**
   * Disable the continue button under certain conditions.
   *
   * @return {boolean} Whether the continue button should be disabled.
   */
  const disableContinueButton = () => {
    if (step === 1 && step1Value === "") return true
    if (step === 2 && step1Value === "bug" && step2BugValue === "") return true
    if (step === 3 && step1Value === "feature" && step3FeatureValue === "") return true
    if (step === 5 && step1Value === "feature" && step5FeatureValue === "") return true
    if (step === 6 && step1Value === "feature" && step6FeatureValue === "") return true
    if (step === 8 && step1Value === "feature" && step8FeatureValue === "") return true
    if (step === 3 && step1Value === "request" && step3RequestValue === "") return true
    if (step === 4 && step1Value === "request" && step4RequestValue === "") return true
    return false
  }

  /**
   * Generate the text to be displayed on the continue/submit button.
   *
   * @return {string} The text to be displayed on the button.
   */
  const handleContinueSubmitText = () => {
    if (step1Value === "bug" && step === 5) return t("Feedback_Modal.Submit")
    if (step1Value === "feature" && step === 8) return t("Feedback_Modal.Submit")
    if (step1Value === "request" && step === 6) return t("Feedback_Modal.Submit")


    return "Continue"
  }

  return (
    <div className="feedback">
      <Alert
        message={isSubmited ? t("Feedback_Modal.Thank_you") : t("Feedback_Modal.Got_Feedback")}
        type={isSubmited ? "success" : "warning"}
        onClick={() => handleInitForm()}
        closable />
      <Modal
        open={showForm}
        onOk={handleContinueOrSubmit}
        onCancel={handleCancel}
        className="feedback-modal"
        footer={[
          <Button key="back" onClick={handleCancelOrBack} type="text">
            {step === 1 ? "Cancel" : "Back"}
          </Button>,
          <Button key="submit" type="primary" onClick={handleContinueOrSubmit} disabled={disableContinueButton()}>
            {handleContinueSubmitText()}
          </Button>,
        ]}
      >
        <Tabs activeKey={step.toString()} renderTabBar={() => <></>} className="mt-15" animated={true}>
          <TabPane key="1">
            <FeedbackStep1 step1Value={step1Value} setStep1Value={setStep1Value} />
          </TabPane>
          <TabPane key="2">
            <FeedbackStep2 step1Value={step1Value} step2BugValue={step2BugValue} setStep2BugValue={setStep2BugValue} />
          </TabPane>
          <TabPane key="3">
            <FeedbackStep3
              step1Value={step1Value} step3BugValue={step3BugValue} setStep3BugValue={setStep3BugValue}
              step3FeatureValue={step3FeatureValue} setStep3FeatureValue={setStep3FeatureValue}
              step3RequestValue={step3RequestValue} setStep3RequestValue={setStep3RequestValue}
            />
          </TabPane>
          <TabPane key="4">
            <FeedbackStep4
              step1Value={step1Value} step4BugValue={step4BugValue} setStep4BugValue={setStep4BugValue}
              step4FeatureValue={step4FeatureValue} setStep4FeatureValue={setStep4FeatureValue}
              step4RequestValue={step4RequestValue} setStep4RequestValue={setStep4RequestValue}
            />
          </TabPane>
          <TabPane key="5">
            <FeedbackStep5
              step1Value={step1Value} step5BugValue={step5BugValue} setStep5BugValue={setStep5BugValue}
              step5FeatureValue={step5FeatureValue} setStep5FeatureValue={setStep5FeatureValue}
              step5RequestValue={step5RequestValue} setStep5RequestValue={setStep5RequestValue}
            />
          </TabPane>
          <TabPane key="6">
            <FeedbackStep6
              step1Value={step1Value} step6FeatureValue={step6FeatureValue} setStep6FeatureValue={setStep6FeatureValue}
              step6RequestValue={step6RequestValue} setStep6RequestValue={setStep6RequestValue}
            />
          </TabPane>
          <TabPane key="7">
            <FeedbackStep7
              step1Value={step1Value} step7FeatureValue={step7FeatureValue} setStep7FeatureValue={setStep7FeatureValue}
            />
          </TabPane>
          <TabPane key="8">
            <FeedbackStep8 step1Value={step1Value} step8FeatureValue={step8FeatureValue} setStep8FeatureValue={setStep8FeatureValue} />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  )
}

export default Feedback