/* eslint-disable */
import React, {Component} from 'react';
import PropType from 'prop-types';
import {TextInput} from '../TextInput';
import style from './style.module.scss';
export default class MainSearch extends Component {
  render() {
    return (
      <div className={style.searchBox}>
        <TextInput
          className='search-input'
          placeholder='Search here ....'
          htmlType='Search'
          allowClear={false}
          onChange={this.props.onChange}
          value={this.props.value}
          onBlur={this.props.onPressEnter}
          onPressEnter={(e) => {
            if (e.which == 13 || e.keyCode == 13) {
              this.props.onPressEnter && this.props.onPressEnter(e);
            }
          }}
        />
        <span className={style.searchBtn}>
          <svg viewBox='0 0 512.006 512'>
            <path
              data-name='Path 460'
              d='M505.749,475.587l-145.6-145.6A201.683,201.683,0,0,0,405.333,202.67C405.333,90.926,314.41,0,202.666,0S0,90.925,0,202.669,90.923,405.336,202.667,405.336a201.683,201.683,0,0,0,127.317-45.184l145.6,145.6a21.33,21.33,0,1,0,30.165-30.165ZM202.667,362.669c-88.235,0-160-71.765-160-160s71.765-160,160-160,160,71.765,160,160S290.9,362.669,202.667,362.669Z'
              transform='translate(0 -0.003)'
            />
          </svg>
        </span>
        {this.props.value && (
          <span className={style.clearBtn} onClick={this.props.onClear}>
            <svg viewBox='0 0 10.462 10.462'>
              <path
                data-name='Path 270'
                d='M10.017,11.072,6.059,7.115,2.1,11.072a.746.746,0,1,1-1.055-1.055L5,6.059,1.047,2.1A.746.746,0,0,1,2.1,1.047L6.059,5l3.957-3.957A.746.746,0,1,1,11.072,2.1L7.115,6.059l3.957,3.957a.746.746,0,1,1-1.055,1.055Z'
                transform='translate(-0.829 -0.829)'
              />
            </svg>
          </span>
        )}
      </div>
    );
  }
}
MainSearch.Prototype = {
  className: PropType.string,
};
