import {
  LIST_GROUP_ORGANISATIONS_STARTED,
  LIST_GROUP_ORGANISATIONS_SUCCESS,
} from '../../Redux.constants';
import {
  getGroupOrganisationListStarted,
  getGroupOrganisationListSuccess,
} from './helper';

const INITIAL_STATE = {
  groupOrganizationList: [],
  limit: 11,
  organisationGroupListOffset: 0,
  totalOrganisationCount: 0,
  organisationGroupListSelectOptions: [],

  grouporganizationdata: {
    ispending: false,
    completed: false,
    failed: false,
    data: [],
  },

  isProcessing: null,
};
export default function GroupsOrganizationsReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case LIST_GROUP_ORGANISATIONS_STARTED:
      return getGroupOrganisationListStarted(state);
    case LIST_GROUP_ORGANISATIONS_SUCCESS:
      return getGroupOrganisationListSuccess(state, action.payload);
    default:
      return {...state};
  }
}
