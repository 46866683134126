import UserDetails from './UserDetails';
import ResetPassword from './ResetPassword';

export const NAV_TAB = [
  {
    label: 'Contact Info',
    key: 'contactInfo',
    component: UserDetails,
    title: 'Contact Info',
  },
  {
    label: 'Change Password',
    key: 'changePassword',
    component: ResetPassword,
    title: 'Change Password',
  },
];
