import React from 'react';
import 'antd/dist/antd.css';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import './main.css';

import {SMART_SEARCH} from '../config/constants';
import AnswerStorageContextProvider from './Components/new/Context/AnswerStorageContext';
import BreadCrumbContextProvider from './Components/new/Context/BreadCrumbContext';
import ComponentSwitchContextProvider from './Components/new/Context/ComponentSwitchContext';
import { LoadingProvider } from './Components/FullScreenLoader/LoadingContext';
import {ROUTES} from './Routes.constants';
import ContactUs from './Routes/ContactUs/ContactUs';
import MainFrame from './Routes/MainFrame';
import {MainSearch} from './Routes/MainSearch';
import SharePat from './Routes/SharePat/SharePat';
import WelcomePage from './Routes/WelcomePage';
import ReduxProvider from './configureRedux';

import './newStyle.css';
import reportWebVitals from './reportWebVitals';
import './style.scss';

import './i18n.js'
import TagSelect from 'Components/TagSelect';
import TagDirectory from 'Components/TagDirectory';
import TagFloatingInfo from 'Components/TagFloatingInfo';

ReactGA.initialize('G-80R5MDB8RW');

ReactDOM.render(
  <LoadingProvider>
    <ReduxProvider>
      <Router>
        <Switch>
          <Route path={ROUTES.LOGIN} component={WelcomePage} />
          <Route exact path='/'>
            <Redirect
              to={
                window.location.hostname == SMART_SEARCH ||
                window.location.hostname == 'www.' + SMART_SEARCH
                  ? ROUTES.SEARCH
                  : ROUTES.NETWORKS
              }
            />
          </Route>
          {/* Temporary redirect to Networks from old Dashboard */}
          {/* Redirect from /dashboard to /networks */}
          <Route path='/dashboard'>
            <Redirect to='/networks' />
          </Route>
          <Route path={'/test'} component={TagSelect} />
          <Route path={'/test1'} component={TagFloatingInfo} />
          <Route path={'/test2'} component={() => <TagDirectory visible={true} setVisible={() => {}} />} />

          <Route path={ROUTES.SEARCH} component={MainSearch} />
          <Route path={ROUTES.CONTACT} component={ContactUs} />
          <Route path={ROUTES.FORGOT_PASSWORD} component={WelcomePage} />
          <Route path={ROUTES.SHARED_PAT} component={SharePat} />
          <BreadCrumbContextProvider>
            <AnswerStorageContextProvider>
              <ComponentSwitchContextProvider>
                <Route path={ROUTES.INDEX} component={MainFrame} />
              </ComponentSwitchContextProvider>
            </AnswerStorageContextProvider>
          </BreadCrumbContextProvider>
        </Switch>
      </Router>
    </ReduxProvider>
  </LoadingProvider>
  ,
  document.getElementById('root')
);
reportWebVitals();
