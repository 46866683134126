export function onChangeMissionForm(state, payload) {
  return {
    ...state,
    ...handleFormChange(state, payload),
  };
}
const handleFormChange = (state, payload) => {
  switch (payload.step) {
    case 'step1':
      return handleStep1(state, payload);
    case 'step2':
      return handleStep2(state, payload);
    default:
      return {};
  }
};

const handleStep1 = (state, payload) => {
  const createMissionForm = JSON.parse(JSON.stringify(state.createMissionForm));
  createMissionForm.step1[payload.field] = payload.value;
  return {createMissionForm};
};

const handleStep2 = (state, payload) => {
  const createMissionForm = JSON.parse(JSON.stringify(state.createMissionForm));
  createMissionForm.step2.questions.push(payload.question);
  return {createMissionForm};
};

export function questQuestionSelected(state, payload) {
  let questSelectQuestion = JSON.parse(
    JSON.stringify(state.questSelectQuestion)
  );
  questSelectQuestion.questionId = payload.questionId;
  questSelectQuestion.questionContent = payload.questionContent;
  return {...state, questSelectQuestion: questSelectQuestion};
}

export const questAnswerUpdate = (state, payload) => {
  let questAnswer = JSON.parse(JSON.stringify(state.questSelectQuestion));
};
