import axios from "axios";
import { API_BASE_URL } from "../../config/constants";

const headers = () => {
    return {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('idToken')}`,
        }
    }
}

export function addMemberToEntity(data) {
    return {
        call: axios.post(`${API_BASE_URL}users/add_to_entity/`, data, headers())
    }
}

export function updatedMemberToEntity(data) {
    return {
        call: axios.patch(`${API_BASE_URL}users/add_to_entity/`, data, headers())
    }
}


export function getMemberToEntity(typeEntity, id) {
    const controller = new AbortController();
    return {
        call: axios.get(`${API_BASE_URL}entity-members/?entity_name=${typeEntity}&entity_id=${id}`, {
            signal: controller.signal,
            ...headers()
        }),
        controller
    }
}

export function deleteMemberToEntity(typeEntity, id) {
    const controller = new AbortController();
    return {
        call: axios.delete(`${API_BASE_URL}entity-members/?entity_name=${typeEntity}&member_id=${id}`, {
            signal: controller.signal,
            ...headers()
        }),
        controller
    }
}