import {
  PATS_PER_PRACTICE_COLORS,
  PHASE_OVERVIEW_COLORS,
} from 'Routes/Dashboard/Dashboard';
import {Tooltip} from 'antd';
import React from 'react';

interface Phase {
  name: string;
  count: number;
}

interface ProgressBarProps {
  phases: Phase[];
  progressType: 'PATPerPractice' | 'PhaseOverview' | 'MilestoneOverview';
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  phases,
  progressType,
}) => {
  const totalPractices = phases.reduce(
    (total, phase) => total + phase.count,
    0
  );

  return (
    <div className='flex'>
      {phases.map((phase, index) => {
        const backgroundColor =
          progressType === 'PhaseOverview'
            ? PHASE_OVERVIEW_COLORS[index]
            : PATS_PER_PRACTICE_COLORS[index];

        return (
          <Tooltip
            title={`${phase.count} (${(
              (phase.count / totalPractices) *
              100
            ).toFixed(2)}%)`}
            key={index}>
            <div
              className={`flex items-center justify-center text-center ${
                progressType === 'PATPerPractice'
                  ? 'text-white'
                  : 'text-transparent'
              } ${backgroundColor} ${
                progressType === 'PhaseOverview'
                  ? 'h-[15px]'
                  : progressType === 'PATPerPractice'
                  ? 'h-[30px]'
                  : 'h-[20px]'
              } ${
                index === 0
                  ? 'rounded-l-md'
                  : index === phases.length - 1
                  ? 'rounded-r-md'
                  : ''
              } ${
                progressType === 'MilestoneOverview' ||
                index === phases.length - 1
                  ? ''
                  : 'mr-[1px]'
              } pl-5 text-xs`}
              style={{width: `${(phase.count / totalPractices) * 100}%`}}></div>
          </Tooltip>
        );
      })}
    </div>
  );
};
