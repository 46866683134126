import React from 'react';
import { Tag } from 'antd';
import './styles/TagStatus.scss';

/**
 * Renders a Tag component with a specific color based on the value of the `tag` prop.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.tag - The tag value.
 * @return {JSX.Element} A JSX element representing the Tag component.
 */
const TagStatus = ({ tag }) => {
  return <Tag color={
    tag === "CREATED" ? "#FFF0F6" : "" || tag === "PLANNING" ? "#E6EEF7" : "" || tag === "IN_ACTION" ? "#E0FFE7" : "" || tag === "CLOSED" ? "#EFEFEF" : ""
  }>
    <span className="text-black">{tag}</span>
  </Tag>;
};

export default TagStatus;
