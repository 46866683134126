import React from 'react';

interface IconProps {
  name: string;
  className?: string;
}

export const Icon: React.FC<IconProps> = ({name, className}) => {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/icons/${name}.svg`}
      alt={`Icon ${name}`}
      className={className}
    />
  );
};
