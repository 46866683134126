import React, { useState, useEffect } from 'react';
import { Alert, Modal } from "antd";
import { getArchetypesData } from '../../Routes/OrganisationInsights/Children/ViewDetails/components/Team/models';
import './styles/ArchetypeModal.scss';

/**
 * Renders the ArchetypeModal component.
 *
 * @param {function} closeModal - A function to close the modal.
 * @param {boolean} visibleModal - A boolean indicating whether the modal is visible.
 * @param {object} selectPersona - An object representing the selected persona.
 * @return {JSX.Element} The rendered ArchetypeModal component.
 */
const ArchetypeModal = ({ closeModal, visibleModal, selectPersona }) => {
  const [archetype, setArchetype] = useState([]);

  /**
   * Loads the archetypes asynchronously.
   *
   * @return {Promise<void>} A promise that resolves when the archetypes are loaded.
   */
  const loadArchetypes = async () => {
    if (!selectPersona?.archetype) return setArchetype([]);
    const archetypes = await getArchetypesData();
    archetypes.map((item) => {
      if (selectPersona?.archetype === item.id) setArchetype(item);
      return item;
    });
  }

  /**
   * Renders a list with a title.
   *
   * @param {Array} list - The list to render.
   * @param {string} title - The title of the list.
   * @return {JSX.Element} The rendered list component.
   */
  const renderList = (list, title) => {
    if (!list?.length) return <></>;
    return (<>
      <p className="text-blue">{title}</p>
      <ul>
        {
          list?.map((item, index) => <li key={index} type="square">• {item}</li>)
        }
      </ul>
    </>)
  }

  useEffect(() => {
    loadArchetypes();
  }, [selectPersona?.archetype])

  return (
    <div className='archetypemodal'>
      <Modal
        visible={visibleModal}
        onCancel={closeModal}
        footer={false}
        className="mission-modal"
        bodyStyle={{
          width: '100%',
          padding: 0
        }}
        centered
        closeIcon={<div className='modal-close-icon'>x</div>}
      >
        <div className="team-modal-name-container">
          <img className="team-modal-avatar" src={selectPersona?.image || '/img/avatar.png'} alt="" />
          <div>
            <p className="text-white">{selectPersona?.name}</p>
            <p className="text-white">{archetype?.name}</p>
          </div>
        </div>
        <div className="p-15">
          {archetype?.description ?
            <>
              {renderList(archetype?.description, 'Description')}
              {renderList(archetype?.typical_influencers, 'Typical Influencers')}
              {renderList(archetype?.approaches, 'Approaches')}
              {renderList(archetype?.ways_to_present, 'Ways to Present Data')}
            </>
            :
            <>
              <Alert message="No archetype has been assigned to this team member." type="warning" showIcon />
            </>
          }
        </div>
        <div className="my-15 text-center">
          <button className="staff-card-btn" onClick={closeModal}>Close</button>
        </div>
      </Modal>
    </div >
  );
};

export default ArchetypeModal;
