/* eslint-disable */
import {Checkbox} from 'antd';
import React, {Component} from 'react';
import SearchBar from '../SearchBox';
import style from './style.module.scss';
export default class SearchArea extends Component {
  render() {
    let {offset = 0, size = 0, total = 0} = this.props;
    let start = offset;
    let end = offset + size;
    return (
      <>
        <div className={style.searchContainer}>
          <div className={style.searchContainer__input}>
            <div className={style.itemSearch}>
              {!this.props.hideSearchArea && <SearchBar props={this.props} />}
            </div>
          </div>
          {!!this.props.archiveOnChange && (
            <div className={style.searchContainer__controls}>
              <Checkbox
                onChange={this.props.archiveOnChange}
                checked={this.props.viewArchives}>
                View Archives Only
              </Checkbox>
              {/* <Checkbox onChange={this.props.favouriteOnChange}>
            Exclude Favourite
          </Checkbox> */}
            </div>
          )}
        </div>
      </>
    );
  }
}
