import React, { useEffect, useRef, useState } from 'react';
import { BsCloudDownload } from 'react-icons/bs';
import { MdDeleteForever } from 'react-icons/md';
import { UploadOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import moment from 'moment-timezone';

import {
  deleteFile,
  getFiles,
  uploadCommentsFiles,
} from '../../../../Services/Meetings.service';

import './styles/FilesTable.scss';

/**
 * Renders a table of files with upload and download functionality.
 *
 * @param {string} id - The ID of the table.
 * @param {string} urlFiles - The URL to fetch the files.
 * @param {string} postUrlFile - The URL to post the file.
 * @param {string} field - The field to post the file.
 * @param {boolean} asDrop - Whether to show the file upload as a drop area.
 * @param {Array} customFields - Custom fields for the file table.
 * @param {string} getSufix - The suffix for the URL to fetch the files.
 * @param {string} dragDropTitle - The title to display in the drop area.
 * @param {boolean} multiple - Whether to allow multiple file uploads.
 * @param {Function} getFileCallback - The callback function to get the file.
 * @return {JSX.Element} The rendered file table.
 */
const FilesTable = ({
  id,
  urlFiles,
  postUrlFile,
  field,
  asDrop,
  customFields,
  getSufix,
  dragDropTitle,
  multiple,
  getFileCallback,
}) => {
  const uploadRef = useRef();
  const [files, setFiles] = useState([]);

  const uploadFileFetch = async (file) => {
    if (getFileCallback) {
      getFileCallback(file)
      return
    }
    try {
      const fileRes = await uploadCommentsFiles(id, file, postUrlFile, field)
        .call;
      setFiles([{ ...fileRes.data }, ...files]);
      if (uploadRef?.current) uploadRef.current.value = null;
    } catch (error) { }
  };

  const getFilesFetch = async () => {
    try {
      const res = await getFiles(id, `${urlFiles}${getSufix || ''}`).call;
      setFiles(res.data.results);
    } catch (error) { }
  };

  const deleteFilesFetch = async (fileId) => {
    try {
      await deleteFile(fileId, postUrlFile).call;
    } catch (error) { }
    setFiles(files.filter((item) => item.id != fileId));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    uploadFileFetch(file);
    handleDragLeave(event);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.target?.classList.add('drag-over');
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.target?.classList.remove('drag-over');
  };

  useEffect(() => {
    if (id) {
      getFilesFetch();
    }
  }, [id]);

  return (
    <>
      <Col span={24} className='mb-15 filestable'>
        <div className='right-pane__header'>
          <input
            ref={(e) => (uploadRef.current = e)}
            type='file'
            style={{ display: 'none' }}
            onChange={(e) => uploadFileFetch(e.target.files[0])}
            onDragEnd={(e) => uploadFileFetch(e.target.files[0])}
          />
          {asDrop ? (
            <>
              <div
                className='dragable'
                onClick={() => uploadRef.current.click()}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}>
                <UploadOutlined />
                <p>{dragDropTitle || "Click or drag file to this area to upload your scorecard"}</p>
              </div>
              {multiple && <p className="text-black mt-10">Updates</p>}
            </>
          ) : (
            <>
              <h3 className='flex items-center'>
                Documents{' '}
                <span
                  className='close-icon-file mr-5 text-blue'
                  onClick={() => uploadRef.current.click()}>
                  +
                </span>
              </h3>
              <p>Files must be 1MB or less in size.</p>
            </>
          )}
        </div>
      </Col>

      {multiple && <>
        <Row span={24} className="mb-10 bg-gray-prep">
          <Col span={customFields ? (24 / (customFields.length + 1)) : 12}>
            <p className="text-black">File Name</p>
          </Col>
          {customFields ?
            <>
              {customFields?.map((item, index) =>
                <Col span={24 / (customFields.length + 1)} key={index}>
                  <p className="text-black">{item.label}</p>
                </Col>
              )}
            </>
            : <>
              <Col span={12}>
                <p className="text-black">Actions</p>
              </Col>
            </>}
        </Row>
        <div className='files-container'>
          {
            files.map((file, index) =>
              <Row span={24} className="mb-10" key={index}>
                <Col span={customFields ? (24 / (customFields.length + 1)) : 12}>
                  <a className="ml-5" href={file.file} target="_blank" rel="noopener noreferrer" download>
                    {file?.filename}
                  </a>
                </Col>
                {customFields ?
                  <>
                    {customFields?.map((item, index) => {
                      const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}$/;
                      let field = file?.[item.field] || ''
                      if (regex.test(field)) field = moment(field).format('MM/DD/YY hh:mm A TZ')
                      return (
                        <Col span={24 / (customFields.length + 1)} key={index}>
                          {field}
                        </Col>
                      )
                    }
                    )}
                  </>
                  : <>
                    <Col span={12}>
                      <a href={file.file} target="_blank" rel="noopener noreferrer" download>
                        <BsCloudDownload
                          className='pointer mx-15'
                          size={'1.2em'}
                        />
                      </a>
                      <MdDeleteForever
                        className='pointer'
                        size='1.2rem'
                        onClick={() => deleteFilesFetch(file.id)}
                      />
                    </Col>
                  </>}
              </Row>
            )
          }
          {!files?.length &&
            <Row span={24} className="mb-10">
              <Col span={24}>
                <p className="text-black text-center">No files uploaded yet.</p>
              </Col>
            </Row>
          }
        </div>
      </>}
      {!multiple &&
        <>
          <div className='files-container'>
            {
              files.map((file, index) =>
                <Row span={24} className="mb-10" key={index}>
                  <Col span={24}>
                    <a className="ml-5" href={file.file} target="_blank" rel="noopener noreferrer" download>
                      {file?.filename}
                    </a>
                  </Col>
                </Row>
              )
            }
          </div>
        </>
      }
    </>
  );
};

export default FilesTable;
