export function getAccountInsightsStart(state) {
  return {
    ...state,
    getAccountInsightsApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function getAccountInsightsSuccess(state, payload) {
  return {
    ...state,
    getAccountInsightsApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
    accountInsights: payload.data ? payload.data.results : [],
    accountInsightCount: payload.data.count,
  };
}
export function getAccountInsightsFailed(state, payload) {
  return {
    ...state,
    getAccountInsightsApi: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching list',
    },
  };
}

export function addAccountInsightsStart(state) {
  return {
    ...state,
    addAccountInsightsApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function addAccountInsightsSuccess(state, payload) {
  return {
    ...state,
    addAccountInsightsApi: {
      isProcessing: false,
      success: true,
      error: false,
    },
  };
}
export function addAccountInsightsFailed(state, payload) {
  return {
    ...state,
    addAccountInsightsApi: {
      isProcessing: false,
      success: false,
      error: 'Error in adding team',
    },
  };
}

//Departments

export function getDepartmentsStart(state) {
  return {
    ...state,
    getDepartmentsApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function getDepartmentsSuccess(state, payload) {
  return {
    ...state,
    getDepartmentsApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
    departmentList: payload.data ? payload.data.results : [],
    departmentListCount: payload.data.count,
  };
}
export function getDepartmentsFailed(state, payload) {
  return {
    ...state,
    getDepartmentsApi: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching list',
    },
  };
}

export function addDepartmentsStart(state) {
  return {
    ...state,
    addDepartmentApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function addDepartmentsSuccess(state, payload) {
  return {
    ...state,
    addDepartmentApi: {
      isProcessing: false,
      success: true,
      error: false,
    },
  };
}
export function addDepartmentsFailed(state, payload) {
  return {
    ...state,
    addDepartmentApi: {
      isProcessing: false,
      success: false,
      error: 'Error in adding departments',
    },
  };
}

//Designations

export function getDesignationsStart(state) {
  return {
    ...state,
    getDesignationsApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function getDesignationsSuccess(state, payload) {
  return {
    ...state,
    getDesignationsApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
    designationList: payload.data ? payload.data.results : [],
    designationListCount: payload.data.count,
  };
}
export function getDesignationsFailed(state, payload) {
  return {
    ...state,
    getDesignationsApi: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching list',
    },
  };
}

export function addDesignationStart(state) {
  return {
    ...state,
    addDesignationApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function addDesignationSuccess(state, payload) {
  return {
    ...state,
    addDesignationApi: {
      isProcessing: false,
      success: true,
      error: false,
    },
  };
}
export function addDesignationFailed(state, payload) {
  return {
    ...state,
    addDesignationApi: {
      isProcessing: false,
      success: false,
      error: 'Error in adding designation',
    },
  };
}
