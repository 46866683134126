export * from './TeamInfo';
export function updateMissionDetailsStarted(state) {
  return {
    ...state,
    updateMissionDetailsApi: {
      isProcessing: true,
      success: false,
      error: false,
      data: null,
    },
  };
}
export function updateMissionDetailsSuccess(state, payload) {
  return {
    ...state,
    updateMissionDetailsApi: {
      isProcessing: false,
      success: true,
      error: false,
      data: payload.data,
    },
    objectiveList: payload.objectives || state.objectiveList,
  };
}
export function updateMissionDetailsFailed(state, payload) {
  return {
    ...state,
    updateMissionDetailsApi: {
      isProcessing: false,
      success: false,
      error: payload.error,
      data: null,
    },
  };
}

export function getMissionDetailsStarted(state) {
  return {
    ...state,
    getMissionDetailsApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}

export function getMissionDetailsSuccess(state, payload) {
  let objectives = undefined;
  let objectivesPatchId = null;
  let overview = null;
  if (payload.data?.overview) {
    objectives = payload.data.overview.objectives || [];
    objectivesPatchId = payload.data.overview.id;
    overview = payload.data.overview;
  }
  return {
    ...state,
    getMissionDetailsApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
    viewMissionDetails: {
      // ...state.viewMissionDetails,
      ...payload.data,
    },
    objectiveList: objectives,
    objectiveListPatchId: objectivesPatchId,
    missionOverviewData: overview,
    // objectiveList: objectives || state.objectiveList,
    // objectiveListPatchId: objectivesPatchId || state.objectiveListPatchId,
    // missionOverviewData: overview || state.missionOverviewData,
  };
}
export function getMissionDetailsFailed(state, payload) {
  return {
    ...state,
    getMissionDetailsApi: {
      isProcessing: false,
      success: null,
      error: payload.error,
    },
  };
}
export function evidenceSearchStarted(state) {
  return {
    ...state,
    evidenceSearchApi: {
      isProcessing: true,
      error: null,
    },
    evidenceSearchResult: [],
  };
}

export function evidenceSearchSuccess(state, payload) {
  return {
    ...state,
    evidenceSearchApi: {
      isProcessing: false,
      error: null,
    },
    evidenceSearchResult: payload.data,
  };
}
export function evidenceSearchFailed(state, payload) {
  return {
    ...state,
    evidenceSearchApi: {
      isProcessing: false,
      error: true,
    },
  };
}
