import {
  ADD_NOTES_FAILED,
  ADD_NOTES_STARTED,
  ADD_NOTES_SUCCESS,
  GET_NOTES_FAILED,
  GET_NOTES_STARTED,
  GET_NOTES_SUCCESS,
} from '../../Redux.constants';
import {
  getNotesFailed,
  getNotesStart,
  getNotesSuccess,
  addNotesFailed,
  addNotesStart,
  addNotesSuccess,
} from './Helper';

const INITIAL_STATE = {
  getNotesApi: {
    isProcessing: null,
    success: null,
    error: null,
  },
  addNotesApi: {
    isProcessing: null,
    success: null,
    error: null,
  },
  notesList: [],
  totalNotesCount: null,
};

export default function AddNotesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_NOTES_STARTED:
      return getNotesStart(state);
    case GET_NOTES_SUCCESS:
      return getNotesSuccess(state, action.payload);
    case GET_NOTES_FAILED:
      return getNotesFailed(state, action.payload);
    case ADD_NOTES_STARTED:
      return addNotesStart(state);
    case ADD_NOTES_SUCCESS:
      return addNotesSuccess(state, action.payload);
    case ADD_NOTES_FAILED:
      return addNotesFailed(state, action.payload);
    default:
      return {...state};
  }
}
