/* eslint-disable */
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from '../../../../ReduxV2/store';
import { Mission } from './components/Mission';

const ViewDetails = () => {

  return (
    <Provider store={store}>
      <Mission />
    </Provider>
  )
}

export default ViewDetails;