/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: replace any's with correct Types
// Create a new file, e.g., useDashboard.ts
import useFetchWithLoading from 'Hooks/useFetchWithLoading';
import {
  getMilestonOverview,
  getNetworks,
  getPatsPerNetwork,
  getOrgOwners,
  getMarkets,
} from 'Services/AdminDashboard.service';
import moment from 'moment';
import {useEffect, useState} from 'react';
import { Owner } from 'types/Owner';
import { calculateStartDateEndDateBasedOnTimeRange } from 'utils/timeUtils';

export const useDashboard = () => {
  const [patsData, setPatsData] = useState([
    {title: 'PATS completed', total: 0},
    {title: 'PATS in-progress', total: 0},
    {title: 'PATS not started', total: 0},
    {title: 'Open projects', total: 0},
  ]);

  const defaultTimeFrameOptions = [
    {value: 'Last Month', label: 'Last Month'},
    {value: 'Last 3 Months', label: 'Last 3 Months'},
    {value: 'Last 6 Months', label: 'Last 6 Months'},
    {value: 'Last Year', label: 'Last Year'},
    {value: 'All Time', label: 'All Time'},
  ];

  const [timeFrameOptions, setTimeFrameOptions] = useState([...defaultTimeFrameOptions, {value: 'Custom Date', label: 'Custom Date'}]);

  const [showCustomDate, setShowCustomDate] = useState<boolean>(false);
  const [pats, setPats] = useState<any>([]);
  const [phases, setPhases] = useState<any>([]);
  const [milestoneOverview, setMilestoneOverview] = useState<any>([]);
  const [selectedTimeRange, setSelectedTimeRange] = useState<string | null>(
    null
  );
  const [networks, setNetworks] = useState<any>([]);
  const [owners, setOwners] = useState<any>([]);
  const [selectedOwners, setSelectedOwners] = useState<any>(undefined);
  const [markets, setMarkets] = useState<any>([]);
  const [selectedMarkets, setSelectedMarkets] = useState<any>(undefined);
  const [selectedNetwork, setSelectedNetwork] = useState<number | null>(null);
  const [totalPats, setTotalPats] = useState<number>();
  const [totalNetworks, setTotalNetworks] = useState<number>();

  useEffect(() => {
    if(showCustomDate == true) {
      const [from, to] = selectedTimeRange?.split(',') ?? [moment().format('YYYY-MM-DDT00:00:00'), moment().format('YYYY-MM-DDT00:00:00')]
      setTimeFrameOptions(([...defaultTimeFrameOptions, {value: 'Custom Date', label: `${moment(from).format('YYYY/MM/DD')} to ${moment(to).format('YYYY/MM/DD')}`}]))
    }
  }, [showCustomDate])

  // Function to set the selected time range
  const selectTimeRange = (timeRange: string) => {
    if(timeRange === 'Custom Date'){
      setShowCustomDate(true);

      return;
    }
    const range = calculateStartDateEndDateBasedOnTimeRange(timeRange);
    setTimeFrameOptions(([...defaultTimeFrameOptions, {value: 'Custom Date', label: 'Custom Date'}]))
    setSelectedTimeRange(range);
  };

  const selectNetwork = (network: any) => {
    setSelectedNetwork(network);
  };

  const selectOwners = (owners: any) => {
    setSelectedOwners(owners);
  };

  const selectMarkets = (owners: any) => {
    setSelectedMarkets(owners);
  };

  const { data: patsPerNetworkData} = useFetchWithLoading<any>({
    apiFunction:getPatsPerNetwork,
    params: [{
      timeRange: selectedTimeRange,
      network: selectedNetwork,
      owners: selectedOwners,
      markets: selectedMarkets,
    }],
    triggers: [selectedTimeRange, selectedNetwork, selectedOwners, selectedMarkets],
  });
  
  const { data: milestoneOverviewData } = useFetchWithLoading<any>({
    apiFunction:getMilestonOverview,
    params: [{
      timeRange: selectedTimeRange,
      network: selectedNetwork,
      owners: selectedOwners,
      markets: selectedMarkets,
    }],
    triggers: [selectedTimeRange, selectedNetwork, selectedOwners, selectedMarkets],
  });

  const { data: networkData } = useFetchWithLoading<any>({
    apiFunction:getNetworks,
    params: [],
    triggers:[],
  });

  const { data: orgOwnersData } = useFetchWithLoading<any>({
    apiFunction:getOrgOwners,
    params: [],
    triggers:[],
  });

  const { data: marketsData } = useFetchWithLoading<any>({
    apiFunction:getMarkets,
    params: [],
    triggers:[],
  });

  useEffect(() => {
    if(patsPerNetworkData) {
      // Handle the successful patsPerNetworkData
      setTotalPats(patsPerNetworkData.data.total_pats);
      setTotalNetworks(patsPerNetworkData.data.total_networks);
      setPatsData([
        {title: 'PATS completed', total: patsPerNetworkData.data.pats_completed || 0},
        {
          title: 'PATS in-progress',
          total: patsPerNetworkData.data.pats_in_progress || 0,
        },
        {
          title: 'PATS not started',
          total: patsPerNetworkData.data.pats_not_started || 0,
        },
        {title: 'Open projects', total: patsPerNetworkData.data.open_projects || 0},
      ]);

      // Transform networks_data into the desired format
      const transformedPats = patsPerNetworkData.data.networks_data.map(
        (item: any) => ({
          name: `pats_qty_${item.pats_qty}`, // Adjust the name as needed
          count: parseInt(item.networks, 10), // Assuming count should be an integer
        })
      );

      setPats(transformedPats);

      // Transform phase_overview into the desired format
      const transformedPhases = patsPerNetworkData.data.phase_overview.map(
        (item: any) => ({
          name: `Phase ${item.phase}`, // Adjust the name as needed
          count: parseInt(item.info.count, 10),
        })
      );
      setPhases(transformedPhases);
    }
  }, [patsPerNetworkData]);

  useEffect(() => {
    if(milestoneOverviewData){
      setMilestoneOverview(milestoneOverviewData?.data);
    }

  }, [milestoneOverviewData]);

  useEffect(() => {
    if(networkData) {
        const networkLabels = [
          ...networkData.data.map(({id, name}: {id: number; name: string}) => ({
            value: id,
            label: name,
          })),
        ];

        setNetworks(networkLabels);
    }
  }, [networkData]);

  useEffect(() => {
    if(orgOwnersData) {
      const ownersOptions = [
          ...orgOwnersData.data.map((owner: Owner) => ({
            value: owner.id,
            label: `${owner.last_name} ${owner.first_name} `,
          })),
        ]

        setOwners(ownersOptions);
    }
  }, [orgOwnersData])

  useEffect(() => {
    if(marketsData) {
        const marketOptions = [
          ...marketsData.data.map(({market}: {market: string}) => ({
            value: market,
            label: market,
          })),
        ];

        setMarkets(marketOptions);
    }
  }, [marketsData]);


  return {
    patsData,
    pats,
    phases,
    milestoneOverview,
    TimeFrameOptions:timeFrameOptions,
    selectTimeRange,
    selectNetwork,
    networks,
    totalPats,
    totalNetworks,
    selectOwners,
    owners,
    selectMarkets,
    markets,
    showCustomDate,
    setShowCustomDate,
    selectedTimeRange,
    setSelectedTimeRange,
  };
};
