import axios, { AxiosResponse } from "axios";
import { API_BASE_URL } from "../../config/constants";
import { AbortableRequestConfig } from 'types/Request';
import { MissionTeam, PaginatedMissionTeam, PostPatchMissionTeam } from "types/MissionTeam";

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    }
  }
}

export function getMissionStaff(id: number): {
  call: Promise<AxiosResponse<PaginatedMissionTeam>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}mission-team-staff/?mission=${id}`, {
      signal: controller.signal,
      ...headers()
    } as AbortableRequestConfig),
    controller
  }
}

export function getMissionStaffById(id: number):{
  call: Promise<AxiosResponse<MissionTeam>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}mission-team-staff/${id}/`, {
      signal: controller.signal,
      ...headers()
    } as AbortableRequestConfig),
    controller
  }
}

export function postMissionStaff(data: any):{
  call: Promise<AxiosResponse<MissionTeam>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}mission-team-staff/`, data, headers())
  }
}

export function patchMissionStaff(data: any): {
  call: Promise<AxiosResponse<MissionTeam>>;
} {
  return {
    call: axios.patch(`${API_BASE_URL}mission-team-staff/${data?.id}/`, data, headers())
  }
}

export function deleteMissionStaff(id: number): {
  call: Promise<AxiosResponse<void>>;
} {
  return {
    call: axios.delete(`${API_BASE_URL}mission-team-staff/${id}/`, {
      ...headers()
    } as AbortableRequestConfig),
  }
}

export function getMissionEngaged(id: number): {
  call: Promise<AxiosResponse<PaginatedMissionTeam>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}mission-engaged-teams/?mission=${id}`, {
      signal: controller.signal,
      ...headers()
    } as AbortableRequestConfig),
    controller
  }
}

export function getMissionEngagedById(id: number): {
  call: Promise<AxiosResponse<MissionTeam>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}mission-engaged-teams/${id}/`, {
      signal: controller.signal,
      ...headers()
    } as AbortableRequestConfig),
    controller
  }
}

export function postMissionEngagged(data: PostPatchMissionTeam): {
  call: Promise<AxiosResponse<MissionTeam>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}mission-engaged-teams/`, data, headers())
  }
}

export function patchMissionEngagged(data: PostPatchMissionTeam): {
  call: Promise<AxiosResponse<MissionTeam>>;
} {
  return {
    call: axios.patch(`${API_BASE_URL}mission-engaged-teams/${data.id}/`, data, headers())
  }
}

export function deleteMissionEngagged(id: number): {
  call: Promise<AxiosResponse<void>>;
} {
  return {
    call: axios.delete(`${API_BASE_URL}mission-engaged-teams/${id}/`, {
      ...headers()
    }),
  }
}