import axios from 'apiConfig';
import {API_BASE_URL} from 'config/constants';

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    },
  };
};

export function getPFTList(id, page, perPage, title__icontains) {
  const controller = new AbortController();

  const paramObj = {
    organization: id,
    page,
    page_size: perPage,
    title__icontains,
  };

  //remove undefined params
  Object.keys(paramObj).forEach(
    (key) => paramObj[key] === undefined && delete paramObj[key]
  );
  const params = new URLSearchParams(paramObj);

  return {
    call: axios.get(`${API_BASE_URL}pfts/?${params.toString()}`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  };
}

export function deletePFTList(id) {
  const controller = new AbortController();

  return {
    call: axios.delete(`${API_BASE_URL}pfts/${id}/`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  };
}

export function postPFT(data) {
  return {
    call: axios.post(`${API_BASE_URL}pfts/`, data, headers()),
  };
}

export function patchPFT(data) {
  return {
    call: axios.patch(`${API_BASE_URL}pfts/${data.id}/`, data, headers()),
  };
}

export async function saveGoals(planId, {goals}) {
  const goalsPost = await Promise.all(
    goals.map(async (item) => {
      let linkString = '';
      if (item.links) {
        linkString = item.links.links.join(', ');
      }
      const resGoal = axios
        .post(
          `${API_BASE_URL}pfts-goals/`,
          {
            pft: planId,
            title: item.title,
            goal_type: item.goal_type,
            current_state: item.current_state,
            target: item.target,
            trend: item.trend,
            links: linkString,
          },
          headers()
        )
        .then((res) => {
          const id = res.data.id;
          item.dataSources.files.forEach((file) => {
            const formData = new FormData();
            formData.append('file', file.originFileObj);
            formData.append('goal', id);
            uploadFile(formData)
              .then((response) => {
                if (response.status === 200) {
                  console.log(`${file.name} file uploaded successfully`);
                }
              })
              .catch((error) => {
                console.error(
                  `${file.name} file upload failed: ${error.message}`
                );
              });
          });
        });
      // .then((res) => {
      //   const resDataSource = axios
      //     .post(
      //       `${API_BASE_URL}pfts-data-sources/`,
      //       {
      //         pft_goal: res?.data?.id,
      //         links: item.dataSources.links.join(),
      //       },
      //       headers()
      //     )
      //     .then(async (resData) => {
      //       const resDataSourceFiles = item.dataSources.files.map(
      //         async (file) => {
      //           const formData = new FormData();
      //           formData.append('file', file.originFileObj);

      //           const res = await pftDataSourceFiles(
      //             resData.data.id,
      //             formData
      //           ).call;
      //         }
      //       );

      //       await Promise.all(resDataSourceFiles);
      //     });
      // });
    })
  );
}

export async function saveSingleGoal(planId, goal) {
  let linkString = '';
  if (goal.links) {
    linkString = goal.links.links.join(', ');
  }
  const resGoal = axios
    .post(
      `${API_BASE_URL}pfts-goals/`,
      {
        pft: planId,
        title: goal.title,
        goal_type: goal.goal_type,
        current_state: goal.current_state,
        target: goal.target,
        trend: goal.trend,
        links: linkString,
      },
      headers()
    )
    .then((res) => {
      const id = res.data.id;
      goal.dataSources.files.forEach((file) => {
        const formData = new FormData();
        formData.append('file', file.originFileObj);
        formData.append('goal', id);
        uploadFile(formData)
          .then((response) => {
            if (response.status === 200) {
              console.log(`${file.name} file uploaded successfully`);
            }
          })
          .catch((error) => {
            console.error(`${file.name} file upload failed: ${error.message}`);
          });
      });
    });
}

export function pftGoals(data) {
  return {
    call: axios.post(`${API_BASE_URL}pfts-data-sources/`, data, headers()),
  };
}

export function pftDataSourceLinks(data) {
  return {
    call: axios.post(`${API_BASE_URL}pfts-data-sources/`, data, headers()),
  };
}

export function pftDataSourceFiles(id, data) {
  return {
    call: axios.post(
      `${API_BASE_URL}pfts-data-sources/${id}/files/`,
      data,
      headers()
    ),
  };
}

export function getPft(id) {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}pfts/${id}/`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  };
}

export function getPftGoals(id) {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}pfts-goals/?pft=${id}`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  };
}
// export function getPftGoalsDataSource(id) {
//   console.log('getPftGoalsDataSource was hit');
//   const controller = new AbortController();

//   return {
//     call: axios.get(`${API_BASE_URL}pfts-data-sources/?pft_goal=${id}`, {
//       signal: controller.signal,
//       ...headers(),
//     }),
//     controller,
//   };
// }

export function getPftGoalsDataSource(id) {
  const controller = new AbortController();

  const request = axios.get(`${API_BASE_URL}goal-files/?goal=${id}`, {
    signal: controller.signal,
    ...headers(),
  });

  return request.then((response) => {
    // console.log('Response:', response);
    return {
      data: response.data,
      controller,
    };
  });
}

export async function patchPlan(data) {
  const visionRequest = axios.patch(
    `${API_BASE_URL}pfts/${data.id}/`,
    {
      title: data.title,
      created: data.created,
      practice_vision: data.practice_vision,
      client_type: data.client_type,
      areas_work_on: data.areas_work_on,
      practice_alignment: data.practice_alignment,
      quality: data.quality,
      practice_priorities: data.practice_priorities,
      strategic_goals: data.strategic_goals,
      status: data.status,
      title: data.title
    },
    headers()
  );

  const res = await Promise.all([visionRequest]);

  return res[0];
}

export async function patchSaveGoal(goal) {
  let linkString = '';
  if (goal.links) {
    linkString = goal.links.links.join(', ');
  }
  const goalPatch = await axios.patch(
    `${API_BASE_URL}pfts-goals/${goal.id}/`,
    {
      title: goal.title,
      target: goal.target,
      current_state: goal.current_state,
      trend: goal.trend,
      data_pulled_at: goal.data_pulled_at,
      links: linkString,
    },
    headers()
  );

  // const resDataSource = await axios.patch(
  //   `${API_BASE_URL}pfts-data-sources/${goal.dataSources.dataSourceId}/`,
  //   {
  //     title: goal.data_title,
  //     links: goal.dataSources.links.join(),
  //   },
  //   headers()
  // );

  // const uptateFilesToDataSource = goal.dataSources.files.map(async (file) => {
  //   if (!file.hasOwnProperty('id')) {
  //     const formData = new FormData();
  //     formData.append('file', file.originFileObj);

  //     const res = await pftDataSourceFiles(
  //       goal.dataSources.dataSourceId,
  //       formData
  //     ).call;
  //   }
  // });

  // await Promise.all(uptateFilesToDataSource);
}

export function deleteFileFromGoal(id, fileId) {
  return {
    call: axios.delete(`${API_BASE_URL}pfts-data-sources/${id}/files/`, {
      ...headers(),
      data: {
        file: fileId,
      },
    }),
  };
}

export function deleteGoalApi(id) {
  return {
    call: axios.delete(`${API_BASE_URL}pfts-goals/${id}/`, headers()),
  };
}

// Uploads a file
export function uploadFile(data) {
  // Return the Axios POST promise directly
  return axios.post(`${API_BASE_URL}goal-files/`, data, headers());
}

// Delete a file
export function deleteFile(id) {
  // Return the Axios POST promise directly
  return axios.delete(`${API_BASE_URL}goal-files/${id}/`, headers());
}
