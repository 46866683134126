import React, { createRef, useEffect, useState } from 'react';
import {
  DownloadOutlined,
  DeleteOutlined,
  PlusSquareFilled,
  SearchOutlined,
} from '@ant-design/icons';
import { Input, Table, Tabs, Tag } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { deleteMeeting, getMeetings, getPastMeetings } from '../../../Services/Meetings.service';
import { ClearLocalStorage } from '../../../utils/localStorageOperations';
import MeetingsReflections from '../../MeetingsReflections/MeetingsReflections';
import { CreateMeeting, PdfModal } from './Components';
import { getNotification } from '../../../Components/GetNotification';
import DeleteConfirmationModal from '../../../Components/DeleteConfirmationModal/DeleteConfirmationModal';

import './styles/Meetings.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectMeetStep } from '../../../ReduxV2/state';

const { Column } = Table;
const { TabPane } = Tabs;

/**
 * The Meetings component renders a page that displays upcoming and past meetings.
 *
 * @return {JSX.Element} The rendered component.
 */
const Meetings = () => {
  const [pdfVisible, setPdfVisible] = useState(false);
  const [meetId, setMeetId] = useState(0);
  const [pdfId, setPdfId] = useState();
  const [eventsMeet, setEventsMeet] = useState([]);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [queryString, setQueryString] = useState('');
  const [deleteMeetingId, setDeleteMeetingId] = useState();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
 
  const calendarRef = createRef();

  const { id } = useParams();
  const dispatch = useDispatch();

  const stepMeet = useSelector((state) => {
    return state.meet.value;
  });
  const pftMeetingsResult = eventsMeet?.filter(
    ({ title }) =>
      title && title?.toLowerCase().includes(queryString.toLowerCase())
  );

  const handleDateClick = async ({ id, status }) => {
    setMeetId(id);
    try {
      switch (status.code) {
        case 'created':
          dispatch(selectMeetStep(2));
          break;
        case 'prepared':
          dispatch(selectMeetStep(2));

          break;
        case 'finished':
          dispatch(selectMeetStep(3));
          break;
        case 'reflection':
          dispatch(selectMeetStep(3));
          break;
        default:
          break;
      }
    } catch (error) { }
  };

  /**
   * Opens a PDF modal.
   *
   * @param {type} id - The ID of the PDF.
   * @return {type} None.
   */
  const openPdfModal = (id) => {
    setPdfId(id);
    setPdfVisible(true);
  };

  /**
   * Close the PDF modal.
   *
   * @return {void}
   */
  const closePdfModal = () => {
    setPdfId(null);
    setPdfVisible(false);
  };

  const getMeetingApi = async () => {
    let start_date = moment().startOf('day');

    try {
      const meetings = await getMeetings(start_date, id).call;
      setEventsMeet(meetings?.data?.results);
    } catch (error) {
      ClearLocalStorage();
    }
  };

  const getUpcomingMeetingsApi = async () => {
    try {
      let start_date = moment().startOf('day');
      const meetings = await getPastMeetings(start_date, id).call;
      setUpcomingMeetings(meetings?.data?.results);
    } catch (error) { }
  };

  const returnHome = () => {
    dispatch(selectMeetStep(1));
    getMeetingApi();
    getUpcomingMeetingsApi();
  };

  useEffect(() => {
    getMeetingApi();
    getUpcomingMeetingsApi();
  }, []);

  const deleteMeetingById = async (id) => {
    try {
      await deleteMeeting(id).call;
      getMeetingApi();
      getUpcomingMeetingsApi();
      getNotification('success', {
        header: '',
        body: 'The meeting has been deleted successfully',
      });
    } catch (error) { }
  };

  useEffect(() => {
    if(deleteMeetingId) {
      setIsDeleteModalVisible(true)
    }
  }, [deleteMeetingId])

  return (
    <div className='intel'>
      {stepMeet == 1 && (
        <div>
          <h1>Meetings</h1>
          <Tabs type='card'>
            <TabPane tab='Upcoming Meetings' key='1'>
              <div>
                <div className='flex w-50 ml-auto mb-15'>
                  <Input
                    prefix={<SearchOutlined />}
                    className='border-r-50'
                    onChange={(e) => setQueryString(e.target.value)}
                  />
                  <PlusSquareFilled
                    data-testid="addMeetingButton"
                    style={{
                      fontSize: '35px',
                      color: '#3C5DD6',
                      cursor: 'pointer',
                      margin: 'auto',
                      marginLeft: '10px',
                    }}
                    onClick={() => {
                      setMeetId(0);
                      dispatch(selectMeetStep(2));
                    }}
                  />
                </div>
                <Table
                  dataSource={pftMeetingsResult}
                  pagination={{ defaultPageSize: 5 }}>
                  <Column
                    className='pointer'
                    title='Meeting Name'
                    dataIndex='title'
                    key='title'
                    onCell={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          handleDateClick(record);
                        },
                      };
                    }}
                  />
                  <Column
                    className='pointer'
                    title='Created by'
                    dataIndex='created_by'
                    key='created_by'
                    render={(text) => `${text.first_name} ${text.last_name}`}
                    onCell={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          handleDateClick(record);
                        },
                      };
                    }}
                  />
                  <Column
                    className='pointer'
                    title='Meeting Date'
                    dataIndex='start'
                    key='start'
                    render={(start) => moment(start).format('ll')}
                    onCell={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          handleDateClick(record);
                        },
                      };
                    }}
                  />
                  <Column
                    className='pointer'
                    title='Status'
                    dataIndex='status'
                    key='status'
                    render={(tag) => (
                      <Tag color={tag?.color}>
                        <span className='text-black'>{tag?.name}</span>
                      </Tag>
                    )}
                    onCell={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          handleDateClick(record);
                        },
                      };
                    }}
                  />
                  <Column
                    className='pointer'
                    title='Network'
                    dataIndex='organizations'
                    key='organizations'
                    render={(org) => org?.map((e) => e.name).join(',')}
                    onCell={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          handleDateClick(record);
                        },
                      };
                    }}
                  />
                  <Column
                    className='pointer'
                    title='Actions'
                    dataIndex='id'
                    key='id'
                    render={(id, record) => (
                      <div className='flex'>
                        <span
                          data-testid="downloadMeetingAction"
                          className='ml-15 pointer'
                          onClick={() => openPdfModal(id)}>
                          <DownloadOutlined/>
                        </span>
                        <span
                          data-testid="deleteMeetingAction"
                          className='ml-15 pointer'
                          onClick={() => setDeleteMeetingId(id)}>
                          <DeleteOutlined/>
                        </span>
                      </div>
                    )}
                  />
                </Table>
              </div>
            </TabPane>
            <TabPane tab='Past Meetings' key='2'>
              <Table
                dataSource={upcomingMeetings}
                pagination={{ defaultPageSize: 5 }}>
                <Column
                  className='pointer'
                  title='Meeting Name'
                  dataIndex='title'
                  key='title'
                  onCell={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        handleDateClick(record);
                      },
                    };
                  }}
                />
                <Column
                  className='pointer'
                  title='Created by'
                  dataIndex='created_by'
                  key='created_by'
                  render={(text) => `${text.first_name} ${text.last_name}`}
                  onCell={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        handleDateClick(record);
                      },
                    };
                  }}
                />
                <Column
                  className='pointer'
                  title='Meeting Date'
                  dataIndex='start'
                  key='start'
                  render={(start) => moment(start).format('ll')}
                  onCell={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        handleDateClick(record);
                      },
                    };
                  }}
                />
                <Column
                  className='pointer'
                  title='Status'
                  dataIndex='status'
                  key='status'
                  render={(tag) => (
                    <Tag color={tag?.color}>
                      <span className='text-black'>{tag?.name}</span>
                    </Tag>
                  )}
                  onCell={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        handleDateClick(record);
                      },
                    };
                  }}
                />
                <Column
                  className='pointer'
                  title='Network'
                  dataIndex='organizations'
                  key='organizations'
                  render={(org) => org?.map((e) => e.name).join(',')}
                  onCell={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        handleDateClick(record);
                      },
                    };
                  }}
                />
                <Column
                  className='pointer'
                  title='Actions'
                  dataIndex='id'
                  key='id'
                  render={(id, record) => (
                    <div className='flex'>
                      <span
                        data-testid="downloadMeetingAction"
                        className='ml-15 pointer'
                        onClick={() => openPdfModal(id)}>
                        <DownloadOutlined/>
                      </span>
                      <span
                        data-testid="deleteMeetingAction"
                        className='ml-15 pointer'
                        onClick={() => setDeleteMeetingId(id)}>
                        <DeleteOutlined/>
                      </span>
 
                    </div>
                  )}
                />
              </Table>
            </TabPane>
          </Tabs>
        </div>
      )}
      {stepMeet == 2 && (
        <div>
          <div className='mb-15'>
            <p className='text-black pointer' onClick={returnHome}>
              {' '}
              <img
                className='arrow-back'
                src='/img/arrowBack.png'
                alt='back'
              />{' '}
              Back to Meetings
            </p>
          </div>
          <CreateMeeting
            meetId={meetId}
            returnHome={returnHome}
            changeToReflection={() => dispatch(selectMeetStep(3))}
          />
        </div>
      )}
      {stepMeet == 3 && (
        <div>
          <div className='mb-15'>
            <p className='text-black pointer' onClick={returnHome}>
              {' '}
              <img
                className='arrow-back'
                src='/img/arrowBack.png'
                alt='back'
              />{' '}
              Back to Meetings
            </p>
          </div>
          <MeetingsReflections
            meetId={meetId}
            returnHome={returnHome}
            returnPrep={() => dispatch(selectMeetStep(2))}
          />
        </div>
      )}
      <PdfModal
        id={pdfId}
        visible={pdfVisible}
        onCancel={closePdfModal}
        returnHome={returnHome}
      />
      <DeleteConfirmationModal
        visible={isDeleteModalVisible}
        cancel={() => {
          setIsDeleteModalVisible(false)
          setDeleteMeetingId()
        }}
        save={() => {
          deleteMeetingById(deleteMeetingId)
          setIsDeleteModalVisible(false)
          setDeleteMeetingId()
        }}
      />
    </div>
  );
};

export default Meetings;
