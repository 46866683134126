import React, {useState, useEffect, useRef} from 'react';
import {Link, useHistory, withRouter} from 'react-router-dom';
import Container from '../../Components/Container';
import SVGIcons from '../../Components/SVGIcons';
import {Dropdown} from '../Dropdown';
import ProfileElement from '../../Components/ProfileElement';
import style from './style.module.scss';
import {ROUTES} from '../../Routes.constants';
import toolbox from '../../Assets/toolbox.png';
import {SwitchContext} from '../../Components/new/Context/ComponentSwitchContext';
import {GetLocalStorage} from '../../utils/localStorageOperations';
import Logo from './Logo';
import {getNetworks} from 'Services/AdminDashboard.service';
import {SearchSelect} from 'Components/atoms/select/SearchSelect';
import {ToolboxIcon} from 'Assets/icons/ToolboxIcon';
import {GroupsIcon} from 'Assets/icons/GroupsIcon';
import { TagOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { useTranslation } from "react-i18next"

const Header = ({children, isStandAlone}) => {
  const [addOnClass, setAddOnClass] = useState(false);
  const [cancelLogout] = useState(false);
  const [networks, setNetworks] = useState([]);
  const wrapperRef = useRef(null);

  // i18n translation hook
  const [t] = useTranslation()

  const history = useHistory();

  const context = React.useContext(SwitchContext);

  const showProfile = () => {
    setAddOnClass(!addOnClass);
  };

  const setWrapperRef = (node) => {
    wrapperRef.current = node;
  };

  const handleClickOutside = (event) => {
    if (
      !isStandAlone &&
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target)
    ) {
      setAddOnClass(false);
    }
  };

  const hideDropDown = () => {
    setAddOnClass(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    // Close the user dropdown window when pressing the back button in the browser
    window.addEventListener('popstate', () => {
      setAddOnClass(false);
    });

    // Handles Networks API call
    const {call, controller} = getNetworks();

    call
      .then((response) => {
        // Add 'All Networks' option to the beginning of the list
        const networkLabels = [
          {value: '', label: 'All Networks'},
          ...response.data.map(({id, name}) => ({
            value: id,
            label: name,
          })),
        ];
        // Update the networks state
        setNetworks(networkLabels);
      })
      .catch((error) => {
        console.error(error.message);
      });

    // Save the controller in the component's state for cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      // Cleanup function to abort the request when the component unmounts
      if (controller) {
        controller.abort();
      }
    };
  }, []); // Empty dependency array means this effect runs once, similar to componentDidMount

  return (
    <>
      {!isStandAlone && (
        <header
          className={`${style.mainHeader}`}
          style={{backgroundColor: '#30DEF7'}}>
          <div className='flex flex-row justify-between px-4 py-2'>
            <div>
              {!isStandAlone ? (
                <div className='flex flex-row justify-between gap-36 items-center'>
                  <div>
                    <Link to={ROUTES.NETWORKS}>
                      <Logo />
                    </Link>
                  </div>
                    <div className='flex flex-row gap-12 items-center'>
                      <div className='flez items-center'>
                        <a
                          onClick={() => {
                            context.changecomponent('dashboard');
                            history.push(ROUTES.GROUPS);
                          }}
                          className='flex flex-row items-center gap-2'>
                          <div>
                            <GroupsIcon />
                          </div>
                          <div className='text-normal text-black'>{t("Header.Groups")}</div>
                        </a>
                      </div>
                      <SearchSelect
                        handleGetBothChange={(e, i) => {
                          history.push(
                            `/${e}/organisation-insights/?orgName=${encodeURIComponent(
                              i.label
                            )}`
                          );
                        }}
                        search
                        border
                        placeholder='Networks'
                        options={networks}
                      />
                      <Button
                        style={{ border: '1px solid black', backgroundColor: '#A8EEF8', color: 'black' }}
                        icon={<TagOutlined />}
                        onClick={() => { history.push(`/tags`)}}
                      >
                        Tags
                      </Button>
                    </div>
                  <div>
                 </div>
                </div>
              ) : (
                <span>
                  <img
                    className='h-3rem'
                    src={
                      GetLocalStorage('clientData')?.logo ||
                      '/img/blueagilis-whitelogo.png'
                    }
                    alt='logo'
                  />
                </span>
              )}
            </div>
            <div className='flex flex-row items-center gap-12'>
              {!isStandAlone && (
                <div className='flez items-center'>
                  <a
                    onClick={() => {
                      context.changecomponent('dashboard');
                      history.push(ROUTES.TOOLBOX);
                    }}
                    className='flex flex-row items-center gap-2'>
                    <div className='stroke-black'>
                      <ToolboxIcon />
                    </div>
                    <div className='text-normal text-black'>{t("Header.Tool_Box")}</div>
                  </a>
                </div>
              )}
              <div>
                <div className={style.profileLink} ref={setWrapperRef}>
                  <Dropdown
                    addOnClass={addOnClass}
                    customButton={
                      <div
                        onClick={() => {
                          showProfile();
                        }}
                        className='flex flex-row items-center gap-2'>
                        <img
                          height={20}
                          width={20}
                          className='rounded-full'
                          src={
                            localStorage.getItem('profileImage') !== 'null'
                              ? localStorage.getItem('profileImage')
                              : '/img/avatar.png'
                          }
                          alt='logo'
                        />
                        <div className='text-normal text-black'>{t("Header.Account")}</div>
                      </div>
                    }>
                    <ProfileElement hideDropDown={hideDropDown} />
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
      {children}
    </>
  );
};

export default withRouter(Header);
