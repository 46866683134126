import React from 'react';
import {Button, Modal} from 'antd';

type OverwriteChangesModalProps = {
  open: boolean,
  handleCancel: React.MouseEventHandler<HTMLElement>,
  handleSave: React.MouseEventHandler<HTMLElement>,
}

const OverwriteChangesModal: React.FC<OverwriteChangesModalProps> = ({open, handleCancel, handleSave}) => {
  return (
    <Modal 
      data-testid='overwriteChangesModal'
      aria-modal="true"
      aria-labelledby="modalTitle"
      open={open}
      footer={null}
    >
      <div data-testid="overwriteModalContent" className='full-height full-width flex flex-col gap-3 mt-6  pb-0 px-4'>
        <h1 data-testid="overwriteModalTitle" className='text-3xl font-bold'>Save and Exit</h1>
        <p className='text-sm text-black'>
          This will overwrite any previous changes made.
        </p>

        <div data-testid="overwriteModalButtons" className='flex flex-row justify-end mt-3'>
          <Button data-testid='cancelChangesButton' type='text' onClick={handleCancel}>Cancel</Button>
          <Button data-testid='acceptChangesButton' type='primary' onClick={handleSave}>Save and Exit</Button>
        </div>
      </div>
    </Modal>
  );
};

export default OverwriteChangesModal;
