import { Col, Row, Tooltip } from 'antd';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Container from '../../Components/Container';
import SVGIcons from '../../Components/SVGIcons';
import { parseWebUrl, makeWebUrl } from '../../utils/queryStringUtils';
import classNames from 'classnames';
import { PAGE_BREADCRUMB, STEPS } from './constants';
import style from './style.module.scss';
import { Breadcrumbs } from '../../Components/Breadcrumbs';
import OrganisationProfile from './Children/ViewDetails/Children/OrganisationProfile';
import { FaPlus } from 'react-icons/fa';
import ModalWindow from '../../Components/ModalWindow';
import FormBlock from '../../Components/FormBlock';
import { ButtonElement } from '../../Components/ButtonElement';
import { API_BASE_URL } from '../../../config/constants';
import axios from 'apiConfig';
import { BiLock } from 'react-icons/bi';
class ViewNetworkGroup extends Component {
  constructor(props) {
    super(props);
    let data;
    this.state = {
      isViewEmployees: false,
      currentPage: 1,
      showAddMissionModal: false,
      activeStep: this.getCurrentTabId(),
      employeePageLimit: 6,
      departmentPageLimit: 6,
      designationPageLimit: 3,
      groupData: {},
      showAddNetworkModal: false,
      selectedNetwork: '',
      addNetworkError: '',
    };
    if (this.props.location.data) {
      this.state.data = this.props.location.data;
      localStorage.setItem(
        'pageUrlData',
        JSON.stringify(this.props.location.data)
      );
    } else data = JSON.parse(localStorage.getItem('pageUrlData'));
    this.datatoRender = data;
  }

  fetchOrganisationList = (searchText = '') => {
    /*let { params } = this.props.match;
    this.props.getOrganisationList({
      q: searchText,
      limit: 10,
      offset: 0,
      groupId: params?.id || 0,
    });*/
  };
  componentDidMount() {
    this.fetchOrganisationList();
  }

  componentWillMount() {
    let { params } = this.props.match;
    this.props.FetchGroupDetailAction(params.id, this.state.name);
    localStorage.setItem('groupId', params.id);
  }

  getCurrentTabId = () => parseWebUrl().tab || STEPS[0].id;

  getCurrentTab = () => STEPS.find((step) => step.id == this.getCurrentTabId());

  getOrganisationData = (organisationData) => {
    return (
      <>
        <li>
          <Tooltip title='Official Website'>
            <span>
              <SVGIcons type={'SVG-web'} />
            </span>
          </Tooltip>
          <span>{organisationData.website}</span>
        </li>
        <li>
          <Tooltip title='Contact Phone'>
            <span>
              <SVGIcons type={'SVG-phone'} />
            </span>
          </Tooltip>
          <span>{organisationData.phone}</span>
        </li>
        <li>
          <Tooltip title='Contact Email'>
            <span>
              <SVGIcons type={'SVG-email'} />
            </span>
          </Tooltip>
          <span>{organisationData.contact_email}</span>
        </li>
      </>
    );
  };

  onSelectStep = (step) => {
    this.setState({ activeStep: step.id });
    const params = parseWebUrl();
    params.tab = step.id;
    const URL = window.location.pathname + makeWebUrl(params);
    this.props.history.push(URL);
  };

  getSidebarSection = (item, index) => {
    return (
      <li
        onClick={() => this.onSelectStep(item)}
        className={item?.bgColor === true ? style.bg__orange : ''}>
        <a
          className={classNames({
            [style.current]: this.getCurrentTabId() == item.id,
          })}>
          <span>{item.icon}</span>
          <span>{item.label}</span>
        </a>
      </li>
    );
  };
  /* eslint-disable */
  renderStepContents = (step) => (
    <div className={style.insightsBody}>
      <step.content
        groupId={this.props.orgGroupData?.id}
        groupList={this.props.orgGroupList}
        currentState={this.props.viewMissionDetails}
        saveMission={this.props.saveMission}
        getMissionDetails={this.props.getMissionDetails}
        updateMission={this.props.updateMission}
        addInsightsForm={this.props.addInsightsForm}
        getDepartments={this.props.getDepartments}
        getDesignations={this.props.getDesignations}
        designationList={this.props.designationList}
        departmentList={this.props.departmentList}
        addInsightsFormChange={this.props.addInsightsFormChange}
        addEmployees={this.props.addEmployees}
        resetEmployeeForm={this.props.resetEmployeeForm}
        fetchEmployeesList={this.props.fetchEmployeesList}
        employeesList={this.props.employeesList}
        addDesignationAction={this.props.addDesignationAction}
        addDepartmentAction={this.props.addDepartmentAction}
        employeePageLimit={this.state.employeePageLimit}
        departmentPageLimit={this.state.departmentPageLimit}
        designationPageLimit={this.state.designationPageLimit}
        addNotes={this.props.addNotes}
        getNotes={this.props.getNotes}
        notesList={this.props.notesList}
        addNotesApi={this.props.addNotesApi}
        getNotesApi={this.props.getNotesApi}
        updateNotes={this.props.updateNotes}
        totalNotesCount={this.props.totalNotesCount}
        getPresignedUrl={this.props.getPresignedUrl}
        uploadFile={this.props.uploadFile}
        integrateApi={this.props.integrateApi}
        deleteDepartment={this.props.deleteDepartment}
        deleteNotes={this.props.deleteNotes}
        organisationGroupList={this.props.organisationGroupList}
        orgGroupData={this.props.orgGroupData}
        groupOrganizationList={this.props.groupOrganizationList}
        getOrganisationList={this.props.getOrganisationList}
        name={this.props.name}
        point_of_contact={this.props.point_of_contact}
        country={this.props.country}
        contact_email={this.props.contact_email}
        phone={this.props.phone}
        website={this.props.website}
        fetchOrganisationList={this.fetchOrganisationList}
        getOrganisationGroupList={this.props.getOrganisationGroupList}
      />
    </div>
  );
  renderInsightHeading = (organisationName) => (
    <div className={style.insightsAside__header}>
      <h3>Network Insights</h3>
      <h4>{organisationName}</h4>
    </div>
  );
  renderBreadCrumbs = (organisationName) => (
    <Breadcrumbs data={PAGE_BREADCRUMB(this.datatoRender, organisationName)} />
  );

  filterOptionList = () => {
    let { organisationListOptions, connectedNetworkList, groupOrganizationList } =
      this.props;
    return organisationListOptions.filter(
      (x) => !groupOrganizationList.some((item) => item.id === x.value)
    );
  };
  onModalClose = () => {
    this.setState({
      showAddNetworkModal: false,
    });
  };
  addNetworks = () => {
    let { params } = this.props.match;
    if (
      this.state.selectedNetwork &&
      !this.props.addConnectedNetwork.isProcessing
    )
      axios({
        url: `${API_BASE_URL}organization/${this.state.selectedNetwork}/`,
        method: 'patch',
        data: {
          group_id: localStorage.getItem('groupId'),
        },
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
          'content-type': 'application/json',
        },
      }).then(
        (res) => {
          this.props.getGroupOrganizationList({
            id: localStorage.getItem('groupId'),
            limit: 7,
            offset: 0,
            q: '',
          });
          this.fetchOrganisationList();
          this.onModalClose();
        },
        (err) => { }
      );
    else
      this.setState({
        addNetworkError: 'Please select a network',
      });
  };
  addNetworkWindow = () => {
    let { addConnectedNetwork } = this.props;
    return (
      <ModalWindow
        title='Add Network'
        visible={this.state.showAddNetworkModal}
        onCancel={this.onModalClose}
        isNonMaskable={true}
        isNotClosable={true}
        footer={
          <>
            <ButtonElement
              children='Cancel'
              type='link'
              onClick={this.onModalClose}
              disabled={false}
            />
            <ButtonElement
              type='primary'
              children='Save'
              onClick={this.addNetworks}
              loading={addConnectedNetwork?.isProcessing}
            />
          </>
        }>
        <FormBlock
          elementType={'asyncselect'}
          onSearch={this.fetchOrganisationList}
          onSelect={(value) => {
            this.setState({
              addNetworkError: '',
              selectedNetwork: value,
            });
          }}
          value={this.state.selectedNetwork}
          placeholder='Select Network'
          error={this.state.addNetworkError}
          options={this.filterOptionList()}
        />
      </ModalWindow>
    );
  };
  render() {
    const organisationName = parseWebUrl().orgName;
    let { organisationData, orgGroupData } = this.props;
    let data = this.props.location;
    const step = this.getCurrentTab();

    return (
      <section className={style.organisationInsights}>
        {this.addNetworkWindow()}

        <Container>
          <div className={style.insightsHeader}>
            {this.renderBreadCrumbs(orgGroupData?.name)}
            <span
              className={style.insightsHeader__span}
              style={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
                color: 'rgba(77,164,218,.8)',
                cursor: 'pointer',
              }}
              onClick={() => this.setState({ showAddNetworkModal: true })}>
              <FaPlus size='1.2rem' color='rgba(77,164,218,.8)' />
              <span> Connect Network</span>
            </span>
          </div>
          <div className={style.insightsContent}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <div className={style.insightsAside}>
                  <OrganisationProfile
                    orgGroupData={orgGroupData}
                    showViewEmployees={this.showViewEmployees}
                  />
                  {this.renderInsightHeading(organisationName)}

                  <ul className={style.insightsAside__list}>
                    {STEPS.map(this.getSidebarSection)}
                  </ul>

                  {/*<div
                    className={`${style.asideBacklinkorange}  ${style.disabled} `}
                    style={{borderRadius: '1.2rem'}}>
                    <a>
                      <span
                        className={`ToolboxImg ${style.ToolboxImg} `}></span>
                      <span
                        className={style.asideBacklinkorange_text}
                        // onClick={() => {
                        //   this.props.history.push({
                        //     pathname: `/view-sprint-organization/${this.datatoRender.id}/?orgName=${organisationName}`,
                        //     data: this.datatoRender,
                        //   });
                        // }}
                      >
                        <h4>Network Mapping Tool </h4>
                        <p>Create a visual map of your network</p>
                      </span>
                      <span className={style.lock_icon}>
                        <BiLock size='1.3rem' />
                      </span>
                    </a>
                  </div>*/}
                </div>
              </Col>
              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                {this.renderStepContents(step)}
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    );
  }
}

export default withRouter(ViewNetworkGroup);
