import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  value: 1,
};

export const meetSlice = createSlice({
  name: 'meet',
  initialState,
  reducers: {
    selectMeetStep: (state, {payload}) => {
      state.value = payload;
    },
    resetMeet: (state) => {
      state.value = 1;
    },
  },
});

export const {selectMeetStep, resetMeet} = meetSlice.actions;
