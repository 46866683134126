import { PaginatedResponse, ResponseList } from 'types/Request';
import { Team } from 'types/Team';
import { Tag } from './Tags';
import { PatTypes } from 'Services/UserPat.service';

export type UserPat = {
  id: number;
  created_by: PatCreator;
  current_phase: PatPhase | null;
  created_at: string;
  title: string;
  status: string;
  organization: number;
};

export type UserPatDetail = UserPat & {
  ext_id: string;
  tags: Tag[] | string []
  last_option: number;
  collaborators: Team[];
  completed_at: string | null;
  shared: boolean;
  pat_options: PatOption[];
  competency_notes: PatCompetencyNote[];
} & {
  type: PatType
};

type PatCreator = {
  id: number;
  name: string;
  email: string;
};

export type PatCategory = {
  id: number;
  name: string;
  code: string;
  information: string;
  pat_competencies: PatCompetency[];
};

type PatCompetency = {
  id: string;
  description: string;
  about: string;
  important: string;
  lag_measures: string;
  category: number;
  improvement_tactics: PatImprovementTactic[];
  pat_options: PatOption[];
};

type PatCompetencyNote = {
  id?: number;
  notes: string;
  user_pat?: number;
  pat_competency: number;
};

type PatImprovementTactic = {
  id: number;
  description: string;
  pat_competency: number;
};

export type PatOption = {
  id: number;
  order: number;
  points: number;
  description: string;
  phase: PatPhase | null | number;
  competency: number;
};

type PatPhase = {
  name: string;
  color: string;
};

export type PatchUserPat = {
  id: number;
  competency_notes: PatCompetencyNote[];
  organization: number | string;
  status: string;
  title: string;
  pat_options: PatchUserPatOption[];
};

type PatchUserPatOption = {
  pat_option: number | string;
};

export enum PatStatus  {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export type PatType = { 
  id: number;
  name: string;
  code: string | PatTypes;
}


export type PaginatedUserPat = PaginatedResponse<UserPat>;
export type PaginatedPatCategory = PaginatedResponse<PatCategory>;
export type ResponseListPatType = ResponseList<PatType>;

