import {
  LIST_MSOP_REPORT_START,
  LIST_MSOP_REPORT_FAILED,
  LIST_MSOP_REPORT_SUCCESS,
} from '../../Redux.constants';
import {Default} from './InitialState';
import {listMsopStarted, listMsopFailed, listMsopSuccess} from './Helper';

const ListMsopReportReducer = (state = Default(), action) => {
  switch (action.type) {
    case LIST_MSOP_REPORT_START:
      return listMsopStarted(state, action);
    case LIST_MSOP_REPORT_FAILED:
      return listMsopFailed(state, action);
    case LIST_MSOP_REPORT_SUCCESS:
      return listMsopSuccess(state, action.payload);
    default:
      return state;
  }
};
export default ListMsopReportReducer;
