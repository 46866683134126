import {ApiBase} from '../../../utils/apiBase';
import {LIST_ORGANISATIONS, LIST_ORGANIZATION_GROUP} from '../../Api.constants';
import {
  UPDATE_GROUP_START,
  UPDATE_GROUP_FAILED,
  UPDATE_GROUP_SUCCESS,
  CREATE_ORGANISATION_VALIDATION_ERROR,
} from '../../Redux.constants';
import * as yup from 'yup';
import {getNotification} from '../../../Components/GetNotification';

const UPDATE_ORGANISATION_FORM_SCHEMA = yup.object().shape({
  name: yup
    .string()
    // .matches(/^[A-Za-z]+$/, 'Organisation title should only have Alphabets.')
    .required('name is required'),
  discription: yup
    .string()
    // .matches(/^[A-Za-z]+$/, 'Point of contact should only have Alphabets.')
    .required('discription is  required'),
});

function fetchValidationErrors(input) {
  return new Promise((resolve, reject) => {
    UPDATE_ORGANISATION_FORM_SCHEMA.validate(input, {abortEarly: false}).catch(
      (error) => {
        let validationList = error.inner.map((item) => {
          return {
            path: item.path,
            message: item.message,
          };
        });
        resolve(validationList);
      }
    );
  });
}

export function UpdateGroupDetails(input, callback) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_GROUP_SUCCESS,
    });
    try {
      // await UPDATE_ORGANISATION_FORM_SCHEMA.validate(input);
      await updateGroupAction({dispatch, input, callback});
    } catch (error) {
      let createOrganisationValidationErrors = await fetchValidationErrors(
        input
      );
      dispatch({
        type: CREATE_ORGANISATION_VALIDATION_ERROR,
        payload: createOrganisationValidationErrors,
      });
    }
  };
}

async function updateGroupAction({dispatch, input, callback}) {
  try {
    const id = input.get('id');
    const apiBaseInstance = new ApiBase();
    let response = await apiBaseInstance.instance.patch(
      LIST_ORGANIZATION_GROUP + `${id}/`,
      input,
      {headers: {'Content-Type': 'multipart/form-data'}}
    );
    dispatch({
      type: UPDATE_GROUP_SUCCESS,
    });
    callback();
    getNotification('success', {
      header: 'Success!',
      body: 'Successfully updated group',
    });
  } catch (error) {
    dispatch({
      type: UPDATE_GROUP_FAILED,
      payload: {apiError: error},
    });
    getNotification('error', {
      header: 'Failed!',
      body: 'Failed to update group',
    });
  }
}

export const updateOrganisation = (updateRequest, callback) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_ORGANISATION_START,
    });
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.patch(
        LIST_ORGANISATIONS + `${updateRequest.id}/`,
        updateRequest
      );

      dispatch({
        type: UPDATE_ORGANISATION_SUCCESS,
      });
      callback && callback();
    } catch (error) {
      dispatch({
        type: UPDATE_ORGANISATION_FAILED,
        payload: {apiError: error},
      });
      callback && callback();
    }
  };
};
