/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {Button, Modal} from 'antd';

type ModalComponentsProps = {
  open: boolean;
  handleCancel: React.MouseEventHandler<HTMLElement>;
  handleOk: React.MouseEventHandler<HTMLElement>;
  title: string;
  contentMessage: undefined | string;
  cancelText: string;
  okText: string;
}

const ModalComponent: React.FC<ModalComponentsProps> = ({
    open,
    handleCancel,
    handleOk,
    title = '',
    contentMessage,
    cancelText,
    okText,
  }) => {
  return (
    <Modal 
      data-testid='modal'
      aria-modal="true"
      aria-labelledby="modal"
      open={open}
      footer={null}
    >
      <div data-testid="modalComponentContent" className='full-height full-width flex flex-col gap-3 mt-6  pb-0 px-4'>
        <h1 data-testid="modalComponentTitle" className='text-3xl font-bold'>{title}</h1>
        {contentMessage && (<p className='text-sm text-black'>
          {contentMessage}
        </p>)}
        <div data-testid="modalComponentButtons" className='flex flex-row justify-end mt-3'>
          <Button data-testid='cancelChangesButton' type='text' onClick={handleCancel}>{cancelText}</Button>
          <Button data-testid='okChangesButton' type='primary' onClick={handleOk}>{okText}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalComponent;
