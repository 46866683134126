import React from 'react';
import {Card} from 'antd';

type MilestoneCardProps = {
  description: string;
  isPositive: boolean;
};

export const MilestoneCard: React.FC<MilestoneCardProps> = ({
  description,
  isPositive,
}) => {
  const color = isPositive ? 'bg-positive' : 'bg-negative';
  return (
    <Card className='border-none rounded-lg w-full min-h-[425px] shadow-lg'>
      <div className='flex items-center justify-between mb-2.5 h-6'>
        {/* <div className='w-6 h-6 rounded-full bg-primary flex place-content-center'>
          <span className='text-white'>1</span>
        </div> */}
        <div />
        <div className={`rounded-full h-2 w-27 ${color}`} />
      </div>
      <div className='text-base font-medium'>{description}</div>
    </Card>
  );
};
