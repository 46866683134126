export function addMsopStarted(state) {
  return {
    ...state,
    addMsopReportApi: {loading: true, data: null, error: false},
  };
}
export function addMsopSuccess(state, payload) {
  return {
    ...state,
    addMsopReportApi: {
      loading: false,
      data: payload.msopReportResponse,
      error: false,
    },
  };
}
export function addMsopFailed(state) {
  return {
    ...state,
    addMsopReportApi: {loading: false, data: null, error: true},
  };
}
