import { Modal } from 'antd';
import React, { Component } from 'react';
import { ButtonElement } from '../../../../Components/ButtonElement';
import FormBlock from '../../../../Components/FormBlock';
import ModalWindow from '../../../../Components/ModalWindow';
import { GetLocalStorage } from '../../../../utils/localStorageOperations';
import "./modal.scss";

const CloseIconImg = () => {
  return <div className='modal-close-icon'>x</div>
}

export default class ForgetPassword extends Component {

  state = {
    email: ''
  }
  constructor(props) {
    super(props);
  }
  onSubmitButtonClick = () => {
    this.props.forgotPasswordAction({
      email: this.state.email
    }, () => {
      this.props.onCancel();
    });
  }
  renderResetPasswordForm = () => {
    return <ul className='password-list'>
      <li className='modal-form-input'>
        <FormBlock
          label={'Email'}
          placeholder='example@email.com'
          onChange={(event) => {
            this.setState({
              email: event.target.value
            });
          }} /></li>
      <li>
        <ButtonElement type='primary' className='modal-form-button' children='Send Reset Link'
          loading={this.props.api.isProcessing}
          onClick={this.onSubmitButtonClick} /></li>
    </ul>;
  }
  render() {
    return (
      <Modal
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        footer={false}
        bodyStyle={{
          width: '100%',
          padding: 0
        }}
        centered
        closeIcon={<CloseIconImg />}>
        <div>
          <div className='modal-title'>
            <img className='modal-image-header' src={GetLocalStorage('logo') || "/img/blueagilis-whitelogo.png"} />
          </div>
          <div className='modal-form'>
            <h1><b>Forgot Password</b></h1>
            <p>Send a link to your email to reset password</p>
            {this.renderResetPasswordForm()}
          </div>
        </div>
      </Modal>
    );
  }
}
