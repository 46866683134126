import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { SubjectManager } from './models';
import { Subscription } from 'rxjs';
import './styles/DebounceLoading.scss';

export const dialogStartSubject$ = new SubjectManager();
export const dialogStopSubject$ = new SubjectManager();

/**
 * DebounceLoading is a function that creates a loading component with a debounce effect.
 *
 * @return {JSX.Element} The loading component.
 */
const DebounceLoading = () => {

  const [loading, setLoading] = useState(false);
  let loadingStartSubject$ = new Subscription();
  let loadingStopSubject$ = new Subscription();

  useEffect(() => {
    loadingStartSubject$ = dialogStartSubject$.getSubject.subscribe(() => handleClickOpen());
    loadingStopSubject$ = dialogStopSubject$.getSubject.subscribe(() => handleClose());
    return () => {
      loadingStartSubject$.unsubscribe();
      loadingStopSubject$.unsubscribe();
    };
  }, []);

  const handleClickOpen = () => {
    setLoading(true);
  };

  const handleClose = () => {
    setLoading(false);
  };

  return <div className={`debounceloading ${loading ? 'block' : 'd-none'}`}>
    <Spin />
  </div >;
};

export default DebounceLoading;
