import axios from 'apiConfig';
import { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'config/constants';
import { AbortableRequestConfig } from 'types/Request';
import { 
  PaginatedPatCategory, 
  PaginatedUserPat, 
  PatchUserPat, 
  UserPatDetail,
  PatType, 
} from 'types/Pat';
import { PaginatedTeam } from 'types/Team';

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    },
  };
};

export function getUsersPat(orgId: number, page?: number, perPage?: number, title__icontains?: string): {
  call: Promise<AxiosResponse<PaginatedUserPat>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  const paramObj: any = {
    organization: orgId,
    page,
    page_size: perPage,
    title__icontains,
  };


  //remove undefined params
  Object.keys(paramObj).forEach((key) => paramObj[key] === undefined && delete paramObj[key]);
  const params = new URLSearchParams(paramObj);
  
  return {
    call: axios.get(`${API_BASE_URL}user-pats/?${params.toString()}`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function getUsersPatInspect(id: number): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}user-pats/${id}/inspect/`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function deleteUsersPat(id: number): {
  call: Promise<AxiosResponse<void>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.delete(`${API_BASE_URL}user-pats/${id}/`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function getUsersPatDetail(id: number): {
  call: Promise<AxiosResponse<UserPatDetail>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}user-pats/${id}/`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function getUsersPatDetailByToken(id: number): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}user-pats/${id}/`, {
      signal: controller.signal,
      headers: {},
    } as AbortableRequestConfig),
    controller,
  };
}

export function patchUsersPat(data: PatchUserPat): {
  call: Promise<AxiosResponse<UserPatDetail>>;
} {
  return {
    call: axios.patch(`${API_BASE_URL}user-pats/${data?.id}/`, data, headers()),
  };
}

export function patchUsersPatShared(id: number, data: any) {
  return {
    call: axios.patch(`${API_BASE_URL}user-pats/${id}/`, data, {
      headers: {},
    }),
  };
}

export function patchUsersPatCollaborators(id: number, data: any): {
  call: Promise<AxiosResponse<UserPatDetail>>;
} {
  return {
    call: axios.patch(`${API_BASE_URL}user-pats/${id}/collaborators/`, data, {
      headers: {},
    }),
  };
}

export function postUsersPat(data: any): {
  call: Promise<AxiosResponse<UserPatDetail>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}user-pats/`, data, headers()),
  };
}

export function getPatTypes():{
  call: Promise<AxiosResponse<PatType[]>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}pat-types/`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export enum PatTypes {
  blueAgilis =  1,
  stratis =  2,
}

export function getPatCategories(type = PatTypes.blueAgilis):{
  call: Promise<AxiosResponse<PaginatedPatCategory[]>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}pat-categories/?type=${type}`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function createPatToken(id: number, collaborators = {}): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.post(
      `${API_BASE_URL}user-pats/${id}/share/`,
      collaborators,
      headers()
    ),
  };
}

export function getAboutTC(id: number): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(
      `${API_BASE_URL}improvement-tactic-info/?improvement_tactic=${id}`,
      {
        signal: controller.signal,
        headers: {},
      } as AbortableRequestConfig),
    controller,
  };
}

export function getCollaboratorId(id: number): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}pat-collaborators/${id}/`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function getCollaborators(id: number): {
  call: Promise<AxiosResponse<PaginatedTeam>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}pat-collaborators/?user_pat=${id}`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function postAddCollaborator(data: any) {
  return {
    call: axios.post(`${API_BASE_URL}pat-collaborators/`, data, headers()),
  };
}

export function deleteCollaborator(id: string | number): {
  call: Promise<AxiosResponse<void>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.delete(`${API_BASE_URL}pat-collaborators/${id}/`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}
