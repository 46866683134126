export const prepInitialData = [
  {
    label: "Network",
    value: false
  },
  {
    label: "Project",
    value: false
  },
  {
    label: "Sprint",
    value: false
  },
  {
    label: "Attendees",
    value: false
  },
  {
    label: "Goals for meeting",
    value: false
  },
  {
    label: "Personal goals for facilitators",
    value: false
  },
];

export const noteInitalData = [
  {
    label: "Note",
    value: false
  },
  {
    label: "Observation",
    value: false
  },
  {
    label: "Barriers",
    value: false
  },
  {
    label: "Action Items",
    value: false
  },
  {
    label: "Feedback",
    value: false
  },
];

export const reflectInitialData = [
  {
    label: "How did this meeting go?",
    value: false
  },
  {
    label: "Goals",
    value: false
  },
  {
    label: "Emotional Footprint",
    value: false
  },
];