import {getNotification} from '../../../Components/GetNotification';
import {ApiBase} from '../../../utils/apiBase';
import {
  CREATE_ORGANISATION,
  CREATE_ORGANISATION_FAILED,
  CREATE_ORGANISATION_SUCCESS,
  CREATE_ORGANISATION_VALIDATION_ERROR,
  UPDATE_ERROR_MESSAGE,
} from '../../Redux.constants';
import * as yup from 'yup';

export * from './changePassword';
export * from './updateProfile';

const ADD_ORGANISATION_FORM_SCHEMA = yup.object().shape({
  name: yup
    .string()
    // .matches(/^[A-Za-z]+$/, 'Organisation title should only have Alphabets.')
    .required('Network Title is required'),
  point_of_contact: yup
    .string()
    // .matches(/^[A-Za-z]+$/, 'Point of contact should only have Alphabets.')
    .required('Point of contact required'),
  country: yup.string().required('Location is required'),
  contact_email: yup
    .string()
    .email(
      "Invalid Email id! A valid email id will be of the format 'blueagilis@gmail.com'"
    )
    .required('Contact Email is required*'),
  phone: yup
    .string()
    .min(10)
    .max(14, 'Phone number must be [+91] [10 digit subscriber number] ')
    .matches(
      /^((\+*)((0[ -]+)*|(91 )*)(\d{12}|\d{10}))|\d{5}([- ]*)\d{6}$/,
      'Invalid Phone number. Accepted format [+91][10 digit subscriber number] or [10 digit subscriber number]'
    )
    .required('Phone number is required*'),
  /* eslint-disable max-len */
  website: yup
    .string()
    .matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      'Enter a valid url. ( Eg: http://example.com , www.example.com  , example.com )'
    )
    .required('Website is required*'),
});
function fetchValidationErrors(input) {
  return new Promise((resolve, reject) => {
    ADD_ORGANISATION_FORM_SCHEMA.validate(input, {abortEarly: false}).catch(
      (error) => {
        let validationList = error.inner.map((item) => {
          return {
            path: item.path,
            message: item.message,
          };
        });
        resolve(validationList);
      }
    );
  });
}
export function CreateOrganisation(input, callback) {
  return async (dispatch) => {
    dispatch({
      type: CREATE_ORGANISATION,
      payload: {},
    });
    try {
      await ADD_ORGANISATION_FORM_SCHEMA.validate(input);
      await addOrganisation(dispatch, input, callback);
    } catch (error) {
      let createOrganisationValidationErrors = await fetchValidationErrors(
        input
      );
      dispatch({
        type: CREATE_ORGANISATION_VALIDATION_ERROR,
        payload: createOrganisationValidationErrors,
      });
      dispatch({
        type: CREATE_ORGANISATION_FAILED,
        payload: {
          createOrgnisationError: null,
        },
      });
    }
  };
}

async function addOrganisation({dispatch, input, callback}) {
  try {
    const apiBaseInstance = new ApiBase();
    let response = await apiBaseInstance.instance.post('/organization/', input);
    dispatch({
      type: CREATE_ORGANISATION_SUCCESS,
      payload: {
        organisation: response.data,
      },
    });
    callback();
    getNotification('success', {
      header: 'Success!',
      body: 'Created new network',
    });
  } catch (error) {
    dispatch({
      type: CREATE_ORGANISATION_FAILED,
      payload: {},
    });
    getNotification('error', {
      header: 'Failed!',
      body: 'Failed to create network',
    });
  }
}

export function updateErrorMessage(input) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ERROR_MESSAGE,
      payload: input,
    });
  };
}
