import axios, { AxiosResponse, AxiosError } from 'axios';
import {API_BASE_URL} from 'config/constants';
import { PaginatedSprintWorkflow, SprintWorkflow, SprintWorkflowStep, SprintWorkflowSubstep } from 'types/SprintWorkflow';

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    }
  }
}

function makeRequest<T>(
  url: string,
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE',
  data: any = {},
): { call: Promise<AxiosResponse<T>>; controller: AbortController } {
  const controller = new AbortController();
  const headersConfig = headers(); // Define your headers() function

  const config = {
    signal: controller.signal,
    ...headersConfig,
  };

  const call = axios({
    method,
    url,
    data,
    ...config,
  });

  call.catch((error: AxiosError) => {
    errorHandler(error);
  });

  return {
    call,
    controller,
  };
}

const errorHandler = (error: AxiosError) => {
  // Check if it's a network error (e.g., no internet connection)
  if (error.isAxiosError && !error.response) {
    console.error('Network error:', error.message);
  } else if (error.response) {
    // Handle HTTP error responses (status code other than 2xx)
    console.error('HTTP error:', error.response.status, error.response.data);
  } else {
    // Handle other types of errors
    console.error('Other error:', error.message);
  } 
};

export const getSprintWorkflow = (sprint_id: number) => {
  const url = `${API_BASE_URL}sprint-workflows/?mission_sprint=${sprint_id}`;
  return makeRequest<PaginatedSprintWorkflow>(url, 'GET');
};

export const patchSprintWorkflow = (workflow_id: number, name: string, summary: string) => {
  const url = `${API_BASE_URL}sprint-workflows/${workflow_id}/`;
  return makeRequest<SprintWorkflow>(url, 'PATCH', { name, summary });
};

export const patchSprintWorkflowStep = (sw_step: number, name: string, order: number) => {
  const url = `${API_BASE_URL}sw-steps/${sw_step}/`;
  return makeRequest<SprintWorkflowStep>(url, 'PATCH', { name, order });
};


export const postSprintWorkflowStep = (workflow_id: number, name: string, order: number) => {
  const url = `${API_BASE_URL}sw-steps/`;
  return makeRequest<SprintWorkflowStep>(url, 'POST', { sprint_workflow: workflow_id, name, order });
};

export const deleteSprintWorkflowStep = (step_id: number) => {
  const url = `${API_BASE_URL}sw-steps/${step_id}/`;
  return makeRequest<void>(url, 'DELETE');
};

export const postSprintWorkflow = (workflow: any) => {
  const url = `${API_BASE_URL}sprint-workflows/`;
  return makeRequest<SprintWorkflow>(url, 'POST', { ...workflow });
};

export const postSprintWorkflowSubstep = (sw_step: number, name: string, order: number, role_responsible?: number, notes?: string) => {
  const url = `${API_BASE_URL}sw-substeps/`;
  return makeRequest<SprintWorkflowSubstep>(url, 'POST', { sw_step, name, order, role_responsible, notes });
};

export const patchSprintWorkflowSubstep = (sw_substep: number, name: string | undefined, order: number | undefined, role_responsible?: number, notes?: string) => {
  const url = `${API_BASE_URL}sw-substeps/${sw_substep}/`;
  return makeRequest<SprintWorkflowSubstep>(url, 'PATCH', { name, order, role_responsible, notes });
};

export const deleteSprintWorkflowSubstep = (sw_substep: number) => {
  const url = `${API_BASE_URL}sw-substeps/${sw_substep}/`;
  return makeRequest<void>(url, 'DELETE');
};

export const getSprintWorkflowRoles = () => {
  const url = `${API_BASE_URL}client-roles/`;
  return makeRequest(url, 'GET');
}