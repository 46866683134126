import axios from "axios";
import moment from 'moment';
import { API_BASE_URL } from "../../config/constants";

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    }
  }
}

export function getDemographicSpecialist(id) {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}specialists/?demographics=${id}`, {
      signal: controller.signal,
      ...headers()
    }),
    controller
  }
}

export function postDemographicSpecialist(data) {
  return {
    call: axios.post(`${API_BASE_URL}specialists/`, data, headers())
  }
}

export function deleteDemographicSpecialist(id) {
  return {
    call: axios.delete(`${API_BASE_URL}specialists/${id}/`, headers())
  }
}

export function uploadSpecialistDocument(data) {
  return {
    call: axios.post(`${API_BASE_URL}specialists-files/`, data, headers())
  }
}

export function getSpecialistDocuments(id) {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}specialists-files/?specialist=${id}`, {
      signal: controller.signal,
      ...headers()
    }),
    controller
  }
}

export function deleteSpecialistDocuments(id) {
  return {
    call: axios.delete(`${API_BASE_URL}specialists-files/${id}/`, headers())
  }
}