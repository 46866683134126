import axios from "axios";
import moment from 'moment';
import { API_BASE_URL } from "config/constants";

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    }
  }
}

export function getIntelDocuments(orgId, page, perPage, title__icontains) {
  const controller = new AbortController();

  const paramObj = {
    organization: orgId,
    page,
    page_size: perPage,
    title__icontains,
  };
  Object.keys(paramObj).forEach((key) => paramObj[key] === undefined && delete paramObj[key]);
  const params = new URLSearchParams(paramObj);

  return {
    call: axios.get(`${API_BASE_URL}documents/?${params.toString()}`, {
      signal: controller.signal,
      ...headers()
    }),
    controller
  }
}

export function postIntelDocument(data) {
  return {
    call: axios.post(`${API_BASE_URL}documents/`, data, headers())
  }
}

export function deleteIntelDocument(id) {
  return {
    call: axios.delete(`${API_BASE_URL}documents/${id}/`, {
      ...headers()
    }),
  }
}