import React, { useEffect, useState } from 'react';
import { Col, Row, Select, Tabs, Radio, DatePicker } from 'antd';
import _ from 'lodash';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useFieldArray, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { getNotification } from '../../Components/GetNotification';
import {
  deleteAction,
  saveComments,
  saveMeetingReflection,
} from '../../Services/Meetings.service';
import { FilesTable } from './components/FilesTable';
import { FormData } from './models/formData';
import { ClearLocalStorage } from '../../utils/localStorageOperations';
import moment from 'moment';
import './styles/MeetingsComments.scss';
import { getMemberList } from '../../Services/Teams.service';

const { TabPane } = Tabs;

/**
 * Renders a component for displaying and editing meeting comments.
 *
 * @param {object} meetData - the data for the meeting
 * @param {object} allFormData - form data for registration, submission, and errors
 * @param {function} activeSubmitForm - a function to activate the submit form
 * @return {JSX.Element} - the rendered component
 */
const MeetingsComments = ({ meetData, allFormData, activeSubmitForm }) => {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = allFormData;
  const [meetDataComments, setMeetDataComments] = useState({});
  const [editorStateLocal, setEditorStateLocal] = useState({
    note: EditorState.createEmpty(),
    observation: EditorState.createEmpty(),
    barriers: EditorState.createEmpty(),
    action: EditorState.createEmpty(),
    feedback: EditorState.createEmpty(),
  });

  const [members, setMembers] = useState([]);

  const onEditorChange = (key, editorState) => {
    setEditorStateLocal({ ...editorStateLocal, [key]: editorState });
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'actions',
  });
  const watchFieldArray = watch('actions');
  let controlledFields = [];

  if (watchFieldArray) {
    controlledFields = fields.map((field, index) => {
      return {
        ...field,
        ...watchFieldArray[index],
      };
    });
  }

  const handleOk = async (params) => {
    params.note.text = JSON.stringify(
      convertToRaw(editorStateLocal.note.getCurrentContent())
    );
    params.observation.text = JSON.stringify(
      convertToRaw(editorStateLocal.observation.getCurrentContent())
    );
    params.barriers.text = JSON.stringify(
      convertToRaw(editorStateLocal.barriers.getCurrentContent())
    );
    params.feedback.text = JSON.stringify(
      convertToRaw(editorStateLocal.feedback.getCurrentContent())
    );

    try {
      const data = {
        status: 'finished',
        ext_ref_value: params.ext_ref_value,
        ext_ref_first_keyword: params.ext_ref_first_keyword,
        ext_ref_second_keyword: params.ext_ref_second_keyword,
      };

      await saveComments(meetData?.id, params);
      saveMeetingReflection(meetData?.id, data).call;

      activeSubmitForm();
    } catch (error) {
      getNotification('error', {
        header: 'Failed!',
        body: 'An apology, an error has occurred when trying to save your comments. please try again later',
      });
    }
  };

  const fetchMeetDataComments = async () => {
    try {
      setMeetDataComments({
        note: { id: meetData.notes.id, files: meetData.notes.files },
        observation: {
          id: meetData.observation.id,
          files: meetData.observation.files,
        },
        barriers: { id: meetData.barriers.id, files: meetData.barriers.files },
        feedback: { id: meetData.feedback.id, files: meetData.feedback.files },
      });

      setValue('note', meetData.notes);
      setValue('observation', meetData.observation);
      setValue('barriers', meetData.barriers);
      setValue('feedback', meetData.feedback);
      setValue('action', meetData.action_items_checks);
      setValue('ext_ref_value', meetData.ext_ref_value);
      setValue('ext_ref_first_keyword', meetData.ext_ref_first_keyword);
      setValue('ext_ref_second_keyword', meetData.ext_ref_second_keyword);

      remove();
      meetData.action_items?.map((action, index) => {
        append({
          name: action.name,
          associated_users: action.associated_users.map((member) => member.id),
          meeting: action.meeting,
          due_date: moment(action.due_date),
          actionId: action.id,
        });
      });

      const content = {
        entityMap: {},
        blocks: [
          {
            key: 'e4sm9',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
      };

      setEditorStateLocal({
        note: EditorState.createWithContent(
          convertFromRaw(
            Object.entries(meetData.notes.text).length === 0
              ? content
              : JSON.parse(meetData.notes.text)
          )
        ),
        observation: EditorState.createWithContent(
          convertFromRaw(
            Object.entries(meetData.observation.text).length === 0
              ? content
              : JSON.parse(meetData.observation.text)
          )
        ),
        barriers: EditorState.createWithContent(
          convertFromRaw(
            Object.entries(meetData.barriers.text).length === 0
              ? content
              : JSON.parse(meetData.barriers.text)
          )
        ),
        feedback: EditorState.createWithContent(
          convertFromRaw(
            Object.entries(meetData.feedback.text).length === 0
              ? content
              : JSON.parse(meetData.feedback.text)
          )
        ),
      });
    } catch (error) {
      ClearLocalStorage();
    }
  };

  /**
   * Deletes an action from the API.
   *
   * @param {number} index - The index of the action.
   * @param {string} actionId - The ID of the action to be deleted.
   */
  const deleteActionApi = async (index, actionId) => {
    try {
      if (actionId) {
        const res = await deleteAction(actionId).call;
      }
      remove(index);
    } catch (error) { }
  };

  const loadMembers = async () => {
    try {
      const allNetworks = await getMemberList(id).call;
      setMembers(allNetworks.data?.results);
    } catch (error) { }
  };

  useEffect(() => {
    loadMembers();
    if (meetData) {
      fetchMeetDataComments();
    }
  }, [meetData]);

  return (
    <div className='meetingscomments px-25'>
      <h3>Notes</h3>
      <form onSubmit={handleSubmit(handleOk)}>
        <Tabs type='card'>
          {FormData.map((item, index) => (
            <TabPane tab={item.label} key={index}>
              <Row className='mt-2rem' gutter={[10, 35]}>
                {item.checkboxs.map((check, index) => (
                  <Col xs={12} md={4} key={index}>
                    <div className='flex items-center'>
                      <input
                        type='checkbox'
                        {...register(`${item.key}.${check.key}`)}
                      />
                      <span className='ml-5'>{check.label}</span>
                    </div>
                  </Col>
                ))}
                <Col
                  span={24}
                  className={`mb-15 ${item.key !== 'action' ? 'block' : 'hidden'
                    }`}>
                  <Editor
                    editorClassName='textarea mb-15'
                    toolbarClassName='m-0'
                    editorState={editorStateLocal[item.key]}
                    onEditorStateChange={(e) => onEditorChange(item.key, e)}
                    toolbar={{
                      options: [
                        'inline',
                        'blockType',
                        'fontSize',
                        'list',
                        'textAlign',
                        'link',
                        'history',
                      ],
                      inline: {
                        options: ['bold', 'italic', 'underline'],
                      },
                      list: {
                        options: ['unordered', 'ordered'],
                      },
                    }}
                  />
                  <FilesTable
                    key={index}
                    id={meetDataComments[item.key]?.id}
                    field={item.field}
                    urlFiles={item.urlFiles}
                    postUrlFile={item.postUrlFiles}
                    label={item.label}
                  />
                </Col>
                <Col
                  span={24}
                  className={`mb-15 ${item.key == 'action' ? 'block' : 'hidden'
                    }`}>
                  {controlledFields.length ? (
                    controlledFields.map((action, index) => (
                      <Row className='mt-5' key={action.id}>
                        <Col span={7}>
                          <p>Action</p>
                          <Controller
                            name={`actions.${index}.name`}
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <input
                                {...field}
                                className={`input-class w-90 ${errors[`actions.${index}.name`]
                                  ? 'border-error'
                                  : ''
                                  }`}
                                onChange={(net) => {
                                  field.onChange(net);
                                }}
                              />
                            )}
                          />
                        </Col>
                        <Col span={7}>
                          <p>Due Date</p>
                          <Controller
                            name={`actions.${index}.due_date`}
                            control={control}
                            render={({ field }) => (
                              <DatePicker
                                {...field}
                                placeholderText='Select date'
                                className='input-class w-90'
                                onChange={(date) => field.onChange(date)}
                                value={field.value ? moment(field.value) : ''}
                                format='MM-DD-YYYY'
                              />
                            )}
                          />
                        </Col>
                        <Col span={7}>
                          <label>Attendees</label>
                          <Controller
                            name={`actions.${index}.associated_users`}
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                className={`select-class-multiple ${errors[`actions.${index}.associated_users`]
                                  ? 'border-error'
                                  : ''
                                  }`}
                                mode='multiple'
                                size='default'
                                placeholder='Select attendees'
                                bordered={false}
                                onChange={(net) => {
                                  field.onChange(net);
                                }}
                                options={members.map((member) => ({
                                  value: member.id,
                                  label: member.email || member.name,
                                }))}
                              />
                            )}
                          />
                        </Col>
                        <Col span={3} className='flex vertical'>
                          <div
                            className='close-icon'
                            onClick={() =>
                              deleteActionApi(index, action.actionId)
                            }>
                            -
                          </div>
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <div className='text-center'>
                      <p>Not actions added yet</p>
                    </div>
                  )}
                  <div
                    className='add-button-container-g'
                    onClick={() =>
                      append({
                        name: '',
                        associated_users: [],
                        meeting: meetData?.id,
                        actionId: null,
                      })
                    }>
                    <p className='text-default-blue-g'>Add Action</p>
                  </div>
                </Col>
              </Row>
            </TabPane>
          ))}
        </Tabs>
        <div>
          <h3>External Reflection</h3>
          <p>How did the participants think the meeting went?</p>
          <div className='mt-2rem'>
            <div className='mt-2rem '>
              <Controller
                name='ext_ref_value'
                control={control}
                render={({ field }) => (
                  <Radio.Group
                    {...field}
                    className='flex flex-wrap justify-center'
                    buttonStyle='solid'
                    onChange={(e) => field.onChange(e.target.value)}>
                    {_.range(-10, 11).map((item) => (
                      <Radio.Button
                        className={`font-18 ${errors.emotion_value ? 'border-error' : ''
                          }`}
                        value={item}>
                        {item}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                )}
              />
            </div>
            <div className='mt-2rem flex justify-beetween'>
              <input
                className={`input-class ${errors.ext_ref_first_keyword ? 'border-error' : ''
                  }`}
                aria-invalid={errors.ext_ref_first_keyword ? 'true' : 'false'}
                {...register('ext_ref_first_keyword')}
                placeholder='Word #1'
              />
              <input
                className={`input-class ${errors.ext_ref_second_keyword ? 'border-error' : ''
                  }`}
                aria-invalid={errors.ext_ref_second_keyword ? 'true' : 'false'}
                {...register('ext_ref_second_keyword')}
                placeholder='Word #2'
              />
            </div>
          </div>
        </div>
        <div className='container-button'>
          <button type='submit'>Next</button>
        </div>
      </form>
    </div>
  );
};

export default MeetingsComments;
