import axios from "axios";
import moment from 'moment';
import { API_BASE_URL, SMART_SEARCH } from "../../config/constants";

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    }
  }
}

export function getNewSmartSearch() {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}smart-searches/?page_size=100`, {
      signal: controller.signal,
      ...headers()
    }),
    controller
  }
}

export function patchNewSmartSearch({ id, favorite }) {
  const controller = new AbortController();

  return {
    call: axios.patch(`${API_BASE_URL}smart-searches/${id}/`, { favorite }, headers())
  }
}

export function getSmartSeach(action) {

  const publicSearch = window.location.host == SMART_SEARCH ? {
    headers: {}
  } : headers();

  return {
    call: axios.post(`${API_BASE_URL}smart-actions/`, { action }, publicSearch)
  }
}

export function getSmartKeyDrivers(action) {
  const data = {
    "goal": action,
  }

  return {
    call: axios.post(`${API_BASE_URL}smart-key-drivers/`, data, headers()),
  };
}

export function getSmartSeachSprint(data) {

  const publicSearch = window.location.host == SMART_SEARCH ? {
    headers: {}
  } : headers();

  return {
    call: axios.post(`${API_BASE_URL}smart-sprints/`, data, publicSearch)
  }
}

export function getSmartSeachV1(term) {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}smart-search/?nlm=true&google_scholar=true&term=${term}`, {
      signal: controller.signal,
      ...headers()
    }),
    controller
  }
}

export function getAllResearchs(id) {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}mission-smart-searches/?mission=${id}`, {
      signal: controller.signal,
      ...headers()
    }),
    controller
  }
}

export function getSummaryByResearch(data) {
  const controller = new AbortController();

  return {
    call: axios.post(`${API_BASE_URL}smart-summary/`, { text: data }, {
      timeout: 20000,
      ...headers()
    }),
    controller
  }
}

export function postSmartSeach(data) {
  return {
    call: axios.post(`${API_BASE_URL}mission-smart-searches/`, data, headers())
  }
}


export function patchPatientLanguages(data) {
  return {
    call: axios.patch(`${API_BASE_URL}patient-languages/${data.id}/`, data, headers())
  }
}

export function deleteSmartSolution(id) {
  return {
    call: axios.delete(`${API_BASE_URL}smart-searches/${id}/`, {
      ...headers()
    }),
  }
}

export function postContactUs(data) {
  return {
    call: axios.post(`${API_BASE_URL}contact-us/`, data)
  }
}