import React from 'react';

export default function RegisterationSuccesPage(prop) {
  return (
    <div className='verify-email'>
      <div className='verify-email__content'>
        <div className='verify-email__content--logo'>
          <span>
            <img src='/img/blueagilis-blacklogo.png' alt='logo' />
          </span>
        </div>
        <h3>Verify your email </h3>
        <p>
          Click the link in the email we sent to &nbsp;
          <>{prop.props.signUpResponse.email}</>.
        </p>
        <p>This helps keep your account secure.</p>
        {/* <p>No email in your inbox or spam folder? Let’s resend it.</p> */}
        <p>
          Proceed to the login page from <a onClick={() => prop.resetToLogin(false)}>here</a>
        </p>
        <p>
          If you mistyped your email when signing up,
          <a onClick={() => prop.resetToLogin(true)}>Create a new account.</a>
        </p>
      </div>
    </div>
  );
}
