import React from 'react';
import {CircularProgressbar} from 'react-circular-progressbar';

export const PercentageCard: React.FC<{
  title: string;
  percentage: number;
  onClick?:()=>void
}> = ({title, percentage,onClick}) => {
  return (
    <div className='PercentageCard hover:border-blue-200/80 rounded-lg shadow-lg w-full bg-white p-4 flex gap-2.5 items-center justify-center md:flex-col' onClick={onClick} data-value={title}>
      <div className='flex-1' data-value={title}>
        <h3 className='text-lg text-center font-semibold tracking-tight break-normal' data-value={title}>
          {title}
        </h3>
      </div>
      <div className='flex-1 max-w-[100px]'>
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          strokeWidth={10}
          styles={{
            path: {
              stroke: '#06a5bb',
              strokeLinecap: 'round',
              transition: 'stroke-dashoffset 0.5s ease 0s',
            },
            trail: {
              stroke: '#e6e9ef',
              strokeLinecap: 'round',
            },
            text: {
              fill: '#000',
              fontSize: '1.25rem',
              fontWeight: 600,
            },
          }}
        />
      </div>
    </div>
  );
};
