import React from 'react';
import { Provider } from 'react-redux';
import store from '../../ReduxV2/store';
import Meetings from './Meetings/Meetings';
import './styles/Meet.scss';


/**
 * Renders the Meet component.
 *
 * @return {JSX.Element} The rendered Meetings component wrapped in a Provider.
 */
const Meet = () => {
  return <Provider store={store}>
    <Meetings />
  </Provider>;
};

export default Meet;
