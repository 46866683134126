import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {getNotification} from 'Components/GetNotification';
import {
  getObservation,
  patchObservation,
  postObservation,
} from 'Services/Observation.service';
import './styles/Observations.scss';
import {debounce} from 'lodash';
import TextareaDebounce from 'Components/TextareaDebounce/TextareaDebounce';
import DebounceLoading, {
  dialogStartSubject$,
  dialogStopSubject$,
} from 'Components/DebounceLoading/DebounceLoading';
import {AUTOSAVING_DELAY} from '../../../../../config/constants';
import {Observation, ObservationField, ObservationLog} from 'types/Observation';
import {UpdateLogCard} from 'Components/molecules/card/UpdateLogCard';

const observation_fields: ObservationField[] = [
  {
    name: 'pride_in',
    label: 'What does the practice take pride in?',
  },
  {
    name: 'physical_env',
    label: 'Observations about the physical environment:',
  },
  {
    name: 'workflows',
    label: 'Observations about practice workflows:',
  },
  {
    name: 'org_or_norms',
    label: 'Observations about organizational and/or practice norms',
  },
  {
    name: 'additional',
    label: 'Additional Observations',
  },
];

/**
 * Renders the Observations component.
 *
 * @return {JSX.Element} The rendered component.
 */
const Observations = () => {
  const [dataObservation, setDataObservation] = useState<Observation[]>([]);
  const observationLogs = dataObservation[0]?.logs ?? ([] as ObservationLog[]);
  const {
    handleSubmit,
    control,
    setValue,
    formState: {},
  } = useForm({
    defaultValues: {
      pride_in: '',
      physical_env: '',
      workflows: '',
      org_or_norms: '',
      additional: '',
    },
  });

  const refForm = useRef<HTMLButtonElement | null>(null);

  const network = useSelector((state) => {
    //@ts-ignore
    return state.network.value;
  });

  const saveObservation = async (params: any) => {
    dialogStartSubject$.setSubject = true;
    const data = {
      organization: network,
      ...params,
    };

    try {
      if (dataObservation[0]?.id) {
        delete data.organization;
        await patchObservation(dataObservation[0]?.id, data).call;
      } else {
        await postObservation(data).call;
      }
      getNotification('success', {
        header: 'Success!',
        body: 'Observation updated successfully',
      });

      loadObservation();
    } catch (error) {}
    dialogStopSubject$.setSubject = false;
  };

  const loadObservation = async () => {
    try {
      if (network) {
        const res = await getObservation(network).call;
        setDataObservation(res.data.results);
        setValue('pride_in', res.data.results[0]?.pride_in);
        setValue('physical_env', res.data.results[0]?.physical_env);
        setValue('workflows', res.data.results[0]?.workflows);
        setValue('org_or_norms', res.data.results[0]?.org_or_norms);
        setValue('additional', res.data.results[0]?.additional);
      }
    } catch (error) {}
  };

  const activeSubmitForm = () => {
    if (refForm.current) {
      refForm?.current.click();
    }
  };

  const debounceActive = useCallback(
    debounce(activeSubmitForm, AUTOSAVING_DELAY),
    []
  );

  useEffect(() => {
    loadObservation();
  }, [network]);

  return (
    <div className='observations'>
      <div className='w-80'>
        <form onSubmit={handleSubmit(saveObservation)} className='mt-15'>
          <div className='title p-10'>
            <div className='flex justify-beetween items-center font-18'>
              <p className='text-white'>Observations</p>
              <div>
                <button
                  type='submit'
                  className='btn-edit hidden'
                  ref={(e) => (refForm.current = e)}>
                  Save
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className='flex flex-wrap gap-10'>
              {observation_fields.map((field, index) => (
                <div key={index} className='w-49'>
                  <p>{field.label}</p>
                  <TextareaDebounce
                    name={field.name}
                    control={control}
                    debounceAction={debounceActive}
                    className={`w-100`}
                  />
                </div>
              ))}
            </div>
          </div>
        </form>
      </div>
      <div className='w-20 mt-15 px-10'>
        <UpdateLogCard logs={observationLogs} />
      </div>
      <DebounceLoading />
    </div>
  );
};

export default Observations;
