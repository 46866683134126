import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import './CollaboratorsModal.scss';
import {Button, Col, Divider, Input, Modal, Row, Select} from 'antd';
import {
  getMemberList,
  postTeamPersona,
  updateMember,
} from '../../../../Services/Teams.service';
import {getMissionStaffById} from '../../../../Services/MissionTeams.service';
import {PersonaData} from '../../../OrganisationInsights/Children/ViewDetails/components/Team/components';
import {PlusOutlined} from '@ant-design/icons';
import {useParams} from 'react-router-dom';
import {
  getCollaboratorId,
  postAddCollaborator,
} from '../../../../Services/UserPat.service';

const CollaboratorsModal = ({
  visibleModal,
  closeVisibleModal,
  collaboratorId = 0,
  patId,
}) => {
  const [attendees, setAttendees] = useState([]);
  const [namePersona, setNamePersona] = useState('');
  const [selectedPersona, setSelectedPersona] = useState({});

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: {errors},
    watch,
    getValues,
  } = useForm();
  const watchPersonaId = watch('persona');

  const {id} = useParams();

  const addPersona = async () => {
    try {
      const res = await postTeamPersona([
        {
          name: namePersona,
          email: '',
          user: '',
          organization: id,
        },
      ]).call;
      loadAttendees();
    } catch (error) {}
  };

  const loadAttendees = async () => {
    try {
      const res = await getMemberList(id).call;
      setAttendees(
        res.data?.results?.map((att) => ({value: att.id, label: att.name}))
      );
    } catch (error) {}
  };

  const editCollaborator = async () => {
    try {
      const res = await getCollaboratorId(collaboratorId).call;
      setValue('persona', res?.data?.persona?.id);
    } catch (error) {
      console.log(error);
    }
  };

  const updatePersonaData = (key, data) => {
    setSelectedPersona({...selectedPersona, [key]: data});
  };

  const addCollaborator = async ({persona}) => {
    const {email, role} = selectedPersona;
    try {
      const res = await updateMember({id: persona, email, role});
      if (!collaboratorId) {
        const resAddCollaborator = await postAddCollaborator({
          user_pat: patId,
          persona,
        }).call;
      }
      closeVisibleModal();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    reset();
    if (collaboratorId) {
      editCollaborator();
    }
    loadAttendees();
  }, [collaboratorId]);

  return (
    <Modal
      title='Add Collaborator'
      visible={visibleModal}
      onCancel={closeVisibleModal}
      footer={false}
      className='mission-modal'>
      <form onSubmit={handleSubmit(addCollaborator)}>
        <p className='text-black text-lg mb-10'>
          Name <span className='text-red'>*</span>
        </p>
        <Row gutter={10}>
          <Col span={24} className='mb-10'>
            <p>You could select a Team Member or add a new one.</p>
            <Controller
              name='persona'
              control={control}
              rules={{required: true}}
              render={({field}) => (
                <Select
                  {...field}
                  className={`staff-select-class ${
                    errors.persona ? 'border-error' : ''
                  }`}
                  placeholder='Select an option'
                  optionFilterProp='children'
                  bordered={false}
                  onChange={(net) => {
                    field.onChange(net);
                  }}
                  options={attendees}
                  disabled={collaboratorId}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{margin: '8px 0'}} />
                      <div className='flex'>
                        <Input
                          className='input-class ml-10'
                          value={namePersona}
                          onChange={(e) =>
                            setNamePersona && setNamePersona(e.target.value)
                          }
                        />
                        <Button
                          type='text'
                          icon={<PlusOutlined />}
                          onClick={() => addPersona && addPersona()}>
                          Add new
                        </Button>
                      </div>
                    </>
                  )}
                />
              )}
            />
          </Col>
          <Col span={24} className='mb-10'>
            <PersonaData
              personaId={watchPersonaId}
              showEmail
              showEditNotification
              updatePersonaData={(key, data) =>
                updatePersonaData && updatePersonaData(key, data)
              }
            />
          </Col>
          <Col span={24} className='text-center mt-15'>
            <button
              type='button'
              className='modal-delete-btn'
              onClick={closeVisibleModal}>
              Cancel
            </button>
            <button type='submit' className='modal-dont-delete ml-10'>
              Save
            </button>
          </Col>
        </Row>
      </form>
    </Modal>
  );
};

export default CollaboratorsModal;
