import axios from 'apiConfig';
import moment from 'moment';
import { API_BASE_URL } from '../../config/constants';

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    },
  };
};

export function getMemberList(orgId) {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}team-personas/?organization=${orgId}`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  };
}

export function getSearchMember(value) {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}team-personas/?q=${value}`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  };
}

export function getMemberByPk(id) {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}team-personas/${id}/`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  };
}

export function postTeamPersona(data) {
  return {
    call: axios.post(`${API_BASE_URL}team-personas/`, data, headers()),
  };
}

export function patchTeamPersona(data) {
  return {
    call: axios.patch(
      `${API_BASE_URL}team-personas/${data?.id}/`,
      data,
      headers()
    ),
  };
}

export function getPersonaMission(id) {
  const controller = new AbortController();
  return {
    call: axios.get(
      `${API_BASE_URL}team-personas-missions/?team_persona=${id}`,
      {
        signal: controller.signal,
        ...headers(),
      }
    ),
    controller,
  };
}

export function getPersonasFromMission(id) {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}team-personas-missions/?mission=${id}`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  };
}

export function getDataWithFilters(personaId, missionId) {
  const controller = new AbortController();
  return {
    call: axios.get(
      `${API_BASE_URL}team-personas-missions/?team_persona=${personaId}&mission=${missionId}`,
      {
        signal: controller.signal,
        ...headers(),
      }
    ),
    controller,
  };
}

export function postRelatedMemberToMission(data) {
  return {
    call: axios.post(`${API_BASE_URL}team-personas-missions/`, data, headers()),
  };
}

export function patchRelatedMemberToMission(data) {
  return {
    call: axios.patch(
      `${API_BASE_URL}team-personas-missions/${data?.id}/`,
      data,
      headers()
    ),
  };
}

export function addMemberToTeam(member) {
  return {
    call: axios.post(`${API_BASE_URL}team-personas/`, member, headers()),
  };
}

export function updateMember(member) {
  const { id } = member;
  delete member.id;
  return {
    call: axios.patch(`${API_BASE_URL}team-personas/${id}/`, member, headers()),
  };
}

export function postMember(member) {
  return {
    call: axios.post(`${API_BASE_URL}team-personas/`, [member], headers()),
  };
}

export function deleteMember(id) {
  return {
    call: axios.delete(`${API_BASE_URL}team-personas/${id}/`, headers()),
  };
}

export function uploadProfileImage(id, file) {
  let formData = new FormData();
  formData.append('image', file.file);

  return {
    call: axios.patch(
      `${API_BASE_URL}team-personas/${id}/`,
      formData,
      headers()
    ),
  };
}

export function getArchetypes() {
  return {
    call: axios.get(`${API_BASE_URL}archetypes/`, headers()),
  };
}

export function getTeamPersonasMissions(id) {
  return {
    call: axios.get(
      `${API_BASE_URL}team-personas-missions/?team_persona=${id}`,
      headers()
    ),
  };
}