import moment from 'moment';

export const getShortDate = (date:Date) => {
  return moment(date).format('yyyy-MM-DD');
};

export const getDisplayDate = (date:Date) => {
  return moment(date).format('DD MMM yyyy');
};

export const getDisplayTime = (date:Date) => {
  return moment(date).format('HH:MM:SS a');
};
export const getDisplayDateTime = (date:Date) => {
  return moment(date).format('lll');
};

// Used for current Date and Time
export const formatToISOStringWithoutZ = (date: Date): string => {
  const isoString = date.toISOString();
  return isoString.slice(0, 19);
};

// Used for start date
export const formatToYearMonthDay = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}T00:00:00`;
};

export const calculateStartDateEndDateBasedOnTimeRange = (
  timeRange: string
): string => {
  const currentDate = new Date();
  const daysInMonth = 30; // Assuming a month has approximately 30 days

  switch (timeRange) {
    case 'Last Month':
      const lastMonthStart = new Date(currentDate);
      lastMonthStart.setDate(currentDate.getDate() - daysInMonth);
      lastMonthStart.setHours(0, 0, 0, 0);
      const lastMonthEnd = new Date(currentDate);
      return `${formatToYearMonthDay(
        lastMonthStart
      )},${formatToISOStringWithoutZ(lastMonthEnd)}`;

    case 'Last 3 Months':
      const last3MonthsStart = new Date(currentDate);
      last3MonthsStart.setDate(currentDate.getDate() - daysInMonth * 3);
      last3MonthsStart.setHours(0, 0, 0, 0);
      const last3MonthsEnd = new Date(currentDate);
      return `${formatToYearMonthDay(
        last3MonthsStart
      )},${formatToISOStringWithoutZ(last3MonthsEnd)}`;

    case 'Last 6 Months':
      const last6MonthsStart = new Date(currentDate);
      last6MonthsStart.setDate(currentDate.getDate() - daysInMonth * 6);
      last6MonthsStart.setHours(0, 0, 0, 0);
      const last6MonthsEnd = new Date(currentDate);
      return `${formatToYearMonthDay(
        last6MonthsStart
      )},${formatToISOStringWithoutZ(last6MonthsEnd)}`;

    case 'Last Year':
      const lastYearStart = new Date(currentDate);
      lastYearStart.setFullYear(currentDate.getFullYear() - 1);
      lastYearStart.setHours(0, 0, 0, 0);
      const lastYearEnd = new Date(currentDate);
      return `${formatToYearMonthDay(
        lastYearStart
      )},${formatToISOStringWithoutZ(lastYearEnd)}`;

    default:
      return '';
  }
};