import React, {useEffect, useRef, useState} from 'react';
import {DeleteOutlined} from '@ant-design/icons';
import {Button, Input, InputRef, Table, Upload} from 'antd';
import {
  deleteDemographicSpecialist,
  getDemographicSpecialist,
  postDemographicSpecialist,
  uploadSpecialistDocument,
} from '../../../../Services/Specialist.service';
import SpecialistDocuments from './components/SpecialistDocuments/SpecialistDocuments';
import './styles/Specialist.scss';

const {Search} = Input;

/**
 * Renders a component that displays a list of specialists and allows
 * the user to upload documents for each specialist.
 *
 * @param {Object} demographic - The demographic information.
 * @return {JSX.Element} The rendered component.
 */
const Specialist: React.FC<{
  demographic: number;
}> = ({demographic}) => {
  const [specialists, setSpecialists] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const specialistName = useRef<InputRef>(null);

  type DocumentsRecord = {
    id: number;
    name: string;
    demographics: number;
  };

  const columns = [
    {
      title: 'Specialist',
      dataIndex: 'name',
      key: 'name',
      width: '120px',
    },
    {
      title: 'Documents',
      dataIndex: '',
      key: '',
      render: (_: any, record: DocumentsRecord) => {
        const uploadFile = async (e: any) => {
          setUploadLoading((prev) => !prev);
          try {
            let formData = new FormData();
            if (record?.id) {
              //@ts-ignore
              formData.append('specialist', record.id);
            }
            formData.append('file', e.file?.originFileObj);

            await uploadSpecialistDocument(formData).call;
            setUploadLoading((prev) => !prev);
          } catch (error) {}
        };

        return (
          <Upload
            beforeUpload={() => {}}
            showUploadList={false}
            customRequest={() => {}}
            onChange={uploadFile}>
            <Button>Upload documents (Optionally)</Button>
          </Upload>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (_: any, record: DocumentsRecord) => (
        <SpecialistDocuments
          specialistId={record?.id}
          uploadLoading={uploadLoading}
        />
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '50px',
      render: (_: any, record: DocumentsRecord) => (
        <div>
          <a className='ml-10' onClick={() => deleteSpecialist(record?.id)}>
            <DeleteOutlined style={{color: '#F46767'}} />
          </a>
        </div>
      ),
    },
  ];

  /**
   * Deletes a specialist with the given ID.
   *
   * @param {type} id - The ID of the specialist to delete.
   * @return {type} undefined
   */
  const deleteSpecialist = async (id: number) => {
    try {
      await deleteDemographicSpecialist(id).call;
      loadSpecialists();
    } catch (error) {}
  };

  const addSpecialist = async () => {
    try {
      if (specialistName.current?.input?.value) {
        const data = {
          demographics: demographic,
          name: specialistName.current.input.value,
        };
        await postDemographicSpecialist(data).call;
        loadSpecialists();
      }
    } catch (error) {}
  };

  const loadSpecialists = async () => {
    try {
      const res = await getDemographicSpecialist(demographic).call;
      setSpecialists(res?.data?.results);
    } catch (error) {}
  };

  useEffect(() => {
    if (demographic) {
      loadSpecialists();
    }
  }, [demographic]);

  return (
    <div className='specialist'>
      <div className='flex'>
        <p>Do you have any preferred provider agreements?</p>
        <Search
          placeholder='Add Names with Enter or the click on the button'
          enterButton='add'
          ref={specialistName}
          size='large'
          onSearch={addSpecialist}
        />
      </div>
      <div className='mt-15'>
        <Table
          columns={columns}
          dataSource={specialists}
          pagination={false}
          scroll={{y: 240}}
        />
      </div>
    </div>
  );
};

export default Specialist;
