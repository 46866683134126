import axios, { AxiosResponse } from "axios";
import { API_BASE_URL } from "../../config/constants";
import { AbortableRequestConfig } from 'types/Request';
import { PostPatchSprintTeam, SprintTeam } from "types/SprintTeam";

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    }
  }
}

export function getSprintEngagedById(id: number): {
  call: Promise<AxiosResponse<SprintTeam>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}sprints-engaged-teams/${id}/`, {
      signal: controller.signal,
      ...headers()
    } as AbortableRequestConfig),
    controller
  }
}

export function postSprintEngagged(data: PostPatchSprintTeam): {
  call: Promise<AxiosResponse<SprintTeam>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}sprints-engaged-teams/`, data, headers())
  }
}

export function patchSprintEngagged(data: PostPatchSprintTeam): {
  call: Promise<AxiosResponse<SprintTeam>>;
} {
  return {
    call: axios.patch(`${API_BASE_URL}sprints-engaged-teams/${data.id}/`, data, headers())
  }
}

export function deleteSprintEngagged(id: number): {
  call: Promise<AxiosResponse<void>>;
} {
  return {
    call: axios.delete(`${API_BASE_URL}sprints-engaged-teams/${id}/`, {
      ...headers()
    }),
  }
}