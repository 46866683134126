import {
  DELETE_DESIGNATION_START,
  DELETE_DESIGNATION_FAILED,
  DELETE_DESIGNATION_SUCCESS,
} from '../../Redux.constants';
import {Default} from './InitialState';
import {deleteApiStarted, deleteApiFailed, deleteApiSuccess} from './Helper';

const deleteDesignationReducer = (state = Default(), action) => {
  switch (action.type) {
    case DELETE_DESIGNATION_START:
      return deleteApiStarted(state, action);
    case DELETE_DESIGNATION_FAILED:
      return deleteApiFailed(state, action);
    case DELETE_DESIGNATION_SUCCESS:
      return deleteApiSuccess(state, action);
    default:
      return state;
  }
};
export default deleteDesignationReducer;
