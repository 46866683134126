import React from 'react';

type Props = {
  children: React.ReactNode;
  textCenter?: boolean;
};

export const DashboardCard = ({children, textCenter}: Props) => {
  return (
    <div
      className={`bg-white flex flex-col gap-2 mx-12 p-4 ${
        textCenter && 'text-center'
      }`}>
      {children}
    </div>
  );
};
