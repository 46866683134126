import {createSlice} from '@reduxjs/toolkit';
import { PftJourneyCategoryScore } from 'types/PftJourney';
import { Phase } from 'types/TransformationJourney';
type PftJourneyState = {
  id: number | null;
  phases: Phase[];
  category_scores: PftJourneyCategoryScore[];
};

const initialState:PftJourneyState = {
  id: null,
  phases: [],
  category_scores: [],
};

export const pftJourneySlice = createSlice({
  name: 'pft',
  initialState,
  reducers: {
    selectPftJourney: (state, {payload}) => {
      state.id = payload;
    },
    setPftJourneyPhases: (state, {payload}) => {
      // Order the phases no matter the order the backend send you the request
      payload.sort((a:Phase, b:Phase) => {
        const phaseA = parseInt(a.name.split(' ')[1]);
        const phaseB = parseInt(b.name.split(' ')[1]);
        return phaseA - phaseB;
      });
      state.phases = payload;
    },
    setPftCategoryScores: (state, {payload}) => {
      state.category_scores = payload;
    },
    cleanPftJourney: (state) => {
      state.id = null;
      state.phases = [];
      state.category_scores = [];
    },
  },
});

export const {selectPftJourney, setPftCategoryScores, setPftJourneyPhases, cleanPftJourney} = pftJourneySlice.actions;
