import * as React from 'react';

type LinkButtonProps = {
  variant?: 'primary' | 'secondary';
  size?: 'sm' | 'md' | 'lg';
  children: React.ReactNode;
} & React.LinkHTMLAttributes<HTMLAnchorElement>;

const LinkButtonVariants = {
  primary: `bg-blue-600 border hover:bg-blue-700 hover:text-white 
    active:border-blue-400 
    aria-disabled:bg-bray-600 aria-disabled:text-white`,
  secondary: `bg-transparent border border-blue-600 text-blue-600 
    hover:bg-blue-600/20 
    active:bg-blue-600/20 active:border-blue-400 
    aria-disabled:bg-gray-600 aria-disabled:text-gray-800`,
};

const LinkButtonSizes = {
  sm: 'px-3 py-1.5 text-sm',
  md: 'px-3 py-2 text-base',
  lg: 'px-3 py-2.5 text-lg',
};

export const LinkButton = React.forwardRef(function Button(
  props: LinkButtonProps,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const {variant = 'primary', size = 'md', ...linkProps} = props;
  const baseStyle =
      'flex gap-x-1.5 items-center text-white rounded-md whitespace-nowrap transition-colors duration-200 ease-in-out',
    variantStyle = LinkButtonVariants[variant],
    sizeStyle = LinkButtonSizes[size];

  return (
    <a
      className={[baseStyle, sizeStyle, variantStyle].join(' ')}
      {...linkProps}
      rel='noopener noreferrer'
      ref={ref}
    />
  );
});
