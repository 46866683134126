import React from 'react';

import {SwitchContext} from '../../../Components/new/Context/ComponentSwitchContext';

const Coloredcard = (props) => {
  const {changecomponent} = React.useContext(SwitchContext);

  return (
    <div
      className={props.style.style}
      onClick={() => {
        if (props.page) changecomponent(props.page);
      }}>
      <span className={props.icon}></span>
      <span className='mt-1'>{props.text}</span>
      {props.children}
    </div>
  );
};

export default Coloredcard;

export const Greycard = (props) => {
  return (
    <a href={props.file}>
      <div className='GreyDashboardDiv dashboarddivgradient3'>
        <span>
          <span>{props.file.split('/').pop().replaceAll('+', ' ')}</span>
        </span>
        <span className='FloatRight'>
          <img className='DownloadImg'></img>
        </span>
      </div>
    </a>
  );
};
