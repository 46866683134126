import React, { useState, useEffect } from 'react';
import useManualApiWithLoading from 'Hooks/useManualApiWithLoading';
import { Modal, Tooltip, Collapse, Select } from 'antd';
import TagSelect from 'Components/TagSelect';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { PatStatus, ResponseListPatType } from 'types/Pat';
import type { SelectProps } from 'antd';
import {
  fetchUserInfo,
  createOrUpdatePat,
  cleanPat,
  updatePatTitle,
  updatePatType,
  updateAnswer,
  updateAnswerNote,
  selectPrevQuestion,
  selectNextQuestion,
  cleanStatusMessage,
  fetchPats,
  deleteCollaboratorById,
  updatePatTags,
  fetchPatCategories
} from 'ReduxV2/state'; // Adjust import paths as needed
import { RootState } from 'types/reduxState';
import { getNotification } from 'Components/GetNotification';
import OverwriteChangesModal from '../OvewriteChangesModal';
import { PatTypes, getPatTypes } from 'Services/UserPat.service';
import { removeTagsForArray } from 'Services/Tag.service';
import { TagType } from 'types/Tags';

const { Panel } = Collapse;
const MESSAGES = {
  CANNOT_ADD_COLLABORATORS: 'Please save as a draft to add collaborators.',
  CANNOT_SAVE_WITHOUT_TITLE: 'Please enter a title to save a draft.',
  CANNOT_SAVE_NOTE_WITHOUT_ANSWER: 'Please select an answer to save a note.',
};

interface AssessmentModalProps {
  isEditingCompletedPat: boolean;
  setIsEditingCompletedPat: (isEditing: boolean) => void;
  visible: boolean;
  togglePatModalVisibility: () => void;
  editCollaborator: (collaboratorId: number) => void;
  setVisibleCollaboratorModal: (visible: boolean) => void;
  setVisibleConfirmation: (visible: boolean) => void;
  loadPats: () => void;
}

const AssessmentModal: React.FC<AssessmentModalProps> = ({
  isEditingCompletedPat,
  setIsEditingCompletedPat,
  visible,
  togglePatModalVisibility,
  editCollaborator,
  setVisibleCollaboratorModal,
  setVisibleConfirmation,
  loadPats
}) => {
  const dispatch = useDispatch();

  const id = useSelector((state:RootState) => state.pat._id);
  const title = useSelector((state:RootState) => state.pat.title);
  const tags = useSelector((state:RootState) => state.pat.tags);
  const type = useSelector((state:RootState) => state.pat.type);
  const questions = useSelector((state:RootState) => state.pat.questions);
  const collaborators = useSelector((state:RootState) => state.pat.collaborators);
  const currentQuestionIndex = useSelector((state:RootState) => state.pat.currentQuestionIndex);
  const canAddCollaborators = useSelector((state:RootState) => {
    return state.pat._id !== null;
  });
  const currentQuestion = questions[currentQuestionIndex];
  const currentAnswer = useSelector((state:RootState) => {
    const answers = state?.pat?.answers ?? [];
    if(!answers.length) {
      return null;
    }
    return answers.find((answer) => answer?.competency === currentQuestion?.id) ?? null;
  });
  const isComplete = useSelector((state:RootState) => state.pat.isComplete);
  const statusMessage = useSelector((state:RootState) => state.pat.statusMessage);
  const status = useSelector((state:RootState) => state.pat.status);

  const [isOverwriteModalVisible, setIsOverwriteModalVisible] = useState(false) 
  const [debounceTimer, setDebounceTimer] = useState<any>(undefined);
  const [isSaving, setIsSaving] = useState(false);
  const [dataTypesOptions, setDataTypesOptions] = useState<SelectProps['options']>([]);
  const [tagsToBeRemoved, setTagsToBeRemoved] = useState([]);
  const [tagRefreshCounter, setTagRefreshCounter] = useState(0);

  const { 
    data:dataPatTypes,
    callFunction: fetchPatTypes
  } = useManualApiWithLoading<ResponseListPatType>({
    apiFunction: getPatTypes
  });

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    fetchPatTypes()
    if (userId) {
        dispatch((fetchUserInfo as any)(userId));
    }
  }, [])

  useEffect(() => {
    if(dataPatTypes?.data) {
      setDataTypesOptions(dataPatTypes.data.map(p => {
        return {value:p.code, label: p.name}
      }))
    }

  }, [dataPatTypes])

  useEffect(() => {
    if(type) dispatch(fetchPatCategories(type as unknown as PatTypes));
  }, [type])

  const debounceDispatch = (action:any, delay = 1000) => {
    setIsSaving(true);
    if(isSaving) return;

    clearTimeout(debounceTimer);
    const timer = setTimeout(() => {
      dispatch(action).then(() => {
        setIsSaving(false);
      });
    }, delay);
    setDebounceTimer(timer);
  };

  const handleSave = (isCompletedPat = false) => {
    setIsSaving(true);
    clearTimeout(debounceTimer);

    if(tagsToBeRemoved?.length > 0) {
      removeTagsForArray(tagsToBeRemoved, TagType.PAT, id as any);
    }
 
    setTagRefreshCounter(prev => prev + 1);
    dispatch((createOrUpdatePat(isCompletedPat ? PatStatus.COMPLETED : PatStatus.IN_PROGRESS)) as any)?.then(() => {
      setIsSaving(false);
      togglePatModalVisibility();
    });
    setIsOverwriteModalVisible(false);
  };

  useEffect(() => {
    if(statusMessage && status){
      getNotification(status, {
        header: status.charAt(0).toUpperCase() + status.slice(1),
        body: statusMessage,
      });
      dispatch(cleanStatusMessage());
    }
  }, [statusMessage]);

  useEffect(() => {
    setTagRefreshCounter(prev => prev + 1);
  }, []);

  return (
    <Modal
      data-testid='newPatModal'
      title='New Practice Assessment'
      open={visible}
      footer={false}
      onCancel={togglePatModalVisibility}
      className='assesmenttool'
      afterClose={() => {
        if(!isSaving && !isEditingCompletedPat) {
          dispatch((createOrUpdatePat(PatStatus.IN_PROGRESS)) as any)?.then(() => {
            dispatch(cleanPat());
          });
        }

        if(isEditingCompletedPat) {
          dispatch(cleanPat())
        }

        setIsSaving(false);
        loadPats();
        dispatch(fetchPats());
        setIsEditingCompletedPat(false)
      }}
      width={1200}
      style={{height: 'calc(100vh - 100px)'}}
      bodyStyle={{overflowY: 'scroll'}}>
      <OverwriteChangesModal 
        handleSave={()=> handleSave(isEditingCompletedPat)}
        handleCancel={()=>{setIsOverwriteModalVisible(false)}}
        open={isOverwriteModalVisible}
      />
      <div className='flex flex-row justify-start gap-4 mb-2'>
        <div className=''>
          <h3>Title</h3>
          <input
            data-testid='inputTitle'
            className='input-class'
            placeholder='Title'
            value={title}
            onChange={(e) => {
              dispatch(updatePatTitle(e.target.value));
              if(!isEditingCompletedPat)
              debounceDispatch(createOrUpdatePat(PatStatus.IN_PROGRESS));
            }}
        />
        </div>

        <div className=''>
          <h3>Tag</h3>
          <TagSelect
            id={`${id}-${tagRefreshCounter}`}
            tagsToBeRemoved={tagsToBeRemoved}
            setTagsToBeRemoved={setTagsToBeRemoved}
            selectedTags={tags}
            setSelectedTags={(payload:any) => {
             dispatch(updatePatTags(payload));
            }}
          />
        </div>
      </div>
      <div className='my-15'>
        <h3>Select Type of Assessment</h3>
        <Select
          placeholder='Type'
          value={dataTypesOptions?.find(o => o.value == type) ?? dataTypesOptions?.find(o => o.value == PatTypes.blueAgilis)}
          options={dataTypesOptions}
          className='!min-w-[300px] !font-medium !rounded'
          onChange={(value) => {
            dispatch(fetchPatCategories(value as unknown as PatTypes)),
            dispatch(updatePatType(value));
            if(!isEditingCompletedPat)
            debounceDispatch(createOrUpdatePat(PatStatus.IN_PROGRESS));
          }}
 
        />
      </div>
      <div className='w-full'>
        <Collapse expandIconPosition='end'>
          <Panel header='Collaborators' key='1'>
            <div>
              <div
                className={`text-end mb-15`}>
                {!canAddCollaborators && (
                  <Tooltip title={MESSAGES.CANNOT_ADD_COLLABORATORS}>
                    <button
                      type='button'
                      disabled={true}
                      className='modal-delete-btn bg-white cursor-auto opacity-50'
                      onClick={() => setVisibleCollaboratorModal(true)}>
                      + Add Collaborator
                    </button>                    
                  </Tooltip>
                )}
                {!!canAddCollaborators && (
                  <button
                    type='button'
                    className='modal-delete-btn bg-white'
                    onClick={() => setVisibleCollaboratorModal(true)}>
                    + Add Collaborator
                  </button>
                )}
              </div>
              <table
                className={`w-100 ${
                  collaborators?.length ? 'd-block' : 'd-none'
                }`}>
                <thead>
                  <tr className='text-left'>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {collaborators?.map((collaborator, index) => (
                    <tr
                      key={index}
                      className={`text-20 p-5 ${
                        index % 2 != 0 ? 'bg-white' : 'inspect-odd'
                      }`}>
                      <td className='p-5 text-sm'>
                        {collaborator?.persona?.name}
                      </td>
                      <td className='p-5 text-sm'>
                        {collaborator?.persona?.email}
                      </td>
                      <td className='p-5 text-sm flex'>
                        <a
                          onClick={() => {
                            editCollaborator(collaborator?.id);
                          }}>
                          <EditOutlined />
                        </a>
                        <a
                          className='ml-10'
                          onClick={() => {
                            if(collaborator?.id) {
                              dispatch(deleteCollaboratorById(collaborator.id));
                            }
                          }}>
                          <DeleteOutlined style={{color: '#F46767'}} />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Panel>
        </Collapse>
      </div>
      <div className='flex justify-center my-15'>
        <div className='flex items-center'>
          <button 
            data-testid='prevButtton'
            type='button'
            className='p-5 cursor-pointer disabled:cursor-auto disabled:opacity-50'
            disabled={currentQuestionIndex === 0}
            onClick={() => {
            dispatch(selectPrevQuestion());
          }}>
            Previous
          </button>
          <p data-testid='questionText' className='font-18 mx-15'>
            Question: {currentQuestionIndex + 1} / {questions?.length}
          </p>
          <button 
            data-testid='nextButton'
            className='p-5 cursor-pointer disabled:cursor-auto disabled:opacity-50'
            disabled={currentQuestionIndex === questions?.length - 1}
            onClick={() => {dispatch(selectNextQuestion());}}
          >
            Next
          </button>
        </div>
      </div>
      <div>
        <div className='bg-categorie'>
          <p className='text-white'>
            Category: {currentQuestion?.category_name}
          </p>
        </div>
        <div className='bg-competencie'>
          <div className='p-10'>
            <p>{currentQuestion?.description}</p>
          </div>
          <div className='p-10 flex flex-wrap gap-10'>
            {currentQuestion?.options.map((option, index) => (
              <div
                data-testid='currentQuestionOption'
                key={index}
                className={`p-5 pointer ${option?.id == currentAnswer?.pat_option ? 'selected-card' : 'card'}`}
                onClick={() => {
                  dispatch(updateAnswer({
                    competency: option?.competency,
                    pat_option: option?.id,
                  }));
                  if(!isEditingCompletedPat) 
                  dispatch(createOrUpdatePat(PatStatus.IN_PROGRESS));
                }}>
                <p className='select-none'>{option?.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className='mt-15'>
          <p>Notes</p>
          <textarea
            data-testid='notesTextArea'
            className='textarea'
            value={currentAnswer?.notes ?? ''}
            onChange={(e) => {
              dispatch(updateAnswerNote({
                competency: currentQuestion.id,
                notes: e.target.value
              }));

              if(!isEditingCompletedPat)
              debounceDispatch(createOrUpdatePat(PatStatus.IN_PROGRESS));
            }}
          />
        </div>
      </div>
      <div className='flex justify-center my-15 gap-4'>
        {(title.length == 0) && (
          <Tooltip 
            title={MESSAGES.CANNOT_SAVE_WITHOUT_TITLE}>
            <button
              data-testid='saveAsDraftButtonDisabled'
              type='button'
              disabled={true}
              className='modal-dont-delete block !cursor-auto !opacity-50'>
                SAVE AS DRAFT
            </button>
          </Tooltip>
        )}
        {(title.length > 0) && (
          <button
            data-testid='saveChangesButton'
            type='button'
            disabled={title.length == 0 || isSaving}

            className='disabled:cursor-not-allowed disabled:opacity-50 modal-dont-delete block'
            onClick={() => isEditingCompletedPat ? setIsOverwriteModalVisible(true) : handleSave()}
          >
            {isEditingCompletedPat ? 'SAVE CHANGES' : 'SAVE AS DRAFT'}
          </button>
        )}

        <button
          data-testid='completeButton'
          type='button'
          disabled={isEditingCompletedPat || !isComplete}
          className={'modal-dont-delete block disabled:cursor-not-allowed disabled:opacity-50'}
          onClick={() => {
            setIsSaving(true);
            setVisibleConfirmation(true);
          }}>
          COMPLETE
        </button>
      </div>
    </Modal>
  );
};

export default AssessmentModal;