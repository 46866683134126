import React from 'react';
import { getUserDetailsFromStorage } from '../../utils/localStorageOperations';
import { ProfileLayout } from './ProfileLayout';
import style from './style.module.scss';
import { NAV_TAB } from './UserProfile.constants';

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: NAV_TAB[0].key,
      userDetails: getUserDetailsFromStorage(),
    };
  }
  setActiveTab = (activeTab) => this.setState({ activeTab });

  commonProps = (tabDetails) => ({
    tabDetails,
    userDetails: this.state.userDetails || {},
    setActiveTab: this.setActiveTab,
  });
  renderComponent = () => {
    const activeItem = NAV_TAB.find(
      (item) => item.key === this.state.activeTab
    );
    if (!activeItem) return '';
    return <activeItem.component {...this.commonProps(activeItem)} />;
  };
  render() {
    return (
      <section className={style.userSettings}>
        <div className={style.profileLayout}>
          <ProfileLayout
            changeProfileData={this.props.changeProfileData}
            getPresignedUrl={this.props.getPresignedUrl}
            imageUrl={this.props.imageUrl}
            integrateApi={this.props.integrateApi}
            imageApi={this.props.imageApi}
            updateProfileApi={this.props.updateProfile}
            userDetails={this.state.userDetails}
            setActiveTab={this.setActiveTab}
            activeTab={this.state.activeTab}>
            {this.renderComponent()}
          </ProfileLayout>
        </div>

      </section>
    );
  }
}
export default UserProfile;
