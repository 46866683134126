import React from 'react';
import MainSearch from '../MainSearch';
import {parseWebUrl, makeWebUrl} from '../../utils/queryStringUtils';
import {withRouter} from 'react-router';
import {HEADER_SEARCH_PAGES} from './constants';
class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ' ',
      offset: this.props.props.offset,
      limit: this.props.props.size,
      hidden: this.props.props.hideSearchArea,

      searchStarted: false,
    };
  }
  componentDidMount() {
    this.setPageDetails();
  }
  setPageDetails() {
    let search = '',
      offset = this.props.props.offset,
      limit = this.props.props.size,
      hidden = this.props.props.hideSearchArea;
    const pageDetails = this.getPageDetails(this.props.location.pathname);
    if (pageDetails) {
      limit = pageDetails.defaultParams.limit;
      offset = pageDetails.defaultParams.offset;
      hidden = false;
    }
    this.setState({search, limit, offset, hidden});
  }

  getPageDetails = (pathname = '') =>
    HEADER_SEARCH_PAGES.find((item) => {
      if (item.exact && pathname === item.path) return true;
      if (!item.exact && pathname.startsWith(item.path)) return true;
    });

  onPressEnter = (e) => {
    if (e.code === 'Enter') {
      this.onChangeSearch(e);
      const params = parseWebUrl();
      if (params.q === e.target.value) return;
      params.q = e.target.value;
      params.limit = this.props.props.size;
      this.props.history.push({
        pathname: window.location.pathname,
        search: makeWebUrl(params),
      });
      this.setState({searchStarted: true});
    }
  };

  onClear = () => {
    this.setState({search: ''});
    if (this.state.searchStarted) {
      this.setState({searchStarted: false});
      const params = parseWebUrl();
      params.q = '';
      params.limit = this.props.props.size;
      this.props.history.push({
        pathname: window.location.pathname,
        search: makeWebUrl(params),
      });
    }
  };

  onChangeSearch = (e) => this.setState({search: e.target.value});

  render() {
    return (
      <>
        <MainSearch
          onChange={this.onChangeSearch}
          value={this.state.search ? this.state.search : ''}
          onPressEnter={this.onPressEnter}
          square={this.props.square}
          onClear={this.onClear}
        />
      </>
    );
  }
}
export default withRouter(SearchBar);
