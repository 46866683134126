import {
  FETCH_TEAM_BUILD_FAILED,
  FETCH_TEAM_BUILD_START,
  FETCH_TEAM_BUILD_SUCCESS,
  FETCH_OTHER_PERSON_FAILED,
  FETCH_OTHER_PERSON_START,
  FETCH_OTHER_PERSON_SUCCESS,
  ADD_TEAM_BUILD_FAILED,
  ADD_TEAM_BUILD_START,
  ADD_TEAM_BUILD_SUCCESS,
  GET_TEAM_BUILD_FAILED,
  GET_TEAM_BUILD_START,
  GET_TEAM_BUILD_SUCCESS,
} from '../../Redux.constants';
import {
  fetchTeamBuildDetailsStarted,
  fetchTeamBuildDetailsSuccess,
  fetchTeamBuildDetailsFailed,
  fetchOtherPersonDetailsStarted,
  fetchOtherPersonDetailsSuccess,
  fetchOtherPersonDetailsFailed,
  addTeamMembersStarted,
  addTeamMembersSuccess,
  addTeamMembersFailed,
  getTeamBuildStarted,
  getTeamBuildSuccess,
  getTeamBuildFailed,
} from './Helper';

const INITIAL_STATE = {
  getTeamBuildDetails: {
    isProcessing: null,
    success: null,
    error: null,
  },
  getOtherPersonDetails: {
    isProcessing: null,
    success: null,
    error: null,
  },
  addTeamMembersDetails: {
    isProcessing: null,
    success: null,
    error: null,
  },
  getTeamBuildData: {
    isProcessing: null,
    success: null,
    error: null,
  },
  teamMembersData: null,
  teamBuildData: null,
  otherPersonData: null,
  getTeamBuild: null,
};
export default function TeamBuildReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_TEAM_BUILD_START:
      return fetchTeamBuildDetailsStarted(state);
    case FETCH_TEAM_BUILD_SUCCESS:
      return fetchTeamBuildDetailsSuccess(state, action.payload);
    case FETCH_TEAM_BUILD_FAILED:
      return fetchTeamBuildDetailsFailed(state, action.payload);

    case FETCH_OTHER_PERSON_START:
      return fetchOtherPersonDetailsStarted(state);
    case FETCH_OTHER_PERSON_SUCCESS:
      return fetchOtherPersonDetailsSuccess(state, action.payload);
    case FETCH_OTHER_PERSON_FAILED:
      return fetchOtherPersonDetailsFailed(state, action.payload);

    case ADD_TEAM_BUILD_START:
      return addTeamMembersStarted(state);
    case ADD_TEAM_BUILD_SUCCESS:
      return addTeamMembersSuccess(state, action.payload);
    case ADD_TEAM_BUILD_FAILED:
      return addTeamMembersFailed(state, action.payload);

    case GET_TEAM_BUILD_START:
      return getTeamBuildStarted(state);
    case GET_TEAM_BUILD_SUCCESS:
      return getTeamBuildSuccess(state, action.payload);
    case GET_TEAM_BUILD_FAILED:
      return getTeamBuildFailed(state, action.payload);

    default:
      return {...state};
  }
}
