import axios, { AxiosResponse } from "axios";
import { API_BASE_URL } from "../../config/constants";
import { AbortableRequestConfig } from "types/Request";
import { LanguageDetail, PaginatedLanguage, PaginatedLanguageDetail, PatchLanguage, PostLanguage } from "types/Language";

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    }
  }
}

export function getAllLenguageList(): {
  call: Promise<AxiosResponse<PaginatedLanguage>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}languages/`, {
      signal: controller.signal,
      ...headers()
    } as AbortableRequestConfig),
    controller
  }
}

export function getPatientLanguages(id: number): {
  call: Promise<AxiosResponse<PaginatedLanguageDetail>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}patient-languages/?demographics=${id}`, {
      signal: controller.signal,
      ...headers()
    } as AbortableRequestConfig),
    controller
  }
}

export function postPatientLanguages(data: PostLanguage): {
  call: Promise<AxiosResponse<LanguageDetail>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}patient-languages/`, data, headers())
  }
}

export function patchPatientLanguages(data: PatchLanguage): {
  call: Promise<AxiosResponse<LanguageDetail>>;
} {
  return {
    call: axios.patch(`${API_BASE_URL}patient-languages/${data.id}/`, data, headers())
  }
}

export function deletePatientLanguages(id: number): {
  call: Promise<AxiosResponse<void>>;
} {
  return {
    call: axios.delete(`${API_BASE_URL}patient-languages/${id}/`, {
      ...headers()
    }),
  }
}