import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import queryString from 'query-string';
import HeaderNav from '../../Components/HeaderNav';
import {
  getPatCategories,
  getUsersPatDetailByToken,
  patchUsersPatCollaborators,
  patchUsersPatShared,
} from '../../Services/UserPat.service';
import { notification } from 'antd';
import './styles/SharePat.scss';

/**
 * Render the SharePat component.
 *
 * @return {JSX.Element} The rendered component.
 */
const SharePat = () => {
  const [title, setTitle] = useState('');
  const [step, setStep] = useState(0);
  const [patCompetencies, setPatCompetencies] = useState([]);
  const [patData, setPatData] = useState({});
  const [selectPatOptions, setSelectPatOptions] = useState({});
  const [notePats, setNotePats] = useState({});
  const [disable, setDisable] = useState(false);
  const [patCategories, setPatCategories] = useState([]);
  const [patType, setPatType] = useState(undefined);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
    watch,
  } = useForm();

  const { token } = useParams();
  const history = useHistory();

  const nameCategorieText = patCategories?.find(
    (test) => test.id == patCompetencies[step]?.category
  );

  const prevStep = () => {
    if (step != 0) {
      setStep(step - 1);
    }
  };

  const nextStep = () => {
    if (step != patCompetencies?.length - 1) {
      setStep(step + 1);
    }
  };

  /**
   * Selects a card based on the provided ID.
   *
   * @param {type} id - The ID of the card to be selected.
   * @return {type} undefined
   */
  const selectCard = (id) => {
    if (patData?.status != 'COMPLETED') {
      patCompetencies[step]?.pat_options.forEach(({ id }) => {
        if (
          selectPatOptions.hasOwnProperty(id) &&
          selectPatOptions[id]?.value
        ) {
          setSelectPatOptions((prev) => ({ ...prev, [id]: { value: false } }));
          return;
        }
      });
      if (!selectPatOptions[id]?.value) {
        setSelectPatOptions((prev) => ({ ...prev, [id]: { value: true } }));
      }
    } else {
      notification.error({
        message: 'Attention',
        description:
          'The pat is completed, the information of the questionnaire cannot be edited',
      });
    }
  };

  const getAllPats = async (type) => {
    try {
      const patCategoriesData = await getPatCategories(type).call;
      setPatCategories(patCategoriesData?.data);
      const categories = [];
      patCategoriesData?.data?.map((categorie) =>
        categories.push(...categorie?.pat_competencies)
      );
      setPatCompetencies(categories);
    } catch (error) { }
  };

  const loadPat = async () => {
    try {
      const res = await getUsersPatDetailByToken(token).call;
      setPatData(res.data);
      setPatType(res?.data?.type?.code)
      const options = {};
      const notesObj = {};
      res.data?.pat_options?.forEach(({ pat_option }, index) => {
        options[pat_option.id] = { value: true };
      });

      res.data?.competency_notes?.forEach(({ notes, pat_competency }, index) => {
        notesObj[pat_competency] = { value: notes };
      });
      setNotePats(notesObj);
      if (res.data?.pat_options.length) {
        setStep(res.data?.pat_options.length - 1)
        // setStep(
        //   res.data?.pat_options[res.data?.pat_options.length - 1].pat_option
        //     .competency - 1
        // );
      }
      setTitle(res?.data?.title);
      setSelectPatOptions(options);
    } catch (error) {
      history.push('/not-found');
    }
  };

  /**
   * Saves the options and competency notes for a user's Pat.
   *
   * @param {Object} params - The parameters for saving the options.
   * @return {Promise} A promise that resolves when the options are saved.
   */
  const saveOption = async (params) => {
    const pat_options = [];
    Object.keys(selectPatOptions).map((key) => {
      if (selectPatOptions[key]?.value) {
        pat_options.push({
          pat_option: key,
        });
      }
    });

    const competency_notes = [];
    Object.keys(notePats).map((key) => {
      competency_notes.push({
        pat_competency: key,
        notes: notePats[key]?.value,
      });
    });

    const data = {
      pat_options,
      competency_notes,
    };

    try {
      await patchUsersPatCollaborators(token, params).call;
      await patchUsersPatShared(token, data).call;
      notification.success({
        message: 'Success',
        description: 'The Pat options has been updated successfully',
      });
    } catch (error) { }
  };

  useEffect(() => {
    const { email } = queryString.parse(location.search);

    if (email) {
      setValue('email', email);
      setDisable(true);
    }

    loadPat();
  }, []);

  useEffect(() => {
    if(patType) getAllPats(patType);
  }, [patType])

  return (
    <div>
      <HeaderNav />
      <div className='sharepat'>
        <form onSubmit={handleSubmit(saveOption)}>
          <div className='my-15'>
            <h3 className='text-blue'>{title}</h3>
          </div>
          <p>To be a collaborator please fill the following fields:</p>
          <div className='flex justify-beetween'>
            <div className='w-50'>
              <p>
                <span className='text-red'>* </span>
                Name
              </p>
              <input
                className={`input-class w-90 ${errors.name ? 'border-error' : ''
                  }`}
                type='text'
                {...register('name', { required: true })}
              />
            </div>
            <div className='w-50'>
              <p>
                <span className='text-red'>* </span>
                Email
              </p>
              <input
                className={`input-class w-90 ${errors.email ? 'border-error' : ''
                  }`}
                type='text'
                {...register('email', { required: true })}
                disabled={disable}
              />
            </div>
          </div>
          <div className='flex justify-center my-15'>
            <div className='flex items-center'>
              <button type='button' className='p-5 pointer' onClick={prevStep}>
                Previous
              </button>
              <p className='font-18 mx-15'>
                Question: {step + 1} / {patCompetencies?.length}
              </p>
              <button type='button' className='p-5 pointer' onClick={nextStep}>
                Next
              </button>
            </div>
          </div>
          <div>
            <div className='bg-categorie'>
              <p className='text-white'>Category: {nameCategorieText?.name}</p>
            </div>
            <div className='bg-competencie'>
              <div className='p-10'>
                <p>{patCompetencies[step]?.description}</p>
              </div>
              <div className='p-10 flex flex-wrap gap-10'>
                {patCompetencies[step]?.pat_options.map((option, index) => (
                  <div
                    key={index}
                    className={`${selectPatOptions[option?.id]?.value
                      ? 'selected-card'
                      : 'card'
                      } p-5 pointer`}
                    onClick={() => selectCard(option.id)}>
                    <p>{option?.description}</p>
                  </div>
                ))}
              </div>
            </div>
            <div
              className={`mt-15 ${patData?.status == 'COMPLETED' ? 'cursor-block' : ''
                }`}>
              <p>Notes</p>
              <textarea
                className={`textarea ${patData?.status == 'COMPLETED' ? 'cursor-block' : ''
                  }`}
                value={notePats[patCompetencies[step]?.id]?.value || ''}
                onChange={(e) =>
                  setNotePats((prev) => ({
                    ...prev,
                    [patCompetencies[step]?.id]: { value: e?.target?.value },
                  }))
                }
                disabled={patData?.status == 'COMPLETED'}
              />
            </div>
          </div>
          {patData?.status != 'COMPLETED' && (
            <div className='flex justify-center my-15'>
              <button type='submit' className='modal-dont-delete mx-15'>
                SAVE AS DRAFT
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default SharePat;
