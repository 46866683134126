import axios from 'apiConfig';
import { AxiosResponse } from 'axios';
import { API_BASE_URL } from '../../config/constants';
import { 
  Mission, 
  MissionMeasure,
  MissionQualitativeItem, 
  NewMission 
} from 'types/Mission';
import { AbortableRequestConfig } from 'types/Request';

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    },
  };
};

export function getMissions(orgId: number): {
  call: Promise<AxiosResponse<Mission[]>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}missions/?organization=${orgId}`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function getMissionDetail(id: any): {
  call: Promise<AxiosResponse<Mission>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}missions/${id}/`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function getMissionsNew(orgId: any, q = ''): {
  call: Promise<AxiosResponse<Mission[]>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}missions/?organization=${orgId}&q=${q}`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function getMissionMeasures(missionId: number): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}mission-measures/?mission=${missionId}`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function postMission(data: NewMission): {
  call: Promise<AxiosResponse<Mission>>;
} {
  return {
    call: axios.post(
      `${API_BASE_URL}missions/?organization=${data.organization}`,
      data,
      headers()
    ),
  };
}

export function postMissionMeasure(data: MissionMeasure): {
  call: Promise<AxiosResponse<MissionMeasure>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}mission-measures/`, data, headers()),
  };
}

export function patchMissionMeasure(data: MissionMeasure): {
  call: Promise<AxiosResponse<MissionMeasure>>;
} {
  return {
    call: axios.patch(
      `${API_BASE_URL}mission-measures/${data.id}/`,
      data,
      headers()
    ),
  };
}

export async function deleteMissionMeasure(id: number) {
  return {
    call: axios.delete(`${API_BASE_URL}mission-measures/${id}/`, {
      ...headers(),
    }),
  };
}

export function postMissionQualitativeItem(data: MissionQualitativeItem): {
  call: Promise<AxiosResponse<MissionQualitativeItem>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}qualitative-items/`, data, headers()),
  };
}

export function patchMissionQualitativeItem(data: MissionQualitativeItem): {
  call: Promise<AxiosResponse<MissionQualitativeItem>>;
} {
  return {
    call: axios.patch(
      `${API_BASE_URL}qualitative-items/${data.id}/`,
      data,
      headers()
    ),
  };
}

export async function deleteMissionQualitativeItem(id: number) {
  return {
    call: axios.delete(`${API_BASE_URL}qualitative-items/${id}/`, {
      ...headers(),
    }),
  };
}

export function patchMissions(data: Mission): {
  call: Promise<AxiosResponse<Mission>>;
} {
  return {
    call: axios.patch(`${API_BASE_URL}missions/${data.id}/`, data, headers()),
  };
}

export function getMissionsOrgBridge(id: any): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}mission-org-bridge/${id}/`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export async function deleteMissionFromTeams(id: number) {
  return {
    call: axios.delete(`${API_BASE_URL}team-personas-missions/${id}/`, {
      ...headers(),
    }),
  };
}

export function deleteMissionNew(id: number) {
  return {
    call: axios.delete(`${API_BASE_URL}missions/${id}/`, {
      ...headers(),
    }),
  };
}
