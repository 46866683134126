import {getNotification} from '../../../Components/GetNotification';
import {ApiBase} from '../../../utils/apiBase';
import {
  UPDATE_CHANGE_PASSWORD_FORM,
  CHANGE_PASSWORD_ERRORS,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_STARTED,
  CHANGE_PASSWORD_SUCCESS,
} from '../../Redux.constants';
import * as yup from 'yup';
import {dummyRequest} from '../../../utils/dummyUtils';

const SCHEMA = yup.object().shape({
  old_password: yup.string().required('*Old Password is required'),
  password: yup
    .string()
    .matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
      'Must contain At least one upper case letter,one lower case letter,one digit & one special character '
    )
    // .notOneOf(
    //   [yup.ref('old_password'), null],
    //   'Old Password and New Password cannot be same'
    // )
    .min(8, 'Password is too short - should be 8 chars minimum')
    .required('*New Password required'),
  confirm_password: yup
    .string()
    .min(8, 'Password is too short - should be 8 chars minimum')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('*Confirm Password is required'),
});

export function updateChangePasswordForm(payload) {
  return {
    type: UPDATE_CHANGE_PASSWORD_FORM,
    ...payload,
  };
}

export function changePassword(form, next) {
  return async (dispatch) => {
    const reqBody = form.reduce(
      (obj, item) => ({...obj, [item.key]: item.value}),
      {}
    );
    try {
      await SCHEMA.validate(reqBody);
      dispatch({type: CHANGE_PASSWORD_STARTED});
      dispatch({
        type: CHANGE_PASSWORD_ERRORS,
        errorFields: {},
      });
      const apiBaseInstance = new ApiBase().instance;
      apiBaseInstance
        .post('/users/change_password/', reqBody)
        .then((res) => dispatch(changePasswordSuccess(res, next)))
        .catch((error) => dispatch(changePasswordFailed(error)));
    } catch (error) {
      let errorFields = await fetchValidationErrors(reqBody);
      dispatch({
        type: CHANGE_PASSWORD_ERRORS,
        errorFields,
      });
    }
  };
}

function changePasswordSuccess(res, next) {
  getNotification('success', {
    header: 'Success!',
    body: 'Password changed successfully',
  });
  next && next();
  return {
    type: CHANGE_PASSWORD_SUCCESS,
  };
}
function changePasswordFailed(err) {
  getNotification('error', {
    header: 'Failed!',
    body: err?.response?.data?.error
      ? err.response.data.error
      : 'Failed to change password',
  });
  return {
    type: CHANGE_PASSWORD_FAILED,
  };
}

function fetchValidationErrors(input) {
  return new Promise((resolve, reject) => {
    SCHEMA.validate(input, {abortEarly: false}).catch((error) => {
      let validationList = error.inner.reduce((obj, item) => {
        return {
          ...obj,
          [item.path]: item.message,
        };
      }, {});
      resolve(validationList);
    });
  });
}
