import axios, {AxiosResponse} from 'axios';
import {API_BASE_URL} from '../../config/constants';
import {AbortableRequestConfig} from 'types/Request';

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    },
  };
};

type QueryParams = {
  timeRange?: string | null,
  network?: number[] | undefined,
  owners?: number[] | undefined,
  markets?: number[] | undefined,
}

export function getPatsPerNetwork({
  timeRange,
  network,
  owners,
  markets,
}: QueryParams): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  let url = `${API_BASE_URL}pats-per-network/`;

  const params: Record<string, unknown> = {};

  if (timeRange) {
    params.created_at = timeRange;
  }

  if (network && network?.length > 0) {
    params.organizations = network.join(',');
  }

  if (owners && owners?.length > 0) {
    params.pat_owners = owners.join(',');
  }

  if (markets && markets?.length > 0) {
    params.markets = markets?.join(',');
  }

  return {
    call: axios.get(url, {
      signal: controller.signal,
      params,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function getMilestonOverview({
  timeRange,
  network,
  owners,
  markets,
}: QueryParams): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  let url = `${API_BASE_URL}competencies-overview/`;

  const params: Record<string, unknown> = {};

  if (timeRange) {
    params.created_at = timeRange;
  }

  if (network && network?.length > 0) {
    params.organizations = network.join(',');
  }

  if (owners && owners?.length > 0) {
    params.pat_owners = owners.join(',');
  }

  if (markets && markets?.length > 0) {
    params.markets = markets.join(',');
  }

  return {
    call: axios.get(url, {
      signal: controller.signal,
      params,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function getNetworks(): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  const url = `${API_BASE_URL}all-organizations/`;

  return {
    call: axios.get(url, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function getOrgOwners(): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  const url = `${API_BASE_URL}org-owners/`;

  return {
    call: axios.get(url, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function getMarkets(): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  const url = `${API_BASE_URL}markets/`;

  return {
    call: axios.get(url, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}