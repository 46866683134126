import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import {
  getKeyDriversPD,
  patchKeyDriverPD,
  postKeyDriverPD,
  deleteKeyDriverPD,
  patchKeyDriverPDOrder,
} from '../../../../../../../../Services/KeyDrivers.service';
import { SecondaryDrivers } from '../SecondaryDrivers';
import { PercentageCircle } from "@components/atoms";
import './styles/PrimaryDrivers.scss';
import { getNotification } from '../../../../../../../../Components/GetNotification';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

/**
 * Renders the PrimaryDrivers component.
 *
 * @param {Object} mvgoalId - the ID of the mvgoal
 * @return {JSX.Element} - the rendered PrimaryDrivers component
 */
const PrimaryDrivers = ({ mvgoalId }) => {
  const [primaryDrivers, setPrimaryDrivers] = useState([]);
  const [visibleDriverModal, setVisibleDriverModal] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
    watch,
    getValues,
  } = useForm({ defaultValues: { percentage: 0 } });

  /**
   * Saves the primary driver with the given parameters.
   *
   * @param {Object} params - The parameters for saving the primary driver.
   * @return {Promise} - A Promise that resolves when the primary driver is saved.
   */
  const savePrimary = async (params) => {
    try {
      const data = {
        ...params,
        mvgoal: mvgoalId,
        order: primaryDrivers.length
          ? primaryDrivers[primaryDrivers.length - 1].order + 1
          : 1,
        percentage: params?.percentage ? params?.percentage : 0,
      };
      if (data?.id) {
        await patchKeyDriverPD({
          id: data?.id,
          description: data?.description,
          percentage: data?.percentage,
        }).call;
      } else {
        await postKeyDriverPD(data).call;
      }
      loadPrimaryDrivers();
      setVisibleDriverModal(false);
    } catch (error) {
      getNotification('error', {
        header: 'Attention',
        body: error.response.data.percentage,
      });
    }
  };

  /**
   * Delete PD function.
   *
   * @param {type} id - the ID of the PD to delete
   * @return {type} undefined
   */
  const deletePD = async (id) => {
    try {
      setVisibleDriverModal(false);
      const res = await deleteKeyDriverPD(id).call;
      loadPrimaryDrivers();
    } catch (error) { }
  };

  /**
   * Opens the driver modal, resetting the values and optionally filling in the details for a specific index.
   *
   * @param {number} index - The index of the driver to fill in the details for. If null, no details will be filled.
   * @return {void}
   */
  const openDriverModal = async (index = null) => {
    reset();
    if (index !== null) {
      setValue('id', primaryDrivers[index]?.id);
      setValue('description', primaryDrivers[index]?.description);
      setValue('percentage', primaryDrivers[index]?.percentage);
    }
    setVisibleDriverModal(true);
  };

  const loadPrimaryDrivers = async () => {
    if (mvgoalId) {
      try {
        const res = await getKeyDriversPD(mvgoalId).call;
        setPrimaryDrivers(res?.data?.results);
      } catch (error) { }
    }
  };

  /**
   * Reorders the primary key of an item asynchronously.
   *
   * @param {Object} param - An object containing the source, destination, and draggableId.
   * @param {Object} param.source - The source object.
   * @param {Object} param.destination - The destination object.
   * @param {string} param.draggableId - The id of the draggable.
   * @return {Promise<void>} Resolves when the primary key is reordered.
   */
  const orderPrimaryKey = async ({ source, destination, draggableId }) => {
    if (!destination) {
      return;
    }
    if (
      source.index === destination.index &&
      source.droppableId === destination.droppableId
    ) {
      return;
    }
    try {
      await patchKeyDriverPDOrder(draggableId, {
        order: primaryDrivers[destination.index].order,
      }).call;
      loadPrimaryDrivers();
    } catch (error) { }
  };

  useEffect(() => {
    loadPrimaryDrivers();
  }, [mvgoalId]);

  return (
    <div className='primarydrivers flex'>
      <div
        className={`w-50 relative  ${primaryDrivers.length ? '' : 'grid grid-vertical'
          }`}>
        <p className='text-center absolute top-15 w-100'>
          <b>Primary Drivers</b>
        </p>
        <DragDropContext onDragEnd={(result) => orderPrimaryKey(result)}>
          <Droppable droppableId='primary'>
            {(droppablProv) => (
              <div {...droppablProv.droppableProps} ref={droppablProv.innerRef}>
                {primaryDrivers?.map((driver, index) => (
                  <Draggable
                    draggableId={`${driver?.id}`}
                    index={index}
                    key={driver?.id}>
                    {(draggbleProv) => (
                      <div className='w-100 h-300 grid grid-vertical'>
                        <div
                          className='primarydrivers-card'
                          onClick={() => openDriverModal(index)}
                          {...draggbleProv.draggableProps}
                          ref={draggbleProv.innerRef}
                          {...draggbleProv.dragHandleProps}>
                          <p className='text-white'>
                            {' '}
                            <b> {driver?.description} </b>
                          </p>
                            {driver && <PercentageCircle percentage={driver.percentage} />}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppablProv.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div
          className={`keydrivers-btn-new bg-blue pointer ${primaryDrivers.length ? 'sticky bottom-15 l-14' : ''
            }`}
          onClick={() => openDriverModal()}>
          <p className='text-white'>
            {' '}
            <b> + Add New Driver </b>
          </p>
        </div>
      </div>
      <div className={`w-50 h-760 relative`}>
        <p className='text-center w-100 my-15'>
          <b>Secondary Drivers</b>
        </p>
        {primaryDrivers?.map((driver, index) => (
          <SecondaryDrivers pdId={driver?.id} key={index} />
        ))}
      </div>
      <Modal
        title='Primary Driver'
        visible={visibleDriverModal}
        onCancel={() => setVisibleDriverModal(false)}
        footer={false}
        className='mission-modal'>
        <form onSubmit={handleSubmit(savePrimary)}>
          <div>
            <p>Primary Driver</p>
            <input
              type='text'
              className='input-class'
              placeholder='Enter text'
              {...register('description', { required: true })}
            />
          </div>
          <div className='mt-15'>
            <p>How much is this driver contributing to the problem?</p>
            <input
              type='number'
              className='input-class'
              placeholder='Enter Percentage'
              {...register('percentage')}
            />
          </div>
          <div className='mt-15 text-end'>
            {getValues('id') && (
              <button
                type='button'
                className='modal-dont-delete bg-red mr-10'
                onClick={() => deletePD(getValues('id'))}>
                Delete
              </button>
            )}
            <button
              type='button'
              className='modal-delete-btn'
              onClick={() => setVisibleDriverModal(false)}>
              Cancel
            </button>
            <button type='submit' className='modal-dont-delete ml-10'>
              Save
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default PrimaryDrivers;
