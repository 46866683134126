import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'antd';
import './SmartSolutionChatbot.scss';
import smartSolution from 'Assets/icons/smartSolution.svg';

const SmartSolutionChatbot: React.FC = () => {
  const [coachUrl, setCoachUrl] = useState('');

  useEffect(() => {
    const baseCoachUrl = process.env.REACT_APP_API_COACH;
    // Construct the URL with parameters
    const url = new URL(baseCoachUrl!);
    
    setCoachUrl(url.toString());
  }, []);

  const openCoachInNewTab = useCallback(() => {
    if (coachUrl) {
      window.open(coachUrl, '_blank', 'noopener,noreferrer');
    }
  }, [coachUrl]);

  return (
    <Button
      type='primary'
      shape='round'
      size='large'
      style={{
        background: '#08161F',
        position: 'fixed',
        bottom: '60px',
        right: '40px',
        zIndex: 1000,
      }}
      onClick={openCoachInNewTab}
    >
      <div className='flex flex-row gap-1'>
          <img src={smartSolution} alt='SolutionsIcon' />
      </div>
    </Button>
  );
};

export default SmartSolutionChatbot;