import React from 'react';
import '../../styles/AssesmentTool.scss';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {selectPat, selectPatsPage} from 'ReduxV2/state';
import {Table, Tag} from 'antd';
import moment from 'moment';
import {BsShare} from 'react-icons/bs';
import {useDispatch, useSelector} from 'react-redux';
import {PatStatus} from 'types/Pat';
import TagFloatingInfo from 'Components/TagFloatingInfo';

const {Column} = Table;

interface PatRecord {
  id: number;
  title: string;
  created_by: {
    name: string;
  };
  created_at: string;
  status: string;
  current_phase: {
    color: string;
    name: string;
  };
}

type PatTableProps = {
  identifyModal: (record: PatRecord) => void;
  setShareId: (id: number) => void;
  setShareModal: (show: boolean) => void;
  setIsEditingCompletedPat: (isEditing: boolean) => void;
  setSelectDeleteIdPat: (id: number) => void;
  setConfirmationDelete: (show: boolean) => void;
}

const PatTable: React.FC<PatTableProps> = ({
  identifyModal,
  setShareId,
  setShareModal,
  setIsEditingCompletedPat,
  setSelectDeleteIdPat,
  setConfirmationDelete,
}): React.ReactElement => {
  const dispatch = useDispatch();

  const totalPats = useSelector((state: any) => state.pats.count);
  const patsPerPage = useSelector((state: any) => state.pats.perPage);
  const pats = useSelector((state: any) => state.pats.items);
  const isUserAdmin = useSelector((state: any) => state.user.user.client_admin);

  return (
    <Table
      data-testid='patTable'
      rowClassName='pointer'
      dataSource={pats}
      pagination={{
        defaultPageSize: patsPerPage,
        total: totalPats,
        onChange: (page) => {
          dispatch(selectPatsPage(page));
        },
      }}>
      <Column
        className='pointer'
        title='Title'
        dataIndex='title'
        key='title'
        onCell={(record:PatRecord , rowIndex) => {
          return {
            onClick: (event) => {
              identifyModal(record);
            },
          };
        }}
      />
      <Column
        className='pointer'
        title='Tags'
        dataIndex='tags'
        key='tags'
        render={(tags) => <TagFloatingInfo tags={tags}/>}
      />
      <Column
        className='pointer'
        title='Created by'
        dataIndex='created_by'
        key='created_by'
        render={(text) => <p>{text.name}</p>}
        onCell={(record:PatRecord , rowIndex) => {
          return {
            onClick: (event) => {
              identifyModal(record);
            },
          };
        }}
      />
      <Column
        className='pointer'
        title='Creation Date'
        dataIndex='created_at'
        key='created_at'
        render={(text) => <p>{moment(text).format('L')}</p>}
        onCell={(record:PatRecord , rowIndex) => {
          return {
            onClick: (event) => {
              identifyModal(record);
            },
          };
        }}
      />
      <Column
        className='pointer'
        title='Status'
        dataIndex='status'
        key='status'
        onCell={(record:PatRecord , rowIndex) => {
          return {
            onClick: (event) => {
              identifyModal(record);
            },
          };
        }}
      />
      <Column
        className='pointer'
        title='Current Phase'
        dataIndex='current_phase'
        key='current_phase'
        render={(tag) => (
          <Tag color={tag?.color}>
            <span className='text-white'>{tag?.name}</span>
          </Tag>
        )}
        onCell={(record:PatRecord , rowIndex) => {
          return {
            onClick: (event) => {
              identifyModal(record);
            },
          };
        }}
      />
      <Column
        className='pointer'
        title='Options'
        dataIndex='action'
        key='action'
        render={(_, record: any) => (
          <div>
            <a className='ml-10 hidden' onClick={() => {}}>
              <BsShare
                style={{color: '#000000'}}
                onClick={() => {
                  setShareId(record?.id);
                  setShareModal(true);
                }}
              />
            </a>
            {isUserAdmin && record?.status === PatStatus.COMPLETED && (
              <a
                data-testid='editPatButton'
                className='ml-10'
                onClick={() => {
                  dispatch(selectPat(record?.id));
                  setIsEditingCompletedPat(true);
                }}>
                <EditOutlined style={{color: '#888895'}} />
              </a>
            )}
            <a
              data-testid='deletePatButton'
              className='ml-10'
              onClick={() => {
                setSelectDeleteIdPat(record?.id);
                setConfirmationDelete(true);
              }}>
              <DeleteOutlined style={{color: '#888895'}} />
            </a>
          </div>
        )}
      />
    </Table>
  );
};

export default PatTable;
