import React from 'react';
import {PercentageCard} from 'Components/molecules/card';
import {TagSteps} from 'Components/molecules/tag';
import {useSelector} from 'react-redux';
import {PftJourneyCategoryScore} from 'types/PftJourney';

const ScreenHeader: React.FC<{title: string}> = ({title}) => (
  <div className='flex items-center justify-between mb-4'>
    <h2 className='text-4xl font-semibold'>{title}</h2>
  </div>
);

export const PftScores: React.FC = () => {
  //@ts-ignore
  const phases = useSelector((state) => state.pftJourney.phases);

  const categoryScores = useSelector(
    //@ts-ignore
    (state) => state.pftJourney.category_scores
  );

  return (
    <div>
      <ScreenHeader title='Your Transformation Journey: Category Scores' />
      <div className='flex gap-4 mb-4 items-center'>
        <h2 className='text-4xl whitespace-nowrap'>Summary:</h2>
        <TagSteps tags={phases} />
      </div>
      <div className='grid gap-12 md:grid-cols-2 lg:grid-cols-3'>
        {categoryScores.map((item: PftJourneyCategoryScore, index: number) => (
          <PercentageCard
            title={item.category}
            percentage={item.percentage}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};
