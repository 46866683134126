import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Radio, Alert, Collapse } from 'antd';
import { Controller, useForm, useFieldArray } from 'react-hook-form';

import { getSprint, patchSprints, postSprintReflextions } from '../../../../../../../../Services/Sprints.service';
import { useDispatch, useSelector } from 'react-redux';
import { getNotification } from '../../../../../../../../Components/GetNotification';
import { cleanSprint } from '../../../../../../../../ReduxV2/state';
import { debounce } from 'lodash';
import TextareaDebounce from '../../../../../../../../Components/TextareaDebounce/TextareaDebounce';
import FormTable from '../../../../../../../../Components/FormTable/FormTable';
import DebounceLoading, { dialogStartSubject$, dialogStopSubject$ } from '../../../../../../../../Components/DebounceLoading/DebounceLoading';
import { AUTOSAVING_DELAY } from '../../../../../../../../../config/constants';
import './styles/Closed.scss';
import { CreateWorkflow } from 'Components/organisms/form/sprint/CreateWorkflow';

import { useTranslation } from "react-i18next"

const { Panel } = Collapse;

/**
 * Renders a component for the "Closed" state.
 *
 * @returns {JSX.Element} The rendered component.
 */
const Closed = () => {
  const { register, handleSubmit, control, setValue, getValues, reset, formState: { errors }, watch } = useForm();

  const { fields: fields_worked, append: append_worked, remove: remove_worked } = useFieldArray({
    control,
    name: "worked"
  });

  const { fields: fields_failure, append: append_failure, remove: remove_failure } = useFieldArray({
    control,
    name: "failure"
  });

  const { fields: fields_barrier, append: append_barrier, remove: remove_barrier } = useFieldArray({
    control,
    name: "barrier"
  });

  const { fields: fields_lesson, append: append_lesson, remove: remove_lesson } = useFieldArray({
    control,
    name: "lesson"
  });

  const { fields: fields_success, append: append_success, remove: remove_success } = useFieldArray({
    control,
    name: "success"
  });

  const { fields: fields_do_differently, append: append_do_differently, remove: remove_do_differently } = useFieldArray({
    control,
    name: "do_differently"
  });

  const dispatch = useDispatch();

  const refForm = useRef();

  const [t] = useTranslation()

  const sprintId = useSelector(state => {
    return state.sprint.value
  });

  /**
   * Saves the closed items.
   *
   * @param {object} params - The parameters.
   * @param {array} params.worked - The worked items.
   * @param {array} params.failure - The failure items.
   * @param {array} params.barrier - The barrier items.
   * @param {array} params.lesson - The lesson items.
   * @param {array} params.success - The success items.
   * @param {array} params.do_differently - The do differently items.
   * @return {boolean} Whether the items were saved successfully.
   */
  const saveClosed = async (params) => {
    dialogStartSubject$.setSubject = true;
    const buildData = (items, init = {}) => {
      if (items.length == 0) items.push({ ...init });
      return items.map((item) => {
        return {
          ...item,
          ...init,
        };
      }).filter((item) => item?.id || item?.created);
    }
    const sendItems = async (items, type) => {
      try {
        const items_init = {
          mission_sprint: sprintId,
          reflection_type: type,
        };
        if (items?.length == 0) items.push({ ...items_init });
        const data = buildData(items, items_init);
        await postSprintReflextions(data).call;
        setValue(type.toString().toLowerCase(), data);
      } catch (error) {
        return false;
      }
      return true;
    }
    const saved =
      await sendItems(params.worked, "WORKED") &&
      await sendItems(params.failure, "FAILURE") &&
      await sendItems(params.barrier, "BARRIER") &&
      await sendItems(params.lesson, "LESSON") &&
      await sendItems(params.success, "SUCCESS") &&
      await sendItems(params.do_differently, "DO_DIFFERENTLY");

    if (!saved) return;
    try {
      const data = {
        ...params,
        id: sprintId,
        status: "CLOSED",
        desired_outcomes_achieved: params?.desired_outcomes_achieved == "yes" ? true : false,
      }

      await patchSprints(data).call

      getNotification('success', {
        header: '',
        body: 'The information has been saved successfully',
      });

    } catch (error) {

    }
    dialogStopSubject$.setSubject = false;
  }

  const loadSprint = async () => {
    if (sprintId) {
      reset();
      try {
        const res = await getSprint(sprintId).call;
        setValue("desired_outcomes_achieved", res?.data?.desired_outcomes_achieved ? "yes" : "no")
        setValue("notable_feedback", res?.data?.notable_feedback)
        if (res.data?.worked_reflections?.length > 0) {
          res.data.worked_reflections.map((item) => {
            append_worked(item);
            return item;
          });
        } else {
          append_worked({ created: true });
        }
        if (res.data?.failure_reflections?.length > 0) {
          res.data.failure_reflections.map((item) => {
            append_failure(item);
            return item;
          });
        } else {
          append_failure({ created: true });
        }
        if (res.data?.barrier_reflections?.length > 0) {
          res.data.barrier_reflections.map((item) => {
            append_barrier(item);
            return item;
          });
        } else {
          append_barrier({ created: true });
        }
        if (res.data?.lesson_reflections?.length > 0) {
          res.data.lesson_reflections.map((item) => {
            append_lesson(item);
            return item;
          });
        } else {
          append_lesson({ created: true });
        }
        if (res.data?.success_reflections?.length > 0) {
          res.data.success_reflections.map((item) => {
            append_success(item);
            return item;
          });
        } else {
          append_success({ created: true });
        }
        if (res.data?.do_differently_reflections?.length > 0) {
          res.data.do_differently_reflections.map((item) => {
            append_do_differently(item);
            return item;
          });
        } else {
          append_do_differently({ created: true });
        }
      } catch (error) { }
    }
  }

  const activeSubmitForm = () => {
    refForm?.current.click()
  }

  const debounceActive = useCallback(
    debounce(activeSubmitForm, AUTOSAVING_DELAY)
    , []);

  const [formTableData] = useState({
    control: control,
    debounceActive: debounceActive,
    errors: errors,
  })

  useEffect(() => {
    loadSprint()
  }, [sprintId]);

  return (
    <div className='closed'>
      <div className="bg-blue-900 p-10 my-15">
        <div className="flex justify-beetween items-center font-18">
          <p className="text-white">Closed</p>
        </div>
      </div>
      <form onSubmit={handleSubmit(saveClosed)}>
        <div>
          <div className="flex justify-end font-18">
            <div>
              <div>
                <button type="submit" className="btn-edit d-none" ref={e => refForm.current = e}>Save</button>
              </div>
            </div>
          </div>
        </div>
        <Collapse expandIconPosition='end' defaultActiveKey={['1', '2']}>
          <Panel header='Reflection' key='1'>
            <div>
              <p><b>Review results of {t('commons.interventions')}, Evaluate objectively what worked and what didn’t</b></p>
              <div className="mt-15">
                <p className="mr-5">Did you achieve the outcomes desired?</p>
                <Controller
                  name={`desired_outcomes_achieved`}
                  control={control}
                  render={({ field }) => (
                    <Radio.Group onChange={(e) => {
                      field.onChange(e)
                      debounceActive()
                    }} value={field.value}>
                      <Radio.Button value="yes">Yes</Radio.Button>
                      <Radio.Button value="no">No</Radio.Button>
                    </Radio.Group>
                  )}
                />
              </div>
              <div className="mt-15">
                <p className="mr-5">What worked?</p>
                <FormTable
                  {...formTableData}
                  name="worked"
                  fields={fields_worked}
                  headers={[
                    {
                      type: "inputMulti",
                      name: "description",
                    }
                  ]}
                  onAppend={append_worked}
                  onRemove={remove_worked}
                />
              </div>
              <div className="mt-15">
                <p className="mr-5">What didn’t work and what adjustments will you make in the next {t('commons.intervention').toLowerCase()} to achieve your desired outcome?</p>
                <FormTable
                  {...formTableData}
                  name="failure"
                  fields={fields_failure}
                  headers={[
                    {
                      type: "inputMulti",
                      title: "Failure",
                      name: "description",
                    },
                    {
                      type: "inputMulti",
                      title: "Adjustments to do",
                      name: "adjustments",
                    }
                  ]}
                  onAppend={append_failure}
                  onRemove={remove_failure}
                />
              </div>
              <div className="mt-15">
                <p className="mr-5">What barriers did you encounter and how did you overcome them?</p>
                <FormTable
                  {...formTableData}
                  name="barrier"
                  fields={fields_barrier}
                  headers={[
                    {
                      type: "inputMulti",
                      title: "Barrier",
                      name: "description",
                    },
                    {
                      type: "inputMulti",
                      title: "How did you resolve",
                      name: "how_to_resolve",
                    }
                  ]}
                  onAppend={append_barrier}
                  onRemove={remove_barrier}
                />
              </div>
              <div className="mt-15">
                <p className="mr-5">What lessons were learned?</p>
                <FormTable
                  {...formTableData}
                  name="lesson"
                  fields={fields_lesson}
                  headers={[
                    {
                      type: "inputMulti",
                      name: "description",
                    }
                  ]}
                  onAppend={append_lesson}
                  onRemove={remove_lesson}
                />
              </div>
              <div className="mt-15">
                <p className="mr-5">Successes to celebrate?</p>
                <FormTable
                  {...formTableData}
                  name="success"
                  fields={fields_success}
                  headers={[
                    {
                      type: "inputMulti",
                      name: "description",
                    }
                  ]}
                  onAppend={append_success}
                  onRemove={remove_success}
                />
              </div>
              <div className="mt-15">
                <p className="mr-5">Anything you would do differently?</p>
                <FormTable
                  {...formTableData}
                  name="do_differently"
                  fields={fields_do_differently}
                  headers={[
                    {
                      type: "inputMulti",
                      name: "description",
                    }
                  ]}
                  onAppend={append_do_differently}
                  onRemove={remove_do_differently}
                />
              </div>
              <div className="mt-15">
                <p className="mr-5">Notable feedback</p>
                <TextareaDebounce name="notable_feedback" control={control} debounceAction={debounceActive} />
              </div>
            </div>
          </Panel>
          <Panel header='Revise Your Workflows' key='2'>
            <div className='grid gap-4'>
              <h3 className='text-base font-medium py-2'>What parts of the Minimal Viable Solution require practice staff/providers to do something differently than their existing&nbsp;workflow?</h3>
              <CreateWorkflow readOnly={true} title={'Your Workflows'} />
            </div>
          </Panel>
        </Collapse>
      </form>
      <div className="my-15">
        <Alert message={`You could create a new ${t('commons.intervention').toLowerCase()} on areas that need to be modified because it did not work well. Please save your changes before that.`} type="warning" showIcon />
      </div>
      <div className="text-center">
        <button type="button" className="btn-edit" onClick={() => dispatch(cleanSprint())}>+ New {t('commons.intervention')}</button>
      </div>
      <DebounceLoading />
    </div >
  );
};

export default Closed;
