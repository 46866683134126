import React from 'react';
import moment from 'moment';
import {Card} from 'antd';
import {ObservationLog} from 'types/Observation';
import {DemographicLog} from 'types/Demographic';

import { useTranslation } from "react-i18next"

export const UpdateLogCard: React.FC<{
  logs: ObservationLog[] | DemographicLog[];
}> = ({logs}) => {

  // i18n translation hook
  const [t] = useTranslation()

  return (
    <Card
      title='Version History'
      className='border-none rounded-lg shadow-lg'
      headStyle={{
        padding: '1rem',
        paddingTop: 0,
        paddingBottom: 0,
        border: 'none',
        fontSize: '1.125rem',
        fontWeight: 700,
      }}
      bodyStyle={{padding: '1rem', paddingLeft: 0, paddingTop: 0}}>
      <div
        className={
          'max-h-[460px] overflow-y-scroll divide-y divide-solid divide-primary p-4 pr-2 pt-0'
        }>
        {logs.map((log, index: number) => {
          const date = moment(log?.last_update).format('L');
          return (
            <div
              key={index}
              className={'grid gap-2 py-2' + (index ? '' : ' pt-0')}>
              <b className='font-semibold leading-none'>
                {index ? 'Date' : 'Last Update'}:
              </b>
              <span className='font-medium'>{date}</span>
              {log.user && (
                <>
                  <b className='font-semibold leading-none'>{t("By")}:</b>
                  <span className='font-medium'>{log.user?.name}</span>
                </>
              )}
            </div>
          );
        })}
      </div>
    </Card>
  );
};
