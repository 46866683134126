/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {Dispatch, SetStateAction, useEffect, useRef} from 'react';
import {UploadOutlined} from '@ant-design/icons';
import {Col} from 'antd';

import {uploadCommentsFiles} from 'Services/Meetings.service';
import 'Routes/MeetingsComments/components/FilesTable/styles/FilesTable.scss';
import {ScoreCardFileType} from 'types/ScoreCard';
interface FilesTableProps {
  id: string;
  postUrlFile: string;
  field: string;
  postUploadFileCallback: () => void;
  triggerUpload: boolean;
  setTriggerUpload: Dispatch<SetStateAction<boolean>>;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  fileType: string | undefined;
  file: File | undefined;
  setFile: Dispatch<SetStateAction<File | undefined>>;
}

const ExcelScoreCardUpload: React.FC<FilesTableProps> = ({
  id,
  postUrlFile,
  field,
  postUploadFileCallback,
  triggerUpload = false,
  setTriggerUpload,
  setIsModalVisible,
  fileType,
  file,
  setFile,
}) => {
  const uploadRef = useRef<any>(null);

  const uploadFileFetch = async () => {
    try {
      await uploadCommentsFiles(id, file, postUrlFile, field);
      if (uploadRef.current) uploadRef.current.value = '';
      // Wait due backend delay before refreshing again
      setTimeout(() => {
        postUploadFileCallback();
      }, 2000);
    } catch (error) {
      // Handle the error as needed
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setFile(event?.dataTransfer?.files?.[0]);
    handleDragLeave(event);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.currentTarget.classList.add('drag-over');
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.currentTarget.classList.remove('drag-over');
  };

  useEffect(() => {
    if (triggerUpload && fileType == ScoreCardFileType.EXCEL) {
      uploadFileFetch();
      setTriggerUpload(false);
      setIsModalVisible(false);
      setFile(undefined);
    }
  }, [triggerUpload]);

  return (
    <>
      <Col span={24} className='mb-15 filestable'>
        <div className='right-pane__header mt-4 '>
          <input
            data-testid='dragAndDropFile'
            ref={(e) => (uploadRef.current = e)}
            type='file'
            style={{display: 'none'}}
            onChange={(e: any) => setFile(e?.target?.files?.[0])}
            onDragEnd={(e: any) => setFile(e?.target?.files?.[0])}
          />
          <div
            className='dragable'
            onClick={() => uploadRef.current.click()}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}>
            <UploadOutlined />
            <p>{'Click or drag file to this area to upload your scorecard'}</p>
          </div>
          <p className='mt-4'>File to upload: {file?.name}</p>
        </div>
      </Col>
    </>
  );
};

export default ExcelScoreCardUpload;
