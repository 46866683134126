import React, { useState } from 'react';
import { Table, Button, Checkbox, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface Tag {
  key: string;
  name: string;
}

const generateMockTags = () => {
    const result:Tag[] = []
    for(let i=1; i<=100; i++){
        result.push({ key: i.toString(), name: `Tag Name ${i}` }); 

    }
    return result
}
const initialTags: Tag[] = generateMockTags();

const TagTable: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tags, setTags] = useState(initialTags);

  const onSelectChange = (selectedKeys: React.Key[]) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const deleteSelected = () => {
    setTags(prevTags => prevTags.filter(tag => !selectedRowKeys.includes(tag.key)));
    setSelectedRowKeys([]);
  };

  const columns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <>{text}</>,
    },
    {
      title: () => {
        return (
            <div className='flex flex-row items-center justify-around'>
                <span>Action</span>
                <Button
                    icon={<DeleteOutlined />}
                    onClick={deleteSelected}
                />
            </div>
        )
      },
      key: 'action',
      render: (text: string, record: Tag) => (
        <Space size="middle"> </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tags}
        scroll={{y: '400px'}}
        pagination={{ pageSize: 25, showQuickJumper: true }}
      />
    </>
  );
};

export default TagTable;
