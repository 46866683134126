import {
  CHANGE_PASSWORD_ERRORS,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_STARTED,
  CHANGE_PASSWORD_SUCCESS,
  UPDATE_CHANGE_PASSWORD_FORM,
  UPDATE_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_START,
} from '../../Redux.constants';
import {
  updateChangePassword,
  changePasswordStarted,
  changePasswordFailed,
  changePasswordSuccess,
  updateFormConfigErrors,
  updateUserProfileStart,
  updateUserProfileSuccess,
  updateUserProfileFailed,
} from './helper';
import {Default} from './InitialState';

export default function UserReducer(state = Default(), action) {
  switch (action.type) {
    case UPDATE_CHANGE_PASSWORD_FORM:
      return updateChangePassword(state, action);
    case CHANGE_PASSWORD_STARTED:
      return changePasswordStarted(state, action);
    case CHANGE_PASSWORD_FAILED:
      return changePasswordFailed(state, action);
    case CHANGE_PASSWORD_SUCCESS:
      return changePasswordSuccess(state, action);
    case CHANGE_PASSWORD_ERRORS:
      return updateFormConfigErrors(state, action);
    case UPDATE_USER_PROFILE_START:
      return updateUserProfileStart(state);
    case UPDATE_USER_PROFILE_SUCCESS:
      return updateUserProfileSuccess(state, action.payload);
    case UPDATE_USER_PROFILE_FAILED:
      return updateUserProfileFailed(state, action.payload);
    default:
      return {...state};
  }
}
