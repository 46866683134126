import * as React from 'react';

type ButtonProps = {
  children: React.ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonSizes = {
  xs: 'px-2 py-1 text-sm rounded font-medium',
  sm: 'px-3 py-1.5 text-sm rounded-md',
  md: 'px-3 py-2 text-base rounded-md',
  lg: 'px-6 py-3 text-base rounded-md',
};

export const Button = React.forwardRef(function Button(
  props: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const {children, size = 'md', ...buttonProps} = props;
  const baseStyle =
      'flex gap-x-2 items-center text-white bg-primary whitespace-nowrap transition-colors duration-200 ease-in-out hover:bg-primary-hover',
    sizeStyle = ButtonSizes[size];

  return (
    <button
      className={[baseStyle, sizeStyle].join(' ')}
      {...buttonProps}
      ref={ref}>
      {children}
    </button>
  );
});
