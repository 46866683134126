import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  deleteSprint,
  getSprint,
  getSprints,
  patchSprints,
  postSprints,
} from '../../../../../../Services/Sprints.service';
import {
  Col,
  Input,
  Radio,
  Modal,
  Row,
  Table,
} from 'antd';
import { Controller, useForm } from 'react-hook-form';
import {
  PlusSquareFilled,
  SearchOutlined,
  DeleteOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import './styles/Sprints.scss';
import { useDispatch } from 'react-redux';
import { Menu } from './components';

import { useHistory, useParams } from 'react-router-dom';
import {
  cleanSprint,
  selectSprint,
} from '../../../../../../ReduxV2/state/sprints';
import { TagStatus } from './components/TagStatus';
import { TagType } from 'types/Tags';

import { useTranslation } from "react-i18next"
import TagFloatingInfo from 'Components/TagFloatingInfo';
import TagSelect from 'Components/TagSelect';

const { Column } = Table;

/**
 * Renders the Sprints component.
 *
 * @return {JSX.Element} The rendered component.
 */
const Sprints = () => {
  const [allSprints, setAllSprints] = useState({ results: [] });
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagsToBeRemoved, setTagsToBeRemoved] = useState([]);

  const [t] = useTranslation()
  const history = useHistory(); // Initialize useHistory

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
    watch,
    getValues,
  } = useForm({ defaultValues: { why_sprinting: 'TO_CREATE_DEMAND' } });

  const mission = useSelector((state) => {
    return state.mission.value.id;
  });

  const sprintId = useSelector((state) => {
    return state.sprint.value;
  });

  const dispatch = useDispatch();

  const loadSprints = async () => {
    if (mission) {
      try {
        const res = await getSprints(mission).call;
        setAllSprints(res.data);
      } catch (error) { }
    }
  };

  const editSprintAction = async (id) => {
    try {
      const res = await getSprint(id).call;
      Object.keys(res?.data).map((key) => {
        if(key === 'tags'){
          setSelectedTags(res?.data[key]?.map(t => t?.name))
        } else {
          setValue(key, res?.data[key]);
        }
 
      });
      setVisibleModal(true);
    } catch (error) { }
  };

  const deleteSprintAction = async (id) => {
    try {
      const res = await deleteSprint(id).call;
      loadSprints();
    } catch (error) { }
  };

  const saveSprint = async (params) => {
    const data = {
      ...params,
      mission,
    };

    data.tags = params.tags || [];
    try {
      if (data.id) {
        if(tagsToBeRemoved?.length > 0) {
          removeTagsForArray(tagsToBeRemoved, TagType.SPRINT, data?.id);
        }
        await patchSprints(data).call;
      } else {
        await postSprints(data).call;
      }
      setVisibleModal(false);
      loadSprints();
    } catch (error) { }
  };

  useEffect(() => {
    loadSprints();
  }, [mission]);

  useEffect(() => {
    dispatch(cleanSprint());
  }, []);

  useEffect(() => {
    setValue('tags', selectedTags);
  }, [selectedTags]);

  const {id:organisationId} = useParams();

  return (
    <div>
      {sprintId ? (
        <div>
          <div
            className='my-15 pointer'
            onClick={() => dispatch(cleanSprint())}>
            <p>
              <b>Return to {t('commons.interventions')}</b>
            </p>
          </div>
          <Menu />
        </div>
      ) : (
        <div className='sprints'>
          <div className='flex w-50 ml-auto mb-15'>
            <Input
              prefix={<SearchOutlined />}
              className='border-r-50'
              onChange={(e) => setQueryString(e.target.value)}
            />
            <div
              className='flex-vertical pointer'
              onClick={() => {
                reset();
                setVisibleModal(true);
              }}>
              <PlusSquareFilled
                style={{
                  fontSize: '35px',
                  color: '#3C5DD6',
                  cursor: 'pointer',
                  margin: 'auto',
                  marginLeft: '10px',
                }}
              />
            </div>
          </div>
          <Modal
            title={`New ${t('commons.intervention')}`}
            visible={visibleModal}
            onCancel={() => setVisibleModal(false)}
            footer={false}
            className='mission-modal'>
            <form onSubmit={handleSubmit(saveSprint)}>
              <Row gutter={10}>
                <Col span={24} className='mb-10'>
                  <p>Name of {t('commons.intervention')}</p>
                  <input
                    type='text'
                    className='input-class'
                    {...register('name')}
                  />
                </Col>
                <Col span={24} className='mb-10'>
                  <p>Tag</p>
                  <TagSelect
                    id={sprintId}
                    tagsToBeRemoved={tagsToBeRemoved}
                    setTagsToBeRemoved={setTagsToBeRemoved}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                  />
                </Col>
                <Col span={24} className='mb-10'>
                  <p className='my-15'>Why are you sprinting?</p>
                  <Controller
                    name='why_sprinting'
                    control={control}
                    render={({ field }) => (
                      <Radio.Group {...field}>
                        <Radio value='TO_CREATE_DEMAND'>To create demand</Radio>
                        <Radio value='TO_INTERVENTION'>
                          To implement an intervention
                        </Radio>
                      </Radio.Group>
                    )}
                  />
                </Col>
                <Col span={24} className='text-center mt-15'>
                  <button
                    type='button'
                    className='modal-delete-btn'
                    onClick={() => setVisibleModal(false)}>
                    Cancel
                  </button>
                  <button type='submit' className='modal-dont-delete ml-10'>
                    Save
                  </button>
                </Col>
              </Row>
            </form>
          </Modal>
          <Table
            data-testid='sprintTable'
            dataSource={allSprints?.results}
            pagination={{ defaultPageSize: 5 }}>
            <Column
              className='pointer'
              title={`Name of ${t('commons.intervention')}`}
              dataIndex='name'
              key='name'
              onCell={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    dispatch(selectSprint({ id: record.id }));
                    history.push(`/${organisationId}/organisation-insights/intel/intervention/?mission=${mission}&mission_sprint=${record?.id}`)
                  },
                };
              }}
            />
            <Column
              className='pointer'
              title={`Tags`}
              dataIndex='uid'
              key='uid'
              render={(_, record) => (
                <div className='flex flex-row gap-2'>
                  <TagFloatingInfo tags={record?.tags ?? []}/>
                </div>
              )}
           />
            <Column
              className='pointer'
              title='Status'
              dataIndex='status'
              key='status'
              render={(tag) => <TagStatus tag={tag} />}
              onCell={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    dispatch(selectSprint({ id: record.id }));
                    history.push(`/${organisationId}/organisation-insights/intel/intervention/?mission=${mission}&mission_sprint=${record?.id}`)
                  },
                };
              }}
            />
            <Column
              className='pointer'
              title='Result'
              dataIndex='desired_outcomes_achieved'
              key='desired_outcomes_achieved'
              render={(_, record) =>
                record.status == 'CLOSED' &&
                _ && <input type='checkbox' checked />
              }
              onCell={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    dispatch(selectSprint({ id: record.id }));
                    history.push(`/${organisationId}/organisation-insights/intel/intervention/?mission=${mission}&mission_sprint=${record?.id}`)
                  },
                };
              }}
            />
            <Column
              className='pointer'
              title='Actions'
              dataIndex=''
              key=''
              render={(_, record) => (
                <div>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    className='ml-10'
                    onClick={() => editSprintAction(record.id)}>
                    <EyeOutlined />
                  </a>

                  <a
                    className='ml-10'
                    onClick={() => deleteSprintAction(record?.id)}>
                    <DeleteOutlined style={{ color: '#F46767' }} />
                  </a>
                </div>
              )}
            />
          </Table>
        </div>
      )}
    </div>
  );
};

export default Sprints;
