import axios from 'axios';
import moment from 'moment';
import { API_BASE_URL } from '../../config/constants';

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    },
  };
};

export function getKeyDriversMVG(id) {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}mission-mv-goals/?mission=${id}`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  };
}

export function postKeyDriverMVG(data) {
  return {
    call: axios.post(`${API_BASE_URL}mission-mv-goals/`, data, headers()),
  };
}

export function patchKeyDriverMVG(data) {
  return {
    call: axios.patch(
      `${API_BASE_URL}mission-mv-goals/${data.id}/`,
      data,
      headers()
    ),
  };
}

export function deleteKeyDriverMVG(id) {
  return {
    call: axios.delete(`${API_BASE_URL}mission-mv-goals/${id}/`, {
      ...headers(),
    }),
  };
}

export function getKeyDriversPD(id) {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}primary-drivers/?mvgoal=${id}`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  };
}

export function postKeyDriverPD(data) {
  return {
    call: axios.post(`${API_BASE_URL}primary-drivers/`, data, headers()),
  };
}

export function patchKeyDriverPD(data) {
  return {
    call: axios.patch(
      `${API_BASE_URL}primary-drivers/${data.id}/`,
      data,
      headers()
    ),
  };
}

export function patchKeyDriverPDOrder(id, data) {
  return {
    call: axios.patch(`${API_BASE_URL}primary-drivers/${id}/`, data, headers()),
  };
}

export function deleteKeyDriverPD(id) {
  return {
    call: axios.delete(`${API_BASE_URL}primary-drivers/${id}/`, {
      ...headers(),
    }),
  };
}

export function getKeyDriversSD(id) {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}secondary-drivers/?primary_driver=${id}`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  };
}

export function postKeyDriverSD(data) {
  return {
    call: axios.post(`${API_BASE_URL}secondary-drivers/`, data, headers()),
  };
}

export function patchKeyDriverSD(data) {
  return {
    call: axios.patch(
      `${API_BASE_URL}secondary-drivers/${data.id}/`,
      data,
      headers()
    ),
  };
}

export function patchKeyDriverSDOrder(id, data) {
  return {
    call: axios.patch(
      `${API_BASE_URL}secondary-drivers/${id}/`,
      data,
      headers()
    ),
  };
}

export function deleteKeyDriverSD(id) {
  return {
    call: axios.delete(`${API_BASE_URL}secondary-drivers/${id}/`, {
      ...headers(),
    }),
  };
}
