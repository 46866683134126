import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  value: {},
};

export const missionSplice = createSlice({
  name: 'mission',
  initialState,
  reducers: {
    selectMission: (state, {payload}) => {
      state.value = {
        id: payload?.id,
        name: payload?.name,
        status: payload?.status,
        trend: payload?.trend,
      };
    },
    updateMission: (state, {payload}) => {
      state.value = {
        ...state.value,
        [payload?.key]: payload?.value,
      };
    },
    resetMission: (state) => {
      state.value = {};
    },
  },
});

export const {selectMission, updateMission, resetMission} = missionSplice.actions;
