import {ApiBase} from '../../../utils/apiBase';
import {LIST_ORGANISATIONS} from '../../Api.constants';
import {
  DELETE_ORGANISATION_START,
  DELETE_ORGANISATION_FAILED,
  DELETE_ORGANISATION_SUCCESS,
} from '../../Redux.constants';

export const deleteOrganisation = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_ORGANISATION_START,
    });
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.delete(
        LIST_ORGANISATIONS + `${id}/`
      );

      dispatch({
        type: DELETE_ORGANISATION_SUCCESS,
        payload: {
          fetchReport: response.data,
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_ORGANISATION_FAILED,
        payload: {apiError: error},
      });
    }
  };
};
