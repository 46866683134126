export const meetingTypes = [
  {
    value: "RELATIONSHIP_B",
    label: "Relationship Building"
  },
  {
    value: "OBSERVATION_G",
    label: "Observation Gathering"
  },
  {
    value: "HUDDLE",
    label: "Huddle"
  },
  {
    value: "OTHER",
    label: "Other"
  },
];

export const hours = [
  {
    value: "07:00",
    label: "07:00 am"
  },
  {
    value: "07:15",
    label: "07:15 am"
  },
  {
    value: "07:30",
    label: "07:30 am"
  },
  {
    value: "07:45",
    label: "07:45 am"
  },
  {
    value: "08:00",
    label: "08:00 am"
  },
  {
    value: "08:15",
    label: "08:15 am"
  },
  {
    value: "08:30",
    label: "08:30 am"
  },
  {
    value: "08:45",
    label: "08:45 am"
  },
  {
    value: "09:00",
    label: "09:00 am"
  },
  {
    value: "09:15",
    label: "09:15 am"
  },
  {
    value: "09:30",
    label: "09:30 am"
  },
  {
    value: "09:45",
    label: "09:45 am"
  },
  {
    value: "10:00",
    label: "10:00 am"
  },
  {
    value: "10:15",
    label: "10:15 am"
  },
  {
    value: "10:30",
    label: "10:30 am"
  },
  {
    value: "10:45",
    label: "10:45 am"
  },
  {
    value: "11:00",
    label: "11:00 am"
  },
  {
    value: "11:15",
    label: "11:15 am"
  },
  {
    value: "11:30",
    label: "11:30 am"
  },
  {
    value: "11:45",
    label: "11:45 am"
  },
  {
    value: "12:00",
    label: "12:00 pm"
  },
  {
    value: "12:15",
    label: "12:15 pm"
  },
  {
    value: "12:30",
    label: "12:30 pm"
  },
  {
    value: "12:45",
    label: "12:45 pm"
  },
  {
    value: "13:00",
    label: "01:00 pm"
  },
  {
    value: "13:15",
    label: "01:15 pm"
  },
  {
    value: "13:30",
    label: "01:30 pm"
  },
  {
    value: "13:45",
    label: "01:45 pm"
  },
  {
    value: "14:00",
    label: "02:00 pm"
  },
  {
    value: "14:15",
    label: "02:15 pm"
  },
  {
    value: "14:30",
    label: "02:30 pm"
  },
  {
    value: "14:45",
    label: "02:45 pm"
  },
  {
    value: "15:00",
    label: "03:00 pm"
  },
  {
    value: "15:15",
    label: "03:15 pm"
  },
  {
    value: "15:30",
    label: "03:30 pm"
  },
  {
    value: "15:45",
    label: "03:45 pm"
  },
  {
    value: "16:00",
    label: "04:00 pm"
  },
  {
    value: "16:15",
    label: "04:15 pm"
  },
  {
    value: "16:30",
    label: "04:30 pm"
  },
  {
    value: "16:45",
    label: "04:45 pm"
  },
  {
    value: "17:00",
    label: "05:00 pm"
  },
  {
    value: "17:15",
    label: "05:15 pm"
  },
  {
    value: "17:30",
    label: "05:30 pm"
  },
  {
    value: "17:45",
    label: "05:45 pm"
  },
  {
    value: "18:00",
    label: "06:00 pm"
  },
  {
    value: "18:15",
    label: "06:15 pm"
  },
  {
    value: "18:30",
    label: "06:30 pm"
  },
  {
    value: "18:45",
    label: "06:45 pm"
  },
  {
    value: "19:00",
    label: "07:00 pm"
  },
];