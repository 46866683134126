export function forgotPasswordStarted(state) {
    return {
        ...state,
        forgotPassword: {
            isProcessing: true,
            success: null,
            error: null
        }
    };
}
export function forgotPasswordSuccess(state) {
    return {
        ...state,
        forgotPassword: {
            isProcessing: false,
            success: true,
            error: null
        }
    };
}
export function forgotPasswordError(state) {
    return {
        ...state,
        forgotPassword: {
            isProcessing: false,
            success: null,
            error: true
        }
    };
}