export function Default() {
  return {
    fetchMsopStateApi: {
      loading: false,
      topSectionData: [],
      bottomSectionData: [],
      error: false,
    },
  };
}
