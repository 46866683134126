import {connect} from 'react-redux';
import {
  listOrganisationAction,
  getPresignedUrl,
  updateErrorMessage,
  updateOrganisation,
  deleteOrganisation,
  listMissionsAction,
} from './../../../../Redux/Actions';
import {
  listOrganisationGroupAction,
  listGroupOrganizationsAction,
} from '../../../../Redux/Actions/ListOrganizationGroupAction';

import {uploadImage} from '../../../../Redux/Actions/UploadImageAction';
import AddNetwork from './AddNetwork';
import {SelectState} from '../../Selector';
const mapStateToProps = (state) => {
  return {
    ...SelectState(state),
    ...state.DeleteOrganisationReducer,
    ...state.UpdateOrganisationReducer,
    ...state.OrganisationReducer,
    ...state.UpdateMissionReducer,
    ...state.MissionReducer,
    ...state.DeleteMissionReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrganisationGroupList: (params, callback) => {
      dispatch(listOrganisationGroupAction(params, callback));
    },
    getGroupOrganizationList: (params, callback) => {
      dispatch(listGroupOrganizationsAction(params, callback));
    },
    getPresignedUrl: (params, callback) => {
      dispatch(getPresignedUrl(params, callback));
    },
    uploadImage: (params) => {
      dispatch(uploadImage(params));
    },
    updateErrorMessage: (params) => {
      dispatch(updateErrorMessage(params));
    },
    deleteOrganisation: (params) => {
      dispatch(deleteOrganisation(params));
    },
    updateOrganisation: (params, callback) => {
      dispatch(updateOrganisation(params, callback));
    },
    listMissions: (params, callback) => {
      dispatch(listMissionsAction(params, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNetwork);
