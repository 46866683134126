import React, { ReactNode, createContext, useContext, useState } from 'react';
import FullScreenLoader from './FullScreenLoader';

type LoadingContextType = {
    startLoading: () => void;
    stopLoading: () => void;
}

const LoadingContext = createContext({ startLoading: () => {}, stopLoading : () => {}});

export const useLoading = () => useContext<LoadingContextType>(LoadingContext);

export const LoadingProvider = ({ children}: { children:ReactNode}) => {
  const [loadingCount, setLoadingCount] = useState(0);

  const startLoading = () => setLoadingCount((count) => count + 1);
  const stopLoading = () => setLoadingCount((count) => count - 1);

  const isLoading = loadingCount > 0;

  return (
    <LoadingContext.Provider value={{ startLoading, stopLoading }}>
        {isLoading && <FullScreenLoader/>}
        {children}
    </LoadingContext.Provider>
  );
};
