/* eslint-disable */
import React, {Component} from 'react';
import PropType, {number} from 'prop-types';
import {TextInput} from '../TextInput';
import TextArea from '../TextArea';
import {SelectElement} from '../SelectElement';
import Mentions from '../Mentions';
import Datepicker from '../Datepicker';
import style from './style.module.scss';
import {AsyncSelect} from '../AsyncSelect';
import {InputPassword} from '../InputPassword';
import CheckBox from '../CheckBox';
import {AutoCompleteInput} from '../AutoCompleteInput';
import NumberInput from '../InputNumber';

import { withTranslation } from "react-i18next"

class FormBlock extends Component {
  constructor(props) {
    super(props);
  }

  getFormElements = (props) => {
    switch (props.elementType) {
      case 'select':
        return this.getSelectElement(props);
      case 'textArea':
        return <TextArea {...props} />;
      case 'mentions':
        return <Mentions {...props} />;
      case 'datepicker':
        return <Datepicker {...props} />;
      case 'asyncselect':
        return <AsyncSelect {...props} />;
      case 'password':
        return <InputPassword {...props} />;
      case 'checkBox':
        return <CheckBox {...props} />;
      case 'autoComplete':
        return <AutoCompleteInput {...props} />;
      case 'number':
        return (
          <div className='ant-input-number_style'>
            <NumberInput {...props} />
          </div>
        );
      default:
        return (
          <TextInput
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
            htmlType={props.htmlType}
            {...props}
          />
        );
    }
  };

  renderComponentProps = () => {
    let selectObject = {};
    selectObject.className = this.props.className;
    selectObject.placeHolder = this.props.placeHolder;
    dateObject.onChange = (date) => this.handleChange(this.props.name, date);
    dateObject.name = this.props.name;
    dateObject.disabled = this.props.disabled;
    if (this.props.value) {
      dateObject.value = this.props.value;
    }
    return dateObject;
  };

  getSelectElement = (props) => {
    let selectObject = {};
    selectObject.optionList = props.optionList;
    selectObject.defaultValue = props.defaultValue;
    selectObject.className = props.className;
    selectObject.onChange = props.onChange;
    selectObject.placeholder = props.placeholder;
    selectObject.mode = props.mode;
    selectObject.disableSearch = props.disableSearch;
    selectObject.value = props.value;
    selectObject.style = props.style;
    selectObject.dropdownRender = props.dropdownRender;
    selectObject.allowClear = props.allowClear;
    selectObject.loading = props.loading;
    if (!props.loading) {
      selectObject.suffixIcon = props.suffixIcon;
    }

    return <SelectElement {...selectObject} />;
  };

  render() {
    return (
      <div className={style.formBlock}>
        <div className={style.formBlock__elements}>
          {this.props.label && (
            <label className={style.formLabel} htmlFor={this.props.htmlForName}>
              {this.props.requiredIcon && (
                <span className={style.requiredStar} title='required'>
                  <svg viewBox='0 0 9.891 9.616'>
                    <path
                      data-name='Path 284'
                      d='M606.744,789.148l2.195,2.449,2.131-2.449-.508,3.226,3.31.263-2.894,1.714,1.858,2.49L609.79,795.6l-.852,3.165-.989-3.24-2.964,1.317,1.92-2.754-2.925-1.533,3.318-.18Z'
                      transform='translate(-603.979 -789.148)'
                    />
                  </svg>
                </span>
              )}
              <span className={style.requiredName}>{this.props.label}</span>
            </label>
          )}
          {this.getFormElements(this.props)}
          {this.props.error?.length ? (
            <div className={style.formError}>
              <span>
                <svg viewBox='0 0 512.072 431.938'>
                  <path
                    data-name='Path 277'
                    d='M47.355,431.938c-36.753,0-59.417-40.275-40.58-71.67L205.967,28.288c22.644-37.754,77.54-37.681,100.139,0L505.3,360.268c18.832,31.39-3.821,71.67-40.581,71.67ZM233.407,44.748,34.216,376.728a15.347,15.347,0,0,0,13.14,23.21h417.36a15.348,15.348,0,0,0,13.141-23.21L278.667,44.748a26.424,26.424,0,0,0-45.26,0Zm-1.372,275.19a24,24,0,1,1,24,24A24,24,0,0,1,232.035,319.938Zm8-56v-112a16,16,0,1,1,32,0v112a16,16,0,0,1-32,0Z'
                    transform='translate(0 0)'
                  />
                </svg>
              </span>
              <span>{this.props.error}</span>
            </div>
          ) : (
            []
          )}
        </div>
        {this.props.hint?.length ? (
          <div className={style.formBlock__hints}>
            <p>{this.props.t('Form_Block.Hint')} : {this.props.hint}</p>
          </div>
        ) : (
          []
        )}
      </div>
    );
  }
}
FormBlock.Prototype = {
  className: PropType.string,
  type: PropType.string,
  placeholder: PropType.string,
  value: PropType.string,
};

export default withTranslation()(FormBlock)
