import React from 'react';
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer'
import './styles/MeetingsPdf.scss';
import moment from 'moment';

const styles = StyleSheet.create({
  page: { padding: "25px", fontSize: "12px", fontWeight: "200" },
  row: { flexDirection: "row" },
  check: { width: "12px", height: "12px", border: "1px solid #3C5DD6", backgroundColor: "#3C5DD6", marginRight: "5px" },
  checkBlanck: { width: "12px", height: "12px", border: "1px solid #000", marginRight: "5px" },
  rowMargin: { flexDirection: "row", marginTop: "15px" },
  rowMargin5: { flexDirection: "row", marginTop: "5px" },
  rowHeaderAtte: { fontSize: "15px", flexDirection: "row", marginTop: "15px", marginBottom: "5px", backgroundColor: "#102431", color: "#fff", padding: "5px" },
  rowHeaderNote: { fontSize: "15px", flexDirection: "row", marginTop: "15px", marginBottom: "5px", backgroundColor: "#E29016", color: "#fff", padding: "5px" },
  rowHeaderObservation: { fontSize: "15px", flexDirection: "row", marginTop: "15px", marginBottom: "5px", backgroundColor: "#5B0949", color: "#fff", padding: "5px" },
  rowHeaderBarries: { fontSize: "15px", flexDirection: "row", marginTop: "15px", marginBottom: "5px", backgroundColor: "#27339F", color: "#fff", padding: "5px" },
  rowHeaderAction: { fontSize: "15px", flexDirection: "row", marginTop: "15px", marginBottom: "5px", backgroundColor: "#236E08", color: "#fff", padding: "5px" },
  rowHeaderFeed: { fontSize: "15px", flexDirection: "row", marginTop: "15px", marginBottom: "5px", backgroundColor: "#A63434", color: "#fff", padding: "5px" },
  w100: { width: "100%" },
  w50: { width: "50%" },
  w33: { width: "33%" },
  mt15: { marginTop: "15px" },
  mr15: { marginRight: "15px" },
  font15: { fontSize: "17px", fontWeight: "900" },
  list: { flexDirection: "row", marginTop: "10px", marginBottom: "5px", borderBottom: ".5px" },
  hrGray: { borderBottom: ".5px", height: "5px", width: "100%", opacity: ".5" },
  hrBlue: { borderBottom: "1px", borderBottomColor: "#4DA3D9", height: "5px", width: "100%" },
  logo: { width: "58px", height: "39px", marginBottom: "15px" },
  hidden: { opacity: "0", height: "0px", width: "0px" }
});

const meetingTypes = {
  RELATIONSHIP_B: { value: "Relationship Building" },
  OBSERVATION_G: { value: "Observation Gathering" },
  HUDDLE: { value: "Huddle" },
  OTHER: { value: "Other" }
};

const atteTypes = {
  UNSURE: { value: "Unsure" },
  NOT_ENGAGED: { value: "Not Engaged" },
  LOW: { value: "Low" },
  MEDIUM: { value: "Medium" },
  HIGH: { value: "High" },
};

const meetingReasons = {
  NOT_RP: { value: "Didn't meet with the right people" },
  NO_TIME: { value: "Didn't have enough time" },
  NO_INFO: { value: "Didn't have the right information" },
  OUT_OF: { value: "Discussion fell out of scope" },
  OTHER: { value: "Other" },
};

/**
 * Renders a meeting pdf.
 *
 * @param {Object} meetingData - The meet data.
 * @param {Array} rules - All meet rules.
 * @return {JSX.Element} The rendered meeting pdf component.
 */
const MeetingsPdf = ({ meetingData, rules }) => {
  return (
    <Document>
      <Page size="A4" style={styles?.page}>
        <Image src="/img/blueagilis-whitelogo.png" style={styles.logo} />
        <View >
          <Text style={styles?.font15}>
            {meetingData?.title}
          </Text>
        </View >
        <View style={styles.hrBlue}></View>
        <View>
          <View style={styles.rowMargin}>
            <Text style={styles.w50}>Date: {moment(meetingData?.start).format('L')}</Text>
            <Text>Meeting Type: {meetingData?.meeting_type == "OTHER" ? meetingData?.other_meeting_type : meetingTypes[meetingData?.meeting_type]?.value}</Text>
          </View>
          {/* 					<View style={styles.hrGray}></View>
					<View style={styles.rowMargin}>
						<Text style={styles.w50}>Start: {moment(meetingData?.start).format('LT')}</Text>
						<Text>End: {moment(meetingData?.end).format('LT')}</Text>
					</View> */}
          <View style={styles.hrGray}></View>
        </View>
        <View style={styles.mt15}>
          <Text style={styles?.font15}>Meeting Prep</Text>
        </View>
        <View style={styles.hrBlue}></View>
        <View>
          <View style={rules[0].value ? styles.rowMargin : styles.hidden}>
            <Text>Network: {meetingData?.organizations ? meetingData?.organizations[0]?.name : ''}</Text>
          </View>
          <View style={rules[0].value ? styles.hrGray : styles.hidden}></View>
          <View style={rules[1].value ? styles.rowMargin : styles.hidden}>
            <Text>Project: {meetingData?.organizations ? meetingData?.missions[0]?.name : ''}</Text>
          </View>
          <View style={rules[1].value ? styles.hrGray : styles.hidden}></View>
        </View>
        <View style={rules[3].value ? styles.mt15 : styles.hidden}>
          <Text style={styles.font15}>Attendees</Text>
        </View>
        <View style={rules[3].value ? styles.rowHeaderAtte : styles.hidden}>
          <Text style={styles.w33}>Name</Text>
          <Text style={styles.w33}>Role</Text>
          <Text>Type</Text>
        </View>
        <View style={rules[3].value ? "" : styles.hidden}>
          {
            meetingData?.attendees
            &&
            meetingData?.attendees.map(atte => (
              <View style={styles.list}>
                <Text style={styles.w33}>{atte?.user?.first_name} {atte?.user?.last_name}</Text>
                <Text style={styles.w33}>{atte?.user?.user_designation}</Text>
                <Text style={styles.w33}>{atteTypes[atte?.attendee_type]?.value}</Text>
              </View>
            ))
          }
        </View>
        <View style={rules[4].value ? styles.mt15 : styles.hidden}>
          <Text style={styles.font15}>Goals For Meeting</Text>
        </View>
        <View style={rules[4].value ? "" : styles.hidden}>
          {
            meetingData?.goals
            &&
            meetingData?.goals.map(goal => (
              goal.category == "MEETING_CAT" && <View style={styles.rowMargin5}>
                <Text style={styles.w100}>{`\u2022 ${goal.name}`}</Text>
              </View>
            ))
          }
        </View>
        <View style={rules[5].value ? styles.mt15 : styles.hidden}>
          <Text style={styles.font15}>Personal Goals For Facilitators</Text>
        </View>
        <View style={rules[5].value ? "" : styles.hidden}>
          {
            meetingData?.goals
            &&
            meetingData?.goals.map(goal => (
              goal.category == "PERSONAL_CAT" && <View style={styles.rowMargin5}>
                <Text style={styles.w100}>{`\u2022 ${goal.name}`}</Text>
              </View>
            ))
          }
        </View>
      </Page>
      <Page size="A4" style={styles?.page}>
        <View style={styles.mt15}>
          <Text style={styles.font15}>Meeting Notes</Text>
        </View>
        <View style={styles.hrBlue}></View>
        <View style={rules[6].value ? styles.mt15 : styles.hidden}>
          <View>
            <Text style={styles.rowHeaderNote}>Note</Text>
          </View>
          <View style={styles.row}>
            <View style={styles.row}>
              <View style={meetingData?.notes?.question_storm ? styles.check : styles.checkBlanck}></View>
              <Text style={styles.mr15}>Question storm</Text>
            </View>
            <View style={styles.row}>
              <View style={meetingData?.notes?.check_assumptions ? styles.check : styles.checkBlanck}></View>
              <Text style={styles.mr15}>Check assumptions</Text>
            </View>
            <View style={styles.row}>
              <View style={meetingData?.notes?.time_for_feedback ? styles.check : styles.checkBlanck}></View>
              <Text style={styles.mr15}>Time for feedback</Text>
            </View>
            <View style={styles.row}>
              <View style={meetingData?.notes?.psychological_safety ? styles.check : styles.checkBlanck}></View>
              <Text>Psychological safety</Text>
            </View>
          </View>
          <View>
            <Text>
              {meetingData?.notes?.text ? JSON.parse(meetingData?.notes?.text)?.blocks?.map(item => item.text + ' ') : ''}
            </Text>
          </View>
        </View>
        <View style={rules[7].value ? styles.mt15 : styles.hidden}>
          <View>
            <Text style={styles.rowHeaderObservation}>Observation</Text>
          </View>
          <View style={styles.row}>
            <View style={styles.row}>
              <View style={meetingData?.observation?.physical_space ? styles.check : styles.checkBlanck}></View>
              <Text style={styles.mr15}>Physical Space</Text>
            </View>
            <View style={styles.row}>
              <View style={meetingData?.observation?.team_dynamics ? styles.check : styles.checkBlanck}></View>
              <Text style={styles.mr15}>Team Dynamic</Text>
            </View>
            <View style={styles.row}>
              <View style={meetingData?.observation?.workflows ? styles.check : styles.checkBlanck}></View>
              <Text style={styles.mr15}>Workflows</Text>
            </View>
            <View style={styles.row}>
              <View style={meetingData?.observation?.team_culture ? styles.check : styles.checkBlanck}></View>
              <Text>Team Culture</Text>
            </View>
          </View>
          <View>
            <Text>
              {meetingData?.observation?.text ? JSON.parse(meetingData?.observation?.text)?.blocks?.map(item => item.text + ' ') : ''}
            </Text>
          </View>
        </View>
        <View style={rules[8].value ? styles.mt15 : styles.hidden}>
          <View>
            <Text style={styles.rowHeaderBarries}>Barries</Text>
          </View>
          <View>
            <Text>
              {meetingData?.barriers?.text ? JSON.parse(meetingData?.barriers?.text)?.blocks?.map(item => item.text + ' ') : ''}
            </Text>
          </View>
        </View>
        <View style={rules[9].value ? styles.mt15 : styles.hidden}>
          <View>
            <Text style={styles.rowHeaderAction}>Action Items</Text>
          </View>
          <View style={styles.row}>
            <View style={styles.row}>
              <View style={meetingData?.action_items_checks?.decisions_made ? styles.check : styles.checkBlanck}></View>
              <Text style={styles.mr15}>Decision Made</Text>
            </View>
            <View style={styles.row}>
              <View style={meetingData?.action_items_checks?.plan_of_action ? styles.check : styles.checkBlanck}></View>
              <Text style={styles.mr15}>Plan of Action</Text>
            </View>
            <View style={styles.row}>
              <View style={meetingData?.action_items_checks?.action_items ? styles.check : styles.checkBlanck}></View>
              <Text style={styles.mr15}>Actions Items</Text>
            </View>
            <View style={styles.row}>
              <View style={meetingData?.action_items_checks?.due_dates ? styles.check : styles.checkBlanck}></View>
              <Text>Due Dates</Text>
            </View>
          </View>
        </View>
        <View style={rules[10].value ? styles.mt15 : styles.hidden}>
          <View>
            <Text style={styles.rowHeaderFeed}>Feedback</Text>
          </View>
          <View style={styles.row}>
            <View style={styles.row}>
              <View style={meetingData?.feedback?.whats_working ? styles.check : styles.checkBlanck}></View>
              <Text style={styles.mr15}>What’s working</Text>
            </View>
            <View style={styles.row}>
              <View style={meetingData?.feedback?.pain_points ? styles.check : styles.checkBlanck}></View>
              <Text style={styles.mr15}>Pain Points</Text>
            </View>
            <View style={styles.row}>
              <View style={meetingData?.feedback?.lessons_learned ? styles.check : styles.checkBlanck}></View>
              <Text>Lessons Learned</Text>
            </View>
          </View>
          <View>
            <Text>
              {meetingData?.feedback?.text ? JSON.parse(meetingData?.feedback?.text)?.blocks?.map(item => item.text + ' ') : ''}
            </Text>
          </View>
        </View>
      </Page>
      <Page Page size="A4" style={styles?.page}>
        <View style={rules[11].value ? styles.row : styles.hidden}>
          <Text>How this meeting go?: {meetingData?.reflection}</Text>
        </View>
        <View style={rules[11].value ? styles.hrGray : styles.hidden}></View>
        <View style={rules[12].value ? styles.mt15 : styles.hidden}>
          <Text style={styles?.font15}>
            Goals
          </Text>
        </View >
        <View style={rules[12].value ? styles.rowHeaderAtte : styles.hidden}>
          <Text style={styles.w33}>Goal</Text>
          <Text style={styles.w33}>Achieved</Text>
          <Text style={styles.w33}>Reason</Text>
        </View>
        <View style={rules[12].value ? "" : styles.hidden}>
          {
            meetingData?.goals
            &&
            meetingData?.goals.map(goal => (
              <View style={styles.list}>
                <Text style={styles.w33}>{goal?.name}</Text>
                <Text style={styles.w33}>{goal?.achieved ? "Yes" : "No"}</Text>
                <Text style={styles.w33}>{goal?.reason_type == "OTHER" ? goal?.other_reason : meetingReasons[goal?.reason_type]?.value}</Text>
              </View>
            ))
          }
        </View>
        <View style={rules[13].value ? styles.mt15 : styles.hidden}>
          <View style={styles.row}>
            <Text>Emotional Footprint: {meetingData?.emotion_value}</Text>
          </View>
        </View>
        <View style={rules[13].value ? styles.hrGray : styles.hidden}></View>
        <View style={rules[13].value ? styles.rowMargin : styles.hidden}>
          <Text style={styles.w50}>Word #1: {meetingData?.emotion_first_keyword}</Text>
          <Text>Word #2: {meetingData?.emotion_second_keyword}</Text>
        </View>
        <View style={rules[13].value ? styles.hrGray : styles.hidden}></View>
      </Page>
    </Document >
  );
};

export default MeetingsPdf;
