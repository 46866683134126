import {
  FETCH_ORG_DETAILS_FAILED,
  FETCH_ORG_DETAILS_STARTED,
  FETCH_ORG_DETAILS_SUCCESS,
  LIST_ORGANISATIONS_FAILED,
  LIST_ORGANISATIONS_STARTED,
  LIST_ORGANISATIONS_SUCCESS,
} from '../../Redux.constants';
import {
  getOrganisationListFailed,
  getOrganisationListStarted,
  getOrganisationListSuccess,
  fetchOrgDetailsStarted,
  fetchOrgDetailsSuccess,
  fetchOrgDetailsFailed,
} from './Helper';

const INITIAL_STATE = {
  getOrganisations: {
    isProcessing: null,
    success: null,
    error: null,
  },
  getOrganisationsDetails: {
    isProcessing: null,
    success: null,
    error: null,
  },
  organisationList: [],
  organisationData: null,
  limit: 11,
  organisationListOffset: 0,
  totalOrganisationCount: 0,
  organisationListSelectOptions: []
};
export default function OrganisationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LIST_ORGANISATIONS_STARTED:
      return getOrganisationListStarted(state);
    case LIST_ORGANISATIONS_SUCCESS:
      return getOrganisationListSuccess(state, action.payload);
    case LIST_ORGANISATIONS_FAILED:
      return getOrganisationListFailed(state, action.payload);
    case FETCH_ORG_DETAILS_STARTED:
      return fetchOrgDetailsStarted(state);
    case FETCH_ORG_DETAILS_SUCCESS:
      return fetchOrgDetailsSuccess(state, action.payload);
    case FETCH_ORG_DETAILS_FAILED:
      return fetchOrgDetailsFailed(state, action.payload);
    default:
      return { ...state };
  }
}
