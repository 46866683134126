import {getNotification} from '../../../Components/GetNotification';
import {ApiBase} from '../../../utils/apiBase';
import {FORGOT_PASSWORD} from '../../Api.constants';
import {
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
} from '../../Redux.constants';

export function forgotPasswordAction(params, callback) {
  return async (dispatch) => {
    dispatch({type: FORGOT_PASSWORD_START});
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.post(
        FORGOT_PASSWORD,
        params
      );
      dispatch({type: FORGOT_PASSWORD_SUCCESS});
      getNotification('success', {
        header: 'Success!',
        body: response.data,
      });
      callback && callback();
    } catch (error) {
      dispatch({
        type: FORGOT_PASSWORD_FAILED,
      });
      let body = 'Failed to sent reset password link';
      if (error.response?.data?.error?.email)
        body = error.response.data.error.email[0];
      getNotification('error', {
        header: 'Failed!',
        body,
      });
    }
  };
}
