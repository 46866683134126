import React, { useEffect, useState } from 'react';
import { Radio, Select } from 'antd';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getNotification } from '../../Components/GetNotification';
import {
  addSuccessesGoal,
  loadGoals,
  removeSuccessesGoal,
  updateSuccessesGoal,
  updatedGoalData,
} from '../../ReduxV2/state';
import {
  getMeetingGoals,
  getMeetingsDatails,
  saveMeetingReflection,
  updateMeetingGoals,
} from '../../Services/Meetings.service';
import { ClearLocalStorage } from '../../utils/localStorageOperations';
import './styles/MeetingsReflections.scss';

/**
 * Renders a component for reflecting on meetings.
 *
 * @param {Object} meetId - The ID of the meeting.
 * @param {function} returnHome - A function to return to the home page.
 * @param {function} returnPrep - A function to return to the preparation page.
 * @return {JSX.Element} The component for reflecting on meetings.
 */
const MeetingsReflections = ({ meetId, returnHome, returnPrep }) => {
  const dispatch = useDispatch();
  const [goalSelected, setGoalSelected] = useState();

  const goals = useSelector((state) => {
    return state.goal.value;
  });

  const reasons = [
    { value: 'NOT_RP', label: "Didn't meet with the right people" },
    { value: 'NO_TIME', label: "Didn't have enough time" },
    { value: 'NO_INFO', label: "Didn't have the right information" },
    { value: 'OUT_OF', label: 'Discussion fell out of scope' },
    { value: 'OTHER', label: 'Other' },
  ];
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const fetchLoadGoals = async () => {
    try {
      const meetGoals = await getMeetingGoals(meetId).call;
      const meet = await getMeetingsDatails(meetId).call;
      setValue('reflection', meet.data.reflection);
      setValue('emotion_value', meet.data.emotion_value);
      setValue('emotion_first_keyword', meet.data.emotion_first_keyword);
      setValue('emotion_second_keyword', meet.data.emotion_second_keyword);
      dispatch(loadGoals(meetGoals.data.results));
    } catch (error) {
      ClearLocalStorage();
    }
  };

  /**
   * Handles the ok action and saves the meeting reflections.
   *
   * @param {Object} params - The parameters for saving the meeting reflections.
   * @return {Promise<void>} - A promise that resolves when the reflections are saved.
   */
  const handleOk = async (params) => {
    try {
      params.status = 'reflection';
      const saveReflections = saveMeetingReflection(meetId, params).call;
      const updateMeetingGoal = goals.map(
        (goal) =>
          updateMeetingGoals(goal.id, {
            achieved: goal.achieved,
            reason_type: goal.reason_type,
            other_reason: goal.other_reason,
            successes: goal.successes.length
              ? goal.successes.map(({ text }) => text).join(',')
              : '',
          }).call
      );

      await Promise.all([saveReflections, ...updateMeetingGoal]);
      getNotification('success', {
        header: 'Success!',
        body: 'Your reflection has been saved successfully',
      });
      returnHome();
    } catch (error) {
      getNotification('error', {
        header: 'Failed!',
        body: 'An apology, an error has occurred when trying to save your reflections. please try again later',
      });
    }
  };

  useEffect(() => {
    if (meetId) {
      fetchLoadGoals();
    }
  }, [meetId]);

  return (
    <div className='meetingsreflections'>
      <form onSubmit={handleSubmit(handleOk)}>
        <h3>Meeting Notes</h3>
        <div className='layoutSection w-90 text-center mt-15'>
          <h3>How did this meeting go?</h3>
          <p className='mb-15'>Select one option</p>
          <Controller
            name='reflection'
            control={control}
            render={({ field }) => (
              <Radio.Group {...field}>
                <Radio value='NOT_WELL'>
                  Did not meet minimal viable goals
                </Radio>
                <Radio value='NEUTRAL'>Met minimal viable goals</Radio>
                <Radio value='WELL'>Exceeded expectations</Radio>
              </Radio.Group>
            )}
          />
        </div>
        <div className='layoutSection w-90 my-15'>
          <div className='text-center'>
            <h3>Goals</h3>
          </div>
          <div className='mt-2rem'>
            <Select
              className='select-class'
              placeholder='Select a goal'
              bordered={false}
              options={goals?.map((goal, index) => ({
                value: index,
                label: goal.name,
              }))}
              onChange={(e) => {
                setGoalSelected(`${e}`);
              }}
            />

            {goalSelected && (
              <div>
                <div className='flex justify-beetween'>
                  <div>
                    <h3 className='my-15'>Achieved</h3>
                    <Radio.Group
                      onChange={(e) =>
                        dispatch(
                          updatedGoalData({
                            index: goalSelected,
                            value: { achieved: e.target.value },
                          })
                        )
                      }
                      value={goals[goalSelected].achieved}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </div>
                  <div
                    className={`flex-1 ml-25 ${!goals[goalSelected]?.achieved ? 'hidden' : ''
                      }`}>
                    <h3 className='flex items-center my-15'>
                      Successes
                      <span
                        className='close-icon-file mr-5 text-blue'
                        onClick={() =>
                          dispatch(addSuccessesGoal({ index: goalSelected }))
                        }>
                        +
                      </span>
                    </h3>
                    <div>
                      {goals[goalSelected]?.successes.map(
                        (successes, index) => (
                          <div key={index} className='flex items-center my-15'>
                            <input
                              className={`input-class w-100`}
                              placeholder='Enter a success'
                              value={successes.text}
                              onChange={(e) =>
                                dispatch(
                                  updateSuccessesGoal({
                                    index: goalSelected,
                                    successIndex: index,
                                    value: e.target.value,
                                  })
                                )
                              }
                            />
                            <span
                              className='close-icon-file mr-5 text-blue'
                              onClick={() =>
                                dispatch(
                                  removeSuccessesGoal({
                                    index: goalSelected,
                                    successIndex: index,
                                  })
                                )
                              }>
                              -
                            </span>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div
                    className={`flex-1 ml-25 ${goals[goalSelected]?.achieved ? 'hidden' : ''
                      }`}>
                    <h3 className='my-15'>Reason type</h3>
                    <Select
                      className='select-class'
                      placeholder='Select your reason'
                      bordered={false}
                      options={reasons}
                      value={goals[goalSelected].reason_type}
                      onChange={(e) =>
                        dispatch(
                          updatedGoalData({
                            index: goalSelected,
                            value: { reason_type: e },
                          })
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  className={
                    goals[goalSelected]?.reason_type == 'OTHER' ? '' : 'hidden'
                  }>
                  <h3 className='my-15'>Reason</h3>
                  <textarea
                    className='textarea'
                    value={goals[goalSelected].other_reason}
                    onChange={(e) =>
                      dispatch(
                        updatedGoalData({
                          index: goalSelected,
                          value: { other_reason: e.target.value },
                        })
                      )
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='layoutSection w-90'>
          <div className='text-center'>
            <h3>Personal Reflections</h3>
            <p>How do you think the meeting went?</p>
          </div>
          <div className='mt-2rem'>
            <div className='mt-2rem '>
              <Controller
                name='emotion_value'
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Radio.Group
                    {...field}
                    className='flex flex-wrap justify-center'
                    buttonStyle='solid'
                    onChange={(e) => field.onChange(e.target.value)}>
                    {_.range(-10, 11).map((item) => (
                      <Radio.Button
                        className={`font-18 ${errors.emotion_value ? 'border-error' : ''
                          }`}
                        value={item}>
                        {item}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                )}
              />
            </div>
            <div className='mt-2rem flex justify-beetween'>
              <input
                className={`input-class ${errors.emotion_first_keyword ? 'border-error' : ''
                  }`}
                aria-invalid={errors.emotion_first_keyword ? 'true' : 'false'}
                {...register('emotion_first_keyword', { required: true })}
                placeholder='Word #1'
              />
              <input
                className={`input-class ${errors.emotion_second_keyword ? 'border-error' : ''
                  }`}
                aria-invalid={errors.emotion_second_keyword ? 'true' : 'false'}
                {...register('emotion_second_keyword', { required: true })}
                placeholder='Word #2'
              />
            </div>
          </div>
        </div>
        <div className='flex justify-beetween'>
          <div className='container-button'>
            <button type='button' onClick={returnPrep}>
              Back
            </button>
          </div>
          <div className='container-button'>
            <button type='submit'>Save</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MeetingsReflections;
