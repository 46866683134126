export function updateErrorMessage(state, payload) {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      name: payload.name,
      errorMessage: '',
    },
    isProcessing: false,
  };
}

export function getGroupOrganisationListSuccess(state, payload) {
  let abc = {
    ...state,
    getOrganisations: {
      isProcessing: false,
      success: true,
      error: null,
    },
    groupOrganizationList: payload?.listOrgResponse,
    organisationGroupListOffset: payload.offset,
    organisationGroupListSelectOptions: payload.listOrgResponse.results
      ? payload.groupOrg.results.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      : [],
  };
  return abc;
}
export function getOrganisationGroupListFailed(state, payload) {
  return {
    ...state,
    getOrganisations: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching list',
    },
  };
}

export function getGroupOrganisationListStarted(state) {
  return {
    ...state,
    grouporganizationdata: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
