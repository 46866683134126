import React, { useEffect, useRef, useState } from 'react'
import { Button, Table, Collapse } from 'antd'
import { HistoryOutlined, CloseCircleOutlined, EyeOutlined, StarOutlined, DeleteOutlined } from '@ant-design/icons'
import moment from 'moment'

import { deleteSmartSolution, getNewSmartSearch, patchNewSmartSearch, getSmartKeyDrivers } from '../../../../../../Services/SmartSolution.service'
import { getNotification } from '../../../../../../Components/GetNotification'
import { SSPanel } from './SSPanel'

import './styles/SmartSolutions.scss'

const { Column } = Table
const { Panel } = Collapse

/**
 * SmartSolutions component.
 *
 * @return {JSX.Element} The rendered JSX element.
 */
const SmartSolutions = () => {
  const searchTerm = useRef()
  const [allResearchs, setAllResearchs] = useState([])
  const [researchSelectedIndex, setResearchSelectedIndex] = useState(-1)
  const [loading, setLoading] = useState(false)
  const [showFavorites, setShowFavorites] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [showResearchs, setShowResearchs] = useState(false)
  const [selectSolutions, setSelectSolutions] = useState([])
  const [selectedItem, setselectedItem] = useState()
  const [isFullHistory, setIsFullHistory] = useState(false)

  /**
   * Performs a search using the given parameters and updates the state accordingly.
   *
   * @param {Event} e - The event that triggered the search (optional).
   * @param {number} retries - The number of retries remaining (optional, default is 3).
   * @return {Promise<void>} - A promise that resolves when the search is complete.
   */
  const search = async (e = null, retries = 3) => {
    setSelectSolutions([])
    setselectedItem()
    if (searchTerm.current.value.length && (e?.key === "Enter" || e === null)) {
      try {
        setLoading(true)
        const res = await getSmartKeyDrivers(searchTerm.current.value).call;
        setSelectSolutions(res.data?.key_drivers)
        loadResearch()
        setLoading(false)
      } catch (error) {
        if (retries > 0)
          setTimeout(() => search(e, retries - 1), 1000)
        else {
          getNotification("warning", {
            header: "Search results",
            body: "No results found for your search",
          })
          setLoading(false)
        }
      }
    }
  }

  const loadResearch = async () => {
    try {
      const res = await getNewSmartSearch().call
      setAllResearchs(res?.data?.results.map((item) => {
        return {
          ...item,
          action: item.action !== "" ? item.action : item.goal,
          solutions: item.solutions.length > 0 ? item.solutions : item.key_drivers,
        }
      }))
    } catch (error) { }
  }

  /**
   * A function that shows solutions.
   *
   * @param {Object} item - The item to be shown.
   * @param {number} index - The index of the item.
   */
  const showSolutions = async (item, index) => {
    setSelectSolutions(item.solutions)
    setselectedItem(item)
    setResearchSelectedIndex(index)
  }

  /**
   * Updates the favorite status of an item.
   *
   * @param {Object} param - The parameter object.
   * @param {string} param.id - The ID of the item.
   * @param {boolean} param.favorite - The current favorite status of the item.
   * @return {Promise<void>} A promise that resolves when the favorite status is updated.
   */
  const addToFavorite = async ({ id, favorite }) => {
    try {
      setUpdating(true)
      const res = await patchNewSmartSearch({ id, favorite: !favorite }).call
      loadResearch()
      setUpdating(false)
    } catch (error) {

    }
  }

  /**
   * Deletes a research with the given ID.
   *
   * @param {number} id - The ID of the research to delete.
   * @return {Promise<void>} A Promise that resolves when the research is deleted.
   */
  const deleteResearch = async (id) => {
    try {
      setUpdating(true)
      const res = await deleteSmartSolution(id).call;
      loadResearch()
      setUpdating(false)
    } catch (error) {

    }
  }

  useEffect(() => {
    loadResearch()
    setAllResearchs([])
    setSelectSolutions([])
    setselectedItem()
  }, [])

  useEffect(() => {
    setIsFullHistory(selectSolutions.length > 0)
    if (selectSolutions.length === 0) setResearchSelectedIndex(-1)
  }, [selectSolutions])

  useEffect(() => {
    if (!selectedItem?.id) return
    const shownSolutions = allResearchs?.filter((item) =>
      showFavorites && item.favorite || !showFavorites) || []
    if (selectedItem && !shownSolutions.find((item) => item.id === selectedItem.id)) {
      setSelectSolutions([])
      setselectedItem()
    }
  }, [allResearchs, selectedItem, showFavorites])

  useEffect(() => {
    const tableSelectedRows = document.querySelectorAll(`#solutions-table tbody tr`)
    const tableSelectedRow = tableSelectedRows[researchSelectedIndex] || null
    if (!tableSelectedRow) return
    tableSelectedRows.forEach((item) => item.classList.remove("selected"))
    tableSelectedRow.classList.add("selected")
  }, [researchSelectedIndex])

  return (
    <div className="smartsolutions-container">
      <p><b>Let's search</b> to see what solutions exist for solving your problem</p>
      <div className="flex">
        <input className="input-class flex-1" type="text" placeholder="Enter your question here" ref={(e) => searchTerm.current = e} onKeyDown={search} />
        <Button type="primary" onClick={() => {
          if (loading) return
          searchTerm.current.value.length && search()
        }} disabled={loading}>
          Search
        </Button>
      </div>
      {
        loading &&
        <div className="text-center mt-10">
          <div className="pageLoader" >
            <div className="pageLoader__inner">
              <span className="loading" />
            </div>
          </div>
          Thank you for waiting! Our AI is hard at work generating your solutions. <br /> Please be patient as it may take a few moments to complete. We appreciate your patience
        </div>
      }
      <div className={`${showResearchs && "flex"} justify-between smartsolutions-table`}>
        {
          allResearchs.length > 0 &&
          <div className={isFullHistory ? "flex-1-4" : "flex-cover"}>
            <Button
              type="default"
              className={`mt-10 ${showResearchs ? "active w-100" : ""}`}
              onClick={() => setShowResearchs(!showResearchs)}
            >
              Search History
              {showResearchs ? <CloseCircleOutlined /> : <HistoryOutlined />}
            </Button>
            {showResearchs &&
              <div className="animate__animated animate__fadeIn">
                <div className="table-caption">
                  <input type="checkbox" id="showFavorites" checked={showFavorites} onChange={() => setShowFavorites(!showFavorites)} />
                  <label htmlFor="showFavorites">Show Favorites</label>
                </div>
                <Table loading={loading || updating}
                  id="solutions-table"
                  rowClassName="pointer"
                  dataSource={allResearchs.filter((item) =>
                    showFavorites && item.favorite || !showFavorites)}
                  showHeader={!isFullHistory}
                  pagination={{
                    defaultPageSize: 5,
                    simple: isFullHistory,
                    showSizeChanger: !isFullHistory,
                  }}
                >
                  {isFullHistory ?
                    <Column
                      onCell={(record, rowIndex) => {
                        return {
                          onClick: event => {
                            showSolutions(record, rowIndex)
                          },
                        };
                      }}
                      render={(item, record, rowIndex) => (
                        <>
                          <div>
                            <a>{item.action}</a>
                            <p className="date">{moment(item.created_at).format('L LT')}</p>
                          </div>
                          <div className="float-right mt-5">
                            <a onClick={() => showSolutions(record, rowIndex)}>
                              <EyeOutlined />
                            </a>
                            <a className="ml-10" onClick={() => addToFavorite(record)}>
                              <StarOutlined style={{ color: record.favorite ? "#FFC53D" : "" }} />
                            </a>

                            <a className="ml-10" onClick={() => deleteResearch(record?.id)}>
                              <DeleteOutlined style={{ color: "#F46767" }} />
                            </a>
                          </div>
                        </>)}
                    />
                    :
                    <>
                      <Column title="Search Prompt" dataIndex="action" key="action"
                        onCell={(record, rowIndex) => {
                          return {
                            onClick: event => {
                              showSolutions(record, rowIndex)
                            },
                          };
                        }}
                        ellipsis={true}
                        sorter={(a, b) => a.action.localeCompare(b.action)}
                      />
                      <Column title="Date" dataIndex="created_at" key="created_at"
                        width={200}
                        render={text => (<p>{moment(text).format('L LT')}</p>)}
                        onCell={(record, rowIndex) => {
                          return {
                            onClick: event => {
                              showSolutions(record, rowIndex)
                            },
                          };
                        }}
                        sorter={(a, b) => a.created_at.localeCompare(b.created_at)}
                      />
                      <Column title="Actions" dataIndex="actions" key="actions"
                        width={100}
                        render={(_, record, rowIndex) => (
                          <div>
                            <a onClick={() => showSolutions(record, rowIndex)}>
                              <EyeOutlined />
                            </a>
                            <a className="ml-10" onClick={() => addToFavorite(record)}>
                              <StarOutlined style={{ color: record.favorite ? "#FFC53D" : "" }} />
                            </a>

                            <a className="ml-10" onClick={() => deleteResearch(record?.id)}>
                              <DeleteOutlined style={{ color: "#F46767" }} />
                            </a>
                          </div>
                        )}
                      />
                    </>
                  }
                </Table>
              </div>
            }
          </div>
        }
        {
          (isFullHistory && !loading) &&
          <div className={`${isFullHistory ? "flex-3-4" : "flex-cover"} animate__animated animate__slideInRight`}>
            <h4 className="mt-10">Solutions</h4>
            <Collapse expandIconPosition="end" defaultActiveKey={["0"]} className="mt-15">
              {
                selectSolutions.map((item, index) => {
                  return (
                    <Panel header={<p className="text-white"><b>{item.name || selectedItem?.action || ""}</b></p>} key={index}>
                      <SSPanel item={item} index={index} />
                    </Panel>
                  )
                })
              }
            </Collapse>
          </div>
        }
      </div>
    </div>
  )
}

export default SmartSolutions