import {
  DELETE_GROUP_START,
  DELETE_GROUP_FAILED,
  DELETE_GROUP_SUCCESS,
} from '../../Redux.constants';
import {deleteApiStarted, deleteApiFailed, deleteApiSuccess} from './Helper';

const Initia_State = {
  deleteGroupstate: {
    isProcessing: null,
    error: null,
    success: null,
  },
};

const deleteGroupReducer = (state = Initia_State, action) => {
  switch (action.type) {
    case DELETE_GROUP_START:
      return deleteApiStarted(state, action);
    case DELETE_GROUP_FAILED:
      return deleteApiFailed(state, action);
    case DELETE_GROUP_SUCCESS:
      return deleteApiSuccess(state, action);
    default:
      return state;
  }
};
export default deleteGroupReducer;
