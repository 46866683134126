import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Row, Col, Select} from 'antd';
import {Controller, useForm, useFieldArray} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {useSelector} from 'react-redux';
import './styles/MVS.scss';
import {getMemberList} from '../../../../../../../../../../Services/Teams.service';
import {
  getMvSolution,
  getMvSolutionDetails,
  postMvSolution,
} from '../../../../../../../../../../Services/Sprints.service';
import {getNotification} from '../../../../../../../../../../Components/GetNotification';
import {debounce} from 'lodash';
import {CheckboxDebounce} from '../../../../../../../../../../Components/ChecboxDebounce';
import FormTable from '../../../../../../../../../../Components/FormTable/FormTable';
import TextareaDebounce from '../../../../../../../../../../Components/TextareaDebounce/TextareaDebounce';
import DebounceLoading, {
  dialogStartSubject$,
  dialogStopSubject$,
} from '../../../../../../../../../../Components/DebounceLoading/DebounceLoading';
import {AUTOSAVING_DELAY} from '../../../../../../../../../../../config/constants';

const CreateWorkflow = () => {
  return (
    <div>
      <div className='flex justify-between'>
        <h2>Creating a Workflow</h2>
        <div>
          <button>Import</button>
          <button>Share</button>
        </div>
      </div>
    </div>
  );
};

/**
 * Renders the MVS component.
 *
 * @return {JSX.Element} The rendered MVS component.
 */
const MVS = () => {
  const [attendees, setAttendees] = useState([]);
  const [historical, setHistorical] = useState([]);
  const [historicalSelect, setHistoricalSelect] = useState(false);
  const [disable, setDisable] = useState(false);
  const [influenced_staff_buy_enabled, setInfluenced_staff_buy_enabled] =
    useState(false);
  const [influenced_staff_NoBuy_enabled, setInfluenced_staff_NoBuy_enabled] =
    useState(false);
  const {id} = useParams();

  const refForm = useRef();

  const sprintId = useSelector((state) => {
    return state.sprint.value;
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: {errors},
    watch,
  } = useForm({
    defaultValues: {
      staff_nudges: [],
      follow_up_action_items: [],
      influenced_staff_NoBuy: [],
    },
  });

  const {
    fields: fields_staff_nudges,
    append: append_staff_nudges,
    remove: remove_staff_nudges,
  } = useFieldArray({
    control,
    name: 'staff_nudges',
  });

  const {
    fields: fields_follow_up_action_items,
    append: append_follow_up_action_items,
    remove: remove_follow_up_action_items,
  } = useFieldArray({
    control,
    name: 'follow_up_action_items',
  });

  /**
   * Saves the MVS with the given parameters.
   *
   * @param {Object} params - The parameters for saving the MVS.
   * @return {Promise} A promise that resolves when the MVS is saved.
   */
  const saveMVS = async (params) => {
    dialogStartSubject$.setSubject = true;
    try {
      const data = {
        ...params,
        id,
        mission_sprint: sprintId,
        follow_up_action_items: params?.follow_up_action_items?.map((item) => ({
          ...item,
          date: moment(item?.data).format('YYYY-MM-DDTHH:mm:ss'),
        })),
      };
      delete data?.influenced_staff_NoBuy;
      delete data?.influenced_staff_buy;
      delete data?.influenced_staff;
      const influenced_staff_buy_check = document.getElementById(
        'influenced_staff_buy_check'
      )?.checked
        ? true
        : false;
      const influenced_staff_NoBuy_check = document.getElementById(
        'influenced_staff_NoBuy_check'
      )?.checked
        ? true
        : false;
      const influenced_staff_buy = influenced_staff_buy_check
        ? params?.influenced_staff_buy?.map((item) => ({
            buy_in: true,
            member: item,
            plan_to_buy_in: '',
          }))
        : [];

      const influenced_staff_NoBuy = influenced_staff_NoBuy_check
        ? params?.influenced_staff_NoBuy?.map((item) => ({
            buy_in: false,
            member: item,
            plan_to_buy_in: '',
          }))
        : [];
      data.influenced_staff = [];
      influenced_staff_buy.length > 0 &&
        data.influenced_staff.push(...influenced_staff_buy);
      influenced_staff_NoBuy.length > 0 &&
        data.influenced_staff.push(...influenced_staff_NoBuy);

      const res = await postMvSolution(data).call;

      getNotification('success', {
        header: '',
        body: 'The information has been saved successfully',
      });
    } catch (error) {}
    dialogStopSubject$.setSubject = false;
  };

  const loadAttendees = async () => {
    try {
      const res = await getMemberList(id).call;
      setAttendees(
        res.data?.results?.map((att) => ({value: att.id, label: att.name}))
      );
    } catch (error) {}
  };

  /**
   * Resets data and retrieves the MvSolutionDetails by ID,
   * updates the values in the form, and sets the enabled
   * status of influenced_staff_buy and influenced_staff_NoBuy.
   *
   * @param {type} id - the ID of the MvSolutionDetails
   * @return {type} returns nothing
   */
  const changeMVSdata = async (id) => {
    reset();
    try {
      const allData = await getMvSolutionDetails(id).call;

      const adaptResponsabilityId = [
        'needs_after_responsible',
        'needs_during_responsible',
        'pre_visit_prep_responsible',
        'prep_work_responsible',
        'sol_inter_responsible',
      ];

      allData?.data?.staff_nudges?.map((item) =>
        append_staff_nudges({
          ...item,
          affected_individual: item?.affected_individual?.id,
        })
      );
      allData?.data?.follow_up_action_items?.map((item) =>
        append_follow_up_action_items({
          ...item,
          attendees: item?.attendees?.map((att) => att?.id),
        })
      );

      delete allData?.data?.follow_up_action_items;
      delete allData?.data?.staff_nudges;

      Object.keys(allData?.data).map((key) => {
        if (adaptResponsabilityId.some((item) => item === key)) {
          setValue(key, allData?.data[key].id);
        } else {
          setValue(key, allData?.data[key]);
        }
      });
      const influenced_staff_buy = [];
      const influenced_staff_NoBuy = [];
      allData?.data?.influenced_staff?.map((item) => {
        if (item.buy_in) {
          influenced_staff_buy.push(
            ...item?.members?.map((member) => member?.member?.id)
          );
        } else {
          influenced_staff_NoBuy.push(
            ...item?.members?.map((member) => member?.member?.id)
          );
        }
      });
      if (influenced_staff_buy.length > 0)
        setInfluenced_staff_buy_enabled(true);
      if (influenced_staff_NoBuy.length > 0)
        setInfluenced_staff_NoBuy_enabled(true);
      setValue('influenced_staff_buy', influenced_staff_buy);
      setValue('influenced_staff_NoBuy', influenced_staff_NoBuy);
    } catch (error) {}
  };

  const loadMVS = async () => {
    if (sprintId) {
      try {
        const res = await getMvSolution(sprintId).call;
        setHistorical(res.data.results);
        changeMVSdata(res?.data?.results[0].id);
      } catch (error) {}
    }
  };

  const activeSubmitForm = () => {
    refForm?.current.click();
  };

  const debounceActive = useCallback(
    debounce(activeSubmitForm, AUTOSAVING_DELAY),
    []
  );

  /**
   * Adds a responsible to the component.
   *
   * @param {string} label - The label for the responsible.
   * @param {string} name - The name of the responsible.
   * @param {string} title - The title of the responsible.
   * @return {JSX.Element} The JSX element representing the responsible component.
   */
  const addResponsible = (label, name, title) => {
    return (
      <>
        <Col xs={24} sm={24} md={7} lg={7}>
          <p className='mb-10'>Responsible</p>
          <Controller
            name={label}
            control={control}
            render={({field}) => (
              <Select
                {...field}
                className={`select-class w-100 ${
                  errors.type ? 'border-error' : ''
                }`}
                placeholder='Select a type'
                optionFilterProp='children'
                bordered={false}
                onChange={(net) => {
                  field.onChange(net);
                  debounceActive();
                }}
                options={attendees}
              />
            )}
          />
        </Col>
        <Col xs={24} sm={24} md={16} lg={16}>
          <p>{title}</p>
          <TextareaDebounce
            name={name}
            control={control}
            debounceAction={debounceActive}
          />
        </Col>
      </>
    );
  };

  const [formTableData] = useState({
    control: control,
    debounceActive: debounceActive,
    errors: errors,
  });

  useEffect(() => {
    reset();
    loadAttendees();
    loadMVS();
  }, [sprintId]);

  return (
    <div className='mvs'>
      <div className='flex'>
        <form onSubmit={handleSubmit(saveMVS)}>
          <div>
            <div className='flex justify-end font-18'>
              <div>
                <div>
                  <button
                    type='submit'
                    className='btn-edit d-none'
                    ref={(e) => (refForm.current = e)}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <DebounceLoading />
    </div>
  );
};

export default MVS;
