/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Select } from 'antd';
import { ScoreCardFileType } from 'types/ScoreCard';

interface FileOption {
  value: string;
  label: string;
}

const { Option } = Select;

type ScoreCardSelectProps = {
  onChange: (e:any) => void;
  defaultValue: string
}

const ScoreCardSelect: React.FC<ScoreCardSelectProps> = ({defaultValue, onChange}) => {
  const fileOptions: FileOption[] = [
    { value: ScoreCardFileType.GOOGLE, label: ScoreCardFileType.GOOGLE },
    { value: ScoreCardFileType.EXCEL, label: ScoreCardFileType.EXCEL },
  ];

  const currentDefaultValue = fileOptions?.find((option: FileOption) => option.value === defaultValue)
  return (
    <Select
      defaultValue={currentDefaultValue}
      filterOption={(input:any, option:any) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      optionFilterProp="children"
      placeholder={<span style={{
        lineHeight: '37px'
      }}>Select a file type</span>}
      showSearch
      style={{ width: 200 }}
      onChange={onChange}
 
    >
      {fileOptions.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default ScoreCardSelect;
