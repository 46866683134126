import {
  GET_DEPARTMENT,
  GET_DEPARTMENT_FAILED,
  GET_DEPARTMENT_SUCCESS,
  CREATE_ORGANISATION_GROUP,
  CREATE_ORGANISATION_GROUP_FAILED,
  CREATE_ORGANISATION_GROUP_SUCCESS,
  UPDATE_ERROR_MESSAGE,
  CREATE_ORGANISATION_GROUP_VALIDATION_ERROR,
  LIST_ORGANISATIONS_GROUP_SUCCESS,
  LIST_ORGANISATIONS_GROUP_STARTED,
  FETCH_GROUP_DETAILS_FAILED,
  FETCH_GROUP_DETAILS_STARTED,
  FETCH_GROUP_DETAILS_SUCCESS,
  LIST_ORGANISATIONS_GROUP_FAILED,
  LIST_GROUP_ORGANISATIONS_STARTED,
} from '../../Redux.constants';
import {
  departmentData,
  departmentDataFailed,
  departmentDataSuccess,
  updateErrorMessage,
  createOrganisationGroupDataFailed,
  createOrganisationGroupDataSuccess,
  createOrganisationGroupData,
  createOrganisationGroupValidationErrors,
  getOrganisationGroupListSuccess,
  getOrganisationGroupListFailed,
  getOrganisationGroupListStarted,
  fetchGroupDetailsStarted,
  fetchGroupDetailsSuccess,
  getGroupOrganisationListStarted,
} from './helper';

const INITIAL_STATE = {
  isRegisteredUser: false,
  departmentdata: {
    ispending: false,
    completed: false,
    failed: false,
    data: [],
  },
  getOrganisations: {
    isProcessing: null,
    success: null,
    error: null,
  },
  getOrganisationsDetails: {
    isProcessing: null,
    success: null,
    error: null,
  },
  organisationGroupList: [],
  orgGroupData: {},
  limit: 11,
  organisationGroupListOffset: 0,
  totalOrganisationCount: 0,
  organisationGroupListSelectOptions: [],

  organisationgroupdata: {
    ispending: false,
    completed: false,
    failed: false,
    data: [],
  },

  name: {
    name: 'name',
    errorMessage: '',
  },
  point_of_contact: {
    name: 'point_of_contact',
    errorMessage: '',
  },
  country: {
    name: 'country',
    errorMessage: '',
  },
  contact_email: {
    name: 'contact_email',
    errorMessage: '',
  },
  phone: {
    name: 'phone',
    errorMessage: '',
  },
  website: {
    name: 'website',
    errorMessage: '',
  },
  isProcessing: null,
};
export default function OrganizationGroupReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case GET_DEPARTMENT:
      return departmentData(state, action.payload);
    case GET_DEPARTMENT_SUCCESS:
      return departmentDataSuccess(state, action.payload);
    case GET_DEPARTMENT_FAILED:
      return departmentDataFailed(state, action.payload);

    case CREATE_ORGANISATION_GROUP:
      return createOrganisationGroupData(state, action.payload);
    case CREATE_ORGANISATION_GROUP_SUCCESS:
      return createOrganisationGroupDataSuccess(state, action.payload);
    case CREATE_ORGANISATION_GROUP_FAILED:
      return createOrganisationGroupDataFailed(state, action.payload);
    case CREATE_ORGANISATION_GROUP_VALIDATION_ERROR:
      return createOrganisationGroupValidationErrors(state, action.payload);
    case UPDATE_ERROR_MESSAGE:
      return updateErrorMessage(state, action.payload);
    case LIST_ORGANISATIONS_GROUP_FAILED:
      return getOrganisationGroupListFailed(state, action.payload);
    case LIST_ORGANISATIONS_GROUP_SUCCESS:
      return getOrganisationGroupListSuccess(state, action.payload);
    case LIST_ORGANISATIONS_GROUP_STARTED:
      return getOrganisationGroupListStarted(state);

    case FETCH_GROUP_DETAILS_STARTED:
      return fetchGroupDetailsStarted(state);
    case FETCH_GROUP_DETAILS_SUCCESS:
      return fetchGroupDetailsSuccess(state, action.payload);
    // case FETCH_GROUP_DETAILS_FAILED:
    //   return fetchOrgDetailsFailed(state, action.payload);

    default:
      return {...state};
  }
}
