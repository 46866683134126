import {getNotification} from '../../../Components/GetNotification';
import {ApiBase} from '../../../utils/apiBase';
import {
  CREATE_ORGANISATION,
  CREATE_ORGANISATION_FAILED,
  CREATE_ORGANISATION_SUCCESS,
  CREATE_ORGANISATION_VALIDATION_ERROR,
  GET_DEPARTMENT,
  GET_DEPARTMENT_FAILED,
  GET_DEPARTMENT_SUCCESS,
  LOGIN_VERIFICATION_SUCCESS,
  UPDATE_ERROR_MESSAGE,
  GET_USER_LIST_START,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILED,
  DELETE_USER_START,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
} from '../../Redux.constants';
import * as yup from 'yup';
import {Users as users} from './InitialState';

export function GetDepartments() {
  return async (dispatch) => {
    dispatch({
      type: GET_DEPARTMENT,
      payload: {},
    });
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.get('/department/', {});
      dispatch({
        type: GET_DEPARTMENT_SUCCESS,
        payload: {
          department: response.data,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_DEPARTMENT_FAILED,
        payload: {},
      });
    }
  };
}

const ADD_ORGANISATION_FORM_SCHEMA = yup.object().shape({
  name: yup
    .string()
    // .matches(/^[A-Za-z]+$/, 'Organisation title should only have Alphabets.')
    .required('Network Title is required'),
  point_of_contact: yup
    .string()
    // .matches(/^[A-Za-z]+$/, 'Point of contact should only have Alphabets.')
    .required('Point of contact required'),
  country: yup.string().required('Location is required'),
  contact_email: yup
    .string()
    .email(
      "Invalid Email id! A valid email id will be of the format 'blueagilis@gmail.com'"
    )
    .required('Contact Email is required'),
  phone: yup
    .string()
    .min(10)
    .max(14, 'Phone number must be [+91] [10 digit subscriber number] ')
    .matches(
      /^((\+*)((0[ -]+)*|(91 )*)(\d{12}|\d{10}))|\d{5}([- ]*)\d{6}$/,
      'Invalid Phone number. Accepted format [+91][10 digit subscriber number] or [10 digit subscriber number]'
    )
    .required('Phone number is required'),
  website: yup
    .string()
    /* eslint-disable max-len */
    .matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      'Enter a valid url.(Eg: http://example.com , www.example.com, example.com)'
    )
    .required('Website is required'),
});
function fetchValidationErrors(input) {
  return new Promise((resolve, reject) => {
    ADD_ORGANISATION_FORM_SCHEMA.validate(input, {abortEarly: false}).catch(
      (error) => {
        let validationList = error.inner.map((item) => {
          return {
            path: item.path,
            message: item.message,
          };
        });
        resolve(validationList);
      }
    );
  });
}
export function CreateOrganisation(input, callback, id) {
  return async (dispatch) => {
    dispatch({
      type: CREATE_ORGANISATION,
      payload: {},
    });
    try {
      // await ADD_ORGANISATION_FORM_SCHEMA.validate(input);
      await addOrganisation({dispatch, input, callback, id});
    } catch (error) {
      let createOrganisationValidationErrors = await fetchValidationErrors(
        input
      );
      dispatch({
        type: CREATE_ORGANISATION_VALIDATION_ERROR,
        payload: createOrganisationValidationErrors,
      });
      dispatch({
        type: CREATE_ORGANISATION_FAILED,
        payload: {
          createOrgnisationError: null,
        },
      });
    }
  };
}
const successMessage = () => {
  return getNotification('success', {
    header: 'Success!',
    body: 'Created new network',
  });
};

/* eslint-disable   max-lines-per-function */
async function addOrganisation({dispatch, input, callback, id}) {
  try {
    const apiBaseInstance = new ApiBase();
    let response = await apiBaseInstance.instance.post(
      '/organization/',
      input,
      {headers: {'Content-Type': 'multipart/form-data'}}
    );

    if (id !== undefined) {
      const apiBaseInstance = new ApiBase();
      let formData = new FormData();
      formData.append('organization', response.data.id);
      formData.append('grp_id', id);
      let response2 = await apiBaseInstance.instance.patch(
        `organization/${response.data.id}/`,
        {
          group_id: id,
        },
        {headers: {'Content-Type': 'multipart/form-data'}}
      );
    }
    dispatch({
      type: CREATE_ORGANISATION_SUCCESS,
      payload: {organisation: response.data},
    });
    callback();
    successMessage();
  } catch (error) {
    dispatch({
      type: CREATE_ORGANISATION_FAILED,
      payload: {
        createOrgnisationError:
          error.response &&
          error.response.data &&
          error.response.data.non_field_errors,
      },
    });
    let body;
    if (error.response.data.hasOwnProperty('name')) {
      body = 'The field "Network Title" may not be blank.';
    } else {
      body = error?.response?.data?.non_field_errors
        ? error.response.data.non_field_errors
        : 'Failed to create a Network';
    }
    getNotification('error', {
      header: error?.response?.data?.non_field_errors
        ? ' Failed - Field Error'
        : 'Failed !',
      body,
    });
  }
}

export function updateErrorMessage(input) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ERROR_MESSAGE,
      payload: input,
    });
  };
}

export const UserListAction = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_USER_LIST_START,
    });
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.get(
        `/member-list/?organisation_id=${id}`,
        {}
      );
      dispatch({
        type: GET_USER_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_USER_LIST_FAILED,
      });
    }
  };
};

export const deleteUserAction = (org_id, userId) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_USER_START,
    });
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.delete(
        `/member-list/delete-member/?organisation_id=${org_id}&user_id=${userId}`,
        {}
      );
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({
        type: DELETE_USER_FAILED,
      });
    }
  };
};
