/* eslint-disable no-console */
export function getOrganisationListStarted(state) {
  return {
    ...state,
    getOrganisations: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function getOrganisationListSuccess(state, payload) {
  let archivedOnly = false;
  let emptyResult = false;
  if (
    payload.listOrgResponse.total_count ===
    payload.listOrgResponse.archived_count
  ) {
    archivedOnly = true;
  }
  if (payload.listOrgResponse.count === 0) emptyResult = true;
  // BE updated added .results in payload.listOrgResponse
  return {
    ...state,
    getOrganisations: {
      isProcessing: false,
      success: true,
      error: null,
    },
    totalOrganisationCount: payload.listOrgResponse.total_count,
    archiveOrganisationCount: payload.listOrgResponse.archived_count,
    organisationCount: payload.listOrgResponse.count,
    organisationList: payload.listOrgResponse.results,
    organisationListOffset: payload.offset,
    archivedOnly: archivedOnly,
    emptyResult: emptyResult,
    organisationListSelectOptions: payload.listOrgResponse.results
      ? payload.listOrgResponse.results.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      : [],
  };
}
export function getOrganisationListFailed(state, payload) {
  return {
    ...state,
    getOrganisations: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching list',
    },
  };
}

export function fetchOrgDetailsStarted(state) {
  return {
    ...state,
    getOrganisationsDetails: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function fetchOrgDetailsSuccess(state, payload) {
  return {
    ...state,
    getOrganisationsDetails: {
      isProcessing: false,
      success: true,
      error: null,
    },
    organisationData: payload.orgDetails,
  };
}
export function fetchOrgDetailsFailed(state, payload) {
  return {
    ...state,
    getOrganisationsDetails: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching details',
    },
  };
}
