import {
  UPDATE_DEPARTMENT_START,
  UPDATE_DEPARTMENT_FAILED,
  UPDATE_DEPARTMENT_SUCCESS,
} from '../../Redux.constants';
import {Default} from './InitialState';
import {updateApiStarted, updateApiFailed, updateApiSuccess} from './Helper';

const updateDepartmentReducer = (state = Default(), action) => {
  switch (action.type) {
    case UPDATE_DEPARTMENT_START:
      return updateApiStarted(state, action);
    case UPDATE_DEPARTMENT_FAILED:
      return updateApiFailed(state, action);
    case UPDATE_DEPARTMENT_SUCCESS:
      return updateApiSuccess(state, action);
    default:
      return state;
  }
};
export default updateDepartmentReducer;
