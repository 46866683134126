import axios, { AxiosResponse } from 'axios';
import {API_BASE_URL} from 'config/constants';
import { AbortableRequestConfig } from 'types/Request';
import { 
  PaginatedSprint, 
  PatchSprint, 
  PatchSprintActionItem,
  PostSprintPriorActionItem,
  SprintActionItem,
  SprintDetail,
  SprintMVSolution,
} from 'types/Sprint';

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    },
  };
};

export function getSprints(missionId: number): {
  call: Promise<AxiosResponse<PaginatedSprint>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}mission-sprints/?mission=${missionId}`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function getSprint(id: number): {
  call: Promise<AxiosResponse<SprintDetail>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}mission-sprints/${id}/`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function postSprints(data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}mission-sprints/`, data, headers()),
  };
}

export function patchSprints(data: PatchSprint | PatchSprintActionItem): {
  call: Promise<AxiosResponse<SprintDetail>>;
} {
  return {
    call: axios.patch(
      `${API_BASE_URL}mission-sprints/${data.id}/`,
      data,
      headers()
    ),
  };
}

export function postSprintReflextions(data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}sprint-reflections/`, data, headers()),
  };
}

export function patchSprintsHuddle(id: number, data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.post(
      `${API_BASE_URL}mission-sprints/${id}/huddle_data_docs/`,
      data,
      headers()
    ),
  };
}

export function deleteSprintHuddle(id: number, data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.delete(
      `${API_BASE_URL}mission-sprints/${id}/huddle_data_docs/`,
      {
        ...headers(),
        data: {
          file: data,
        },
      }
    ),
  };
}

export function deleteEntireSprintHuddle(huddle_id: number): {
  call: Promise<AxiosResponse<void>>;
} {
  return {
    call: axios.delete(`${API_BASE_URL}mission-sprints-huddles/${huddle_id}/`, headers()),
  };
}

export function patchSprintMeasures(data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.post(
      `${API_BASE_URL}sprint-measures/${data.id}/`,
      data,
      headers()
    ),
  };
}

export function postSprintMeasures(data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}sprint-measures/`, data, headers()),
  };
}

export function getSprintMeasuresHistorical(id: number, category: string, version: any): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(
      `${API_BASE_URL}sprint-measures-historical/?mission_sprint=${id}&measure_category=${category}${
        version ? `&version=${version}` : ''
      }`,
      {
        signal: controller.signal,
        ...headers(),
      } as AbortableRequestConfig),
    controller,
  };
}

export function getSprintMeasures(id: number, category: string, version: any): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(
      `${API_BASE_URL}sprint-measures/?mission_sprint=${id}&measure_category=${category}${
        version ? `&version=${version}` : ''
      }`,
      {
        signal: controller.signal,
        ...headers(),
      } as AbortableRequestConfig),
    controller,
  };
}

export function getSprintTracking(id: number): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}sprint-q-tracking/?mission_sprint=${id}`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function patchSprintPriorActionItems(data: PostSprintPriorActionItem): {
  call: Promise<AxiosResponse<SprintActionItem>>;
} {
  return {
    call: axios.post(
      `${API_BASE_URL}prior-action-items/${data.id}/`,
      data,
      headers()
    ),
  };
}

/**
 * @todo This is functionally a duplicate of patchSprintPriorActionItems.
 */
export function postSprintPriorActionItems(data: PostSprintPriorActionItem[]): {
  call: Promise<AxiosResponse<SprintActionItem>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}prior-action-items/`, data, headers()),
  };
}

export function postSprintQuantitativeTracking(data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}sprint-q-tracking/`, data, headers()),
  };
}

export function deleteSprint(id: number): {
  call: Promise<AxiosResponse<void>>;
} {
  return {
    call: axios.delete(`${API_BASE_URL}mission-sprints/${id}/`, headers()),
  };
}

export function getPriorActionItems(id: number): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}prior-action-items/?mission_sprint=${id}`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function postPriorActionItems(data: PostSprintPriorActionItem[]): {
  call: Promise<AxiosResponse<SprintActionItem>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}prior-action-items/`, data, headers()),
  };
}

export function deletePriorActionItems(id: number): {
  call: Promise<AxiosResponse<void>>;
} {
  return {
    call: axios.delete(`${API_BASE_URL}prior-action-items/${id}/`, headers()),
  };
}

export function patchPriorActionItems(data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.patch(
      `${API_BASE_URL}prior-action-items/${data.id}/`,
      data,
      headers()
    ),
  };
}

export function getMvSolution(id: number): {
  call: Promise<AxiosResponse<SprintMVSolution>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}mv-solutions/?mission_sprint=${id}`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function getMvSolutionDetails(id: number): {
  call: Promise<AxiosResponse<SprintMVSolution>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}mv-solutions/${id}/`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function postMvSolution(data: any): {
  call: Promise<AxiosResponse<SprintMVSolution>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}mv-solutions/`, data, headers()),
  };
}

export function getSprintHuddles(id: number): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(
      `${API_BASE_URL}mission-sprints-huddles/?mission_sprint=${id}`,
      {
        signal: controller.signal,
        ...headers(),
      } as AbortableRequestConfig),
    controller,
  };
}

export function getSprintHuddlesDetail(id: number): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}mission-sprints-huddles/${id}/`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function postSprintHuddle(data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.post(
      `${API_BASE_URL}mission-sprints-huddles/`,
      data,
      headers()
    ),
  };
}

export function patchSprintHuddle(data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.patch(
      `${API_BASE_URL}mission-sprints-huddles/${data?.id}/`,
      data,
      headers()
    ),
  };
}

export function postSprintHuddleBarriers(data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}huddle-barriers/`, data, headers()),
  };
}

export function patchSprintHuddleBarriers(data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.patch(
      `${API_BASE_URL}huddle-barriers/${data?.id}/`,
      data,
      headers()
    ),
  };
}

export function deleteSprintHuddleBarriers(id: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.delete(`${API_BASE_URL}huddle-barriers/${id}/`, headers()),
  };
}

export function patchSprintHuddleItems(data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.patch(
      `${API_BASE_URL}huddle-items/${data?.id}/`,
      data,
      headers()
    ),
  };
}

export function postSprintHuddleItems(data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}huddle-items/`, data, headers()),
  };
}

export function deleteSprintHuddleItems(id: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.delete(`${API_BASE_URL}huddle-items/${id}/`, headers()),
  };
}

export function getScorecardTemplate(): {
  call: Promise<AxiosResponse<any>>;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}scorecards-templates/`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
  };
}

export function createScoreCardLink(sprintId: string, fileLink: string): {
    call: Promise<AxiosResponse<any>>;
} {
    const url = 'huddle-scorecards/'
    let data = new FormData();
    data.append("link", fileLink);
    data.append('mission_sprint', sprintId)
    return {
        call: axios.post(`${API_BASE_URL}${url}`, data, headers())
    }
}

export function deleteScoreCardLink(huddleScoreCardId: string): {
    call: Promise<AxiosResponse<any>>;
} {
    const url = 'huddle-scorecards/'
    return {
        call: axios.delete(`${API_BASE_URL}${url}${huddleScoreCardId}/`, headers())
    }
}
