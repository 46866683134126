import React, { useEffect, useState } from 'react'
import { Alert, Button } from 'antd'
import { useSelector } from 'react-redux'
import { RightCircleOutlined, LeftCircleOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom';

import { getSmartSeachSprint } from '../../../../../../Services/SmartSolution.service'
import { getNotification } from '../../../../../../Components/GetNotification'
import { postSprints } from '../../../../../../Services/Sprints.service'
import { postSprintEngagged } from '../../../../../../Services/SprintTeams.service';
import SecondaryDriver from './SecondaryDriver'

import { useTranslation } from "react-i18next"

/**
 * Renders the SSPanel component.
 *
 * @param {object} item - The item object.
 * @param {number} index - The index of the item.
 * @return {JSX.Element} The rendered component.
 */
export const SSPanel = ({
  item,
  index,
}) => {
  const [isSideOpen, setIsSideOpen] = useState(false)
  const [openIndex, setOpenIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const [smartSprint, setSmartSprint] = useState({})
  const [mission_sprint, setMissionSprint] = useState(0)
  const [panelId] = useState(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15))

  const { id } = useParams();

  const [t] = useTranslation()

  const selectedMission = useSelector((state) => {
    return state.mission?.value?.id || 0
  })

  /**
   * Get the smart sprint based on the given driver index.
   *
   * @param {number} driver_index - The index of the driver.
   * @return {Promise<void>} A promise that resolves with no value.
   */
  const getSmartSprint = async (driver_index) => {
    await setIsSideOpen(driver_index === openIndex ? !isSideOpen : true)
    setSmartSprint({})
    setOpenIndex(0)
    try {
      const data = {
        "secondary_driver": item.secondary_drivers[driver_index]?.id,
        "organization": parseInt(id),
      }
      setLoading(true)
      const res = await getSmartSeachSprint(data).call
      setSmartSprint(res?.data)
      setOpenIndex(driver_index)
      setMissionSprint(res?.data?.mission_sprint || 0)
      setLoading(false)
    } catch (error) {
      getNotification("warning", {
        header: `${t('commons.intervention')} results`,
        body: `Failed to generate ${t('commons.intervention').toLowerCase()}, please try again`,
      })
      setLoading(false)
      setIsSideOpen(false)
    }
  }

  const validateSprint = async () => {
    if (mission_sprint == 0) {
      try {
        const data = {
          mission: selectedMission,
          smart_sprint: smartSprint.id,
          name: smartSprint.sprint_name,
          why_sprinting: "TO_CREATE_DEMAND",
        }
        const res = await postSprints(data).call
        const sprint_id = res?.data?.id || 0
        if (sprint_id > 0) {
          await smartSprint?.engaged_list?.map(async (item) => {
            const dataSprint = {
              sprint: sprint_id,
              persona: item?.id,
            }
            await postSprintEngagged(dataSprint).call
          })
          setTimeout(() => {
            localStorage.setItem('mission_id', selectedMission)
            localStorage.setItem('sprint_id', sprint_id)
            window.location.reload()
          }, 1000)
        }
      } catch (error) { }
    } else {
      localStorage.setItem('mission_id', selectedMission)
      localStorage.setItem('sprint_id', mission_sprint)
      window.location.reload()
    }
  }

  useEffect(() => {
    const side = document.querySelector(`.smartsolutions-panel-side-${panelId}`)
    side.addEventListener("animationend", () => {
      if (side.classList.contains("animate__flipOutY"))
        side.classList.add("hidden")
    })
    side.addEventListener("animationstart", () => {
      if (side.classList.contains("animate__flipInY"))
        side.classList.remove("hidden")
    })
    if (!isSideOpen)
      setTimeout(() => {
        side.classList.add("hidden")
      }, 1500)
    else
      side.classList.remove("hidden")
  }, [isSideOpen])

  useEffect(() => {
    setIsSideOpen(false)
    setLoading(false)
  }, [item?.id])

  return (
    <div className="flex justify-between smartsolutions-panel">
      <div className="flex-cover">
        <SecondaryDriver
          item={item}
          index={index}
          getSmartSprint={getSmartSprint}
          isSideOpen={isSideOpen}
          openIndex={openIndex}
          loading={loading}
        />
        {item.impact && <>
          <b>Impact:</b>
          <p>{item.impact}</p>
        </>}
        {item.key_drivers &&
          <>
            <b>Key Drivers:</b>
            <p>
              {
                item.key_drivers?.split(",").map((key) =>
                  <div>• {key}</div>
                )
              }
            </p>
          </>}
        {item.performance_metrics &&
          <><b>Performance Metrics:</b>
            <p>
              {
                item.performance_metrics?.split(",").map((key) =>
                  <div>• {key}</div>
                )
              }
            </p>
          </>}
        {!item.secondary_drivers && <Button type="primary" className="float-right mt-10 hidden"
          onClick={() => getSmartSprint()}
          disabled={loading}
        >
          {isSideOpen ? "Hide" : "Show me how"}
          {isSideOpen ? <LeftCircleOutlined /> : <RightCircleOutlined />}
        </Button>}
      </div>
      <div className={`flex-cover smartsolutions-panel-side-${panelId} animate__animated ${isSideOpen ? "animate__flipInY" : "animate__flipOutY"}`}>
        {
          loading &&
          <div className="text-center mt-10">
            <div className="pageLoader" >
              <div className="pageLoader__inner">
                <span className="loading" />
              </div>
            </div>
            Thank you for waiting! Our AI is hard at work generating your {t('commons.intervention').toLowerCase()}. <br /> Please be patient as it may take a few moments to complete. We appreciate your patience
          </div>
        }
        {
          !loading &&
          <>
            {!smartSprint?.id ?
              <Alert message={`Failed to generate ${t('commons.intervention').toLowerCase()}, please try again`} type="error" showIcon />
              :
              <div className="smartsolutions-sprint">
                <Button
                  type="link"
                  className="w-100 embed"
                  onClick={() => setIsSideOpen(false)}
                >
                  <CloseOutlined />
                </Button>
                <div>
                  <b>{t('commons.intervention')} Name:</b>
                  <p>{smartSprint?.sprint_name}</p>

                  <p><b>{t('commons.intervention')} Length:</b> {smartSprint?.sprint_length}</p>

                  <b>Who needs to be engaged?</b>
                  {smartSprint?.engaged_list?.length === 0 ?
                    <Alert message="We didn't find any roles to be engaged with this intervention" className="mt-5 mb-5" type="warning" showIcon />
                    : <>
                      <ul className="mt-5 mb-5">
                        {smartSprint?.engaged_list?.map((item, index) => {
                          return (
                            <li><CheckOutlined /> {item.role}</li>
                          )
                        })}
                      </ul>
                    </>}

                  <b>KPIs/Lead Measures to be tracked (not lag measures):</b>
                  <p>{smartSprint?.lead_measures}</p>

                  <b>Success Criteria</b>
                  <p>{smartSprint?.success_criteria}</p>

                  <b>Failure Criteria</b>
                  <p>{smartSprint?.failure_criteria}</p>

                  <p><b>{t('commons.intervention').toUpperCase()} BREAKDOWN</b></p>

                  {smartSprint?.sprint_breakdown.map((item, index) =>
                    <>
                      <b>Week {item.week}</b>
                      <p>{item.description}</p>
                    </>
                  )}
                  {item.secondary_drivers && <Button type="primary" className="float-right mt-10"
                    onClick={() => validateSprint()}
                    disabled={loading}
                  >
                    {mission_sprint == 0 ? `Create ${t('commons.intervention')}` : `Go to ${t('commons.intervention')}`}
                  </Button>}
                </div>
              </div>
            }
          </>
        }
      </div>
    </div >
  )
}