import React, {Suspense} from 'react';
import { matchPath } from 'react-router';
import {Provider} from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import DashboardLayout from '../../Components/DashboardLayout/DashboardLayout';
import ErrorBoundary from '../../Components/ErrorBoundary';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import PageLoader from '../../Components/PageLoader';
import PrivateRoute from '../../Components/PrivateRoute';
import store from '../../ReduxV2/store';
import {ROUTES} from '../../Routes.constants';
import {Intel} from '../Intel';
import {Meet} from '../Meet';
import {MeetingsComments} from '../MeetingsComments';
import {PatData} from '../PatData';
import {MeetingsPreparing} from '../MeetingsPreparing';
import {MeetingsReflections} from '../MeetingsReflections';
import Networks from '../Networks';
import OrganisationInsights from '../OrganisationInsights';
import OrganisationInsightsSideMenu from '../../Routes/OrganisationInsights/OrganisationInsightsSideMenu';
import {Teams} from '../Teams';
import ToolBox from '../ToolBox/Dashboard/Dashboard';
import UserProfile from '../UserProfile';
import Groups from '../Groups';
import {NotFound} from '../NotFound';
import ViewNetworkGroupDetail from '../ViewNetworkGroupDetail';
import style from './style.module.scss';
import Feedback from '../../Components/FeedbackButton/Feedback';
import {Dashboard} from 'Routes/Dashboard/Dashboard';
import {DashboardLayoutNew} from 'Components/DashboardLayout/DashboardLayoutNew';
import TagPage from '../TagPage';
import TagDetailPage from '../TagDetailPage';
import SmartSolutionChatbot from 'Components/SmartSolutionChatbot';
import MissionLayout from 'Routes/OrganisationInsights/Children/ViewDetails/components/Mission/MissionLayout';

class MainFrame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotFound: false,
    };
    this.flatRoutePaths = Object.values(ROUTES).flatMap(route => 
      typeof route === 'object' ? Object.values(route) : route
    );
    this.routePaths = [
      {
        path: ROUTES.DASHBOARD,
        children: (
          <DashboardLayout>
            <Dashboard />
          </DashboardLayout>
        ),
      },
      {
        path: ROUTES.NETWORKS,
        children: (
          <Provider store={store}>
            <DashboardLayout>
              <Networks />
            </DashboardLayout>
          </Provider>
        ),
      },
      {
        path: ROUTES.MEETINGS,
        children: (
          <DashboardLayout>
            <Meet />
          </DashboardLayout>
        ),
      },
      {
        path: ROUTES.GROUPS,
        children: (
          <DashboardLayout>
            <Groups />
          </DashboardLayout>
        ),
      },
      {
        path: ROUTES.COMMENTS,
        children: (
          <DashboardLayout>
            <MeetingsComments />
          </DashboardLayout>
        ),
      },
      {
        path: ROUTES.PREPARING,
        children: (
          <Provider store={store}>
            <DashboardLayout>
              <MeetingsPreparing />
            </DashboardLayout>
          </Provider>
        ),
      },
      {
        path: ROUTES.REFLECTIONS,
        children: (
          <Provider store={store}>
            <DashboardLayout>
              <MeetingsReflections />
            </DashboardLayout>
          </Provider>
        ),
      },
      {
        path: ROUTES.TEAMS,
        children: (
          <Provider store={store}>
            <DashboardLayout>
              <Teams />
            </DashboardLayout>
          </Provider>
        ),
      },
      {
        path: ROUTES.INTEL.DEFAULT,
        children: (
          <Provider store={store}>
            <DashboardLayout>
              <Intel />
            </DashboardLayout>
          </Provider>
        ),
      },
      {
        path: ROUTES.INTEL.MA_ACTION_PLANS,
        children: (
          <Provider store={store}>
            <DashboardLayout>
              <Intel />
            </DashboardLayout>
          </Provider>
        ),
      },
      {
        path: ROUTES.INTEL.INTERVENTION,
        children: (
          <Provider store={store}>
            <DashboardLayout>
              <OrganisationInsightsSideMenu>
                <MissionLayout>
                </MissionLayout>
              </OrganisationInsightsSideMenu>
            </DashboardLayout>
          </Provider>
        ),
      },
 
      /*       {
        path: ROUTES.PAT,
        children: (
          <Provider store={store}>
            <DashboardLayout>
              <PatData />
            </DashboardLayout>
          </Provider>
        ),
      }, */
      {
        path: ROUTES.PROFILE,
        children: (
          <Header isStandAlone={false}>
            <UserProfile />
          </Header>
        ),
      },
      {
        path: ROUTES.TOOLBOX,
        children: (
          <Header isStandAlone={false}>
            <ToolBox />
          </Header>
        ),
      },
      // {
      //   path: ROUTES.VIEW_ORGANISATION_DETAILS,
      //   children: <ViewDetails />,
      // },
      // {
      //   path: ROUTES.VIEW_ORGANISATION_DETAILS,
      //   children: <ViewDetails />,
      // },
      {
        path: ROUTES.ORGANISATION_INSIGHTS,
        children: (
          <Header isStandAlone={false}>
            <OrganisationInsights />
          </Header>
        ),
      },
      {
        path: ROUTES.VIEW_NETWORK_GROUP_DETAILS,
        children: (
          <Header isStandAlone={false}>
            <ViewNetworkGroupDetail />
          </Header>
        ),
      },
      {
        path: ROUTES.TAGS,
        children: (
          <Provider store={store}>
            <DashboardLayout>
              <TagPage />
            </DashboardLayout>
          </Provider>
        ),
      },
      {
        path: ROUTES.TAG_DETAIL,
        children: (
          <Provider store={store}>
            <DashboardLayout>
              <TagDetailPage />
            </DashboardLayout>
          </Provider>
        ),
      },
    ];
    this.renderRoutes = this.renderRoutes.bind(this);
    this.handleNotFound= this.handleNotFound.bind(this);
  }

  handleNotFound(isNotFound) {
    this.setState({ isNotFound });
  }


  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.checkIfNotFound();
    }
  }

  checkIfNotFound() {
    const { location } = this.props;
    const isNotFound = !this.flatRoutePaths.some(routePath =>
      matchPath(location.pathname, {
        path: routePath,
        exact: true,
        strict: false,
      })
    );
    this.setState({ isNotFound });
  }

  componentDidMount() {
    this.checkIfNotFound();
  }

  renderRoutes() {
    const routes = [];
    let i = 0;
    this.routePaths.map((route, idx) =>
      routes.push(
        <PrivateRoute
          component={route.children}
          key={idx}
          path={route.path}></PrivateRoute>
      )
    );
    return routes;
  }
  render() {
    let standAlone = false;
    if (window.location.search.includes('standalone=true')) {
      standAlone = true;
    }
    return (
      <section className={style.mainWrapper}>
        <Feedback />
        <main className={style.bodyContent} id='mainframe-body'>
          <ErrorBoundary>
            <Suspense fallback={<LoadingMessage />}>
              <Switch>
                {this.renderRoutes()}
                <Route path='*' component={NotFound} />
              </Switch>
            </Suspense>
          </ErrorBoundary>
        </main>
        <Footer />
        {!this.state.isNotFound && <SmartSolutionChatbot />}
      </section>
    );
  }
}
const LoadingMessage = () => (
  <>
    <PageLoader />
  </>
);
export default MainFrame;
