import React, {useRef, useEffect, useState} from 'react';
import ReactPlayer from 'react-player/youtube';
import '../App.css';
import { Modal } from 'antd';


const Header = ({title, isMinimized, toggleMinimized}) => {
  const iconClass = isMinimized ? 'PlusImg' : 'NegativeImg';
  return (
    <div className='col-12 BlackForeground Bold FontSize20'>
      <span>{title}</span>
      <span
        onClick={toggleMinimized}>
          <div className={iconClass + ' FloatRight Pointer'}></div>
      </span>
    </div>    
  )  
};

const Link = ({title, onClick}) => {
  return (
    <div className="col-6 mt-2">
      <a onClick={onClick}>
        <div className="GreyDashboardDiv dashboarddivgradient3">
          {title}
        </div>
      </a>
    </div>
  )
};

const VideoModal = ({
  isOpen, 
  closeModal, 
  url, 
  title, 
  playerReady,
  playerRef,
  setPlayerReadyTrue
}) => {
  return (
    <Modal
      title={title}
      open={isOpen}
      onCancel={closeModal}
      width={'70%'}
      footer={false}
    className='mission-modal'>
      <div style={{
        display: 'flex', 
        width: '100%', 
        aspectRatio: '16 / 9',
        opacity: playerReady ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out',
      }}>
        <ReactPlayer
          ref={playerRef}
          controls={true} 
          url={url} 
          width="100%"
          height="100%"
          onReady={setPlayerReadyTrue}/>
        </div>
    </Modal>
  )
};

export const DashboardHomeVideos = ({
  title, 
  videos
}) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [playerReady, setPlayerReady] = useState(false);
  const player = useRef(null);

  useEffect(() => {
    if (player.current) {
      if(isModalOpen && playerReady) {
        player.current.getInternalPlayer().playVideo();
      }
    }
  }, [isModalOpen, playerReady]);

  useEffect(() => {
    setPlayerReady(false);
  }, [currentVideo]);

  return (
    <>
      <div className='DashboardHomeDiv mt-4'>
        <div className='row m-0 p-0'>
          <Header 
            title={title} 
            isMinimized={isMinimized} 
            toggleMinimized={() => {setIsMinimized(!isMinimized);}} />
          {!isMinimized && (
            <>
              <div className='col-12'>
                <hr className='hr1' />
              </div>
              {videos.map((video, index) => (
                <Link 
                  key={index} 
                  onClick={() => {
                    // If the player is going to be reset, disable readiness to prevent audio glitches
                    if(currentVideo?.url !== video.url) {
                      setPlayerReady(false);
                    }
                    setCurrentVideo(video);
                    setIsModalOpen(true);
                  }}
                  {...video}/>
              ))}
            </>
          )}
        </div>
      </div>
      <VideoModal
        closeModal={() => {
          setIsModalOpen(false);
          player.current.getInternalPlayer().pauseVideo();
        }}
        isOpen={isModalOpen}
        title={currentVideo?.title}
        url={currentVideo?.url}
        playerRef={player}
        playerReady={playerReady}
        setPlayerReadyTrue={() => {setPlayerReady(true)}}
      />
    </>
  );
};

