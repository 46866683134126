import {
  LIST_MISSIONS_FAILED,
  LIST_MISSIONS_STARTED,
  LIST_MISSIONS_SUCCESS,
} from '../../Redux.constants';
import {} from './Helper';
import {
  getMissionListFailed,
  getMissionListStarted,
  getMissionListSuccess,
} from './Helper/index.js';

var INITIAL_STATE = {
  getMissions: {
    isProcessing: null,
    success: null,
    error: null,
  },
  missionList: [],
  limit: 8,
  listMissionCount: '',
};
export default function MissionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LIST_MISSIONS_STARTED:
      return getMissionListStarted(state);
    case LIST_MISSIONS_SUCCESS:
      return getMissionListSuccess(state, action.payload);
    case LIST_MISSIONS_FAILED:
      return getMissionListFailed(state, action.payload);
    default:
      return {...state};
  }
}
