
export function fetchMsopStarted(state) {
  return {
    ...state,
    fetchMsopStateApi: {
      loading: true,
      topSectionData: [],
      bottomSectionData: [],
      error: false,
    },
  };
}
export function fetchMsopSuccess(state, payload) {
  return {
    ...state,
    fetchMsopStateApi: {
      loading: false,
      topSectionData: setTopArea(payload.fetchReport),
      bottomSectionData: setBottomArea(payload.fetchReport.state),
      error: false,
    },
  };
}
export function fetchMsopFailed(state) {
  return {
    ...state,
    fetchMsopStateApi: {
      loading: false,
      topSectionData: [],
      bottomSectionData: [],
      error: true,
    },
  };
}

function setTopArea(data) {
  let topAreaObject = constants.FORM_TOP_AREA_CONFIG;
  let objectKeys = Object.keys(data.state);
  topAreaObject.map((item) => {
    let key = item.name;
    if (objectKeys.includes(key)) {
      if (key === constants.RIVISION || key === constants.DOCUMENT) {
        item.value = data.state[key] + 1;
      } else if (key === constants.TITILE) {
        item.value = data._mission;
      } else {
        item.value = data.state[key];
      }
    }
  });
  return topAreaObject;
}

function setBottomArea(data) {
  let bottomAreaObject = constants.FORM_BOTTOM_AREA_CONFIG;
  let objectKeys = Object.keys(data);
  bottomAreaObject.map((item) => {
    let key = item.name;
    if (objectKeys.includes(key)) {
      item.value = data[key];
    }
  });
  return bottomAreaObject;
}
