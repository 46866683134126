export * from './teamBuilderHelper';

export function getMeasurementsStart(state) {
  return {
    ...state,
    getMeasurementsApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function getMeasurementsSuccess(state, payload) {
  return {
    ...state,
    getMeasurementsApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
    measurementsList: payload.data ? payload.data.results : [],
  };
}
export function getMeasurementsFailed(state, payload) {
  return {
    ...state,
    getMeasurementsApi: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching list',
    },
  };
}

export function addMeasurementsStart(state) {
  return {
    ...state,
    addMeasurementsApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function addMeasurementsSuccess(state, payload) {
  return {
    ...state,
    addMeasurementsApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
  };
}
export function addMeasurementsFailed(state, payload) {
  return {
    ...state,
    addMeasurementsApi: {
      isProcessing: false,
      success: null,
      error: 'Error in adding Cadence',
    },
  };
}

export function getCadenceStart(state) {
  return {
    ...state,
    getCadenceApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function getCadenceSuccess(state, payload) {
  return {
    ...state,
    getCadenceApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
    cadenceList: payload.data ? payload.data.results : [],
  };
}
export function getCadenceFailed(state, payload) {
  return {
    ...state,
    getCadenceApi: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching list',
    },
  };
}

export function addCadenceStart(state) {
  return {
    ...state,
    addCadenceApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function addCadenceSuccess(state, payload) {
  return {
    ...state,
    addCadenceApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
  };
}
export function addCadenceFailed(state, payload) {
  return {
    ...state,
    addCadenceApi: {
      isProcessing: false,
      success: null,
      error: 'Error in adding Cadence',
    },
  };
}
