import {combineReducers} from 'redux';
import LoginPageReducer from './WelcomePageReducer/LoginPageReducer';
import UserReducer from './userReducer';
import OrganisationReducer from './OrganisationReducer';
import MissionReducer from './MissionReducer';
import CreateMissionReducer from './CreateMissionReducer';
import EmployeeReducer from './EmployeeReducer';
import ViewMissionReducer from './ViewMissionReducer';
import UploadImageReducer from './UploadImageReducer';
import MsopReportReducer from './MsopReportReduder';
import FetchMsopReducer from './FetchMsopReducer';
import ViewSprintReducer from './ViewSprintReducer';
import ListMsopReportReducer from './ListMsopReducer';
import SprintDetailsReducer from './SprintDetailsReducer';
import DeleteOrganisationReducer from './DeleteOraganisationReducer';
import UpdateOrganisationReducer from './UpdateOraganisationReducer';
import UpdateMissionReducer from './UpdateMissionReducer';
import UserProfileReducer from './UserProfileReducer';
import DeleteMissionReducer from './DeleteMissionReducer';
import DeleteDepartmentReducer from './DeleteDepartmentReducer';
import DeleteDesignationReducer from './DeleteDesignationReducer';
import UpdateDepartmentReducer from './UpdateDepartmentReducer';
import UpdateDesignationReducer from './UpdateDesignationReducer';
import AccountInsightsReducer from './AccountInsightsReducer';
import connectedNetworkReducer from './ConnectedNetworksReducer';
import AddNotesReducer from './AddNotesReducer';
import DeletePersonaReducer from './DeletePersonaReducer';
import UpdatePersonaReducer from './UpdatePersonaReducer';
import TeamBuildReducer from './TeamBuildReducer';
import ToolBoxReducer from './ToolBoxReducer';
import OrganizationGroupReducer from './OrganizationGroupReducer';
import updateGroupReducer from './UpdateGroupReducer';
import DeleteGroupReducer from './DeleteGroupReducer';
import GroupsOrganizationsReducer from './OrganizationsInGroupReducer';
import DeleteSummaryReducer from './DeleteSummaryReducer';
import OrganizationSprintDetailsReducer from './OrganizationSprintDetailsReducer';
import MetadataReducer from './metadataReducer';

export default combineReducers({
  MetadataReducer,
  DeleteSummaryReducer,
  LoginPageReducer,
  UserReducer,
  OrganizationGroupReducer,
  GroupsOrganizationsReducer,
  OrganisationReducer,
  MissionReducer,
  CreateMissionReducer,
  EmployeeReducer,
  ViewMissionReducer,
  UploadImageReducer,
  MsopReportReducer,
  FetchMsopReducer,
  ViewSprintReducer,
  ListMsopReportReducer,
  SprintDetailsReducer,
  DeleteOrganisationReducer,
  DeletePersonaReducer,
  UpdateOrganisationReducer,
  UpdateMissionReducer,
  UserProfileReducer,
  DeleteMissionReducer,
  DeleteDepartmentReducer,
  DeleteDesignationReducer,
  UpdateDesignationReducer,
  UpdateDepartmentReducer,
  AccountInsightsReducer,
  AddNotesReducer,
  connectedNetworkReducer,
  UpdatePersonaReducer,
  ToolBoxReducer,
  TeamBuildReducer,
  updateGroupReducer,
  DeleteGroupReducer,
  OrganizationSprintDetailsReducer,
});
