import axios, { AxiosResponse } from 'axios';
import {API_BASE_URL} from '../../config/constants';
import { PaginatedPftJourney } from 'types/PftJourney';
import { AbortableRequestConfig } from 'types/Request';
import { PhaseGoals } from 'types/PhaseGoals';

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    },
  };
};

export function getPftJourneys(user_pat_id: number): {
  call: Promise<AxiosResponse<PaginatedPftJourney>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}transformation-journeys/?user_pat=${user_pat_id}`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function mergeAllCompetencies(data:PhaseGoals) {
const primaryMilestones = data.filtered_competencies.primary_milestones
const secondaryMilestones = data.filtered_competencies.secondary_milestones

  return {
    target_phase:data.target_phase,
    filtered_competencies: [
      ...primaryMilestones,
      ...secondaryMilestones,
    ]
  }
}

export function getMilestonesCompetencyIds(data:PhaseGoals) {
  const primaryMilestones = data.filtered_competencies.primary_milestones
  const secondaryMilestones = data.filtered_competencies.secondary_milestones

  return {
      primaryMilestonesCompetencyIds: primaryMilestones.map(m => m?.competency?.id),
      secondaryMilestonesCompetencyIds: secondaryMilestones.map(m => m?.competency?.id),
  }
}

export function getUserPatPhaseGoals(options: {
  pat_id: number,
  competency_full_info: boolean, 
  custom_phase?: number
}): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const { pat_id, competency_full_info, custom_phase } = options;


  let queryString = `?competency_full_info=${competency_full_info}`;
  if (custom_phase) {
    queryString += `&custom_phase=${custom_phase}`;
  }

  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}user-pats/${pat_id}/phase-goals/${queryString}`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function postPftCompetency(pft_id: number, competency_id: number): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.post(`${API_BASE_URL}tj-competencies/`, {
      trans_journey: pft_id,
      pat_competency: competency_id,
    }, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}
export function getPftCompetencies(pft_id: number): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;  
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}tj-competencies/?trans_journey=${pft_id}`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function postPftJourneyImprovementTactic(tj_competency: number, improvement_tactic: number): {
call: Promise<AxiosResponse<any>>;
  controller: AbortController;  
} {
  const controller = new AbortController();
  return {
    call: axios.post(`${API_BASE_URL}tjc-improvement-tactics/`, {
      tj_competency: tj_competency,
      improvement_tactic: improvement_tactic,
    }, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function createSprintForTJCTactic(tjc_improvement_tactic: number): {
call: Promise<AxiosResponse<any>>;
  controller: AbortController;  
} {
  const controller = new AbortController();
  return {
    call: axios.post(`${API_BASE_URL}tjc-improvement-tactics/${tjc_improvement_tactic}/create-sprint/`, {
    }, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function deleteTJCompetencyImprovementTactic(tjc_improvement_tactic: number): {
call: Promise<AxiosResponse<void>>;
  controller: AbortController;  
} {
  const controller = new AbortController();
  return {
    call: axios.delete(`${API_BASE_URL}tjc-improvement-tactics/${tjc_improvement_tactic}/`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function getTJCMissionMeasures(tj_competency: number): {
call: Promise<AxiosResponse<any>>;
  controller: AbortController;  
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}tjc-mission-measures/?tj_competency=${tj_competency}`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

type UpdateMissionMeasure = {
  mission_measure: number;
  tj_competency: number;
};

export function updateTJCMissionMeasures(tj_competency: number, mission_measures: number[]): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;  
} {
  const controller = new AbortController();
  const payload:UpdateMissionMeasure[] = mission_measures.map((mission_measure) => {
    return {
      tj_competency: tj_competency,
      mission_measure: mission_measure,
    };
  });
  return {
    call: axios.post(`${API_BASE_URL}tjc-mission-measures/`, payload, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function deleteTJCMissionMeasure(tjc_mission_measure: number): {
  call: Promise<AxiosResponse<void>>;
  controller: AbortController;  
} {
  const controller = new AbortController();
  return {
    call: axios.delete(`${API_BASE_URL}tjc-mission-measures/${tjc_mission_measure}/`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

type TjCompetencyPayloadType = {
  tj_competency: number;
  phase: number;
  completed: boolean;
}

export function completeTJCompetency(payload:TjCompetencyPayloadType): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;  
} {
  const { tj_competency, phase, completed } = payload;
  const controller = new AbortController();
  return {
    call: axios.patch(`${API_BASE_URL}tj-competencies/${tj_competency}/`, {
      completed,
      phase,
    }, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function deleteTJCompetencyById(tj_competency: number): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;  
} {
  const controller = new AbortController();
  return {
    call: axios.delete(`${API_BASE_URL}tj-competencies/${tj_competency}/`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function updatePatQuestionsAndNotes(pat_id: number, data: any): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;  
} {
  const controller = new AbortController();
  return {
    call: axios.patch(`${API_BASE_URL}user-pats/${pat_id}/`, data, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function patchPatCompetencyNotes(pat_competency_notes_id: number, notes: string): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;  
} {
  const controller = new AbortController();
  return {
    call: axios.patch(`${API_BASE_URL}pat-competency-notes/${pat_competency_notes_id}/`, {
      notes: notes
    }, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };  
}

type postPatCompetencyNotesProps = {
  user_pat: number;
  pat_competency: number;
  notes: string;
};

export function postPatCompetencyNotes(data: postPatCompetencyNotesProps): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;    
} {
  const controller = new AbortController();
  return {
    call: axios.post(`${API_BASE_URL}pat-competency-notes/`, data, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}