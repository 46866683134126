import React, {useEffect, useState} from 'react';
import {withRouter, useHistory, useLocation} from 'react-router';
import {Input, Pagination, Select} from 'antd';
import {LoadingOutlined, SearchOutlined} from '@ant-design/icons';
import {
  deleteNetworkById,
  fetchNetworks,
  fetchNetworkGroups,
  archiveNetworkById,
  cleanNetworkListViewSelectedNetwork,
  cleanNetworkListViewStatusMessage,
  selectNetworkListViewPage,
  selectNetworkListViewPerPage,
  setNetworkListViewIsSearching,
  setNetworkListViewSearch,
  setNetworkListViewSelectedNetwork,
  setNetworkListViewQueryString,
  setNetworkListViewFromParamString,
  selectNetworkListViewGroup,
} from 'ReduxV2/state';
import {useDispatch, useSelector} from 'react-redux';
import Container from 'Components/Container';
import {EmptyState} from 'Components/EmptyState';
import {InitialCard} from 'Components/InitialCard';
import {AddNetworkCard, NetworkCard} from 'Components/molecules/card';
import {BaseModal, AddOrEditNetworkModal} from 'Components/molecules/modal';
import {getNotification} from 'Components/GetNotification';

const MESSAGES = {
  CONFIRM_ARCHIVE: (name: string | undefined) =>
    `Are you sure you want to archive "${name ?? 'this network'}"?`,
  CONFIRM_DELETE: (name: string | undefined) =>
    `Are you sure you want to delete "${name ?? 'this network'}"?`,
  GROUP_PLACEHOLDER: 'Search a group',
  MODAL_WARNING_TITLE: 'Heads Up!',
  SEARCH_PLACEHOLDER: 'Search here…',
};

const Networks: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // state from redux
  const showAddNetwork = useSelector(
    (state: any) => state.networkListView.showAddNetworkLink
  );
  const listItems = useSelector((state: any) => state.networkListView.items);
  const totalItems = useSelector((state: any) => state.networkListView.count);
  const isSearching = useSelector(
    (state: any) => state.networkListView.isSearching
  );
  const itemsPage = useSelector((state: any) => state.networkListView.page);
  const itemsPerPage = useSelector(
    (state: any) => state.networkListView.perPage
  );
  const itemsSearch = useSelector((state: any) => state.networkListView.search);
  const selectedItem = useSelector(
    (state: any) => state.networkListView.selectedNetwork
  );
  const queryString = useSelector(
    (state: any) => state.networkListView.queryString
  );
  const groups = useSelector((state: any) => state.networkListView.groups);
  const selectedGroup = useSelector(
    (state: any) => state.networkListView.selectedGroup
  );
  const status = useSelector((state: any) => state.networkListView.status);
  const statusMessage = useSelector(
    (state: any) => state.networkListView.statusMessage
  );

  // local state
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | null>(null);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchNetworkGroups());
    const {search} = location;
    if (search) {
      dispatch(setNetworkListViewFromParamString(search));
    }
  }, []);

  useEffect(() => {
    dispatch(fetchNetworks());
  }, [itemsPage, itemsPerPage, selectedGroup]);

  useEffect(() => {
    dispatch(setNetworkListViewQueryString());
  }, [itemsPage, itemsPerPage, itemsSearch, selectedGroup]);

  useEffect(() => {
    history.replace({pathname: location.pathname, search: queryString});
  }, [queryString]);

  useEffect(() => {
    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    const newTimer = setTimeout(() => {
      dispatch(setNetworkListViewIsSearching(true));
      dispatch(fetchNetworks());
    }, 500);
    setSearchTimer(newTimer);
  }, [itemsSearch]);

  useEffect(() => {
    if (statusMessage && status) {
      getNotification(status, {
        header: status.charAt(0).toUpperCase() + status.slice(1),
        body: statusMessage,
      });
      dispatch(cleanNetworkListViewStatusMessage());
    }
  }, [statusMessage]);

  const headerControls = (
    <header className='flex flex-wrap justify-between gap-2'>
      <Input
        suffix={isSearching ? <LoadingOutlined /> : <SearchOutlined />}
        className='w-[300px]'
        size='small'
        placeholder={MESSAGES.SEARCH_PLACEHOLDER}
        value={itemsSearch}
        onChange={(e) => {
          dispatch(setNetworkListViewSearch(e.target.value));
        }}
      />
      <div className='flex gap-2 items-center'>
        <p>Filter By:</p>
        <Select
          value={selectedGroup}
          placeholder={MESSAGES.GROUP_PLACEHOLDER}
          className='min-w-[300px]'
          options={groups}
          onChange={(val) => dispatch(selectNetworkListViewGroup(val))}
        />
      </div>
    </header>
  );

  if (listItems.length === 0 && queryString === '' && status === 'idle') {
    return (
      <Container>
        <LetsBeginCard
          onClick={() => {
            dispatch(cleanNetworkListViewSelectedNetwork());
            setShowEditModal(true);
          }}
        />
        <AddOrEditNetworkModal
          network={selectedItem}
          isOpen={showEditModal}
          groups={groups.filter((group: any) => group.value !== '')}
          onCancel={() => {
            setShowEditModal(false);
          }}
          onOk={() => {
            setShowEditModal(false);
          }}
          afterClose={() => {
            dispatch(cleanNetworkListViewSelectedNetwork());
          }}
        />
      </Container>
    );
  }

  if (listItems.length === 0 && queryString !== '' && status === 'idle') {
    return (
      <Container>
        <div className='grid gap-8 mb-4'>
          {headerControls}
          {/* @ts-ignore */}
          <EmptyState customHeight={400} emptyStateMessage={''} />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div
        className='grid gap-8 mb-4'
        onClick={() => {
          if (selectedItem) {
            dispatch(cleanNetworkListViewSelectedNetwork());
          }
        }}>
        {headerControls}
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6'>
          {!!showAddNetwork && (
            <AddNetworkCard
              onClick={() => {
                dispatch(cleanNetworkListViewSelectedNetwork());
                setShowEditModal(true);
              }}
            />
          )}
          {listItems.map((item: any) => (
            <NetworkCard
              {...item}
              key={item.id}
              showDropdown={selectedItem?.id === item.id}
              onDropdownClick={(id) => {
                if (selectedItem?.id === id) {
                  dispatch(cleanNetworkListViewSelectedNetwork());
                } else {
                  dispatch(setNetworkListViewSelectedNetwork(id));
                }
              }}
              onDeleteClick={() => {
                setShowDeleteModal(true);
              }}
              onEditClick={() => {
                setShowEditModal(true);
              }}
              onArchiveClick={() => {
                setShowArchiveModal(true);
              }}
            />
          ))}
        </div>

        <div className='flex justify-end'>
          <Pagination
            current={itemsPage}
            pageSize={itemsPerPage}
            defaultPageSize={8}
            pageSizeOptions={[8, 16, 32, 64]}
            total={totalItems == 0 ? totalItems : totalItems + 1}
            showSizeChanger={true}
            onChange={(page) => {
              if (page !== itemsPage) {
                dispatch(selectNetworkListViewPage(page));
              }
            }}
            onShowSizeChange={(current, size) => {
              if (size !== itemsPerPage) {
                dispatch(selectNetworkListViewPerPage(size));
              }
            }}
          />
        </div>
      </div>
      <ArchiveModal
        isOpen={showArchiveModal}
        name={selectedItem?.name}
        onCancel={() => {
          setShowArchiveModal(false);
          dispatch(cleanNetworkListViewSelectedNetwork());
        }}
        onOk={() => {
          setShowArchiveModal(false);
          if (selectedItem) {
            dispatch(archiveNetworkById(selectedItem.id));
          }
        }}
      />
      <DeleteModal
        isOpen={showDeleteModal}
        name={selectedItem?.name}
        onCancel={() => {
          setShowDeleteModal(false);
          dispatch(cleanNetworkListViewSelectedNetwork());
        }}
        onOk={() => {
          setShowDeleteModal(false);
          if (selectedItem) {
            dispatch(deleteNetworkById(selectedItem.id));
          }
        }}
      />
      <AddOrEditNetworkModal
        network={selectedItem}
        isOpen={showEditModal}
        groups={groups.filter((group: any) => group.value !== '')}
        onCancel={() => {
          setShowEditModal(false);
        }}
        onOk={() => {
          setShowEditModal(false);
        }}
        afterClose={() => {
          dispatch(cleanNetworkListViewSelectedNetwork());
        }}
      />
    </Container>
  );
};

const LetsBeginCard: React.FC<{onClick: () => void}> = ({onClick}) => {
  return (
    <InitialCard
      customClass={''}
      text={`Let's Begin!`}
      customButton={onClick}
      customButtonText='Add Network'>
      <p>
        Here is the place where you can see the case studies you created. You
        may define a case study by creating it as a network. Pin the network as
        favorite, archive it to denote as network on-hold, and also edit it once
        you create it. (All the cards that's been archived could be viewed by
        clicking on the 'View Archives Only' button)
      </p>
      <p>Start creating networks by clicking the below button</p>
    </InitialCard>
  );
};

type ModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
};

const ArchiveModal: React.FC<ModalProps & {name?: string}> = ({
  isOpen,
  onCancel,
  onOk,
  name,
}) => (
  <BaseModal
    isOpen={isOpen}
    onOk={onOk}
    okText={'Yes'}
    onCancel={onCancel}
    cancelText={'No'}
    title={MESSAGES.MODAL_WARNING_TITLE}>
    <p className='text-base text-black'>{MESSAGES.CONFIRM_ARCHIVE(name)}</p>
  </BaseModal>
);

const DeleteModal: React.FC<ModalProps & {name?: string}> = ({
  isOpen,
  onCancel,
  onOk,
  name,
}) => (
  <BaseModal
    isOpen={isOpen}
    onOk={onOk}
    okText={'Yes'}
    onCancel={onCancel}
    cancelText={'No'}
    title={MESSAGES.MODAL_WARNING_TITLE}>
    <p className='text-base text-black'>{MESSAGES.CONFIRM_DELETE(name)}</p>
  </BaseModal>
);

export default withRouter(Networks);
