import React from 'react';

interface ChipProps {
  className?: string;
  text: string;
}

export const Chip: React.FC<ChipProps> = ({className, text}) => {
  return (
    <span
      className={`rounded py-px px-2 text-sm font-medium text-gray-900 ${
        className ?? ''
      }`}>
      {text}
    </span>
  );
};
