import React from 'react';
import {Select} from 'antd';
import {NetworksIcon} from 'Assets/icons/NetworksIcon';
interface Option {
  value: string;
  label: string;
}
interface Props {
  options: Option[];
  search?: boolean;
  placeholder: string;
  handleChange?: (value: string) => void;
  handleGetBothChange?: (value: string, label: Option) => void;
  border?: boolean;
  placeholderStyle?: Record<string,string>;
  [key: string]: unknown;
}

export const SearchSelect = ({
  options,
  search,
  placeholder,
  handleChange,
  border,
  placeholderStyle = {},
  handleGetBothChange,
  ...rest
}: Props) => {
  // Define a variable to store the border class
  const borderClass = border ? 'border border-black border-solid bg-black' : '';
  // JSX for the customized placeholder with an icon
  const customPlaceholder = (
    <div className='flex flex-row items-center gap-4'>
      <div className='stroke-black'>
        <NetworksIcon size='w-8 h-5' />
      </div>
      <div className='text-16 text-black pt-1'>{placeholder}</div>
    </div>
  );

  return (
    <Select
      className={`${borderClass} text-black`}
      filterOption={(input, option) =>
        (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
      }
      onChange={(value: string, label: Option | Option[]) => {
        if (handleGetBothChange) {
          const selectedOption = Array.isArray(label) ? label[0] : label;
          handleGetBothChange(value, selectedOption);
        } else if (handleChange) {
          handleChange(value);
        }
      }}
      options={options}
      optionFilterProp='children'
      placeholder={<span style={placeholderStyle}>{border ? customPlaceholder : [placeholder]}</span>}
      showSearch={search}
      {...rest}
    />
  );
};
