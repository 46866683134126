import React, { useEffect, useState } from 'react';
import { 
  DeleteOutlined, 
  EyeOutlined, 
  InboxOutlined, 
  LoadingOutlined, 
  SearchOutlined 
} from '@ant-design/icons';
import { Input, Table, Upload } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { deleteIntelDocument, postIntelDocument } from '../../../../Services/IntelDocument.service';
import {
  clearListView,
  fetchItems,
  selectListViewPage,
  selectListViewSearch,
  setListViewType,
  setListViewIsSearching
} from 'ReduxV2/state/listView';
import { DeleteConfirmationModal } from 'Components/DeleteConfirmationModal';
import './styles/Documents.scss';

const { Dragger } = Upload;

/**
 * Renders a component that displays a list of documents.
 *
 * @return {JSX.Element} The rendered component.
 */
const Documents = ({activeKey}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();

  const network = useSelector((state) => state.network.value);
  const listItems = useSelector((state) => state.listView.items);
  const totalItems = useSelector((state) => state.listView.count);
  const itemsPerPage = useSelector((state) => state.listView.perPage);
  const currentPage = useSelector((state) => state.listView.page);
  const itemsSearch = useSelector((state) => state.listView.search);
  const isSearching = useSelector((state) => state.listView.isSearching);
  const [searchTimer, setSearchTimer] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  
  const searchPlaceholder = '';

  const columns = [
    {
      title: 'Name',
      dataIndex: 'filename',
      key: 'filename',
    },
    {
      title: 'Uploaded by',
      dataIndex: 'created_by',
      key: 'created_by',
      render: text => (
        <p>{text?.name ?? 'Untitled Document'}</p>
      )
    },
    {
      title: 'Creation Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => (
        <p>{moment(text).format('L')}</p>
      )
    },
    {
      title: 'Inspect',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div>
          <a target="_blank" rel="noopener noreferrer" href={record?.file} className="ml-10">
            <EyeOutlined />
          </a>

          <a className="ml-10" onClick={() => {
            setItemToDelete(record?.id);
            setModalVisible(true);
          }}>
            <DeleteOutlined style={{ color: "#F46767" }} />
          </a>
        </div>

      )
    },
  ];

  const deleteDocument = async (id) => {
    try {
      await deleteIntelDocument(id).call;
      dispatch(fetchItems());
    } catch (error) {

    }
  }

  const uploadFile = async (e) => {
    try {
      let formdata = new FormData();
      formdata.append('file', e.file?.originFileObj);
      formdata.append('organization', network);

      await postIntelDocument(formdata).call;
      dispatch(fetchItems());
    } catch (error) {

    }
  }

  useEffect(() => {
    dispatch(clearListView());
  }, []);

  // only load items if the documents tab is active
  useEffect(() => {
    if(activeKey === 'documents') {
      dispatch(clearListView());
      dispatch(setListViewType('documents'));
      dispatch(fetchItems());
    }
  }, [network, activeKey]);

  useEffect(() => {
    dispatch(fetchItems());
  }, [currentPage]);

 useEffect(() => {
    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    const newTimer = setTimeout(() => {
      dispatch(setListViewIsSearching(true));
      dispatch(fetchItems());
    }, 1000);
    setSearchTimer(newTimer);
  }, [itemsSearch]);


  return (
    <div className='documents grid gap-8'>
      <Dragger beforeUpload={() => { }} showUploadList={false} customRequest={() => { }} onChange={uploadFile}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Dragger>
      <div className="flex justify-end">
        <Input
          suffix={isSearching ? <LoadingOutlined /> : <SearchOutlined />}
          className='w-[300px]'
          size='small'
          placeholder={searchPlaceholder}
          onChange={(e) => {
            dispatch(selectListViewSearch(e.target.value))
          }}
        />        
      </div>
      <div>
        <Table 
          columns={columns} 
          dataSource={listItems} 
          pagination={{
            defaultPageSize: itemsPerPage,
            total: totalItems,
            onChange: (page) => {
              dispatch(selectListViewPage(page));
            }
          }}>
        </Table >
      </div>
      <DeleteConfirmationModal
        visible={modalVisible}
        cancel={() => setModalVisible(false)}
        save={() => {
          deleteDocument(itemToDelete).then(() => {
            setItemToDelete(null);
            setModalVisible(false);
          });
        }}
      />
    </div>
  );
};

export default Documents;
