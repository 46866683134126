import {
  DELETE_PERSONA_START,
  DELETE_PERSONA_SUCCESS,
  DELETE_PERSONA_FAILED,
} from '../../Redux.constants';
import {Default} from './InitialState';
import {deleteApiStarted, deleteApiFailed, deleteApiSuccess} from './Helper';

const deletePersonaReducer = (state = Default(), action) => {
  switch (action.type) {
    case DELETE_PERSONA_START:
      return deleteApiStarted(state, action);
    case DELETE_PERSONA_FAILED:
      return deleteApiFailed(state, action);
    case DELETE_PERSONA_SUCCESS:
      return deleteApiSuccess(state, action);
    default:
      return state;
  }
};
export default deletePersonaReducer;
