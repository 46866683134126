export const LIST_ORGANISATIONS = '/organization/';
export const LIST_ORGANIZATION_GROUP = '/organization-group/';
export const LIST_MISSIONS = '/mission/';
export const LIST_LINKED_ORGANIZATION_MISSIONS =
  '/mission-org-bridge/mission-organization/';

export const FETCH_ORG_DETAILS = '/organization';
export const FETCH_DEPARTMENTS = '/department/';
export const FETCH_DESIGNATIONS = '/designation/';
export const ADD_EMPLOYEE = '/employee/';
export const OTHER_PERSON = '/other-person/';
export const UPLOAD_IMAGE = '/upload/presigned_url/';
// export const GET_EMPLOYEE_LIST = ({ input, limit, offset }) =>
//   `/employee/?q=${input}&limit=${limit}&offset=${offset}`;
export const GET_EMPLOYEE_LIST = ({input, limit, offset, organisationId}) =>
  `/employee/?q=${input}&limit=${limit}&offset=${offset}&organization=${organisationId}`;

export const FETCH_QUESTIONS = '/question/';
export const ADD_MULTIPLE_QUESTION_ANSWERS =
  '/generic-question-answer/add-multiple/';

export const FORGOT_PASSWORD = '/users/forgot_password/';
export const RESET_PASSWORD = '/users/reset_password/';
export const ADD_MSOP_REPORT_API = '/msop-report-state/';
export const MISSION_HYPOTHESIS = '/mission-hypothesis/';
export const MISSION_SPRINT = '/mission-sprint/';
export const ORGANIZATION_SPRINT = '/organization-sprint/';
export const MISSION_HYPOTHESIS_BULK_UPDATE =
  '/mission-hypothesis/add-multiple/';

export const LIST_ORGANIZATION_DEPARTMENT = '/organization-department/';
export const LIST_ORGANIZATION_DESIGNATION = '/organization-designation/';
export const GET_USER_DETAILS = '/users/details/';

export const GET_CONNECTED_NETWORKS = '/mission-org-bridge/';
export const TEAM_BUILD = '/team-build/';
export const MOST_VALUALE_BULK_UPDATE = '/mvg-table/add-multiple/';
export const MOST_VALUALE = '/mvg-table/';
