import {
  INTEGRATE_IMAGE_ERROR,
  INTEGRATE_IMAGE_STARTED,
  INTEGRATE_IMAGE_SUCCESS,
  UPLOAD_IMAGE_ERROR,
  UPLOAD_IMAGE_STARTED,
  UPLOAD_IMAGE_SUCCESS,
} from '../../Redux.constants';
import {
  uploadImageStarted,
  uploadImageSuccess,
  uploadImageError,
  integrateImageStarted,
  integrateImageSuccess,
  integrateImageError,
} from './Helper';
import {INITIAL_STATE} from './initialState';

export default function UploadImageReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPLOAD_IMAGE_STARTED:
      return uploadImageStarted(state, action.payload);
    case UPLOAD_IMAGE_SUCCESS:
      return uploadImageSuccess(state, action.payload);
    case UPLOAD_IMAGE_ERROR:
      return uploadImageError(state, action.payload);
    case INTEGRATE_IMAGE_STARTED:
      return integrateImageStarted(state, action.payload);
    case INTEGRATE_IMAGE_SUCCESS:
      return integrateImageSuccess(state, action.payload);
    case INTEGRATE_IMAGE_ERROR:
      return integrateImageError(state, action.payload);
    default:
      return {...state};
  }
}
