import { PlusSquareFilled } from '@ant-design/icons';
import { Col, Modal, Radio, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
/* import { Controller, useForm } from "react-hook-form";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getNotification } from '../../../../../../../../Components/GetNotification';
import { deleteMissionMessenger, getMissionMessenger, getMissionMessengerById, patchMissionMessenger, postMissionMessenger } from '../../../../../../../../Services/MissionTeams.service';
import { getMemberList } from '../../../../../../../../Services/Teams.service';
import { PersonaData } from '../PersonaData'; */
import './styles/Messenger.scss';


const Messenger = ({ openModalArchetype }) => {
	/* const [visibleModal, setVisibleModal] = useState(false);
	const [attendees, setAttendees] = useState([]);
	const [allStaff, setAllStaff] = useState({});
	const { register, handleSubmit, reset, control, setValue, formState: { errors }, watch, getValues } = useForm({ defaultValues: { demand_prob_score: "1", trust_other_score: "1", persona: null } });

	const mission = useSelector(state => {
		return state.mission.value.id
	});

	const watchPersonaId = watch("persona");

	const { id } = useParams();

	const saveStaff = async (params) => {
		const data = {
			...params,
			mission,
		}

		try {
			if (params?.id) {
				const res = await patchMissionMessenger(data).call;
			} else {
				const res = await postMissionMessenger(data).call;
			}
			getNotification('success', {
				header: 'success',
				body: 'The messenger was successfully created',
			});
			setVisibleModal(false)
			loadMessenger()
		} catch (error) {
			getNotification('error', {
				header: 'Error',
				body: error.response.data?.non_field_errors.join('.'),
			});
		}
	}

	const loadAttendees = async () => {
		try {
			const res = await getMemberList(id).call
			setAttendees(res.data?.results?.map(att => ({ value: att.id, label: att.name })));
		} catch (error) {

		}
	}

	const loadMessenger = async () => {
		if (mission) {
			try {
				const res = await getMissionMessenger(mission).call
				setAllStaff(res?.data)
			} catch (error) {

			}
		}
	}

	const deleteMessenger = async (id) => {
		try {
			const res = await deleteMissionMessenger(id).call
			getNotification('success', {
				header: 'success',
				body: 'The messenger was successfully deleted',
			});
			loadMessenger()
		} catch (error) {

		}
	}

	const editMessenger = async (id) => {
		reset()
		try {
			const res = await getMissionMessengerById(id).call;
			Object.keys(res?.data).map((key) => {
				if (key == "persona") {
					setValue(key, res?.data[key].id)
				} else {
					setValue(key, String(res?.data[key]))
				}
			});
			setVisibleModal(true)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		loadAttendees()
		loadMessenger()
	}, [mission]);
 */
	return (
		<div className='messenger'>
			{/* <div className="flex justify-beetween items-center">
				<h4>Who are the right messengers to help communicate the importance of solving this mission on your behalf?</h4>
				<PlusSquareFilled
					style={{
						fontSize: '35px',
						color: "#3C5DD6",
						cursor: 'pointer',
						margin: 'auto',
						marginLeft: '10px'
					}}
					onClick={() => {
						setVisibleModal(true)
						reset()
					}}
				/>
			</div>
			<div>
				<Row gutter={10}>
					{
						allStaff?.results?.map((item, index) => (
							<Col xs={24} sm={24} md={12} lg={6} key={index}>
								<div className="messenger-card">
									<img className="staff-card-avatar" src={item?.persona?.image || '/img/avatar.png'} alt="" />
									<p className="staff-card-name">{item?.persona?.name}</p>
									<p>{item?.job_and_resp}</p>
									<div className={`${item?.persona?.archetype == "" ? 'hidden' : 'block'}`} onClick={() => openModalArchetype(item)}>
										<p> <span className="staff-card-archetype"> {item?.persona?.archetype}</span></p>
									</div>
									<div className="messenger-card-option justify-center">
										<div className="flex justify-beetween w-90 m-auto mb-10">
											<div>
												<p>Demand</p>
												<p>{item?.demand_prob_score}</p>
											</div>
											<div>
												<p>Trust</p>
												<p>{item?.trust_other_score}</p>
											</div>
										</div>
										<div>
											<button className="staff-card-btn" onClick={() => editMessenger(item.id)}>Edit</button>
											<button className="staff-card-btn ml-10" onClick={() => deleteMessenger(item.id)}>Delete</button>
										</div>
									</div>
								</div>
							</Col>
						))
					}
				</Row>
			</div>
			<div className={`${allStaff?.results?.length ? 'hidden' : 'staff-no-data'}`}>
				Empty
			</div>
			<Modal
				title="New Messenger"
				visible={visibleModal}
				onCancel={() => setVisibleModal(false)}
				footer={false}
				className="mission-modal"
			>
				<form onSubmit={handleSubmit(saveStaff)}>
					<Row gutter={10}>
						<Col span={24} className="mb-10">
							<p>Tag the person</p>
							<Controller
								name="persona"
								control={control}
								rules={{ required: true }}
								render={({ field }) => (
									<Select
										{...field}
										className={`staff-select-class ${errors.persona ? "border-error" : ""}`}
										placeholder="Select persona"
										optionFilterProp="children"
										bordered={false}
										onChange={(net) => {
											field.onChange(net)
										}}
										options={attendees}
										disabled={getValues('id')}
									/>
								)}
							/>
						</Col>
						<Col span={24} className="mb-10">
							<PersonaData personaId={watchPersonaId} />
						</Col>
						<Col span={24} className={`mb-10`}>
							<p>Primary Job Roles & Responsabilities</p>
							<input type="text" className="input-class" {...register("job_and_resp")} />
						</Col>
						<Col span={24} className={`mb-10`}>
							<p className="my-15">Demand for Problem Score</p>
							<Controller
								name="demand_prob_score"
								control={control}
								render={({ field }) => (
									<Radio.Group 	{...field} className="flex ">
										<Radio value="1" className="grid">1</Radio>
										<Radio value="2" className="grid">2</Radio>
										<Radio value="3" className="grid">3</Radio>
										<Radio value="4" className="grid">4</Radio>
										<Radio value="5" className="grid">5</Radio>
										<Radio value="6" className="grid">6</Radio>
										<Radio value="7" className="grid">7</Radio>
										<Radio value="8" className="grid">8</Radio>
										<Radio value="9" className="grid">9</Radio>
										<Radio value="10" className="grid">10</Radio>
									</Radio.Group>
								)}
							/>
						</Col>
						<Col span={24} className={`mb-10`}>
							<p className="my-15">Trust from Other Score</p>
							<Controller
								name="trust_other_score"
								control={control}
								render={({ field }) => (
									<Radio.Group 	{...field} className="flex">
										<Radio value="1" className="grid">1</Radio>
										<Radio value="2" className="grid">2</Radio>
										<Radio value="3" className="grid">3</Radio>
										<Radio value="4" className="grid">4</Radio>
										<Radio value="5" className="grid">5</Radio>
										<Radio value="6" className="grid">6</Radio>
										<Radio value="7" className="grid">7</Radio>
										<Radio value="8" className="grid">8</Radio>
										<Radio value="9" className="grid">9</Radio>
										<Radio value="10" className="grid">10</Radio>
									</Radio.Group>
								)}
							/>
						</Col>
						<Col span={24} className="text-center mt-15">
							<button type="button" className="modal-delete-btn" onClick={() => setVisibleModal(false)}>Cancel</button>
							<button type="submit" className="modal-dont-delete ml-10">Save</button>
						</Col>
					</Row>
				</form>
			</Modal>
 */}		</div >
	);
};

export default Messenger;
