import React, { useState } from 'react';

import API_Call from '../Axios/apicalls';

import { SwitchContext } from '../../../Components/new/Context/ComponentSwitchContext';
import { AnswerStorageContext } from '../../../Components/new/Context/AnswerStorageContext';

const Finishassessment = () => {
  const { changecomponent } = React.useContext(SwitchContext);
  const { quizid } = React.useContext(AnswerStorageContext);

  const [showProgress, setshowprogress] = useState(false);
  const [apidata, setapidata] = useState();
  const [apidata_average, set_apidata_average] = useState(0);

  React.useEffect(() => {
    API_Call(
      'get',
      `toolbox/results/${quizid}/`,
      null,
      localStorage.getItem('idToken'),
      'multipart/form-data'
    ).then((result) => {
      if (result !== undefined) {
        result.payload = JSON.parse(result.payload.replace(/'/g, '"'));
        result.user_answers = JSON.parse(
          result.user_answers.replace(/'/g, '"')
        );

        result.total_calculation = Object.values(result.payload.calculation_1);

        result.bar_colors = Object.keys(result.payload.calculation_2);
        result.bar_values = Object.values(result.payload.calculation_2);
        setapidata(result);
      }
    });
  }, []);

  React.useEffect(() => {
    if (apidata !== undefined) {
      let val = 0;
      apidata.total_calculation.forEach((x) => {
        val += parseFloat(x);
      });

      set_apidata_average(Math.round(val / apidata.total_calculation.length));
    }
  }, [apidata]);

  return (
    <div className='AssessmentHome'>
      <div className='row m-0 p-0'>
        <div className='col-12'>
          <div className='FinishAssessmentDiv1 dashboarddivgradient4 WhiteForeground Flex row'>
            <div className='col-8 row'>
              <span className='WhiteCircleDiv CongratulationsImg MarginRight10 col-2'></span>

              <span className='mt-3 col-10 '>
                {/* <div className='FontSize20'>Congratulations,</div> */}
                {/* <div className="FontSize16">Your Network Blue Agilis Transformation Assessment Score is <span className="BlackForeground Bold">{`${apidata_average} %`}</span></div> */}
                <div className='FontSize20'>
                  Blue Agilis Transformation Assesment Score{' '}
                  {/* <span className='BlackForeground Bold'>{`${apidata_average} %`}</span> */}
                </div>
              </span>
            </div>

            <div className='text-center FontSize14 MiniDiv col-3 '>
              <div className='mt-2 p-2'>
                <span>You Are In: </span>
                <span className='BlackForeground Bold'> Phase One</span>
                <br />
              </div>
            </div>
          </div>
        </div>

        {showProgress ? (
          <div className='col-12 mt-4'>
            <div className='ProgressBarDiv'>
              <div className='row m-0 p-0'>
                {apidata !== undefined ? (
                  apidata.bar_values.map((val, index) => (
                    <div className='col-12'>
                      <div className='row m-0 p-0'>
                        <div className='col-2 FontSize14 Bold'>
                          Phase {index + 1}
                        </div>
                        <div className='col-9'>
                          <progress
                            value={val}
                            max='100'
                            className={`ProgressBar ${apidata.bar_colors[index]}`}
                          />
                        </div>
                        <div className='col-1 m-0 p-0 FontSize14 Bold'>
                          {Math.round(parseInt(val))} %
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div
          id='modalscreen'
          className='modalscreen'
          onClick={() => {
            document
              .getElementById('modalscreen')
              .addEventListener('click', (e) => {
                if (e.target === e.currentTarget) {
                  document.getElementById('modalscreen').style.display = 'none';
                }
              });
          }}>
          <div className='modal-content-screen'>
            <div className='row m-0 p-0'>
              <div className='col-12 text-center FontSize18 Bold'>
                Contact Details
              </div>
            </div>
            <div className='row m-0 p-0 mt-3'>
              <div className='col-3 Bold'>Name:</div>
              <div className='col-9'>Nadia Adams</div>

              <div className='col-3 Bold'>Phone:</div>
              <div className='col-9'>888-734-2354</div>

              <div className='col-3 Bold'>Email:</div>
              <div className='col-9'>nadia@blueagilis.com</div>
            </div>
          </div>
        </div>

        <div className='col-12 mt-4 '>
          <div
            className={`AssessmentScoreDiv AssessmentScoreDiv-margin blue light_blue`}>
            <div style={{ height: '50%' }}>
              Personalized Population Health Management{' '}
            </div>
            <br />
            <button
              className='my-3 ScoreButton'
            //   onClick={() => {
            //     document.getElementById('modalscreen').style.display = 'block';
            //   }}
            >
              {/* Score
               */}
              {apidata?.payload?.calculation_2?.light_blue.toFixed(2)}%
            </button>
          </div>
          <div className='AssessmentScoreDiv AssessmentScoreDiv-margin  dark_blue'>
            <div style={{ height: '50%' }}>Strategic Execution</div>
            <br />
            <button className='my-3 ScoreButton'>
              {/* Score */}
              {apidata?.payload?.calculation_2?.dark_blue.toFixed(2)}%
            </button>
          </div>

          <div className='AssessmentScoreDiv AssessmentScoreDiv-margin  yellow'>
            <div style={{ height: '50%' }}>Data Driven Quality Improvement</div>
            <br />
            <button className='my-3 ScoreButton'>
              {apidata?.payload?.calculation_2?.yellow.toFixed(2)}%
            </button>
          </div>
          <div className='AssessmentScoreDiv AssessmentScoreDiv-margin pink'>
            <div style={{ height: '50%' }}>Cost of Care</div>
            <br />
            <button className='my-3 ScoreButton'>
              {/* Score */}
              {apidata?.payload?.calculation_2?.pink.toFixed(2)}%
            </button>
          </div>
          <div className='AssessmentScoreDiv purple'>
            <div style={{ height: '50%' }}>
              Team Based, Person and Family Centered Care
            </div>
            <br />
            <button className='my-3 ScoreButton'>
              {apidata?.payload?.calculation_2?.purple.toFixed(2)}%
            </button>
          </div>
        </div>

        <div
          className='col-12 mt-2 FontSize12 Pointer'
          onClick={() => changecomponent('new assessment')}>
          <u>Go to my Assessment</u>
        </div>
      </div>
    </div>
  );
};

export default Finishassessment;
