import React, { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import union from 'Assets/icons/Union.svg';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table/Table';
import DeleteScoreCardModal from '../DeleteScoreCardModal';

export type FileRecord = {
  key: string;
  id: string;
  filename?: string;
  file?: string;
  date: string;
  link?: string;
  created_at: string;
}

type FileHistoryProps = {
  records: FileRecord[];
  setRefreshScoreCards: React.Dispatch<React.SetStateAction<boolean>>;
}

const FileHistory: React.FC<FileHistoryProps> = ({ records, setRefreshScoreCards}) => {
  const [selectedFile, setSelectedFile] = useState<FileRecord | undefined>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const columns: ColumnsType<FileRecord> = [
    {
      title: 'Template Name',
      dataIndex: 'filename',
      key: 'filename',
      sorter: (a, b) => a?.filename ? a.filename?.localeCompare(b.filename ?? '') :  a?.link ? a.link?.localeCompare(b.link ?? '') : 0,
      sortDirections: ['descend', 'ascend'],
      render: (_value, record) => {
        const displayText = record.link ?? record.filename;
        return (
          <div className='flex gap-2 items-center'>
            <a
              onClick={() => {
                setIsDeleteModalOpen(true)
                setSelectedFile(record);
            }}>
              <DeleteOutlined style={{color: '#000'}} />
            </a>
            <a className="text-black ml-5" href={displayText} target="_blank" rel="noopener noreferrer" download>
              {displayText} 
            </a>
            <img className='w-4 mt-1' src={union} alt='Union icon' />
          </div>
        );
      }
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      sortDirections: ['descend', 'ascend'],
      render: (_value, record) => record.created_at
    }
  ];

  return (
    <>
      <DeleteScoreCardModal
        record={selectedFile}
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        callback={() => setRefreshScoreCards(prev => !prev)}
      />
 
      <div className="text-lg py-4">
        <b>History</b>
      </div>
      <div className='w-full'>
        <Table dataSource={records} columns={columns} pagination={false} scroll={{ x: 'max-content' }} />
      </div>
    </>
  );
};

export default FileHistory;
