import {PaginatedResponse} from './Request';

export type PftJourney = {
  id: number;
  created: string;
  updated: string;
  user_pat: number;
};

export type PftJourneyCategoryScore = {
  category: string;
  total_competencies: number;
  total_max_points: number;
  user_points: number;
  percentage: number;
};

export type PftJourneyCompetency = {
  about: string;
  trans_journey: number | null;
  title: string;
  category: number;
  description: string;
  id: number;
  ref_id: number;
  important: string;
  improvement_tactics: PftJourneyImprovementTactic[];
  lag_measures: string;
};

export type PftJourneyCompetencyOption = {
  id: number;
  description: string;
  phase: number;
};

export type PftJourneyImprovementTactic = {
  added_to_tjc: boolean;
  id: number;
  description: string;
  pat_competency: number;
  tjc?: number | null;
  tier?: 'EASY' | 'MEDIUM' | 'HARD';
};

export type PftLibraryCompetency = {
  competency: PftJourneyCompetency;
  current_option: PftJourneyCompetencyOption;
  target_option: PftJourneyCompetencyOption;
};

export enum MetricTrend {
  BELOW_TARGET = 'BELLOW_TARGET',
  CLOSE_TARGET = 'CLOSE_TARGET',
  ABOVE_TARGET = 'ABOVE_TARGET',
}

export type PaginatedPftJourney = PaginatedResponse<PftJourney>;
