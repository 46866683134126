import {MilestoneDetailCard} from 'Components/molecules/card';
import { useLoading } from "Components/FullScreenLoader/LoadingContext";
import {InfoCircleOutlined } from '@ant-design/icons';
import {
  getUserPatPhaseGoals,
  getPftCompetencies,
} from 'Services/PftJourney.service';

import {Select, Tooltip} from 'antd';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {PftLibraryCompetency} from 'types/PftJourney';
import {Phase} from 'types/TransformationJourney';
import {ImprovementTacticModal} from 'Components/molecules/modal';
import {Icon} from 'Components/atoms';

export type loadPatCompetenciesArgs = {
  custom_phase?: number;
  pat_id: number;
  competency_full_info: boolean;
};

const ScreenHeader: React.FC<{title: string}> = ({title}) => (
  <div className='flex items-center justify-between mb-4'>
    <h2 className='text-4xl font-semibold'>{title}</h2>
  </div>
);

const generateUniqueId = () => {
  const timestamp = new Date().getTime(); // Current timestamp in milliseconds
  const randomNum = Math.floor(Math.random() * 1000000); // Random number between 0 and 999999

  return `${timestamp}-${randomNum}`;
};

export const PftLibrary: React.FC<{onClickNext: () => void}> = ({
  onClickNext,
}) => {
  //@ts-ignore
  const phasesFromStore = useSelector((state) => state.pftJourney.phases);

  const [primaryCompetencies, setPrimaryCompetencies] = useState<
    PftLibraryCompetency[]
  >([]);
  const [secondaryCompetencies, setSecondaryCompetencies] = useState<
    PftLibraryCompetency[]
  >([]);
 
  const [pftCompetencies, setPftCompetencies] = useState<any>([]);
  const [phaseOptions, setPhaseOptions] = useState<Phase[]>([]);
  const [defaultPhaseIndex, setDefaultPhaseIndex] = useState<number | null>(
    null
  );

  let missionMeasureIds: number[] = [];
  let tjcMissionMeasures: any[] = [];

  const { startLoading, stopLoading } = useLoading();

  const patId = useSelector((state) => {
    //@ts-ignore
    return state.pat.value;
  });

  const pftId = useSelector((state) => {
    //@ts-ignore
    return state.pftJourney.id;
  });

  const findPhaseOptions = () => {
    const orderedPhases = [...phasesFromStore].sort((a:any, b:any) => {
        const phaseNumberA = parseInt(a.name.split(' ')[1]);
        const phaseNumberB = parseInt(b.name.split(' ')[1]);
        return phaseNumberA - phaseNumberB;
    });

    const options = orderedPhases.map((item: Phase, index: number) => ({
      value: index + 1,
      label: item.name + (item.current ? ' (Current)' : ''),
    }));
    setPhaseOptions(options as any);
  };

  const findDefaultPhaseIndex = () => {
    let defaultOptionIndex = null;
    const defaultOption = phasesFromStore.find(
      (item: Phase) => item.current == true
    );
    if (defaultOption) {
      defaultOptionIndex = phasesFromStore.indexOf(defaultOption) + 1;
    }
    setDefaultPhaseIndex(defaultOptionIndex);
  };

  const loadPatCompetencies = async (phase_num?: number) => {
    try {
      const options: loadPatCompetenciesArgs = {
        pat_id: patId,
        competency_full_info: true,
      };
      if (phase_num) {
        options.custom_phase = phase_num;
      }
      if (patId) {
        startLoading()
        const res = await getUserPatPhaseGoals(options).call;
        const primaryMilestones = res?.data?.filtered_competencies?.primary_milestones
        const secondaryMilestones = res?.data?.filtered_competencies?.secondary_milestones

        setPrimaryCompetencies(primaryMilestones);
        setSecondaryCompetencies(secondaryMilestones);

        stopLoading()
      }
    } catch (error) {}
  };

  const loadPftCompetencies = async () => {
    try {
      if (pftId) {
        startLoading()
        const res = await getPftCompetencies(pftId).call;
        stopLoading()
        setPftCompetencies(res?.data?.results);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (phasesFromStore) {
      startLoading()
      findPhaseOptions();
      findDefaultPhaseIndex();
      loadPatCompetencies();
      loadPftCompetencies();
      stopLoading()
    }
  }, [phasesFromStore]);

  return (
    <div id='pft-library'>
      <ImprovementTacticModal />
      <ScreenHeader title='Your Transformation Journey: Library' />
      <div className='mb-4 flex items-center gap-4'>
        <h2 className='text-3xl font-bold'>Summary:</h2>
        {!!defaultPhaseIndex && (
          <Select
            bordered={false}
            key={defaultPhaseIndex}
            className='bg-white rounded border border-solid border-gray-400 !min-w-[300px]'
            options={phaseOptions}
            defaultValue={defaultPhaseIndex}
            onChange={(val: number) => {
              loadPatCompetencies(val);
            }}
          />
        )}
      </div>
      <h2 className='mb-4 text-3xl'>Goals to Reach Next Phase:</h2>
      <div className='grid gap-4'>
        {primaryCompetencies.length > 0 &&
          <div className='flex gap-2'>
            <h3 className='font-semibold'>Primary Milestones</h3>
            <Tooltip color='black' title='These are the milestones that will contribute the most progress to your phase score!'>
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        }
        {primaryCompetencies.map((item, index) => {
          /**
           * PAT competencies are listed on this screen, but need the ability
           * to add measures and improvement tactics to them.
           * To facilitate that, the PAT competencies will be appended with
           * a transformation competency id, given that there's a PFT competency
           * that matches it.
           */
          const matchingPftCompetency = pftCompetencies.find(
            (pftCompetency: any) =>
              pftCompetency.pat_competency.id == item.competency.id
          );

          /**
           * Get everything necessary from matchingPftCompetency to populate
           * and facilitate its NetworkMeasureSelect's functionality.
           */
          if (matchingPftCompetency) {
            missionMeasureIds = matchingPftCompetency.mission_measures.map(
              (item: any) => item.mission_measure.id
            );
            tjcMissionMeasures = matchingPftCompetency.mission_measures;
          } else {
            missionMeasureIds = [];
            tjcMissionMeasures = [];
          }

          const matchingPftCompetencyId = matchingPftCompetency?.id ?? null;
          return (
            <MilestoneDetailCard
              onDeleteCallback={() => defaultPhaseIndex && loadPatCompetencies(defaultPhaseIndex)}
              pftId={pftId}
              key={`${index}-${generateUniqueId()}`}
              competency={item.competency}
              current_option={item.current_option}
              target_option={item.target_option}
              tjCompetencyId={matchingPftCompetencyId}
              missionMeasureIds={missionMeasureIds}
              tjcMissionMeasures={tjcMissionMeasures}
            />
          );
        })}
        {secondaryCompetencies.length > 0 &&
          <div className='flex gap-2'>
            <h3 className='font-semibold'>Secondary Milestones</h3>
            <Tooltip color='black' title='These milestones are still required to progress'>
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        }
        {secondaryCompetencies?.map((item, index) => {
          /**
           * PAT competencies are listed on this screen, but need the ability
           * to add measures and improvement tactics to them.
           * To facilitate that, the PAT competencies will be appended with
           * a transformation competency id, given that there's a PFT competency
           * that matches it.
           */
          const matchingPftCompetency = pftCompetencies.find(
            (pftCompetency: any) =>
              pftCompetency.pat_competency.id == item.competency.id
          );

          /**
           * Get everything necessary from matchingPftCompetency to populate
           * and facilitate its NetworkMeasureSelect's functionality.
           */
          if (matchingPftCompetency) {
            missionMeasureIds = matchingPftCompetency.mission_measures.map(
              (item: any) => item.mission_measure.id
            );
            tjcMissionMeasures = matchingPftCompetency.mission_measures;
          } else {
            missionMeasureIds = [];
            tjcMissionMeasures = [];
          }

          const matchingPftCompetencyId = matchingPftCompetency?.id ?? null;
          return (
            <MilestoneDetailCard
              onDeleteCallback={() => defaultPhaseIndex && loadPatCompetencies(defaultPhaseIndex)}
              pftId={pftId}
              key={`${index}-${generateUniqueId()}`}
              competency={item.competency}
              current_option={item.current_option}
              target_option={item.target_option}
              tjCompetencyId={matchingPftCompetencyId}
              missionMeasureIds={missionMeasureIds}
              tjcMissionMeasures={tjcMissionMeasures}
            />
          );
        })}
        <div className='flex justify-end'>
          <button
            className='flex gap-2 items-center py-2 px-4 rounded text-white font-medium bg-primary hover:bg-primary-hover'
            onClick={onClickNext}>
            <span>Next</span>
            <Icon name='arrow-right' className='w-5 h-5' />
          </button>
        </div>
      </div>
    </div>
  );
};
