import React, { useCallback, useEffect, useState } from 'react';
import {
  Col,
  Modal,
  Row,
  Select,
  Upload,
  notification,
  Alert,
  Radio,
  Cascader,
  Divider,
  Form,
  Button,
  Input,
} from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { updatePersona } from '../../../../ReduxV2/state/personas';
import { getArchetypesData } from '../../../../Routes/OrganisationInsights/Children/ViewDetails/components/Team/models';
import {
  getTeamPersonasMissions,
  postMember,
  updateMember,
  uploadProfileImage,
} from '../../../../Services/Teams.service';

import { useDispatch, useSelector } from 'react-redux';
import './styles/MemberData.scss';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { AUTOSAVING_DELAY } from '../../../../../config/constants';
import InputDebounce from '../../../../Components/InputDebounce/InputDebounce';
import {
  getUsersSearch,
  loadRolesCascader,
  postClientRoles,
} from '../../../../Services/Users.service';
import ProjectTab from './components/ProjectTab/ProjectTab';

/**
 * Renders a modal member with information.
 *
 * @param {Object} visible - Determines if the modal is visible.
 * @param {Function} closeModal - Function to close the modal.
 * @param {number} memberId - The member id.
 * @return {JSX.Element} The modal component.
 */
const MemberData = ({ visible, closeModal, memberId }) => {
  const [step, setStep] = useState(1);
  const [defaultRole, setDefaultRole] = useState();
  const [archetypeDescription, setArchetypeDescription] = useState('');
  const [archetypes, setArchetypes] = useState([]);
  const [archetype, setArchetype] = useState('');
  const [userId, setUserId] = useState('');
  const [imageProfile, setImageProfile] = useState(null);
  const [projects, setProjects] = useState([]);
  const [missionId, setMissionId] = useState(0);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      send_email: 'no',
    },
  });
  const [roles, setRoles] = useState([]);
  const [newRole, setNewRole] = useState('');

  const { id } = useParams();
  const persona = useSelector((state) => {
    return state.persona.value;
  });

  const membersKey = [
    { label: 'Goals', show: true, key: 'goals' },
    { label: 'Primary Responsibilities', show: true, key: 'biggest_fear' },
    { label: 'Direct Reports', show: true, key: 'occupations' },
    { label: 'Background', show: true, key: 'background' },
    { label: 'Frustrations', show: true, key: 'frustrations' },
    { label: 'Notes', show: true, key: 'notes' },
  ];

  /**
   * Reads an image file and returns its base64 representation.
   *
   * @param {File} img - The image file to read.
   * @param {Function} callback - The callback function to invoke with the base64 representation.
   * @return {void}
   */
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  /**
   * A function that handles custom requests.
   *
   * @param {File} file - The file to be processed.
   * @return {Promise<void>} A promise that resolves once the function is complete.
   */
  const onCustomRequest = async (file) => {
    try {
      if (persona?.id) {
        const res = await uploadProfileImage(persona?.id, file).call;
        dispatch(updatePersona({ image: res?.data?.image }));
      } else {
        getBase64(file.file, (imageUrlUpload) => {
          dispatch(updatePersona({ image: imageUrlUpload }));
        });
        setImageProfile(file);
      }
    } catch (error) { }
  };

  const loadMember = async () => {
    const _archetypes = await loadArchetypes();
    try {
      Object.keys(persona).forEach(function (key, index) {
        console.log({key, value:persona[key] })
        if (key == 'send_email') {
          setValue(key, persona[key] ? 'yes' : 'no');
        } else {
          setValue(key, persona[key]);
        }
      });
      setDefaultRole([persona?.role?.id || '']);
      setValue('role', persona?.role?.id || '');
      const archetype = _archetypes.find(
        (item) => item.value === persona?.archetype
      );
      setArchetypeDescription(archetype?.description || '');

      const res = await getTeamPersonasMissions(persona?.id).call;
      setProjects(
        res.data?.results?.map((item) => {
          return {
            id: item?.mission?.id,
            name: item?.mission?.name,
          };
        })
      );
    } catch (error) { }
  };

  const loadArchetypes = async () => {
    const archetypes = await getArchetypesData();
    const _archetypes = archetypes.map((item) => {
      return {
        value: item.id,
        label: item.name,
        description: item.description?.join(`<br />`),
      };
    });
    setArchetypes(_archetypes);
    return _archetypes;
  };

  /**
   * Handles the "Ok" action for the function.
   *
   * @param {Object} params - The parameters for the function.
   * @return {Promise} A promise that resolves to the result of the function.
   */
  const handleOk = async (params) => {
    console.log({params})
    try {
      delete params.image;
      delete params.user;
      delete params.created_by;
      delete params.organization;

      const data = {
        ...params,
        email: params?.email || '',
        send_email: false,
      };

      let memberRes = {};
      if (params?.id) {
        memberRes = await updateMember(data).call;
      } else {
        memberRes = await postMember({ ...data, user: userId, organization: id })
          .call;
      }

      if (imageProfile) {
        await uploadProfileImage(
          memberRes?.data[0]?.id,
          imageProfile
        ).call;
      }
      notification.success({
        message: 'Success',
        description: 'Member data has been successfully updated',
      });
      closeModal();
    } catch (error) {
      notification.error({
        message: 'Attention',
        description: error.response.data[0].non_field_errors[0],
      });
    }
  };

  const activeSubmitForm = async () => {
    const res = await getUsersSearch(getValues('email')).call;
    if (res?.data?.results?.length) {
      setValue('send_email', 'no');
      setUserId(res?.data?.results[0].id);
    } else {
      setValue('send_email', 'yes');
      setUserId('');
    }
  };

  const debounceActive = useCallback(
    debounce(activeSubmitForm, AUTOSAVING_DELAY),
    []
  );

  const fillRoles = async () => setRoles(await loadRolesCascader(id));

  /**
   * A function that filters the input value based on the given path.
   *
   * @param {any} inputValue - The input value to be filtered.
   * @param {Array} path - The path used for filtering.
   * @return {boolean} Returns true if the input value matches any option in the path, otherwise false.
   */
  const filter = (inputValue, path) =>
    path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );

  /**
   * Renders a dropdown menu with additional functionality.
   *
   * @param {Array} menus - The menus to be rendered.
   * @return {JSX.Element} The rendered dropdown menu.
   */
  const dropdownRender = (menus) => (
    <div>
      {menus}
      <Divider
        style={{
          margin: 0,
        }}
      />
      <div
        style={{
          padding: 8,
        }}>
        <Form layout='inline' className='add-role-form'>
          <Form.Item name='new-role'>
            <Input
              placeholder='New Additional Role'
              value={newRole}
              onChange={(e) => setNewRole(e.target.value)}
              autoComplete='off'
            />
          </Form.Item>
          <Form.Item name='new-role-button'>
            <Button
              type='primary'
              size='small'
              onClick={async () => {
                if ((await onAddRole(newRole)) === true) setNewRole('');
              }}>
              Add
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );

  /**
   * Adds a role.
   *
   * @param {string} role - The role to be added.
   * @return {boolean} - Returns true if the role is added successfully.
   */
  const onAddRole = async (role) => {
    if (role === '') return false;
    try {
      await postClientRoles({
        role: {
          name: role,
        },
      });
      fillRoles();
      notification.success({
        message: 'Success',
        description: 'The role has been added successfully',
      });
      return true;
    } catch (error) { }
    notification.error({
      message: 'Attention',
      description:
        'An error occurred while adding the role, please try again later',
    });
    return false;
  };

  /**
   * Renders the display based on the provided labels.
   *
   * @param {Array} labels - The labels to be rendered.
   * @return {ReactNode} The rendered display.
   */
  const displayRender = (labels) => {
    if (typeof labels[1] === 'string') return <span>{labels[1]}</span>;
    return labels.map((label, i) => {
      if (typeof label === 'number') {
        return roles.map((roleCategory) => {
          return roleCategory.children.map((role) => {
            if (role.value === label) return `${role.label}`;
          });
        });
      } else if (typeof label === 'string') label = `${labels[1]}`;
      else return <span>{label}</span>;
    });
  };

  useEffect(() => {
    reset();
    setValue('email', '');
    if (memberId) {
      loadMember();
    } else {
      loadArchetypes();
    }
    fillRoles();
    return () => {
      reset();
      setDefaultRole([]);
      setImageProfile(null);
      setUserId('');
      setStep(1);
      setProjects([]);
    };
  }, [visible]);

  return (
    <Modal
      title='Edit Member'
      visible={visible}
      footer={false}
      onCancel={closeModal}
      width={step == 2 ? 1200 : 600}
      className='memberdata'
      style={{ height: 'calc(100vh - 100px)' }}
      bodyStyle={{ overflowY: 'scroll' }}>
      <div className='memberdata'>
        <form onSubmit={handleSubmit(handleOk)}>
          <div className='flex-vertical mt-15'>
            <div className='relative'>
              <div className=' avatar-position flex'>
                <Upload
                  fileList={[]}
                  customRequest={onCustomRequest}
                  showUploadList={false}
                  accept={'.png,.jpg,.jpeg'}
                  onRemove={true}>
                  <span className='block border-rounder avatar relative'>
                    <img
                      src={`${persona?.image || '/img/avatar.png'}`}
                      alt='logo'
                      className='border-rounder avatar'
                    />
                    <span title='change profile' className='editOverlay'>
                      Edit
                    </span>
                  </span>
                </Upload>
              </div>
            </div>
            <div className='relative ml-15 w-100-calc'>
              <div className='flex tab-options'>
                <p
                  onClick={() => setStep(1)}
                  className={`tab-desactive ${step == 1 && 'modal-dont-delete'
                    } pointer border-r-15`}>
                  Overview
                </p>
                <p
                  onClick={() => setStep(2)}
                  className={`tab-desactive ${step == 2 && 'modal-dont-delete'
                    } ml-10 pointer border-r-15`}>
                  Persona
                </p>
                {projects?.map((project, index) => (
                  <p
                    onClick={() => {
                      setStep(index + 3);
                      setMissionId(project.id);
                    }}
                    className={`tab-desactive ${step == index + 3 && 'modal-dont-delete'
                      } ml-10 pointer border-r-15`}>
                    {project.name}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <hr />
          {step == 1 && (
            <Row>
              <Col span={24} className='grid m-auto'>
                <p className='text-black text-lg'>
                  <b> Name</b> <span className='text-red'>*</span>
                </p>
                <input
                  type='text'
                  className={`input-class b-color-gray ${errors.name ? 'border-error' : ''
                    } w-100 mb-10`}
                  placeholder='Name'
                  {...register('name', { required: true })}
                  aria-invalid={errors.name ? 'true' : 'false'}
                />
                <p className='text-black text-lg'>
                  <b>Role</b> <span className='text-red'>*</span>
                </p>
                <Cascader
                  placeholder='Select Role Responsible'
                  options={roles}
                  className={`input-class b-color-gray ${errors.role ? 'border-error' : ''
                    } w-100 mb-10`}
                  expandTrigger='hover'
                  showSearch={{
                    filter,
                  }}
                  placement='bottomLeft'
                  dropdownRender={dropdownRender}
                  displayRender={displayRender}
                  value={defaultRole}
                  onChange={(e) => {
                    const clearRole = () => {
                      setValue('role', '');
                      setDefaultRole([]);
                    };
                    if (e === undefined) return clearRole();
                    if (e.length < 2) return clearRole();
                    setValue('role', e[1]);
                    setDefaultRole([e[1] || '']);
                  }}
                />
                <input
                  type='hidden'
                  {...register('role', { required: true })}
                  aria-invalid={errors.role ? 'true' : 'false'}
                />
                <p className='text-black text-lg mt-10'>
                  <b>Email</b>
                </p>
                <InputDebounce
                  name='email'
                  placeholder='Email'
                  control={control}
                  debounceAction={debounceActive}
                  isEmail={true}
                  className='b-color-gray w-100 mb-10'
                />
                <p className='text-black text-lg d-none'>
                  <b>Invite User to Platform?</b>{' '}
                </p>
                <div className='flex items-center mb-10 d-none'>
                  <Controller
                    name={`send_email`}
                    control={control}
                    render={({ field }) => (
                      <Radio.Group
                        onChange={(e) => field.onChange(e)}
                        value={field.value}
                        disabled={userId}>
                        <Radio.Button value='yes'>Yes</Radio.Button>
                        <Radio.Button value='no'>No</Radio.Button>
                      </Radio.Group>
                    )}
                  />
                  <p
                    className={`text-black text-xs ml-10 warning-color p-5 ${userId ? 'block' : 'd-none'
                      }`}>
                    This email is already registered with the platform
                  </p>
                </div>
                <p className='text-black text-lg'>
                  <b>Archetype</b>
                </p>
                <Controller
                  name='archetype'
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className='input-class p-5 w-100 mt-10 b-color-gray'
                      placeholder='Select an archetype'
                      optionFilterProp='children'
                      bordered={false}
                      onChange={(net) => {
                        field.onChange(net);
                        const selectedArchetype = archetypes.find(
                          (item) => item.value == net
                        );
                        setArchetypeDescription(selectedArchetype?.description);
                        setArchetype(selectedArchetype?.label);
                      }}
                      options={archetypes}
                    />
                  )}
                />
                {archetypeDescription !== '' && (
                  <Alert
                    className='mt-10'
                    message={`${archetype} description`}
                    description={
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: archetypeDescription,
                          }}></div>
                      </>
                    }
                    type='info'
                  />
                )}
                <div className={`mt-15`}>
                  <p className='text-black text-lg'>
                    <b>How are you going to be engaging with this person?</b>
                  </p>
                  <textarea
                    className='textarea-member b-color-gray'
                    {...register('behavior')}
                  />
                </div>
              </Col>
            </Row>
          )}
          {step == 2 && (
            <Row gutter={10}>
              {membersKey.map(({ label, show, key }, index) => (
                <Col span={8} key={index}>
                  <p className='text-black mt-10'>{label}</p>
                  <textarea
                    className='textarea-member b-color-gray'
                    {...register(key)}
                  />
                </Col>
              ))}
            </Row>
          )}
          {step >= 3 && (
            <ProjectTab missionId={missionId} personaId={memberId} />
          )}
          <div className='flex justify-center my-15'>
            <button
              type='button'
              className='modal-delete-btn b-rounded-10'
              onClick={closeModal}>
              CANCEL
            </button>
            <button
              type='submit'
              className='modal-dont-delete ml-10 b-rounded-10'>
              SAVE
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default MemberData;
