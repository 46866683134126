/* eslint-disable */
import React, {useState} from 'react';
import {Alert, Input} from 'antd';
const characterLimit = 500;
const defaultHeight = 5;
const TextArea = (props) => {
  const [isLimitexceeded, setIsLimitexceeded] = useState(false);

  const {TextArea} = Input;
  const onChange = (eve) => {
    let limit = props.maxLength || characterLimit;
    if (eve.target.value.length >= limit) setIsLimitexceeded(true);
    else {
      setIsLimitexceeded(false);
    }
    props.handleChange &&
      props.handleChange({
        value: eve.target.value,
        name: eve.target.name,
        index: props.index,
        formObject: props.formObject,
      });
  };
  return (
    <>
      <TextArea
        as='textarea'
        onBlur={props.onBlur}
        rows={props.rows || defaultHeight}
        onChange={(e)=>{props.onChange!==undefined?props.onChange(e):onChange(e)}}
        disabled={props.disabled}
        placeholder={props.placeHolder || props.placeholder}
        value={props.value}
        // type={props.htmlType}
        // maxlength={props.maxLength || characterLimit}
        name={props.name}
        // onKeyUp={props.onKeyUp}
        className={`${props.className || 'textarea-item'} ${
          !props.isValid ? ' error-field' : ''
        }`}
      />
      {/* {isLimitexceeded && (
        <Alert
          message={`Character limit (maximum : ${
            props.maxLength || characterLimit
          }) reached!`}
          banner
        />
      )} */}
    </>
  );
};

export default TextArea;

TextArea.defaultProps = {
  onKeyUp: () => {},
};
