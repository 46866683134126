export function Default() {
  return {
    updateProfile,
    changePasswordApi: {
      loading: false,
    },
    changePasswordFormConfig: [
      {
        placeholder: 'Enter your current password',
        key: 'old_password',
        label: 'Current Password',
        value: '',
        error: '',
        notes: ''
      },
      {
        label: 'New Password',
        placeholder: 'Enter your new password',
        key: 'password',
        value: '',
        error: '',
        notes: 'The password must have at least an uppercase letter, a lowercase letter, a digit & a special character.'
      },
      {
        label: 'Confirm Password',
        placeholder: 'Confirm your new password',
        key: 'confirm_password',
        value: '',
        error: '',
        notes: ''
      },
    ],
  };
}
let updateProfile = {
  loading: false,
  isSuccess: false,
  isFailed: false,
  data: [],
};
