import React from 'react';
import {Modal} from 'antd';

import { useTranslation } from "react-i18next"

type ModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
};

export const ContinueWithoutSavingModal: React.FC<ModalProps> = ({
  isOpen,
  onCancel,
  onOk,
}) => {

  // i18n translation hook
  const [t] = useTranslation()

  return (
    <Modal
      wrapClassName='rounded-2xl'
      open={isOpen}
      onCancel={onCancel}
      footer={false}
      width={550}
      // maskStyle={{backdropFilter: 'blur(1px)'}}
    >
      <div className='grid gap-4 mt-4 px-8'>
        <h1 className='text-4xl leading-tight'>{t("Continue_Without_Saving.Continue")}?</h1>
        <div>
          <span className='text-base font-medium'>
            {t("Continue_Without_Saving.Continue_disclaimer")}.
          </span>
        </div>
        <div className='flex justify-end pt-5 gap-2'>
          <button
            type='button'
            onClick={onOk}
            className='px-4 py-2 text-base font-medium self-center'>
            {t("Button.Continue")}
          </button>
          <button
            type='button'
            onClick={onCancel}
            className={`px-4 py-2 text-base font-medium bg-primary text-white rounded 
              hover:bg-primary-hover`}>
            {t("Button.Cancel")}
          </button>
        </div>
      </div>
    </Modal>
  );
};
