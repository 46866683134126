import { Modal } from 'antd';
import React, { Component } from 'react';
import { ButtonElement } from '../../../../Components/ButtonElement';
import FormBlock from '../../../../Components/FormBlock';
import ModalWindow from '../../../../Components/ModalWindow';
import { GetLocalStorage } from '../../../../utils/localStorageOperations';
import "./modal.scss";

const CloseIconImg = () => {
  return <div className='modal-close-icon'>x</div>
}

export default class ResetPassword extends Component {

  state = {
    newPassword: '',
    confirmPassword: '',
    errorMessage: null,
    uid: '',
    token: ''
  }
  constructor(props) {
    super(props);
  }

  setFormValue(field, value) {
    this.setState({ [field]: value });
    if (this.state.errorMessage)
      this.setState({
        errorMessage: null,
      });
  }

  onSubmit = () => {
    let { search } = this.props.location;
    let uid = this.props.match.params.uid;
    let token = this.props.match.params.token;
    let apiBody = {
      password: this.state.newPassword,
      confirm_password: this.state.confirmPassword,
      uid: uid,
      token: token,
    };
    this.props.resetPasswordAction(apiBody, () => {
      this.redirectToLogin();
      this.props.onCancel()
    });
    // }
  };

  redirectToLogin = () => {
    this.props.history.push(`${localStorage.getItem('clientName')}/login`);
  };


  renderResetPasswordForm = () => {
    return <ul className='password-list'>
      <li className='modal-form-input'>
        <FormBlock
          label={'New Password'}
          elementType='password'
          placeholder='Enter your new password'
          onChange={(event) => this.setFormValue('newPassword', event.target.value)}
          maxLength={500}
          error={this.props.password.errorMessage}
        />
      </li>
      <p className='modal-form-text'>The password must have at least an uppercase letter, a lowercase letter, a digit & a special character.</p>
      <li className='modal-form-input'>
        <FormBlock
          label={'Confirm Password'}
          elementType='password'
          placeholder='Confirm your new password'
          onChange={(event) =>
            this.setFormValue('confirmPassword', event.target.value)
          }
          maxLength={500}
          error={this.props.confirm_password.errorMessage}

        />
      </li>
      <li>
        <ButtonElement type='primary' className='modal-form-button' children='Save Password'
          loading={this.props.api.isProcessing}
          onClick={this.onSubmit} /></li>
    </ul>;
  }
  render() {
    return (
      <Modal
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        footer={false}
        bodyStyle={{
          width: '100%',
          padding: 0
        }}
        centered
        closeIcon={<CloseIconImg />}>
        <div>
          <div className='modal-title'>
            <img className='modal-image-header' src={GetLocalStorage('logo') || '/img/blueagilis-whitelogo.png'} />
          </div>
          <div className='modal-form'>
            <h1><b>Reset Password</b></h1>
            <p>Please choose a new password</p>
            {this.renderResetPasswordForm()}
          </div>
        </div>
      </Modal>
    );
  }
}

