export function updateFormConfigErrors(state, {errorFields = {}}) {
  const {changePasswordFormConfig: config} = state;
  config.map((item) => {
    if (errorFields[item.key]) item.error = errorFields[item.key];
    else item.error = '';
  });
  return {
    ...state,
    changePasswordFormConfig: [...config],
  };
}

export function updateChangePassword(state, payload) {
  //updateFormConfigErrors(state);
  const {changePasswordFormConfig} = state;
  changePasswordFormConfig[payload.index].value = payload.value;
  changePasswordFormConfig[payload.index].error = '';
  return {
    ...state,
    changePasswordFormConfig: [...changePasswordFormConfig],
  };
}

export function changePasswordStarted(state) {
  return {
    ...state,
    changePasswordApi: {loading: true},
  };
}
export function changePasswordFailed(state) {
  return {
    ...state,
    changePasswordApi: {loading: false},
  };
}
export function changePasswordSuccess(state) {
    const {changePasswordFormConfig: config} = state;
    config.map((item) => {
      item.value = '';
    });
  return {
    ...state,
    changePasswordApi: {loading: false},
    changePasswordFormConfig: [...config],
  };
}

export function updateUserProfileStart(state) {
  return {
    ...state,
    updateProfile: {
      loading: true,
      isSuccess: false,
      isFailed: false,
      data: [],
    },
  };
}
export function updateUserProfileSuccess(state, payload) {
  return {
    ...state,
    updateProfile: {
      loading: false,
      isSuccess: true,
      isFailed: false,
      data: payload,
    },
  };
}
export function updateUserProfileFailed(state, payload) {
  return {
    ...state,
    updateProfile: {
      loading: false,
      isSuccess: false,
      isFailed: true,
      data: payload,
    },
  };
}
