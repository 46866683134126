import {
  ADD_EMPLOYEE_FAILED,
  ADD_EMPLOYEE_START,
  ADD_EMPLOYEE_SUCCESS,
  ADD_INSIGHTS_FORM_CHANGES,
  GET_DEPARTMENTS_FAILED,
  GET_DEPARTMENTS_START,
  GET_DEPARTMENTS_SUCCESS,
  GET_DESIGNATIONS_FAILED,
  GET_DESIGNATIONS_START,
  GET_DESIGNATIONS_SUCCESS,
  GET_EMPLOYEE_LIST_FAILED,
  GET_EMPLOYEE_LIST_START,
  GET_EMPLOYEE_LIST_SUCCESS,
  RESET_EMPLOYEE_FORM,
} from '../../Redux.constants';
import {
    addEmployeesFailed,
    addEmployeesStarted,
    addEmployeesSuccess,
    getDepartmentsFailed,
    getDepartmentsStarted,
    getDepartmentsSuccess,
    getDesignationsFailed,
    getDesignationsStarted,
    getDesignationsSuccess,
    getEmployeesFailed,
    getEmployeesStarted,
    getEmployeesSuccess,
    setAddInsightsFormValue,
    resetAddEmployeesForm
} from './Helper';
import { INITIAL_STATE } from './initialState';

function ViewEmployeesOperations(state, action) {
    switch (action.type) {
        case GET_EMPLOYEE_LIST_START:
            return getEmployeesStarted(state);
        case GET_EMPLOYEE_LIST_SUCCESS:
            return getEmployeesSuccess(state, action.payload);
        case GET_EMPLOYEE_LIST_FAILED:
            return getEmployeesFailed(state, action.payload);
        default:
            return { ...state };
    }
}

function addInsightsOperations(state, action) {
    switch (action.type) {
        case ADD_INSIGHTS_FORM_CHANGES:
            return setAddInsightsFormValue(state, action);
        case ADD_EMPLOYEE_START:
            return addEmployeesStarted(state);
        case RESET_EMPLOYEE_FORM:
            return resetAddEmployeesForm(state);
        case ADD_EMPLOYEE_SUCCESS:
            return addEmployeesSuccess(state, action.payload);
        case ADD_EMPLOYEE_FAILED:
            return addEmployeesFailed(state, action.payload);
        default:
            return ViewEmployeesOperations(state, action);
    }
}

export default function EmployeeReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_DEPARTMENTS_START:
            return getDepartmentsStarted(state, action.payload);
        case GET_DEPARTMENTS_SUCCESS:
            return getDepartmentsSuccess(state, action.payload);
        case GET_DEPARTMENTS_FAILED:
            return getDepartmentsFailed(state, action.payload);
        case GET_DESIGNATIONS_START:
            return getDesignationsStarted(state, action.payload);
        case GET_DESIGNATIONS_SUCCESS:
            return getDesignationsSuccess(state, action.payload);
        case GET_DESIGNATIONS_FAILED:
            return getDesignationsFailed(state, action.payload);
        default:
            return addInsightsOperations(state, action);
    }
}
