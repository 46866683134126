import React, { useState } from 'react';
import './Constraints.scss';
import { useEffect } from 'react';
import {
  getConstraintsCatalog,
  getMissionConstraints,
  postMissionConstraints,
  patchMissionConstraints,
} from '../../../../../../../../Services/Constraints.service';
import { Col, Row, Popover } from 'antd';
import { useSelector } from 'react-redux';

/**
 * Initializes the Constraints component.
 *
 * @return {void}
 */
const Constraints = () => {
  const [constraintsData, setContraintsData] = useState([]);
  const [missionContraintsData, setMissionContraintsData] = useState([]);

  const mission = useSelector((state) => {
    return state.mission.value.id;
  });

  const loadCatalog = async () => {
    try {
      const res = await getConstraintsCatalog().call;
      setContraintsData((prev) => res?.data);
      missionContraints(res?.data);
    } catch (error) { }
  };

  /**
   * A function that handles mission constraints.
   *
   * @param {Array} constraintsArray - An array of constraints.
   * @return {Promise} A promise that resolves when the function completes.
   */
  const missionContraints = async (constraintsArray) => {
    try {
      const res = await getMissionConstraints(mission).call;
      if (res.data.length) {
        setMissionContraintsData(createMissionConstraintsObj(res?.data));
      } else {
        const resMissionContraints = await postMissionConstraints(
          constraintsArray.map((item) => ({
            mission,
            constraint: item?.id,
            checkbox: false,
          }))
        ).call;
        setMissionContraintsData(
          createMissionConstraintsObj(resMissionContraints?.data)
        );
      }
    } catch (error) { }
  };

  /**
   * Creates a mission constraints object based on the given data array.
   *
   * @param {Array} data - The data array containing the constraints.
   * @return {Object} - The constraints object.
   */
  const createMissionConstraintsObj = (data) => {
    const constaintsObj = {};
    data.forEach((item, index) => {
      constaintsObj[item.constraint?.id] = item;
    });
    return constaintsObj;
  };

  /**
   * Update mission constraints.
   *
   * @param {boolean} checkbox - The checkbox value.
   * @param {string} id - The mission ID.
   * @return {Promise<void>} A promise that resolves when the update is complete.
   */
  const updateMissionContraints = async (checkbox, id) => {
    try {
      const res = await patchMissionConstraints({ id, checkbox }).call;
      setMissionContraintsData({
        ...missionContraintsData,
        [res.data?.constraint?.id]: {
          ...missionContraintsData[res.data?.constraint?.id],
          checkbox,
        },
      });
    } catch (error) {
    }
  };

  useEffect(() => {
    loadCatalog();
  }, []);

  return (
    <div className='Constraints'>
      <p className='text-black'>What constraints does the project have?</p>
      <p className='flex items-center'>
        Hover in the info icon (<span className='hoverIcon' />) to see more
        information.
      </p>
      <Row gutter={50}>
        {constraintsData?.map((constraint, index) => (
          <Col span={12} key={index}>
            <div className='flex items-center mt-10'>
              <input
                type='checkbox'
                checked={missionContraintsData[constraint?.id]?.checkbox}
                onChange={(e) =>
                  updateMissionContraints(
                    e.target.checked,
                    missionContraintsData[constraint?.id]?.id
                  )
                }
              />
              <span className='ml-5'>{constraint?.name}</span>
              <Popover
                content={constraint?.description}
                style={{ width: '110px' }}>
                <span className='hoverIcon ml-5' />
              </Popover>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Constraints;
