export function departmentData(state, payload) {
  return {
    ...state,
    departmentdata: {
      ispending: true,
      completed: false,
      failed: false,
      data: [],
    },
  };
}
export function departmentDataSuccess(state, payload) {
  return {
    ...state,
    departmentdata: {
      ispending: false,
      completed: true,
      failed: false,
      data: payload.department.results,
    },
  };
}
export function departmentDataFailed(state, payload) {
  return {
    ...state,
    departmentdata: {
      ispending: false,
      completed: false,
      failed: true,
      data: [],
    },
  };
}

export function createOrganisationData(state, payload) {
  return {
    ...state,
    organisationdata: {
      ispending: true,
      completed: false,
      failed: false,
      data: [],
    },
    isProcessing: true,
  };
}
export function createOrganisationDataSuccess(state, payload) {
  return {
    ...state,
    organisationdata: {
      ispending: false,
      completed: true,
      failed: false,
      data: payload.organisation,
    },
    isProcessing: false,
  };
}
export function createOrganisationDataFailed(state, payload) {
  let errorMessage = { nameError: '', websiteError: '' };
  if (payload.createOrgnisationError) {
    payload.createOrgnisationError.map((item) => {
      if (item === 'Organization already exists') {
        errorMessage.nameError = 'Network title already exists';
      }
      if (item === 'Organization with same website already exists') {
        errorMessage.websiteError = 'Network with same website already exists';
      }
    });
  }
  return {
    ...state,
    organisationdata: {
      ispending: false,
      completed: false,
      failed: true,
      data: [],
    },
    isProcessing: false,
    // name: {name: 'name', errorMessage: errorMessage.nameError},
    // website: {name: 'website', errorMessage: errorMessage.websiteError},
  };
}
export function createOrganisationValidationErrors(state, payload) {
  const newState = { ...state };
  newState.loading = false;
  for (let item of payload) {
    newState[item.path] = {
      ...newState[item.path],
      errorMessage: item.message,
    };
  }
  return {
    ...state,
    ...newState,
  };
}
export function updateErrorMessage(state, payload) {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      name: payload.name,
      errorMessage: '',
    },
    isProcessing: false,
  };
}

export function userListStart(state, payload) {
  return {
    ...state,
    userList: {
      ispending: true,
      completed: false,
      failed: false,
      data: [],
    },
  };
}

export function userListSuccess(state, payload) {
  return {
    ...state,
    userList: {
      ispending: false,
      completed: true,
      failed: false,
      data: payload
    },
  };
}
export function userListFailed(state, payload) {
  return {
    ...state,
    userList: {
      ispending: false,
      completed: false,
      failed: true,
      data: [],
    },
  };
}
