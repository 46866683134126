import React, { useEffect, useState } from 'react';
import { Col, Modal, Radio, Row, Select, notification } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { getMissions } from '../../../../Services/Mission.service';
import { getUsersPatDetail } from '../../../../Services/UserPat.service';
import './styles/CreateSprint.scss';
import { postSprints } from '../../../../Services/Sprints.service';
import { useSelector } from 'react-redux';

import { useTranslation } from "react-i18next"

/**
 * Creates a new sprint.
 *
 * @param {object} visible - Determines whether the sprint creation modal is visible.
 * @param {function} cancel - Callback function to cancel sprint creation.
 * @param {string} tactic - The name of the tactic.
 * @return {JSX.Element} The sprint creation modal component.
 */
const CreateSprint = ({ visible, cancel, tactic }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
    watch,
    getValues,
  } = useForm({ defaultValues: { clinical_intervention: false } });

  const [allMission, setAllMission] = useState([]);
  const id = useSelector((state) => {
    return state.pat.value;
  });

  const [t] = useTranslation()


  /**
   * Saves a sprint.
   *
   * @param {Object} params - The parameters for saving the sprint.
   * @return {Promise} A promise that resolves when the sprint is saved.
   */
  const saveSprint = async (params) => {
    try {
      await postSprints(params).call;
      cancel();
      notification.success({
        message: 'Success',
        description: `The ${t('commons.intervention').toLowerCase()} has been created successfully`,
      });
    } catch (error) {
      notification.error({
        message: 'Attention',
        description:
          `An error occurred while creating the ${t('commons.intervention').toLowerCase()}, please try again later`,
      });
    }
  };

  const loadMissions = async () => {
    try {
      const res = await getUsersPatDetail(id).call;
      const resMission = await getMissions(res.data.organization).call;
      setAllMission(
        resMission.data.results.map((item) => ({
          value: item?.id,
          label: item?.name,
        }))
      );
    } catch (error) { }
  };

  useEffect(() => {
    loadMissions();
  }, []);

  useEffect(() => {
    setValue('name', tactic);
  }, [visible]);

  return (
    <Modal
      title={`New ${t('commons.intervention')}`}
      visible={visible}
      footer={false}
      onCancel={cancel}
      className='assesmenttool'
      centered>
      <div className='createsprint my-10'>
        <form onSubmit={handleSubmit(saveSprint)}>
          <Row gutter={10}>
            <Col span={24} className='mb-10'>
              <p>Project Assigned</p>
              <Controller
                name='mission'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={`select-class w-100 ${errors.mission ? 'border-error' : ''
                      }`}
                    showSearch
                    placeholder='Select a Project'
                    bordered={false}
                    onChange={(net) => {
                      field.onChange(net);
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={allMission}
                  />
                )}
              />
            </Col>
            <Col span={24} className='mb-10'>
              <p>Name of {t('commons.intervention')}</p>
              <input
                type='text'
                className='input-class'
                {...register('name')}
              />
            </Col>
            <Col span={24} className='mb-10'>
              <p className='my-15'>
                Are you implementing a clinical intervention?
              </p>
              <Controller
                name='clinical_intervention'
                control={control}
                render={({ field }) => (
                  <Radio.Group {...field}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                )}
              />
            </Col>
            <Col span={24} className='text-center mt-15'>
              <button
                type='button'
                className='modal-delete-btn'
                onClick={() => cancel()}>
                Cancel
              </button>
              <button type='submit' className='modal-dont-delete ml-10'>
                Save
              </button>
            </Col>
          </Row>
        </form>
      </div>
    </Modal>
  );
};

export default CreateSprint;
