import React from 'react';
import { Row, Col } from 'antd';
import { LeftBar } from './LeftBar';
import { TopBar } from './TopBar';
import style from '../style.module.scss';
import Container from '../../../Components/Container';

export function ProfileLayout(props) {
  return (
    <>
      <Row>
        <Col span={24}>
          <LeftBar
            changeProfileData={props.changeProfileData}
            userDetails={props.userDetails}
            activeTab={props.activeTab}
            setActiveTab={props.setActiveTab}
            imageUrl={props.imageUrl}
            getPresignedUrl={props.getPresignedUrl}
            integrateApi={props.integrateApi}
            imageApi={props.imageApi}
            updateProfileApi={props.updateProfileApi}
          />
        </Col>
      </Row>
      <Container>
        <Row justify="center">
          <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
            <div className={style.settingsRight}>
              <TopBar
                activeTab={props.activeTab}
                setActiveTab={props.setActiveTab}
              />
              {props.children}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
