import React from "react"
import { Input, Slider } from "antd"

import { FilesTable } from "../../../Routes/MeetingsComments/components/FilesTable"
import { marks } from "./marks"

import { useTranslation } from "react-i18next"

/**
 * Renders the feedback step 4 component based on the selected step 1 value.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.step1Value - The value of step 1.
 * @param {number} props.step4BugValue - The value of step 4 bug.
 * @param {function} props.setStep4BugValue - The function to set the value of step 4 bug.
 * @param {number} props.step4FeatureValue - The value of step 4 feature.
 * @param {function} props.setStep4FeatureValue - The function to set the value of step 4 feature.
 * @param {string} props.step4RequestValue - The value of step 4 request.
 * @param {function} props.setStep4RequestValue - The function to set the value of step 4 request.
 * @return {ReactNode} The rendered feedback step 4 component.
 */
const FeedbackStep4 = ({
  step1Value,
  step4BugValue,
  setStep4BugValue,
  step4FeatureValue,
  setStep4FeatureValue,
  step4RequestValue,
  setStep4RequestValue,
}) => {

  const [t] = useTranslation()

  return (
    <>
      {step1Value === "bug" && (
        <>
          <h2 className="mb-10">{t('Feedback_Modal.Step4.Bug_header')}</h2>
          <p className="mb-10">{t('Feedback_Modal.Step4.Bug_message')}</p>
          <Slider marks={marks} defaultValue={5} min={1} max={5} step={1}
            value={step4BugValue}
            onChange={(value) => setStep4BugValue(value)}
          />
        </>
      )}
      {step1Value === "feature" && (
        <>
          <h2 className="mb-10">{t('Feedback_Modal.Step4.Feature_header')}</h2>
          <p className="mb-10">{t('Feedback_Modal.Step4.Feature_message')}</p>
          <FilesTable
            id={0}
            field=""
            urlFiles="/"
            postUrlFile="/"
            getSufix=""
            asDrop={true}
            multiple={false}
            dragDropTitle="Click or drag file to this area to upload here"
            getFileCallback={(fileUploaded) =>
              setStep4FeatureValue(fileUploaded)}
          />
          {step4FeatureValue?.name || ""}
        </>
      )}
      {step1Value === "request" && (
        <>
          <h2 className="mb-10">{t('Feedback_Modal.Step4.Request_header')}</h2>
          <p className="mb-10">{t('Feedback_Modal.Step4.Request_message')}</p>
          <Input allowClear required size="small" value={step4RequestValue} onChange={(e) => setStep4RequestValue(e.target.value)} />
        </>
      )}
    </>
  )
}

export default FeedbackStep4