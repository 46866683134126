import { Col, Radio, Row } from "antd"
import React, { useEffect, useState } from "react"

import { getMissionEngaged } from "../../../../../../Services/MissionTeams.service"

/**
 * Renders a project tab component.
 *
 * @param {Object} missionId - The ID of the mission.
 * @param {Object} personaId - The ID of the persona.
 * @return {JSX.Element} The rendered project tab component.
 */
const ProjectTab = ({ missionId, personaId }) => {
  const [mission, setMission] = useState({})
  const [watchIsProblemField, setWatchIsProblemField] = useState('')

  const loadMission = async () => {
    try {
      const res = await getMissionEngaged(missionId).call
      const _mission = res?.data?.results?.filter((mission) => mission?.persona?.id === personaId)[0]
      setMission(_mission)
      setWatchIsProblemField(_mission?.is_problem == true ? 'yes' : 'no')
    } catch (error) {
    }
  }

  useEffect(() => {
    loadMission()
  }, [missionId, personaId])

  return <div className="ProjectTab">
    <form>
      <Row gutter={10}>
        <Col span={24} className='my-10'>
          <p className='mr-5'>
            Does the user think this issue is a problem for them?
          </p>
          <Radio.Group readOnly
            value={watchIsProblemField}>
            <Radio.Button value='yes'>Yes</Radio.Button>
            <Radio.Button value='no'>No</Radio.Button>
          </Radio.Group>
        </Col>
        <Col
          span={24}
          className={`mb-10 ${watchIsProblemField == 'yes' ? 'block' : 'hidden'
            }`}>
          <p className='my-15'>
            How serious do they think the problem is?
          </p>
          <Radio.Group className='flex' readOnly
            value={mission?.how_serious?.toString()}>
            <Radio value='1' className='grid'>
              1
            </Radio>
            <Radio value='2' className='grid'>
              2
            </Radio>
            <Radio value='3' className='grid'>
              3
            </Radio>
            <Radio value='4' className='grid'>
              4
            </Radio>
            <Radio value='5' className='grid'>
              5
            </Radio>
          </Radio.Group>
        </Col>
        <Col
          span={24}
          className={`mb-10 ${watchIsProblemField == 'yes' ? 'block' : 'hidden'
            }`}>
          <p>Why do they think this?</p>
          <textarea className='textarea' value={mission?.why?.toString()} readOnly />
        </Col>
        <Col
          span={24}
          className={`mb-10 ${watchIsProblemField == 'yes' ? 'block' : 'hidden'
            }`}>
          <p className='my-15'>
            How difficult do they think it will be to solve this problem?
          </p>
          <Radio.Group className='flex' readOnly
            value={mission?.difficulty?.toString()}>
            <Radio value='1' className='grid'>
              1
            </Radio>
            <Radio value='2' className='grid'>
              2
            </Radio>
            <Radio value='3' className='grid'>
              3
            </Radio>
            <Radio value='4' className='grid'>
              4
            </Radio>
            <Radio value='5' className='grid'>
              5
            </Radio>
          </Radio.Group>
        </Col>
        <Col
          span={24}
          className={`mb-10 ${watchIsProblemField == 'yes' ? 'block' : 'hidden'
            }`}>
          <p>Why do they think this?</p>
          <textarea className='textarea' value={mission?.difficulty_reason?.toString()} readOnly />
        </Col>
        <Col span={24} className={`mb-10`}>
          <p className='mb-15'>Are they the right influencer?</p>
          <Radio.Group readOnly
            value={mission?.right_influencer?.toString()}>
            <Radio.Button value='true'>Yes</Radio.Button>
            <Radio.Button value='false'>No</Radio.Button>
          </Radio.Group>
        </Col>
        <Col span={24} className={`mb-10`}>
          <p className='my-15'>Demand for Problem Score</p>
          <Radio.Group className='flex' readOnly
            value={mission?.demand_prob_score?.toString()}>
            <Radio value='1' className='grid'>
              1
            </Radio>
            <Radio value='2' className='grid'>
              2
            </Radio>
            <Radio value='3' className='grid'>
              3
            </Radio>
            <Radio value='4' className='grid'>
              4
            </Radio>
            <Radio value='5' className='grid'>
              5
            </Radio>
            <Radio value='6' className='grid'>
              6
            </Radio>
            <Radio value='7' className='grid'>
              7
            </Radio>
            <Radio value='8' className='grid'>
              8
            </Radio>
            <Radio value='9' className='grid'>
              9
            </Radio>
            <Radio value='10' className='grid'>
              10
            </Radio>
          </Radio.Group>
        </Col>
        <Col span={24} className={`mb-10`}>
          <p className='my-15'>Trust from Other Score</p>
          <Radio.Group className='flex' readOnly
            value={mission?.trust_other_score?.toString()}>
            <Radio value='1' className='grid'>
              1
            </Radio>
            <Radio value='2' className='grid'>
              2
            </Radio>
            <Radio value='3' className='grid'>
              3
            </Radio>
            <Radio value='4' className='grid'>
              4
            </Radio>
            <Radio value='5' className='grid'>
              5
            </Radio>
            <Radio value='6' className='grid'>
              6
            </Radio>
            <Radio value='7' className='grid'>
              7
            </Radio>
            <Radio value='8' className='grid'>
              8
            </Radio>
            <Radio value='9' className='grid'>
              9
            </Radio>
            <Radio value='10' className='grid'>
              10
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
    </form>
  </div>
}

export default ProjectTab
