import React from 'react'
import { Collapse, Button } from 'antd'
import { RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons'

const { Panel } = Collapse

/**
 * Renders the secondary drivers for an item.
 *
 * @param {Object} props - The props object containing the following properties:
 *   @param {Object} item - The item object.
 *   @param {number} index - The index of the item.
 *   @param {function} getSmartSprint - The function to get the smart sprint.
 *   @param {boolean} isSideOpen - Indicates whether the side is open.
 * @return {JSX.Element} The JSX element representing the secondary drivers.
 */
const SecondaryDriver = ({
  item,
  index,
  getSmartSprint,
  isSideOpen,
  openIndex,
  loading,
}) => {
  if (!item.secondary_drivers) return <></>
  return (
    <>
      <Collapse expandIconPosition="end" defaultActiveKey={["0"]} className="secondary" key={`secondary-${index}`}>
        {item.secondary_drivers.map((driver, dindex) =>
          <Panel header={<p className="text-white"><b>Secondary Driver: {driver.name}</b></p>} key={`secondary-driver-${dindex}`}>
            <div className='ml-20 mt-10'>
              {driver.solution &&
                <>
                  <b>Solution:</b>
                  <p>{driver.solution}</p>
                </>}
              {driver.description &&
                <>
                  <b>Description:</b>
                  <p>{driver.description}</p>
                </>}
              {driver.kpis_or_okrs &&
                <>
                  <b>Key performance indicatiors or OKRs:</b>
                  <p>{driver.kpis_or_okrs}</p>
                </>}
              {driver.supporting_evidence &&
                <>
                  <b>Supporting evidence:</b>
                  <p>{driver.supporting_evidence}</p>
                </>}
              {item.name && <Button type="primary" className="float-right mt-10"
                onClick={() => getSmartSprint && getSmartSprint(dindex)}
                disabled={loading}
              >
                {(isSideOpen && openIndex === dindex) ? "Hide" : "Show me how"}
                {(isSideOpen && openIndex === dindex) ? <LeftCircleOutlined /> : <RightCircleOutlined />}
              </Button>}
            </div>
          </Panel>
        )}
      </Collapse>
    </>
  )
}

export default SecondaryDriver