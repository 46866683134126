import {
  INPUT_FORM_CHANGE,
  LOGIN_SUCCESS,
  VALIDATION_ERRORS,
  LOGIN_STARTED,
  LOGIN_ERROR,
} from '../../Redux.constants';
import { ApiBase } from '../../../utils/apiBase';
import { getNotification } from '../../../Components/GetNotification';
import { FetchMetadataAction } from '../FetchMetadata';
import * as yup from 'yup';

export function inputFormChange(params) {
  return (dispatch) => {
    dispatch({
      type: INPUT_FORM_CHANGE,
      payload: params,
    });
  };
}

const LOGIN_FORM_SCHEMA = yup.object().shape({
  loginEmail: yup
    .string()
    .email('Login with your registered Email Id (Eg : name@gmail.com)')
    .required('Email is required'),
  loginPassword: yup
    .string()
    .min(8, 'Password is too short, must be atleast 8 charaters long')
    .required('Password is required'),
});
function fetchValidationErrors(input) {
  return new Promise((resolve, reject) => {
    LOGIN_FORM_SCHEMA.validate(input, { abortEarly: false }).catch((error) => {
      let validationList = error.inner.map((item) => {
        return {
          path: item.path,
          message: item.message,
        };
      });
      resolve(validationList);
    });
  });
}

export function login(input, callback) {
  return async (dispatch) => {
    try {
      await LOGIN_FORM_SCHEMA.validate(input);
      await LoginApi({ dispatch, input, callback });
    } catch (error) {
      let loginValidationErrors = await fetchValidationErrors(input);
      dispatch({
        type: VALIDATION_ERRORS,
        payload: loginValidationErrors,
      });
    }
  };
}

async function LoginApi({ dispatch, input, callback }) {
  try {
    const apiBaseInstance = new ApiBase();
    dispatch({ type: LOGIN_STARTED, payload: {} });
    let response = await apiBaseInstance.instance.post('/login/', {
      email: input.loginEmail,
      password: input.loginPassword,
    });
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        loginResponse: response.data,
      },
    });
    dispatch(FetchMetadataAction())
    getNotification('success', {
      header: 'Success',
      body: 'You are logged in successfully',
    });
    callback();
  } catch (error) {
    dispatch({
      type: LOGIN_ERROR,
      payload: error.message,
    });
    getNotification('error', {
      header: 'Sorry, Unable to login',
      body: 'Incorrect email or password',
    });
  }
}
