import axios, { AxiosResponse } from "axios";
import { API_BASE_URL } from "../../config/constants";
import { AbortableRequestConfig } from 'types/Request';
import { PaginatedEthnicity, PaginatedInsuranceType } from "types/Demographic";

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    }
  }
}

export function getIntelDemographic(id: number): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}intel-demographics/?organization=${id}`, {
      signal: controller.signal,
      ...headers()
    } as AbortableRequestConfig),
    controller
  }
}

export function postIntelDemographic(data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}intel-demographics/`, data, headers())
  }
}

export function patchIntelDemographic(data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.patch(`${API_BASE_URL}intel-demographics/${data.id}/`, data, headers())
  }
}

export function getReportDemographic(id: number): {
  call: Promise<AxiosResponse<any>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}reports/?demographics=${id}`, {
      signal: controller.signal,
      ...headers()
    } as AbortableRequestConfig),
    controller
  }
}

export function postReportDemographic(data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}reports/`, data, headers())
  }
}

export function deleteReportDemographic(id: number): {
  call: Promise<AxiosResponse<void>>;
} {
  return {
    call: axios.delete(`${API_BASE_URL}reports/${id}/`, {
      ...headers()
    }),
  }
}

export function getInsuranceList(): {
  call: Promise<AxiosResponse<PaginatedInsuranceType>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}insurance-types/`, {
      signal: controller.signal,
      ...headers()
    } as AbortableRequestConfig),
    controller
  }
}

export function getEthnicitiesList(): {
  call: Promise<AxiosResponse<PaginatedEthnicity>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}ethnicities/`, {
      signal: controller.signal,
      ...headers()
    } as AbortableRequestConfig),
    controller
  }
}