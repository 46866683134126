import React from 'react';
import { DatePicker, Space } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';

const CustomDatePicker = ({onClose, defaultSelectedTimeRange, setSelectedTimeRange}:any) => {
  const [from, to] = defaultSelectedTimeRange?.split(',') ?? [moment().format('YYYY-MM-DDT00:00:00'), moment().format('YYYY-MM-DDT00:00:00')]

  return <Space direction="vertical" size={12}>
    <RangePicker
      open
      defaultValue={[moment(from, dateFormat), moment(to, dateFormat)]}
      format={dateFormat}
      onChange={(date:any) => {
        setSelectedTimeRange(`${date[0].format('YYYY-MM-DDT00:00:00')},${date[1].format('YYYY-MM-DDT00:00:00')}`)
      }}
      onOpenChange={() => {onClose()}}
    />
  </Space>
};

export default CustomDatePicker;