import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Collapse, Row, Select, Radio } from 'antd';
import debounce from 'lodash.debounce';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CheckboxDebounce } from '../../../../../../Components/ChecboxDebounce';
import DateDebounce from '../../../../../../Components/DateDebounce/DateDebounce';
import { getNotification } from '../../../../../../Components/GetNotification';
import InputDebounce from '../../../../../../Components/InputDebounce/InputDebounce';
import TextareaDebounce from '../../../../../../Components/TextareaDebounce/TextareaDebounce';
import {
  deleteMissionMeasure,
  getMissionDetail,
  patchMissionMeasure,
  patchMissionQualitativeItem,
  patchMissions,
  postMissionMeasure,
  postMissionQualitativeItem,
  deleteMissionQualitativeItem,
} from '../../../../../../Services/Mission.service';
import { getMemberList } from '../../../../../../Services/Teams.service';
import './styles/Overview.scss';
import DebounceLoading, {
  dialogStartSubject$,
  dialogStopSubject$,
} from '../../../../../../Components/DebounceLoading/DebounceLoading';
import { AUTOSAVING_DELAY } from '../../../../../../../config/constants';
import { Measures, MissionTeam, Constraints } from './components';
import moment from 'moment';

const { Panel } = Collapse;

/**
 * Renders Overview.
 *
 * @return {JSX.Element} The rendered Overview component.
 */
const Overview = () => {
  const [disable] = useState(false);
  const [otherOption, setOtherOption] = useState(false);
  const [overviewData, setOverviewData] = useState({});
  const [attendees, setAttendees] = useState([]);
  const [measuresData, setMeasuresData] = useState([]);
  const [successData, setSuccessData] = useState([]);
  const [planData, setPlanData] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
    watch,
  } = useForm();

  const refForm = useRef();

  const { id } = useParams();

  const {
    fields: fields_measures,
    append: append_measures,
    remove: remove_measures,
  } = useFieldArray({
    control,
    name: 'measures',
  });

  const {
    fields: fields_success,
    append: append_success,
    remove: remove_success,
  } = useFieldArray({
    control,
    name: 'evaluated_success_qi',
  });

  const {
    fields: fields_plan,
    append: append_plan,
    remove: remove_plan,
  } = useFieldArray({
    control,
    name: 'termination_plan_qi',
  });

  const watchCategory = watch('category');

  const mission = useSelector((state) => {
    return state.mission.value.id;
  });

  /**
   * Saves the overview data.
   *
   * @param {Object} params - The parameters for saving the overview data.
   * @param {Array} params.measures - The measures data.
   * @param {Array} params.evaluated_success_qi - The evaluated success QI data.
   * @param {Array} params.termination_plan_qi - The termination plan QI data.
   * @param {boolean} params.other_problem - The other problem flag.
   * @return {Promise} - A promise that resolves when the overview is successfully saved.
   */
  const saveOverview = async (params) => {
    const measureAuxData = params.measures?.map((measure, index) => {
      if (measuresData[index].hasOwnProperty('id')) {
        return { id: measuresData[index].id, ...measure };
      }

      return measure;
    });
    const successAuxData = params.evaluated_success_qi?.map(
      (measure, index) => {
        if (successData[index].hasOwnProperty('qiId')) {
          return { id: successData[index].id, ...measure };
        }

        return measure;
      }
    );
    const planAuxData = params.termination_plan_qi?.map((measure, index) => {
      if (planData[index].hasOwnProperty('qiId')) {
        return { id: planData[index].id, ...measure };
      }

      return measure;
    });

    dialogStartSubject$.setSubject = true;
    const data = {
      ...params,
    };
    data.other_problem = otherOption ? params.other_problem : null;
    try {
      await patchMissions(data).call;
      getNotification('success', {
        header: 'success',
        body: 'Overview updated successfully',
      });
      const measureReq = await measureAuxData?.map(async (item) => {
        if (item?.id) {
          await patchMissionMeasure({ ...item, mission }).call;
        } else {
          await postMissionMeasure({ ...item, mission }).call;
        }
      });

      await Promise.all(measureReq);
      const qualitativeReq = await [...successAuxData, ...planAuxData].map(
        async (item) => {
          if (item.hasOwnProperty('id')) {
            await patchMissionQualitativeItem(item).call;
          } else {
            await postMissionQualitativeItem(item).call;
          }
        }
      );

      await Promise.all(qualitativeReq);
      loadMeasures();
    } catch (error) {
      console.log(error);
    }
    dialogStopSubject$.setSubject = false;
  };

  const loadMeasures = async () => {
    try {
      const res = await getMissionDetail(mission).call;
      setOverviewData(res.data);
      setMeasuresData(res.data.measures);

      let successDataArray = res?.data?.evaluated_success_qi?.map((item) => ({
        ...item,
        qiId: item.id,
      }));
      setSuccessData(successDataArray);

      let planDataArray = res?.data?.termination_plan_qi?.map((item) => ({
        ...item,
        qiId: item.id,
      }));

      setPlanData(planDataArray);
    } catch (error) { }
  };

  const loadMission = async () => {
    if (mission) {
      try {
        const res = await getMissionDetail(mission).call;
        setOverviewData(res.data);
        setMeasuresData(res.data.measures);
        Object.keys(res?.data).map((key) => {
          if (key == 'call_success_fail') {
            setValue(
              key,
              res?.data[key]?.map((item) => item.id)
            );
            return;
          }
          if (key == 'evaluated_success_qi') {
            let successDataArray = res?.data[key]?.map((item) => ({
              ...item,
              qiId: item.id,
            }));
            setValue(key, successDataArray);
            setSuccessData(successDataArray);
            return;
          }
          if (key == 'termination_plan_qi') {
            let planDataArray = res?.data[key]?.map((item) => ({
              ...item,
              qiId: item.id,
            }));
            setValue(key, planDataArray);
            setPlanData(planDataArray);
          } else {
            setValue(key, res?.data[key]);
          }
        });
        if (res?.data.other_problem) {
          setOtherOption(true);
        }
      } catch (error) { }
    }
  };

  const loadAttendees = async () => {
    try {
      const res = await getMemberList(id).call;
      setAttendees(
        res.data?.results?.map((att) => ({
          value: att.id,
          label: att?.name || att?.email,
        }))
      );
    } catch (error) { }
  };

  const activeSubmitFormGeneral = () => {
    refForm?.current.click();
  };

  const debounceOverview = useCallback(
    debounce(activeSubmitFormGeneral, AUTOSAVING_DELAY),
    []
  );

  /**
   * Delete items based on the provided type and index.
   *
   * @param {string} type - The type of item to delete. Possible values are 'measure', 'success', or 'plan'.
   * @param {number} index - The index of the item to delete.
   * @return {Promise<void>} A Promise that resolves when the items are deleted.
   */
  const deleteItems = async (type, index) => {
    try {
      if (type == 'measure') {
        const id = overviewData?.measures[index]?.id;
        await deleteMissionMeasure(id).call;
        setMeasuresData(measuresData.splice(index, 1));
        remove_measures(index);
      }
      if (type == 'success') {
        const id = overviewData?.evaluated_success_qi[index]?.id;
        await deleteMissionQualitativeItem(id).call;
        setSuccessData(successData.splice(index, 1));
        remove_success(index);
      }
      if (type == 'plan') {
        const id = overviewData?.termination_plan_qi[index]?.id;
        await deleteMissionQualitativeItem(id).call;
        setPlanData(planData.splice(index, 1));
        remove_plan(index);
      }
      loadMeasures();
    } catch (error) { }
  };

  const addNewMeasureData = () => {
    let newMeasureObj = {
      main: false,
      name: '',
      measure_type: 'PERCENTAGE',
      current_state: 0,
      target: 0,
      date: moment(),
      success_threshold: 0,
      failure_threshold: 0,
    };
    append_measures(newMeasureObj);
    setMeasuresData([...measuresData, newMeasureObj]);
  };

  /**
   * Adds qualitative data based on the given type.
   *
   * @param {string} type - The type of data to add.
   * @return {void} This function does not return a value.
   */
  const addQualitativeData = (type) => {
    if (type === 'success') {
      let successObj = {
        mission,
        qualitative_type: 'EVALUATED_SUCCESS',
        name: '',
      };
      append_success(successObj);
      setSuccessData([...successData, successObj]);
    } else {
      let planObj = {
        mission,
        qualitative_type: 'TERMINATION_PLAN',
        name: '',
      };
      append_plan(planObj);
      setPlanData([...planData, planObj]);
    }
  };

  useEffect(() => {
    loadMission();
    loadAttendees();
  }, [mission]);

  return (
    <div className='overview'>
      <form
        onSubmit={handleSubmit(saveOverview)}
        className='mt-15 w-100'
        ref={refForm}>
        <div className='title p-10'>
          <div className='flex justify-beetween items-center font-18'>
            <p className='text-white'>General Information</p>
            <button
              type='submit'
              className='btn-edit d-none'
              ref={(e) => (refForm.current = e)}>
              Save
            </button>
          </div>
        </div>

        <div className={`${disable ? 'pointer-events-none' : ''}`}>
          <Row gutter={10} className='p-5'>
            <Col xs={24} sm={24} md={8} lg={8}>
              <p>Start Date</p>
              <DateDebounce
                name='start'
                control={control}
                debounceAction={debounceOverview}
                className='w-100'
                rules={{
                  required: true,
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <p>End Date</p>
              <DateDebounce
                name='end'
                control={control}
                debounceAction={debounceOverview}
                className='w-100'
                rules={{
                  required: true,
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}></Col>
            <Col span={24}>
              <p className='my-15'>I am working on this problem to improve:</p>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className='flex items-center'>
                <CheckboxDebounce
                  name='quality'
                  control={control}
                  debounceAction={debounceOverview}
                />
                <span className='ml-5'>Quality</span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className='flex items-center'>
                <CheckboxDebounce
                  name='patient_experience'
                  control={control}
                  debounceAction={debounceOverview}
                />
                <span className='ml-5'>Patient Experience</span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className='flex items-center'>
                <CheckboxDebounce
                  name='org_reputation'
                  control={control}
                  debounceAction={debounceOverview}
                />
                <span className='ml-5'>Organizational Reputation</span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className='flex items-center'>
                <CheckboxDebounce
                  name='provider_rev'
                  control={control}
                  debounceAction={debounceOverview}
                />
                <span className='ml-5'>Provider Revenue</span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className='flex items-center'>
                <CheckboxDebounce
                  name='patient_safety'
                  control={control}
                  debounceAction={debounceOverview}
                />
                <span className='ml-5'>Patient Safety</span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className='flex items-center'>
                <CheckboxDebounce
                  name='cost_of_care'
                  control={control}
                  debounceAction={debounceOverview}
                />
                <span className='ml-5'>Cost of Care</span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className='flex items-center'>
                <CheckboxDebounce
                  name='star_rating'
                  control={control}
                  debounceAction={debounceOverview}
                />
                <span className='ml-5'>STAR Rating</span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className='flex items-center'>
                <input
                  type='checkbox'
                  checked={otherOption}
                  onChange={() => {
                    setOtherOption((prev) => !prev);
                    if (getValues('other_problem')) {
                      debounceOverview();
                    }
                  }}
                />
                <span className='ml-5'>Other</span>
                <InputDebounce
                  name='other_problem'
                  control={control}
                  debounceAction={debounceOverview}
                />
              </div>
            </Col>
            <Col span={24}>
              <Constraints />
            </Col>
            <Col span={24} className='my-15'>
              <MissionTeam saved={loadAttendees} />
            </Col>
          </Row>
          <Collapse expandIconPosition='end' defaultActiveKey={['1']}>
            <Panel header='Project Evaluation and Termination Plan' key='1'>
              <Row>
                <Col
                  span={24}
                > 
                  <p>What measure(s) are you trying to improve and by when?</p>
                  <Measures
                    control={control}
                    setValue={setValue}
                    fields_measures={fields_measures}
                    append_measures={addNewMeasureData}
                    remove_measures={deleteItems}
                    measuresData={measuresData}
                  />
                </Col>
                <Col
                  span={24}
                > 
                  <br/>
                  <p>What are you trying to improve?</p>
                  <TextareaDebounce
                    name='improve'
                    control={control}
                    debounceAction={debounceOverview}
                  />
                  <p className='my-15'>What is the current state?</p>
                  <InputDebounce
                    name='current_state'
                    control={control}
                    debounceAction={debounceOverview}
                    className='w-100'
                    placeholder='Enter text'
                  />
                  <p className='flex mt-10'>
                    <b>How will success be evaluated?</b>
                    <div
                      className='borderArrayIcon ml-15'
                      onClick={() => addQualitativeData('success')}>
                      <span>+</span>
                    </div>
                  </p>

                  {fields_success.map((scs, index) => (
                    <div className='w-100 mt-10' key={scs?.id}>
                      <div className='flex'>
                        <InputDebounce
                          name={`evaluated_success_qi[${index}].name`}
                          control={control}
                          debounceAction={debounceOverview}
                          className={`w-100 flex-1`}
                        />
                        <div className='flex items-center ml-5'>
                          <div
                            className='borderArrayIcon'
                            onClick={() => {
                              deleteItems('success', index);
                            }}>
                            <span>-</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <p className='flex mt-10'>
                    <b>What is your termination plan?</b>
                    <div
                      className='borderArrayIcon ml-15'
                      onClick={() => addQualitativeData('plan')}>
                      <span>+</span>
                    </div>
                  </p>

                  {fields_plan.map((tpq, index) => (
                    <div className='w-100 mt-10' key={tpq?.id}>
                      <div className='flex'>
                        <InputDebounce
                          name={`termination_plan_qi[${index}].name`}
                          control={control}
                          debounceAction={debounceOverview}
                          className={`w-100 flex-1`}
                        />
                        <div className='flex items-center ml-5'>
                          <div
                            className='borderArrayIcon'
                            onClick={() => {
                              deleteItems('plan', index);
                            }}>
                            <span>-</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Col>
                <Col span={24} className='mt-15'>
                  <p>Who will make the call on project success or failure?</p>
                  <Controller
                    name='call_success_fail'
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className={`select-class ${errors.type ? 'border-error' : ''
                          }`}
                        mode='multiple'
                        size='default'
                        placeholder='Select attendees'
                        bordered={false}
                        onChange={(net) => {
                          field.onChange(net);
                          debounceOverview();
                        }}
                        options={attendees}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Panel>
            <Panel header='Minimum Viable Story' key='2'>
              <Row>
                <Col span={24} className='my-15'>
                  <p>
                    <b>If this problem does not improve how will the:</b>
                  </p>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <p>Provider suffer?</p>
                  <TextareaDebounce
                    name='provider_suffer'
                    control={control}
                    debounceAction={debounceOverview}
                  />
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <p>Their staff suffer?</p>
                  <TextareaDebounce
                    name='staff_suffer'
                    control={control}
                    debounceAction={debounceOverview}
                  />
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <p>Their patients suffer?</p>
                  <TextareaDebounce
                    name='patients_suffer'
                    control={control}
                    debounceAction={debounceOverview}
                  />
                </Col>
                <Col span={24} className='my-15'>
                  <p>
                    <b>If this problem is solved:</b>
                  </p>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <p>What will the provider gain?</p>
                  <TextareaDebounce
                    name='provider_gain'
                    control={control}
                    debounceAction={debounceOverview}
                  />
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <p>What will the practice gain?</p>
                  <TextareaDebounce
                    name='practice_gain'
                    control={control}
                    debounceAction={debounceOverview}
                  />
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <p>What will the patients gain?</p>
                  <TextareaDebounce
                    name='patients_gain'
                    control={control}
                    debounceAction={debounceOverview}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
      </form>
      <DebounceLoading />
    </div>
  );
};

export default Overview;
