/* eslint-disable */
import {Upload} from 'antd';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonElement} from '../../../Components/ButtonElement';
import FormBlock from '../../../Components/FormBlock';
import ModalWindow from '../../../Components/ModalWindow';
import {Select, Space, Input} from 'antd';
import styles from './style.module.scss';
import {GetDepartments} from '../../../Redux/Actions';
import ImageUpload from '../../../Components/ImageUpload';
import Loader from '../../../Components/Loader';
import {getNotification} from '../../../Components/GetNotification';
import style from '../style.module.scss';
import {CreateGroup} from '../../../Redux/Actions/AddGroupActions';
import {listOrganisationGroupAction} from '../../../Redux/Actions/ListOrganizationGroupAction';
import {UpdateGroupDetails} from '../../../Redux/Actions/UpdateGroupAction';

import { useTranslation } from "react-i18next"

export const AddGroup = (props) => {
  const {TextArea} = Input;
  const [fileList, setFileList] = useState(null);
  const [isSubmited, setIsSubmitted] = useState(false);
  const [data, setdata] = useState({
    name: '',
    discription: '',
  });

  // i18n translation hook
  const [t] = useTranslation()

  useEffect(() => {
    setdata({
      name: props?.groupDetails?.name,
      discription: props?.groupDetails?.discription,
    });
  }, [props.isEditMode]);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setdata({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const submitOrganisationAction = (e) => {
    let formData = new FormData();
    if (fileList) formData.append('image', fileList?.originFileObj);

    formData.append('name', data?.name);
    formData.append('discription', data?.discription);
    props.isEditMode && formData.append('id', props.groupDetails?.id);

    if (props.isEditMode)
      dispatch(
        UpdateGroupDetails(formData, () => {
          dispatch(
            listOrganisationGroupAction({limit: props.limit, offset: 0})
          );
          props.onClose();
        })
      );
    else {
      dispatch(
        CreateGroup(formData, () => {
          dispatch(
            listOrganisationGroupAction({limit: props.limit, offset: 0})
          );
          props.onClose();
        })
      );
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (props.uploadingImage)
      getNotification('info', {
        header: '',
        body: 'Image upload in progress, please wait.',
      });
    else submitOrganisationAction(e);
  };
  useEffect(() => {
    dispatch(GetDepartments());
  }, []);

  const onFileUpload = (fileList) => {
    setFileList(fileList);
  };
  const onChangeUpdateError = (event) => {
    props.updateErrorMessage({name: event.currentTarget.name});
  };
  const onlyNumbers = (evt) => {
    let value = evt.key.match('[a-zA-Z._&^%$#*=/?:;"!~@,s]+');
    if (value && evt.keyCode != 8) {
      evt.preventDefault();
    }
  };

  let {name, image} = props.groupDetails;

  const options = [];
  const [value, setValue] = React.useState(['a10', 'c12', 'h17', 'j19', 'k20']);
  const selectProps = {
    mode: 'multiple',
    style: {width: '100%'},
    value,
    options,
    onChange: (newValue) => {
      setValue(newValue);
    },
    placeholder: 'Select Item...',
    maxTagCount: 'responsive',
  };
  for (let i = 10; i < 36; i++) {
    const value = i.toString(36) + i;
    options.push({
      label: `Long Label: ${value}`,
      value,
    });
  }

  return (
    <div>
      <ModalWindow
        title={`${props.isEditMode ? 'Edit' : 'Add a'} Group`}
        visible={props.showModal}
        className='addnew-window'
        onCancel={props.onClose}
        isNonMaskable={true}
        width={1000}
        footer={false}>
        <form
          onSubmit={(e) => {
            onSubmit(e);
          }}>
          <ul className='blu-vertical-list'>
            <li>
              <div className='split-block'>
                <div className='split-half'>
                  <label className={styles.formLabel}>
                    <span className={styles.requiredStar} title='required'>
                      <svg viewBox='0 0 9.891 9.616'>
                        <path
                          data-name='Path 284'
                          d='M606.744,789.148l2.195,2.449,2.131-2.449-.508,3.226,3.31.263-2.894,1.714,1.858,2.49L609.79,795.6l-.852,3.165-.989-3.24-2.964,1.317,1.92-2.754-2.925-1.533,3.318-.18Z'
                          transform='translate(-603.979 -789.148)'
                        />
                      </svg>
                    </span>

                    <span className={styles.requiredName}>{t('Add_Group.Name')}</span>
                  </label>
                  <Input
                    placeholder='Basic usage'
                    value={data.name}
                    name='name'
                    onChange={handleChange}
                  />
                  {/* <FormBlock
                    requiredIcon={true}
                    error={isSubmited ? props.nameError : ''}
                    name='name'
                    onChange={onChangeUpdateError}
                    defaultValue={name}
                    placeholder='Enter Name of the group'
                  /> */}
                </div>
                {/* <div className='split-half'>
                  <label className={styles.formLabel}>
                    <span className={styles.requiredStar} title='required'>
                      <svg viewBox='0 0 9.891 9.616'>
                        <path
                          data-name='Path 284'
                          d='M606.744,789.148l2.195,2.449,2.131-2.449-.508,3.226,3.31.263-2.894,1.714,1.858,2.49L609.79,795.6l-.852,3.165-.989-3.24-2.964,1.317,1.92-2.754-2.925-1.533,3.318-.18Z'
                          transform='translate(-603.979 -789.148)'
                        />
                      </svg>
                    </span>

                    <span className={styles.requiredName}>Networks</span>
                  </label>
                  <Space direction='vertical' style={{width: '100%'}}>
                    <Select {...selectProps} />
                  </Space>
                </div> */}
              </div>
            </li>
            <li>
              <div className='split-block'>
                <div className='split-half'>
                  <label className={styles.formLabel}>
                    <span className={styles} title='required'>
                      <svg viewBox='0 0 9.891 9.616'>
                        <path
                          data-name='Path 284'
                          d='M606.744,789.148l2.195,2.449,2.131-2.449-.508,3.226,3.31.263-2.894,1.714,1.858,2.49L609.79,795.6l-.852,3.165-.989-3.24-2.964,1.317,1.92-2.754-2.925-1.533,3.318-.18Z'
                          transform='translate(-603.979 -789.148)'
                        />
                      </svg>
                    </span>

                    <span className={styles.requiredName}>{t('Add_Group.Description')}</span>
                  </label>
                  <TextArea
                    rows={4}
                    placeholder='Enter Description'
                    value={data.discription}
                    name='discription'
                    onChange={handleChange}
                  />
                </div>
                <div className='split-half'></div>
              </div>
            </li>

            <li>
              <div className={style.organisationUpload}>
                <label>
                  {t('Add_Group.Logo')}.
                </label>
                <ImageUpload
                  imageUploadMethod={props.getPresignedUrl}
                  uploadImage={props.uploadImage}
                  imageUrl={props.imageUrl}
                  uploadingImage={props.uploadingImage}
                  onFileUpload={onFileUpload}
                  beforeUpload={(file) => {
                    const acceptedFormats = ['jpg', 'png', 'jpeg'];
                    let extension = file.name.split('.').pop();
                    if (acceptedFormats.includes(extension)) return true;
                    else {
                      getNotification('info', {
                        header: '',
                        body: `Please upload a file with extension .jpg, .jpeg
                       or .png`,
                      });
                      return Upload.LIST_IGNORE;
                    }
                  }}
                  defaultFileList={image ? [{url: image}] : []}
                />

                {props.uploadingImage ? (
                  <div className={style.organisationUpload__loader}>
                    <Loader classList={['xs blue']} />
                    <span>{t('Add_Group.Uploading')}...</span>
                  </div>
                ) : (
                  []
                )}
                <div className={style.organisationUpload__hints}>
                  {t('Add_Group.Note')}.
                </div>
              </div>
            </li>
          </ul>
          <div className='blu-list-submit'>
            <ButtonElement type='' children='Cancel' onClick={props.onClose} />
            <ButtonElement
              type='primary'
              htmlType='submit'
              children='Save'
              loading={props.isProcessing}
              disabled={props.isProcessing}
            />
          </div>
        </form>
      </ModalWindow>
    </div>
  );
};
