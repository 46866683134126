import {ApiBase} from '../../../utils/apiBase';
import {
  ADD_NOTES_FAILED,
  ADD_NOTES_STARTED,
  ADD_NOTES_SUCCESS,
  GET_NOTES_FAILED,
  GET_NOTES_STARTED,
  GET_NOTES_SUCCESS,
} from '../../Redux.constants';
import {getNotification} from '../../../Components/GetNotification';

export function addNotes(payload, callback) {
  return async (dispatch) => {
    dispatch({
      type: ADD_NOTES_STARTED,
      payload: {},
    });
    const apiBaseInstance = new ApiBase();
    apiBaseInstance.instance
      .post('/organization-note/', payload)
      .then((response) => {
        dispatch({
          type: ADD_NOTES_SUCCESS,
          payload: {
            data: response.data,
          },
        });
        showSuccessStatus('Note added successfully');
        callback && callback();
      })
      .catch((e) => {
        dispatch({
          type: ADD_NOTES_FAILED,
          payload: {
            error: e,
          },
        });
        showErrorStatus('Failed to add notes');
      });
  };
}

export function getNotes(params, callback) {
  return async (dispatch) => {
    dispatch({
      type: GET_NOTES_STARTED,
      payload: {},
    });
    const apiBaseInstance = new ApiBase();
    apiBaseInstance.instance
      .get(
        `/organization-note/?organization=${params.id}&q=${params.search}&limit=${params.limit}&offset=${params.offset}`
      )
      .then((response) => {
        dispatch({
          type: GET_NOTES_SUCCESS,
          payload: {data: response.data},
        });
        callback && callback();
      })
      .catch((e) => {
        dispatch({
          type: GET_NOTES_FAILED,
          payload: {
            error: e,
          },
        });
        showErrorStatus('Failed to fetch notes');
      });
  };
}

export function updateNotes(request, callback) {
  return async (dispatch) => {
    dispatch({
      type: ADD_NOTES_STARTED,
      payload: {},
    });
    const apiBaseInstance = new ApiBase();
    apiBaseInstance.instance
      .patch(`/organization-note/${request.noteId}/`, request.payload)
      .then((response) => {
        dispatch({
          type: ADD_NOTES_SUCCESS,
          payload: {
            data: response.data,
          },
        });
        showSuccessStatus('Note updated successfully');
        callback && callback();
      })
      .catch((e) => {
        dispatch({
          type: ADD_NOTES_FAILED,
          payload: {
            error: e,
          },
        });
        showErrorStatus('Failed to update note');
      });
  };
}
export const deleteNotes = (params, callback) => {
  return async (dispatch) => {
    const apiBase = new ApiBase();
    apiBase.instance
      .delete(`/organization-note/${params}/ `)
      .then(({data}) => {
        dispatch({type: ''});
        showSuccessStatus('Deleted successfully');
        callback && callback({isSuccess: true});
      })
      .catch((e) => {
        dispatch({type: ''});
        showErrorStatus('Failed to delete Note');
        callback && callback({isSuccess: false});
      });
  };
};

const showErrorStatus = (message) => {
  getNotification('error', {
    header: 'Failed!',
    body: message,
  });
};

const showSuccessStatus = (message) => {
  getNotification('success', {
    header: 'Success!',
    body: message,
  });
};
