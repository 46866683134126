import React, { useEffect } from 'react';
import { Modal, Tabs, notification, Select } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { DOMAIN } from '../../../../../../../config/constants';
import './styles/ShareModal.scss';
import { createPatToken } from '../../../../../../Services/UserPat.service';
import { useState } from 'react';

const { TabPane } = Tabs;

/**
 * Renders a modal for sharing an assessment.
 *
 * @param {object} props - The props object containing the id, visible, cancel, and share properties.
 * @param {string} props.id - The id of the assessment.
 * @param {boolean} props.visible - Indicates whether the modal is visible or not.
 * @param {function} props.cancel - The function to cancel the modal.
 * @param {function} props.share - The function to share the assessment.
 * @return {JSX.Element} The rendered ShareModal component.
 */
const ShareModal = ({ id, visible, cancel, share }) => {
  const [token, setToken] = useState('');
  const [allEmails, setAllEmails] = useState([]);

  const loadTokenForShare = async () => {
    try {
      const res = await createPatToken(id).call;
      setToken(res.data.ex_id);
    } catch (error) { }
  };

  /**
   * Copies the given text to the clipboard and displays a success notification.
   *
   * @param {string} text - The text to be copied to the clipboard.
   * @return {undefined} No return value.
   */
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    notification.success({
      message: 'Success',
      description: 'The link has been copy successfully',
    });
  };

  const shareByEmails = async () => {
    try {
      await createPatToken(id, {
        collaborators: allEmails.map((email) => ({ email })),
      }).call;
      notification.success({
        message: 'Success',
        description: 'The pat has been shared with collaborators',
      });
      cancel();
    } catch (error) { }
  };
  useEffect(() => {
    if (id) {
      setAllEmails([]);
      loadTokenForShare();
    }
  }, [id]);

  return (
    <Modal
      title='Share Assessment'
      visible={visible}
      footer={false}
      onCancel={cancel}
      bodyStyle={{ overflowY: 'scroll' }}>
      <div className='w-100 h-250px items-center sharemodal'>
        <p className='text-left'>Share by:</p>
        <Tabs type='card'>
          <TabPane tab='Link' key='1'>
            <div className='flex'>
              <input
                type='text'
                className='w-100 share-input'
                readOnly
                value={`${DOMAIN}/shared-pat/${token}`}
              />
              <a
                onClick={() =>
                  copyToClipboard(`${DOMAIN}/shared-pat/${token}`)
                }>
                <CopyOutlined />
              </a>
            </div>
            <div className='w-80 flex center-flex-align mt-15 m-auto'>
              <button
                type='button'
                className='modal-delete-btn mr-10'
                onClick={cancel}>
                Cancel
              </button>
            </div>
          </TabPane>
          <TabPane tab='Email' key='2'>
            <div className='flex email-dropdown'>
              <Select
                mode='tags'
                style={{
                  width: '100%',
                  border: 'none',
                  padding: '8px',
                  borderBottom: '1px solid #c8e6fd',
                }}
                value={allEmails}
                placeholder="Enter Email(s) Address Here"
                onChange={(e) => setAllEmails(e)}
                bordered={false}
              />
            </div>
            <div className='w-80 flex center-flex-align mt-15 m-auto'>
              <button
                type='button'
                className='modal-delete-btn mr-10'
                onClick={cancel}>
                Cancel
              </button>
              <button
                type='button'
                className='modal-dont-delete'
                onClick={shareByEmails}>
                Share
              </button>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Modal>
  );
};

export default ShareModal;
