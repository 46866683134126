import {connect} from 'react-redux';
import {
  addDepartmentAction,
  addDesignationAction,
  addEmployeeAction,
  resetEmployeeForm,
  addInsightsFormChangeAction,
  fetchEmployeesListAction,
  getDepartmentsAction,
  getDesignationsAction,
  addNotes,
  getNotes,
  updateNotes,
  getPresignedUrl,
  uploadImage,
  updateDepartment,
  deleteDepartment,
  deleteNotes,
  listOrganisationAction,
  removeEmployeeAction,
} from '../../Redux/Actions';
import {FetchGroupDetailAction} from '../../Redux/Actions/FetchGroupDetailAction/Index';
import {listGroupOrganizationsAction} from '../../Redux/Actions/ListOrganizationGroupAction';
import {
  getMissionDetails,
  updateMission,
  saveMission,
} from '../../Redux/Actions/ViewOrganizationPersonaActions';
import ViewNetworkGroup from './ViewNetworkGroup';
import {SelectState} from './Selector';

const mapStateToProps = (state) => {
  return {
    ...SelectState(state),
    ...state.connectedNetworkReducer,
    organisationListOptions:
      state.OrganisationReducer.organisationListSelectOptions,
    ...state.AddNotesReducer,
    ...state.UploadImageReducer,
    ...state.OrganizationGroupReducer,
    ...state.GroupsOrganizationsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMissionDetails: (params) => {
      dispatch(getMissionDetails(params));
    },
    getOrganisationList: (params) => {
      dispatch(listOrganisationAction(params));
    },
    getGroupOrganizationList: (params, callback) => {
      dispatch(listGroupOrganizationsAction(params, callback));
    },
    saveMission: (params, callback) => {
      dispatch(saveMission(params, callback));
    },
    updateMission: (params) => {
      dispatch(updateMission(params));
    },
    FetchGroupDetailAction: (params) => {
      dispatch(FetchGroupDetailAction(params));
    },
    getDepartments: (params) => {
      dispatch(getDepartmentsAction(params));
    },
    getDesignations: (params) => {
      dispatch(getDesignationsAction(params));
    },
    addInsightsFormChange: (params) => {
      dispatch(addInsightsFormChangeAction(params));
    },
    addEmployees: (params, callback) => {
      dispatch(addEmployeeAction(params, callback));
    },
    removeEmployees: (params, callback) => {
      dispatch(removeEmployeeAction(params, callback));
    },
    resetEmployeeForm: () => {
      dispatch(resetEmployeeForm());
    },
    fetchEmployeesList: (params) => {
      dispatch(fetchEmployeesListAction(params));
    },
    addDepartmentAction: (params, callback) => {
      dispatch(addDepartmentAction(params, callback));
    },
    addDesignationAction: (params, callback) => {
      dispatch(addDesignationAction(params, callback));
    },
    getOrganisationGroupList: (params, callback) => {
      dispatch(listOrganisationGroupAction(params, callback));
    },
    ...addNotesinInsights(dispatch),
  };
};
function addNotesinInsights(dispatch) {
  return {
    getNotes: (params, callback) => {
      dispatch(getNotes(params, callback));
    },
    addNotes: (params, callback) => {
      dispatch(addNotes(params, callback));
    },
    updateNotes: (params, callback) => {
      dispatch(updateNotes(params, callback));
    },
    getPresignedUrl: (params, callback) => {
      dispatch(getPresignedUrl(params, callback));
    },
    uploadFile: (params, cb) => {
      dispatch(uploadImage(params, cb));
    },
    deleteNotes: (params, callback) => {
      dispatch(deleteNotes(params, callback));
    },
    deleteDepartment: (params, callback) => {
      dispatch(deleteDepartment(params, callback));
    },
    updateDepartment: (params, callback) => {
      dispatch(updateDepartment(params, callback));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewNetworkGroup);
