import React, { useEffect, useState } from 'react';
import { Modal, Input, Table } from 'antd';
import { DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import './styles/InviteMembers.scss';
import {
  postTeamPersona,
} from '../../../../Services/Teams.service';
import { useParams } from 'react-router-dom';
import { getUsersSearch } from '../../../../Services/Users.service';
import { getNotification } from '../../../../Components/GetNotification';

const { Column } = Table;

/**
 * Renders a modal for inviting members.
 *
 * @param {Object} visible - Determines if the modal is visible.
 * @param {Function} closeModal - Function to close the modal.
 * @param {Function} saved - Callback function to be called after saving the members.
 * @return {JSX.Element} The modal component.
 */
const InviteMembers = ({ visible, closeModal, saved }) => {
  const [memberUser, setMemberUser] = useState('');
  const [selectedMembers, setSelectedMembers] = useState([]);

  const { id } = useParams();

  const saveMember = async () => {
    try {
      await postTeamPersona(
        selectedMembers.map((item) => {
          if (item.user) {
            delete item.image;
          }
          delete item.is_verified;
          return item;
        })
      ).call;
      closeModal();
      saved && saved();
    } catch (error) { }
  };

  /**
   * Deletes a member from the selected members list.
   *
   * @param {number} indexItem - The index of the member to delete.
   * @return {void} This function does not return a value.
   */
  const deleteMember = (indexItem) => {
    setSelectedMembers(
      selectedMembers.filter((item, index) => index !== indexItem)
    );
  };

  const verifyMember = async () => {
    if (memberUser === '') {
      getNotification('warning', {
        header: 'Add member missing field',
        body: 'Please enter user name or email',
      })
      return;
    }
    try {
      const regex =
        /^[\w-.]+(\+[A-Za-z0-9_.-]+)?@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      let data = {
        name: '',
        email: '',
        image: null,
        user: '',
        is_verified: false,
        organization: id,
      };
      if (regex.test(memberUser)) {
        const res = await getUsersSearch(memberUser).call;
        if (res.data.count) {
          data.name =
            res.data.results[0].first_name +
            ' ' +
            res.data.results[0].last_name;
          data.email = res.data.results[0].email;
          data.image = res.data.results[0].image;
          data.user = res.data.results[0].id;
          data.is_verified = res.data.results[0].is_verified;
        } else {
          data.email = memberUser;
        }
      } else {
        data.name = memberUser;
      }
      setMemberUser('');
      setSelectedMembers([...selectedMembers, data]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSelectedMembers([]);
  }, [visible]);

  return (
    <Modal
      title='Add Member'
      visible={visible}
      footer={false}
      onCancel={closeModal}
      className='assesmenttool'
      width={1200}
      style={{ height: 'calc(100vh - 100px)' }}
      bodyStyle={{ overflowY: 'scroll' }}>
      <div className='invitemembers'>
        <p className='mt-15'>User Name/Email</p>
        <div className='flex-vertical'>
          <Input
            onPressEnter={verifyMember}
            onChange={(e) => setMemberUser(e.target.value)}
            value={memberUser}
          />
          <button
            type='button'
            className='modal-dont-delete ml-10'
            onClick={verifyMember}>
            ADD
          </button>
        </div>
        <p className='my-15'>Member Added</p>
        <Table dataSource={selectedMembers}>
          <Column
            className='pointer'
            title='Name '
            dataIndex='name'
            key='name'
            render={(_, record) => (
              <div className='flex items-center'>
                <img
                  style={{ borderRadius: '50%', width: '30px', height: '30px' }}
                  src={record?.image || '/img/avatar.png'}
                />
                <span className='pointer ml-10'>{record.name}</span>
              </div>
            )}
          />
          <Column
            className='pointer'
            title='Email'
            dataIndex='email'
            key='email'
          />
          <Column
            className='pointer'
            title='Status'
            dataIndex='action'
            key='action'
            render={(_, record, index) => (
              <div>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  className={`ml-15 ${record?.is_verified ? '' : 'hidden'}`}>
                  <CheckOutlined />
                </a>
                <a className='ml-10' onClick={() => { }}>
                  <DeleteOutlined
                    style={{ color: '#F46767' }}
                    onClick={() => deleteMember(index)}
                  />
                </a>
              </div>
            )}
          />
        </Table>
      </div>
      <div className='flex justify-center my-15'>
        <button type='button' className='modal-delete-btn' onClick={closeModal}>
          CANCEL
        </button>
        <button
          type='button'
          className='modal-dont-delete ml-10'
          onClick={saveMember}>
          INVITE
        </button>
      </div>
    </Modal>
  );
};

export default InviteMembers;
