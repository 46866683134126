import React from 'react';
import style from '../../style.module.scss';
import { NAV_TAB } from '../../UserProfile.constants';

export function TopBar(props) {
  return (
    <>
      <ul className={style.settingsRight__tab}>
        {NAV_TAB.map((item, index) => (
          <li key={index}>
            <a
              className={(props.activeTab === item.key) ? style.active : ''}
              children={item.label}
              onClick={() => props.setActiveTab(item.key)}
            />
          </li>
        ))}
      </ul>
    </>
  );
};