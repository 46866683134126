import React from 'react';
import { Modal } from 'antd';
import './styles/DeleteConfirmationModal.scss';
import { useTranslation } from "react-i18next";


/**
 * Renders a delete confirmation modal.
 *
 * @param {object} visible - A boolean indicating whether the modal is visible.
 * @param {function} cancel - The function to call when the cancel button is clicked.
 * @param {function} save - The function to call when the save button is clicked.
 * @return {JSX.Element} The delete confirmation modal component.
 */
const DeleteConfirmationModal = ({ visible, cancel, save }) => {
  const { t } = useTranslation();
  return (
    <Modal
      data-testid='deleteConfirmationModal'
      visible={visible}
      footer={false}
      onCancel={cancel}
      bodyStyle={{ overflowY: 'scroll' }}
    >
      <div className="w-100 h-250px center-flex-align items-center">
        <div>
          <h3 className="text-center">{t('Delete_Confirmation')}</h3>
          <p className="text-center">{t('No_recover_after_delete')}</p>
          <div className="w-80 flex justify-beetween mt-15 m-auto">
            <button data-testid="deleteButton" type="button" className="modal-delete-btn" onClick={save}>{t('Delete')}</button>
            <button data-testid="cancelButton" type="button" className="modal-dont-delete" onClick={cancel}>{t('Cancel')}</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
