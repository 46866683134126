import {
  LOGIN_SUCCESS,
  LOGIN_STARTED,
  INPUT_FORM_CHANGE,
  VALIDATION_ERRORS,
  SIGN_UP_FORM_CHANGE,
  SIGN_UP_VALIDATION_ERRORS,
  SIGN_UP_SUCCESS,
  SIGN_UP_STARTED,
  LOGIN_ERROR,
  SIGN_UP_ERROR,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_VALIDATION_ERROR,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_STARTED,
  GET_USER_DETAILS_FAILED,
} from '../../../Redux.constants';
import {
  inputFormChange,
  LoginSuccess,
  validationErrors,
  loginStarted,
  loginError,
} from './Helper';
import {
  forgotPasswordError,
  forgotPasswordStarted,
  forgotPasswordSuccess,
} from './Helper/forgotPasswordHelper';
import {
  resetPasswordError,
  resetPasswordStarted,
  resetPasswordSuccess,
  resetPasswordValidationErrors,
} from './Helper/resetPasswordHelper';
import {
  signUpFormChange,
  signUpSuccess,
  signUpStarted,
  signUpValidationErrors,
  signUpError,
} from './Helper/signupPageHelper';
import { fetchUserDetailsError, fetchUserDetailsStarted, fetchUserDetailsSuccess } from './Helper/userDetailsHelper';
import { INITIAL_STATE } from './initialState';

function userDetailsOperations(state, action) {
  switch (action.type) {
    case GET_USER_DETAILS_SUCCESS:
      return fetchUserDetailsSuccess(state, action.payload);
    case GET_USER_DETAILS_STARTED:
      return fetchUserDetailsStarted(state, action.payload);
    case GET_USER_DETAILS_FAILED:
      return fetchUserDetailsError(state, action.payload);
    default:
      return { ...state };
  }
}

function resetPasswordOperations(state, action) {
  switch (action.type) {
    case RESET_PASSWORD_START:
      return resetPasswordStarted(state, action.payload);
    case RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state, action.payload);
    case RESET_PASSWORD_FAILED:
      return resetPasswordError(state, action.payload);
    case RESET_PASSWORD_VALIDATION_ERROR:
      return resetPasswordValidationErrors(state, action.payload);
    default:
      return userDetailsOperations(state, action);
  }
}

function forgotPasswordOperations(state, action) {
  switch (action.type) {
    case FORGOT_PASSWORD_START:
      return forgotPasswordStarted(state, action.payload);
    case FORGOT_PASSWORD_SUCCESS:
      return forgotPasswordSuccess(state, action.payload);
    case FORGOT_PASSWORD_FAILED:
      return forgotPasswordError(state, action.payload);
    default:
      return resetPasswordOperations(state, action);
  }
}

export default function CounterReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case INPUT_FORM_CHANGE:
      return inputFormChange(state, action.payload);
    case LOGIN_SUCCESS:
      return LoginSuccess(state, action.payload);
    case LOGIN_STARTED:
      return loginStarted(state, action.payload);
    case LOGIN_ERROR:
      return loginError(state, action.payload);
    case VALIDATION_ERRORS:
      return validationErrors(state, action.payload);
    case SIGN_UP_FORM_CHANGE:
      return signUpFormChange(state, action.payload);
    case SIGN_UP_SUCCESS:
      return signUpSuccess(state, action.payload);
    case SIGN_UP_STARTED:
      return signUpStarted(state, action.payload);
    case SIGN_UP_ERROR:
      return signUpError(state, action.payload);
    case SIGN_UP_VALIDATION_ERRORS:
      return signUpValidationErrors(state, action.payload);
    default:
      return forgotPasswordOperations(state, action);
  }
}
