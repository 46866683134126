import { ApiBase } from '../../../utils/apiBase';
import { ORGANIZATION_SPRINT } from '../../Api.constants';
import {
  UPDATE_SPRINT_START,
  UPDATE_SPRINT_FAILED,
  UPDATE_SPRINT_SUCCESS,
} from '../../Redux.constants';

export const updateSprintOrganizationAction = (updateRequest, callBack) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SPRINT_START,
    });
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.patch(
        ORGANIZATION_SPRINT +
        `${updateRequest.id}/?organization=${localStorage.getItem(
          'organizationId'
        )}`,
        updateRequest
      );

      dispatch({
        type: UPDATE_SPRINT_SUCCESS,
      });
      callBack && callBack(true);
    } catch (error) {
      dispatch({
        type: UPDATE_SPRINT_FAILED,
        payload: { apiError: error },
      });
      callBack && callBack(false);
    }
  };
};
