import {
  PlusSquareFilled,
  SearchOutlined,
  CheckOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Col, Input, Row, Select, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ArchetypeModal from '../../Components/ArchetypeModal/ArchetypeModal';
import { selectPersona } from '../../ReduxV2/state/personas';
import { getAllNetworks } from '../../Services/Network.service';
import { deleteMember, getMemberList } from '../../Services/Teams.service';
import { ClearLocalStorage } from '../../utils/localStorageOperations';
import { InviteMembers, MemberData } from './Components';
import './styles/Teams.scss';

const { Column } = Table;

/**
 * Renders the Teams component.
 *
 * @return {JSX.Element} The Teams component.
 */
const Teams = () => {
  const [networks, setNetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState();
  const [visible, setVisible] = useState(false);
  const [memberDataVisible, setMemberDataVisible] = useState(false);
  //const [newMember, setNewMember] = useState(false);
  const [memberId, setMemberId] = useState(0);
  const [members, setMembers] = useState([]);
  const [queryString, setQueryString] = useState('');
  const [selectPersonaItem, setSelectPersonaItem] = useState({});
  const [archetypeModal, setArchetypeModal] = useState(false);

  const { id } = useParams();

  const filterMembers = members.filter((member) =>
    member?.name?.toLowerCase().includes(queryString.toLowerCase())
  );

  const dispatch = useDispatch();

  const getAllNetworksApi = async () => {
    try {
      const allNetworks = await getAllNetworks().call;
      if (allNetworks?.data?.results?.length) {
        setSelectedNetwork(allNetworks.data?.results[0]?.id);
        setNetworks(
          allNetworks.data.results.map((item) => {
            return { value: item.id, label: item.name };
          })
        );
        loadMembers(allNetworks.data?.results[0]?.id);
      }
    } catch (error) {
      ClearLocalStorage();
    }
  };

  /**
   * Activates the archetype modal with the provided item.
   *
   * @param {type} item - The item to be passed to the modal.
   * @return {type} The return value of the function.
   */
  const activeArchetypeModal = (item) => {
    setSelectPersonaItem(item);
    setArchetypeModal(true);
  };

  /**
   * Load members asynchronously.
   *
   * @param {number} id - The id of the member.
   * @return {Promise<void>} - A promise that resolves when the members are loaded.
   */
  const loadMembers = async (id) => {
    try {
      const allNetworks = await getMemberList(id).call;
      setMembers(
        allNetworks.data?.results?.map((item) => {
          return {
            ...item,
            role_name: item?.role?.name || '',
            archetype_info: item?.archetype_info?.name || '',
          };
        })
      );
    } catch (error) {
      ClearLocalStorage();
    }
  };

  /**
   * Toggle the visibility of the modal.
   *
   * @param {boolean} fullScreen - Whether the modal should be full screen or not.
   * @return {undefined} 
   */
  const modalVisible = (fullScreen = false) => {
    //setNewMember(fullScreen);
    setVisible((currentState) => !currentState);
    if (visible) {
      loadMembers(selectedNetwork);
    }
  };

  /**
   * Update member data modal with new id and visibility.
   *
   * @param {number} id - The new member ID.
   * @return {undefined} No return value.
   */
  const memberDataModal = (id = 0) => {
    setMemberId(id);
    setMemberDataVisible((currentState) => !currentState);
    if (memberDataVisible) {
      loadMembers(selectedNetwork);
    }
  };

  /**
   * Deletes a team member.
   *
   * @param {number} id - The ID of the team member to delete.
   * @return {Promise<void>} A Promise that resolves with no value.
   */
  const deleteTeamMember = async (id) => {
    try {
      await deleteMember(id).call;
      notification.success({
        message: 'Success',
        description: 'The member has been removed successfully',
      });
      loadMembers(selectedNetwork);
    } catch (error) { }
  };

  useEffect(() => {
    if (id) {
      setSelectedNetwork(id);
      loadMembers(id);
    }
  }, []);

  useEffect(() => {
    if (!id) {
      getAllNetworksApi();
    }
  }, []);

  return (
    <div className='teams'>
      <Row>
        <Col xs={24} md={12}>
          <div className='flex items-baseline'>
            <h1>Teams</h1>{' '}
            <span className='ml-10'>
              <b>View and create your personas here</b>
            </span>
          </div>
          {!id && (
            <Select
              className='select-class'
              placeholder='Select a Network'
              bordered={false}
              value={selectedNetwork}
              defaultValue={selectedNetwork}
              options={networks}
              onChange={(e) => {
                loadMembers(e);
                setSelectedNetwork(e);
              }}
            />
          )}
        </Col>
        <Col xs={24} md={12} className='flex items-end'>
          <div className='flex w-100'>
            <Input
              prefix={<SearchOutlined />}
              className='border-r-50'
              onChange={(e) => setQueryString(e.target.value)}
            />
            <PlusSquareFilled
              style={{
                fontSize: '35px',
                color: '#3C5DD6',
                cursor: 'pointer',
                margin: 'auto',
                marginLeft: '10px',
              }}
              onClick={() => {
                dispatch(selectPersona({}));
                setMemberDataVisible((currentState) => !currentState);
              }}
            />
          </div>
        </Col>
        <Col span={24} className='mt-15'>
          <Table dataSource={filterMembers} pagination={{ defaultPageSize: 7 }}>
            <Column
              className='pointer'
              title='Name '
              dataIndex='name'
              key='name'
              render={(_, record) => (
                <div className='flex items-center'>
                  <img
                    style={{ borderRadius: '50%', width: '30px', height: '30px' }}
                    src={record?.image || '/img/avatar.png'}
                  />
                  <span className='pointer ml-10'>{record.name}</span>
                </div>
              )}
              onCell={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    memberDataModal(record.id);
                    dispatch(selectPersona(record));
                  },
                };
              }}
            />
            <Column
              className='pointer'
              title='Email'
              dataIndex='email'
              key='email'
              render={(text, record) => (
                <span className='pointer relative -top-10'>{text}</span>
              )}
              onCell={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    memberDataModal(record.id);
                    dispatch(selectPersona(record));
                  },
                };
              }}
            />
            <Column
              className='pointer'
              title='Status'
              dataIndex='action'
              key='action'
              render={(_, record, index) => (
                <div className='pointer relative -top-10'>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    className={`ml-15 ${record?.user?.is_verified ? '' : 'hidden'
                      }`}>
                    <CheckOutlined />
                  </a>
                </div>
              )}
              onCell={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    memberDataModal(record.id);
                    dispatch(selectPersona(record));
                  },
                };
              }}
            />
            <Column
              className='pointer'
              title='Archetype'
              dataIndex='archetype_info'
              key='archetype_info'
              render={(text, record) => (
                <span className='pointer relative -top-10'>{text}</span>
              )}
              onCell={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    activeArchetypeModal(record);
                  },
                };
              }}
            />
            <Column
              className='pointer'
              title='Role'
              dataIndex='role_name'
              key='role_name'
              render={(text, record) => (
                <span className='pointer relative -top-10'>{text}</span>
              )}
              onCell={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    memberDataModal(record.id);
                    dispatch(selectPersona(record));
                  },
                };
              }}
            />
            <Column
              className='pointer'
              title=''
              dataIndex='action'
              key='action'
              render={(_, record, index) => (
                <div>
                  <a className='ml-10' onClick={() => { }}>
                    <DeleteOutlined
                      style={{ color: '#F46767' }}
                      onClick={() => deleteTeamMember(record.id)}
                    />
                  </a>
                </div>
              )}
            />
          </Table>
        </Col>
      </Row>
      <InviteMembers visible={visible} closeModal={modalVisible} />
      <MemberData
        visible={memberDataVisible}
        closeModal={memberDataModal}
        memberId={memberId}
      />
      <ArchetypeModal
        closeModal={() => setArchetypeModal(false)}
        visibleModal={archetypeModal}
        selectPersona={selectPersonaItem}
      />
    </div>
  );
};

export default Teams;
