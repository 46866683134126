export const INITIAL_STATE = {
  missionProblemTypes: {
    ispending: false,
    completed: false,
    failed: false,
    data: [],
  },
  createMission: {
    ispending: null,
    completed: null,
    failed: null,
    data: [],
  },
  fetchMissionQuestions: {
    ispending: false,
    completed: false,
    failed: false,
    data: [],
  },
  createMissionForm: {
    step1: {
      name: null,
      objectives: [
        {
          objective: null,
          eta: null,
        },
      ],
      description: null,
      start_date: null,
      mission_problem_type: null,
      end_date: null,
    },
    step2: {
      questions: [],
    },
  },
  questSelectQuestion: {
    questionId: null,
    questionContent: null,
  },
};
