import React from 'react';
import './ProjectsLIst.scss';
import {Col, Row} from 'antd';
import ProjectItem from './components/ProjectItem';
import {PerformanceCategory} from 'types/Performance';

/**
 * Renders a list of projects.
 *
 * @param {Object} projects - An array of project objects.
 * @return {JSX.Element} The rendered list of projects.
 */
const ProjectsLIst: React.FC<{
  projects: PerformanceCategory[];
}> = ({projects}) => {
  return (
    <div className='ProjectsLIst'>
      <Row gutter={12} className='mb-15 ProjectsLIst-label-table'>
        <Col span={7} className='text-white'>
          Projects
        </Col>
        <Col span={2} className='text-white'>
          Current
        </Col>
        <Col span={2} className='text-white'>
          Target
        </Col>
        <Col span={3} className='text-white'>
          Current State
        </Col>
        <Col span={2} className='text-white'>
          Trend
        </Col>
        <Col span={2} className='text-white'>
          Sprints
        </Col>
        <Col span={2} className='text-white'>
          Barriers
        </Col>
        <Col span={3} className='text-white'>
          Lessons Learned
        </Col>
        <Col span={1} className='text-white' />
      </Row>
      {projects?.map((project, index) => (
        <ProjectItem project={project} index={index} />
      ))}
    </div>
  );
};

export default ProjectsLIst;
