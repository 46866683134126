import {
  UPDATE_MISSION_DETAILS_START,
  UPDATE_MISSION_DETAILS_FAILED,
  UPDATE_MISSION_DETAILS_SUCCESS,
  FETCH_MISSION_DETAILS_FAILED,
  FETCH_MISSION_DETAILS_START,
  FETCH_MISSION_DETAILS_SUCCESS,
  EVIDENCE_SEARCH_START,
  EVIDENCE_SEARCH_SUCCESS,
  EVIDENCE_SEARCH_FAILED,

  // TeamInfo Department
  TEAMINFO_GET_DEPARTMENT_LIST_START,
  TEAMINFO_GET_DEPARTMENT_LIST_SUCCESS,
  TEAMINFO_GET_DEPARTMENT_LIST_FAILED,
  // TeamInfo Designation
  TEAMINFO_GET_DESIGNATION_LIST_START,
  TEAMINFO_GET_DESIGNATION_LIST_SUCCESS,
  TEAMINFO_GET_DESIGNATION_LIST_FAILED,
  // TeamInfo Employees
  TEAMINFO_GET_EMPLOYEE_LIST_START,
  TEAMINFO_GET_EMPLOYEE_LIST_SUCCESS,
  TEAMINFO_GET_EMPLOYEE_LIST_FAILED,
  // Get existing details for mission TeamInfo
  GET_TEAMDETAILS_TEAMINFO_START,
  GET_TEAMDETAILS_TEAMINFO_SUCCESS,
  GET_TEAMDETAILS_TEAMINFO_FAILED,
  ADD_NEW_MEMBER_TO_LIST,
  MISSION_HYPOTHESIS_START,
  MISSION_HYPOTHESIS_SUCCESS,
  MISSION_HYPOTHESIS_FAILED,
  GET_MISSION_HYPOTHESIS_START,
  GET_MISSION_HYPOTHESIS_SUCCESS,
  GET_MISSION_HYPOTHESIS_FAILED,
  MOST_VALUALE_START,
  MOST_VALUALE_FAILED,
  MOST_VALUALE_SUCCESS,
  GET_MOST_VALUALE_START,
  GET_MOST_VALUALE_SUCCESS,
  GET_MOST_VALUALE_FAILED,
} from '../../Redux.constants';
import {INITIAL_STATE} from './initialState';
import {
  getMissionDetailsFailed,
  getMissionDetailsStarted,
  getMissionDetailsSuccess,
  updateMissionDetailsFailed,
  updateMissionDetailsStarted,
  updateMissionDetailsSuccess,
  evidenceSearchStarted,
  evidenceSearchSuccess,
  evidenceSearchFailed,

  // TeamInfo department section
  teamInfoGetDepartmentStarted,
  teamInfoGetDepartmentSuccess,
  teamInfoGetDepartmentFailed,
  // TeamInfo designation section
  teamInfoGetDesignationStarted,
  teamInfoGetDesignationSuccess,
  teamInfoGetDesignationFailed,
  // TeamInfo employee section
  teamInfoGetEmployeeStarted,
  teamInfoGetEmployeeSuccess,
  teamInfoGetEmployeeFailed,
  // Get Existing details of missions for TeamInfo
  getTeamDetailsStart,
  getTeamDetailsSuccess,
  getTeamDetailsFailed,
  addNewMemberToList,
} from './Helper';
import {
  missionHypothesisFailed,
  missionHypothesisStarted,
  missionHypothesisSuccess,
  getMissionHypothesisStarted,
  getMissionHypothesisSuccess,
  getMissionHypothesisFailed,
  getMostValualeSuccess,
  getMostValualeStarted,
  getMostValualeFailed,
} from './Helper/missionHypothesis';

// eslint-disable-next-line max-lines-per-function
export default function ViewMissionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_MISSION_DETAILS_START:
      return updateMissionDetailsStarted(state);
    case UPDATE_MISSION_DETAILS_SUCCESS:
      return updateMissionDetailsSuccess(state, action.payload);
    case UPDATE_MISSION_DETAILS_FAILED:
      return updateMissionDetailsFailed(state, action.payload);
    case FETCH_MISSION_DETAILS_START:
      return getMissionDetailsStarted(state);
    case FETCH_MISSION_DETAILS_SUCCESS:
      return getMissionDetailsSuccess(state, action.payload);
    case FETCH_MISSION_DETAILS_FAILED:
      return getMissionDetailsFailed(state, action.payload);
    case EVIDENCE_SEARCH_START:
      return evidenceSearchStarted(state);
    case EVIDENCE_SEARCH_SUCCESS:
      return evidenceSearchSuccess(state, action);
    case EVIDENCE_SEARCH_FAILED:
      return evidenceSearchFailed(state, action.payload);
    // TeamInfo Department Section
    case TEAMINFO_GET_DEPARTMENT_LIST_START:
      return teamInfoGetDepartmentStarted(state);
    case TEAMINFO_GET_DEPARTMENT_LIST_SUCCESS:
      return teamInfoGetDepartmentSuccess(state, action.payload);
    case TEAMINFO_GET_DEPARTMENT_LIST_FAILED:
      return teamInfoGetDepartmentFailed(state);
    // TeamInfo Designation Section
    case TEAMINFO_GET_DESIGNATION_LIST_START:
      return teamInfoGetDesignationStarted(state);
    case TEAMINFO_GET_DESIGNATION_LIST_SUCCESS:
      return teamInfoGetDesignationSuccess(state, action.payload);
    case TEAMINFO_GET_DESIGNATION_LIST_FAILED:
      return teamInfoGetDesignationFailed(state);
    // TeamInfoEmployeeSection
    case TEAMINFO_GET_EMPLOYEE_LIST_START:
      return teamInfoGetEmployeeStarted(state);
    case TEAMINFO_GET_EMPLOYEE_LIST_SUCCESS:
      return teamInfoGetEmployeeSuccess(state, action.payload);
    case TEAMINFO_GET_EMPLOYEE_LIST_FAILED:
      return teamInfoGetEmployeeFailed(state);
    // Get existing mission details for TeamInfo
    case GET_TEAMDETAILS_TEAMINFO_START:
      return getTeamDetailsStart(state);
    case GET_TEAMDETAILS_TEAMINFO_SUCCESS:
      return getTeamDetailsSuccess(state, action.payload);
    case GET_TEAMDETAILS_TEAMINFO_FAILED:
      return getTeamDetailsFailed(state);
    case ADD_NEW_MEMBER_TO_LIST:
      return addNewMemberToList(state, action.payload);
    // mission hypothesis
    case MISSION_HYPOTHESIS_START:
      return missionHypothesisStarted(state);
    case MISSION_HYPOTHESIS_SUCCESS:
      return missionHypothesisSuccess(state, action.payload);
    case MISSION_HYPOTHESIS_FAILED:
      return missionHypothesisFailed(state, action.payload);
    case GET_MISSION_HYPOTHESIS_START:
      return getMissionHypothesisStarted(state);
    case GET_MISSION_HYPOTHESIS_SUCCESS:
      return getMissionHypothesisSuccess(state, action.payload);
    case GET_MISSION_HYPOTHESIS_FAILED:
      return getMissionHypothesisFailed(state, action.payload);
    case MOST_VALUALE_START:
      return getMostValualeStarted(state, action.payload);
    case GET_MOST_VALUALE_SUCCESS:
      return getMostValualeSuccess(state, action.payload);
    case MOST_VALUALE_FAILED:
      return getMostValualeFailed(state, action.payload);
    default:
      return {...state};
  }
}
