import {getNotification} from '../../../Components/GetNotification';
import {ApiBase} from '../../../utils/apiBase';
import {
  MISSION_HYPOTHESIS,
  MOST_VALUALE_BULK_UPDATE,
  MOST_VALUALE
} from '../../Api.constants';
import {
  MOST_VALUALE_START,
  MOST_VALUALE_SUCCESS,
  MOST_VALUALE_FAILED,
  GET_MOST_VALUALE_START,
  GET_MOST_VALUALE_SUCCESS,
  GET_MOST_VALUALE_FAILED,
  
} from '../../Redux.constants';

export function updateMostValuealeAction(params, callback) {
  return async (dispatch) => {
    dispatch({
      type: MOST_VALUALE_START,
    });
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.post(
        MOST_VALUALE_BULK_UPDATE,
        params
      );
      dispatch({
        type: MOST_VALUALE_SUCCESS,
      });
      callback && callback();
      getNotification('success', {
        header: 'Success!',
        body: response.data,
      });
      callback & callback(true);
    } catch (error) {
      dispatch({type: MOST_VALUALE_FAILED});
      getNotification('error', {
        header: 'Failed!',
        body: 'Failed to update mission hypothesis.',
      });
      callback && callback(false);
    }
  };
}

export function getMostValualeAction(params) {
  return async (dispatch) => {
    dispatch({
      type: GET_MOST_VALUALE_START,
    });
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.get(
        `${MOST_VALUALE}?mission=${params.id}`
      );
      dispatch({
        type: GET_MOST_VALUALE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({type: GET_MOST_VALUALE_FAILED});
      getNotification('error', {
        header: 'Failed!',
        body: 'Failed to fetch mission hypothesis.',
      });
    }
  };
}

const successNotification = () => {
  return getNotification('success', {
    header: 'Success!',
    body: 'Hypothesis updated successfully.',
  });
};

const failedNotification = () => {
  return getNotification('error', {
    header: 'Failed!',
    body: 'Failed to update mission hypothesis.',
  });
};
