// const urlDev = 'http://127.0.0.1:8000/api/v1/';
// const urlProd = 'http://127.0.0.1:8000/api/v1/';
// const urlDev = 'https://anthemblueagilis.care4all.in/api/v1/';
// const urlProd = 'https://anthemblueagilis.care4all.in/api/v1/';

// const urlDev = 'https://devblueagilis.care4all.in/api/v1/';
// const urlProd = 'https://devblueagilis.care4all.in/api/v1/';

//const urlDev= https://02a6cfde-913c-40da-909b-5b1fdde89841.mock.pstmn.io/api/v1/

// const urlDev = 'https://appblueagilis.care4all.in/api/v1/';
// const urlProd = 'https://appblueagilis.care4all.in/api/v1/';

export const API_BASE_URL =
  process.env.REACT_APP_ENV === 'dev'
    ? process.env.REACT_APP_API_DEV
    : process.env.REACT_APP_ENV === 'beta'
    ? process.env.REACT_APP_API_BETA
    : process.env.REACT_APP_API_QA;
export const API_BASE_STAGE_URL = '/foldername';
export const DOMAIN = API_BASE_URL.replace('-api.', '.').split('/api/')[0];
export const GOOGLE_SEARCH = '/google_search/';
export const SMART_SEARCH = process.env.REACT_APP_SEARCH;
export const AUTOSAVING_DELAY = 800;
