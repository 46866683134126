import axios from "axios";
import moment from 'moment';
import { API_BASE_URL } from "../../config/constants";

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    }
  }
}

export function getObservation(orgId) {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}observations/?organization=${orgId}`, {
      signal: controller.signal,
      ...headers()
    }),
    controller
  }
}

export function postObservation(data) {
  return {
    call: axios.post(`${API_BASE_URL}observations/`, data, headers())
  }
}

export function patchObservation(id, data) {
  return {
    call: axios.patch(`${API_BASE_URL}observations/${id}/`, data, headers())
  }
}