export function missionHypothesisStarted(state) {
  return {
    ...state,
    missionHypothesisApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}

export function missionHypothesisSuccess(state, payload) {
  return {
    ...state,
    missionHypothesisApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
  };
}
export function missionHypothesisFailed(state, payload) {
  return {
    ...state,
    missionHypothesisApi: {
      isProcessing: false,
      success: null,
      error: true,
    },
  };
}

export function getMissionHypothesisStarted(state) {
  return {
    ...state,
    getMissionHypothesisApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
    viewMissionDetails: {
      ...state.viewMissionDetails,
      missionHypothesis: null,
    },
  };
}

export function getMissionHypothesisSuccess(state, payload) {
  return {
    ...state,
    getMissionHypothesisApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
    viewMissionDetails: {
      ...state.viewMissionDetails,
      missionHypothesis: payload?.results?.length ? payload.results : null,
    },
  };
}
export function getMissionHypothesisFailed(state, payload) {
  return {
    ...state,
    getMissionHypothesisApi: {
      isProcessing: false,
      success: null,
      error: true,
    },
  };
}

export function getMostValualeStarted(state) {
  return {
    ...state,
    getMostValualeApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}


export function getMostValualeSuccess(state, payload) {
   return {
    ...state,
    getMostValualeApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
    viewMostValualeDetails: payload?.results?.length ? payload.results : null,
    
  };
}

export function getMostValualeFailed(state, payload) {
  return {
    ...state,
    getMostValualeApi: {
      isProcessing: false,
      success: null,
      error: true,
    },
  };
}

