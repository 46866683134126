export function getToolBoxStarted(state) {
    return {
        ...state,
        getToolBoxApi: {
            isProcessing: true,
            error: false,
            success: false,
        },
    };
}

export function getToolBoxSuccess(state,payload) {
    return {
        ...state,
        getToolBoxApi: {
            isProcessing: false,
            error: false,
            success: true,
        },
        getToolBoxData: payload
    };

}

export function getToolBoxFailed(state) {
    return {
        ...state,
        getToolBoxApi: {
            isProcessing: false,
            error: true,
            success: false, 
        }
    }
}
