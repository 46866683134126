import React from 'react';
import PropTypes from 'prop-types';
import {Select, Space, Spin} from 'antd';
import Loader from '../Loader';
import {EmptyState} from '../EmptyState';

let timeout = null;
const DEBOUNCE_TIME = 350;
const CONFIG = {
  FILTER_OPTION: false,
  SHOW_SEARCH: true,
};

/* eslint-disable max-lines-per-function */
export function AsyncSelect(props) {
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setOptions(props.options);
    setLoading(false);
  }, [props.options]);
  const onInputChange = (textValue) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      props.onSearch(textValue);
      setOptions([]);
      setLoading(true);
    }, DEBOUNCE_TIME);
  };
  const setNoFoundContent = () => {
    if (loading) return <SelectLoader />;
    else return <NoOptions />;
  };
  return (
    <Select
      filterOption={CONFIG.FILTER_OPTION}
      onSearch={onInputChange}
      showSearch={CONFIG.SHOW_SEARCH}
      disabled={props.disabled}
      onSelect={props.onSelect}
      notFoundContent={setNoFoundContent()}
      mode='multi'
      placeholder={props.placeholder}
      value={props.value ? props.value : undefined}
      options={options}
    />
  );
}

AsyncSelect.propTypes = {
  /**
   * callback function invoked when input changes [fires only after specific interval]
   */
  onSearch: PropTypes.func,
  /**
   * callback function invoked when an option is selected
   */
  onSelect: PropTypes.func,
  /**
   * boolean to set disabled
   */
  disabled: PropTypes.bool,
  /**
   * Mode of select [ 'multiple', 'tags']
   */
  mode: PropTypes.oneOf([undefined, 'mulitple', 'tags']),
  /**
   * Placehoder to show on Element
   */
  placeholder: PropTypes.string,
  /**
   * Value to set on the component
   */
  value: PropTypes.oneOfType([PropTypes.any, PropTypes.array]),
  /**
   * Options to render for select
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Label for option
       */
      label: PropTypes.string,
      /**
       * Value for Option
       */
      value: PropTypes.string || PropTypes.number,
    })
  ),
};

AsyncSelect.defaultProps = {
  disabled: false,
  options: [],
  placeholder: '',
  mode: undefined,
};

function SelectLoader() {
  return <Loader hasWrapper={true} classList={['sm blue']} />;
}

function NoOptions() {
  return <EmptyState customHeight={100} emptyStateMessage={''} />;
}
