import { configureStore } from '@reduxjs/toolkit';
import {
  personasSlice,
  goalsSplice,
  improvementTacticModalSlice,
  memberSlice,
  usersSlice,
  listViewSlice,
  networkListViewSlice,
  networksSlice,
  networkMeasuresSlice,
  missionSplice,
  sprintSlice,
  patSlice,
  patsSlice,
  pftJourneySlice,
  meetSlice,
} from './state';

export default configureStore({
  reducer: {
    user: usersSlice.reducer,
    member: memberSlice.reducer,
    goal: goalsSplice.reducer,
    persona: personasSlice.reducer,
    listView: listViewSlice.reducer,
    network: networksSlice.reducer,
    networkListView: networkListViewSlice.reducer,
    networkMeasure: networkMeasuresSlice.reducer,
    mission: missionSplice.reducer,
    sprint: sprintSlice.reducer,
    pat: patSlice.reducer,
    pats: patsSlice.reducer,
    pftJourney: pftJourneySlice.reducer,
    meet: meetSlice.reducer,
    improvementTacticModal: improvementTacticModalSlice.reducer,
  },
});
