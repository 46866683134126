import {createSlice} from '@reduxjs/toolkit';
import { ImprovementTacticModalState } from 'types/ImprovementTactic';

const initialState: ImprovementTacticModalState = {
  isOpen: false,
  id: null,
  improvement_tactic: null,
  what_is_this: '',
  how_drive_improvement: '',
};

export const improvementTacticModalSlice = createSlice({
  name:'improvement_tactic_modal',
  initialState,
  reducers: {
    openImprovementTacticModal: (state, {payload}) => {
      state.isOpen = true;
      state.id = payload.id;
      state.improvement_tactic = payload.improvement_tactic;
      state.what_is_this = payload.what_is_this;
      state.how_drive_improvement = payload.how_drive_improvement;
    },
    closeImprovementTacticModal: (state) => {
      state.isOpen = false;
    },
    clearImprovementTacticModal: (state) => {
      state.id = null;
      state.improvement_tactic = null;
      state.what_is_this = '';
      state.how_drive_improvement = '';
    },
  },
});

export const {openImprovementTacticModal, closeImprovementTacticModal, clearImprovementTacticModal} = improvementTacticModalSlice.actions;