import React from 'react';
import {  Button, Modal } from 'antd';
import { Tag as TagType} from 'types/Tags';

type DeleteTagModalProps = {
  tag: TagType | undefined;
  open: boolean;
  handleCancel: React.MouseEventHandler<HTMLElement>;
  handleSave: (id: any) => void;
};


const DeleteTagModal: React.FC<DeleteTagModalProps> = ({ tag, open, handleCancel, handleSave }) => {
  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if(tag?.name){
      handleSave(tag?.id);
    }
  };
  
  return (
    <Modal
      data-testid="editTagModal"
      aria-modal="true"
      aria-labelledby="modalTitle"
      open={open}
      footer={null}
      onCancel={handleCancel}
    >
      <form onSubmit={onSubmit}>
        <div data-testid="overwriteModalContent" className="full-height full-width flex flex-col gap-3 mt-6 pb-0 px-4">
          <h1 data-testid="overwriteModalTitle" className="text-3xl font-bold">Delete Tag?</h1>
          <br/>
          
          <p>This tag will be permanently deleted. Any elements associated will be untagged.</p>

          <div data-testid="overwriteModalButtons" className="flex flex-row justify-end mt-3">
            <Button data-testid="acceptChangesButton" type="text" htmlType="submit">Confirm</Button>
            <Button data-testid="cancelChangesButton" style={{background:'#06A5BB'}} type="primary" onClick={handleCancel}>Cancel</Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default DeleteTagModal;
