import React from 'react';
import { Modal, notification } from 'antd';
import './styles/TcpModal.scss';
import { useState } from 'react';
import { CreateSprint } from '../CreateSprint';
import { AboutTcModal } from '../AboutTcModal';
import { getAboutTC } from '../../../../Services/UserPat.service';

import { useTranslation } from "react-i18next"

/**
 * Renders a TCP modal component.
 *
 * @param {object} visible - Determines if the modal is visible.
 * @param {function} cancel - Callback function to cancel the modal.
 * @param {array} tcp - List of TCP objects.
 * @return {JSX.Element} The TCP modal component.
 */
const TcpModal = ({ visible, cancel, tcp }) => {
  const [selectTactic, setSelectTactic] = useState('');
  const [sprintModal, setSprintModal] = useState(false);
  const [visibleTcModal, setVisibleTcModal] = useState(false);
  const [selectTacticInformation, setSelectTacticInformation] = useState([]);

  const [t] = useTranslation()

  /**
   * Selects a tactic option.
   *
   * @param {type} tactic - the tactic to select
   * @return {type} undefined
   */
  const selectTacticOption = (tactic) => {
    setSprintModal(true);
    setSelectTactic(tactic);
  };

  /**
   * Opens the about modal for a given ID.
   *
   * @param {type} id - The ID to open the modal for.
   * @return {type} None.
   */
  const openAboutModal = async (id) => {
    try {
      const res = await getAboutTC(id).call;
      if (res.data.length) {
        setSelectTacticInformation(res.data);
        setVisibleTcModal(true);
      } else {
        notification.warning({
          message: 'Attention',
          description:
            'The information of this tactic is not found in the system.',
        });
      }
    } catch (error) { }
  };

  return (
    <div>
      <Modal
        title='Improvement Tactics'
        visible={visible}
        footer={false}
        onCancel={cancel}
        className='assesmenttool'
        style={{ height: 'calc(100vh - 100px)' }}
        bodyStyle={{ overflowY: 'scroll' }}>
        <div className='tcpmodal'>
          {tcp?.map(({ id, description }, index) => (
            <div key={index}>
              <div className='flex items-center'>
                <span
                  className='hoverIcon  mr-5'
                  onClick={() => openAboutModal(id)}
                />
                <p className='my-15 flex-1'>• {description}</p>
              </div>
              <div className='text-end my-5 d-none'>
                <button
                  type='button'
                  className='modal-dont-delete b-rounded-10'
                  onClick={() => selectTacticOption(description)}>
                  Create {t('commons.intervention')}
                </button>
              </div>
            </div>
          ))}
          <div className='text-center my-15'>
            <button
              type='button'
              className='modal-dont-delete b-rounded-10'
              onClick={cancel}>
              Close
            </button>
          </div>
        </div>
      </Modal>
      <CreateSprint
        visible={sprintModal}
        cancel={() => setSprintModal(false)}
        tactic={selectTactic}
      />
      <AboutTcModal
        visible={visibleTcModal}
        onCancel={() => setVisibleTcModal(false)}
        selectTacticInformation={selectTacticInformation}
      />
    </div>
  );
};

export default TcpModal;
