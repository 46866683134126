import React, { useState } from 'react';

export const SwitchContext = React.createContext()

const ComponentSwitchContextProvider = (props) => {

   const [component,setcomponent] = useState("dashboard")

   const changecomponent = (val) => {
        setcomponent(val)
   }

    return (
        <SwitchContext.Provider value={{component, changecomponent  }}>
            {props.children}
        </SwitchContext.Provider>
    )
}

export default ComponentSwitchContextProvider;