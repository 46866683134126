const allpopulatealltestdata = {data: [], loading: true};

export const GetAllPopulateAllTestDataReducer = (state = allpopulatealltestdata, action) =>{
    switch(action.type){
        case 'getallpopulatealltestdata':
            return{
                data:action.payload,
                loading:action.loader
            }
        default:
            return state;
    }
}