import moment from 'moment';
import {getShortDate} from '../../../../utils/timeUtils';
export function getMissionListStarted(state) {
  return {
    ...state,
    getMissions: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function getMissionListSuccess(state, payload) {
  let isEmpty = false;
  if (payload.listMissionCount === 0) {
    isEmpty = true;
  }
  return {
    ...state,
    getMissions: {
      isProcessing: false,
      success: true,
      error: null,
    },
    missionList: formatMissionList(payload),
    listMissionCount: payload.listMissionCount,
    totalMissionCount: payload.totalMissionCount,
    listMissionIsEmpty: isEmpty,
  };
}
export function getMissionListFailed(state, payload) {
  return {
    ...state,
    getMissions: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching list',
    },
    missionList: [],
  };
}

function getMissionProgressInPercentage(startDate, endDate) {
  if (!startDate || !endDate) {
    return 0;
  }
  let isProgressActive = moment().diff(moment(startDate, 'yyyy-MM-DD'));
  if (isProgressActive >= 0) {
    let totalNoOfDays = moment(endDate, 'yyyy-MM-DD').diff(
      moment(startDate, 'yyyy-MM-DD')
    );
    if (totalNoOfDays == 0) {
      return 100;
    }
    let currentDate = getShortDate(new Date());
    let noOfDaysPassed = moment(currentDate, 'yyyy-MM-DD').diff(
      moment(startDate, 'yyyy-MM-DD')
    );
    if (noOfDaysPassed == 0) {
      return 0;
    }
    if (noOfDaysPassed >= totalNoOfDays) {
      return 100;
    }
    return ((noOfDaysPassed / totalNoOfDays) * 100).toFixed(0);
  } else {
    return 0;
  }
}

const formatMissionList = (payload) => {
  //BE updated
  let result = payload?.listMissionResponse?.results;
  // let response = payload.listMissionResponse;
  result.map((item) => {
    let startDate = item.start_date || null;
    let endDate = item.end_date || null;
    // item.organization = response.organization;
    // item._organization = response._organization;
    item.missionProgress =
      startDate || endDate
        ? getMissionProgressInPercentage(startDate, endDate)
        : 0;
  });
  return result;
};
