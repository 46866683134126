import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { Row, Col, Select } from 'antd'

import TextareaDebounce from '../TextareaDebounce/TextareaDebounce'
import InputMultilineDebounce from '../InputMultilineDebounce/InputMultipleDebounce'

import InputDebounce from '../InputDebounce/InputDebounce'
import DateDebounce from '../DateDebounce/DateDebounce'
import './FormTable.scss'
import { Icon } from 'Components/atoms'

/**
 * Render a table-like form with dynamic fields.
 *
 * @param {Object} props - The props object containing the following properties:
 *   @param {string} name - The name of the form table.
 *   @param {string} design - The design of the form table (either "table" or "list").
 *   @param {boolean} debounceActive - The debounce active flag.
 *   @param {Object} control - The control object.
 *   @param {string} addLabel - The label for the add button.
 *   @param {Array} fields - The array of fields.
 *   @param {Object} errors - The object containing error information.
 *   @param {Array} headers - The array of table headers.
 *   @param {function} onAppend - The function to append a new row.
 *   @param {function} onRemove - The function to remove a row.
 *   @param {boolean} isDebounActive - The debounce active flag (default is true).
 * @return {JSX.Element} The rendered form table.
 */
const FormTable = ({
  name,
  design, // table, list
  debounceActive, control,
  addLabel,
  fields,
  errors,
  headers,
  onAppend,
  onRemove,
  isDebounActive = true
}) => {
  const [colW] = useState({
    xs: 24,
    sm: 24,
    md: 12,
  })
  const [headersTable, setHeadersTable] = useState([])
  const [loading, setLoading] = useState(true)
  const hasTitles = headers?.some(item => item?.title)

  const append = () => onAppend && onAppend({ created: true })

  useEffect(() => {
    setTimeout(() => setLoading(false), 500)
  }, [fields])

  useEffect(() => {
    setHeadersTable(headers?.map(item => ({
      ...item,
      width: item.width || Math.floor((22 + (design === "list" ? 1 : 0)) / headers.length),
    })))
  }, [headers])

  return <div className='FormTable'>
    <div className={`container design-${design} p-15`}>
      {hasTitles &&
        <Row>
          <Col xs={1} />
          {headersTable?.map((item, index) => (
            <Col {...colW} lg={item.width}>
              <div>
                <span className='font-medium'>{item.title || ""}</span>
                {item?.optional && <span className="ant-table-cell-optional"> (Optional)</span>}
              </div>
            </Col>
          ))}
          <Col xs={1} />
        </Row>
      }
      {
        fields?.map((field, index) => (
          <Row key={field?.id}>
            <Col xs={1} className="flex">
              <div>
                <button 
                  type='button' 
                  className="w-8 h-8 rounded border border-solid border-gray-400" 
                  disabled={loading} 
                  onClick={() => {
                    if (fields?.length <= 1) return
                    onRemove && onRemove(index)
                    isDebounActive && debounceActive()
                    setLoading(true)
                  }}>
                  <span>-</span>
                </button>
              </div>
            </Col>
            {headersTable?.map((item, indexItem) => {
              const fieldName = `${name}[${index}].${item.name}`
              return (
                <Col {...colW} lg={item.width} key={item?.id}>
                  {item.type === "text" && <InputDebounce name={fieldName} control={control} debounceAction={debounceActive} rules={{ required: item?.required || false }} className={`w-100 !rounded ${errors[`${name}.${index}.${item.name}`] ? "border-error" : ""}`} />}
                  {item.type === "textarea" && <TextareaDebounce name={fieldName} control={control} debounceAction={debounceActive} rules={{ required: item?.required || false }} className={`w-100 !rounded ${errors[`${name}.${index}.${item.name}`] ? "border-error" : ""}`} />}
                  {item.type === "inputMulti" && <InputMultilineDebounce autoheight={true} name={fieldName} control={control} debounceAction={debounceActive} rules={{ required: item?.required || false }} className={`w-100 !rounded ${errors[`${name}.${index}.${item.name}`] ? "border-error" : ""}`} />}
                  {item.type === "date" && <DateDebounce name={fieldName} control={control} debounceAction={debounceActive} rules={{ required: item?.required || false }} className={`w-100 !rounded ${errors[`${name}.${index}.${item.name}`] ? "border-error" : ""}`} />}
                  {item.type === "select" && <Controller
                    name={fieldName}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className={`${item.multiple ? "select-class-multiple-table" : "select-class-new"} ${errors.type ? "border-error" : ""}`}
                        placeholder="Select an item"
                        mode={item.multiple ? "multiple" : undefined}
                        optionFilterProp="children"
                        bordered={false}
                        onChange={(net) => {
                          field.onChange(net)
                          debounceActive()
                        }}
                        options={item?.options || []}
                      />
                    )}
                  />}
                </Col>
              )
            })}
            {design !== "list" && <Col xs={1} className="flex">
              <div>
                <button 
                  type='button' 
                  className="w-full max-w-8 h-8 rounded border border-solid border-gray-400 flex items-center justify-center" 
                  disabled={loading} 
                  onClick={() => {
                    append()
                    isDebounActive && debounceActive()
                    setLoading(true)
                  }}>
                    <Icon name="add-primary" />
                </button>
              </div>
            </Col>}
          </Row>
        ))
      }
      {design === "list" &&
        <Row>
          <Col xs={24} className='flex-vertical'>
            <button type='button' className='add-button-container-g w-100' onClick={() => {
              append()
              isDebounActive && debounceActive()
            }}>+ {addLabel || "Add new row"}</button>
          </Col>
        </Row>
      }
    </div>
  </div>
};

export default FormTable
