import React, { useEffect, useState } from 'react';
import { Col, Collapse, Row, Select } from 'antd';
import moment from 'moment';
import { Controller, useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getUsersRedux } from '../../ReduxV2/state';
import { deleteMeetingGoal } from '../../Services/Meetings.service';
import { getMemberList } from '../../Services/Teams.service';
import './styles/MeetingsPreparing.scss';
import { useParams } from 'react-router-dom';

const { Panel } = Collapse;

/**
 * Renders a component for preparing for meetings.
 *
 * @param {Object} meetData - the meeting data
 * @param {Object} allFormData - the form data
 * @return {ReactNode} - the rendered component
 */
const MeetingsPreparing = ({ meetData, allFormData }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
    watch,
    getValues,
  } = allFormData;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'goals',
  });

  const {
    fields: fields_goals_meet,
    append: append_goals_meet,
    remove: remove_goals_meet,
  } = useFieldArray({
    control,
    name: 'goals_meet',
  });

  const {
    fields: fields_attendees,
    append: append_attendees,
    remove: remove_attendees,
  } = useFieldArray({
    control,
    name: 'array_attendees',
  });

  const watchFieldArray = watch('goals');
  let controlledFields = [];

  if (watchFieldArray) {
    controlledFields = fields.map((field, index) => {
      return {
        ...field,
        ...watchFieldArray[index],
      };
    });
  }

  const [members, setMembers] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();

  const loadMeetData = async () => {
    setValue('title', meetData?.title);
    setValue('type', meetData?.meeting_type);
    setValue('other_meeting_type', meetData?.other_meeting_type);
    setValue('network', meetData?.organizations[0]?.id);
    if (meetData.start) {
      setValue('date', moment(meetData.start));
      setValue('start', moment(meetData?.start).format('HH:mm'));
      setValue('end', moment(meetData?.end).format('HH:mm'));
    }

    setValue('mission', meetData?.missions[0]?.id);
    setValue('sprint', meetData?.sprints[0]?.id);

    meetData?.attendees?.map((member) => {
      append_attendees({
        associated_users: member.team_persona.id,
        attendee_type: member.attendee_type,
      });
    });

    meetData?.goals?.map((goalItem, index) => {
      if (goalItem.category == 'MEETING_CAT') {
        append_goals_meet({ ...goalItem, goalId: goalItem?.id });
      } else {
        append({
          ...goalItem,
          goalId: goalItem?.id,
          associated_users: goalItem.associated_users.map(
            (member) => member.id
          ),
        });
      }
    });
  };

  /**
   * Deletes an action given an index and an action object.
   *
   * @param {number} index - The index of the action to be deleted.
   * @param {object} action - The action object to be deleted.
   * @return {Promise<void>} A promise that resolves with no value.
   */
  const deleteActionGF = async (index, action) => {
    try {
      if (action.hasOwnProperty('goalId')) {
        const res = await deleteMeetingGoal(action.goalId);
      }
      remove(index);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Deletes an action from the global state and removes goals using the specified index.
   *
   * @param {number} index - The index of the action in the global state.
   * @param {Object} action - The action object to be deleted.
   * @return {Promise<void>} - A promise that resolves when the action is successfully deleted.
   */
  const deleteActionGM = async (index, action) => {
    try {
      if (action.hasOwnProperty('goalId')) {
        const res = await deleteMeetingGoal(action.goalId);
      }
      remove_goals_meet(index);
    } catch (error) {
      console.log(error);
    }
  };

  const loadMembers = async () => {
    try {
      const allNetworks = await getMemberList(id).call;
      setMembers(allNetworks.data?.results);
    } catch (error) { }
  };

  useEffect(() => {
    reset();
    remove();
    loadMembers();
    remove_goals_meet();
    remove_attendees();
    dispatch(getUsersRedux());
    if (meetData) {
      loadMeetData();
    }
  }, [meetData]);

  return (
    <div className='meetingspreparing'>
      <div>
        <Collapse expandIconPosition='end' defaultActiveKey={['1']}>
          <Panel header='Attendees' key='1'>
            <Row className='mb-10 bg-gray-prep'>
              <Col span={12}>
                <p className='text-black'>Name</p>
              </Col>
              <Col span={12}>
                <p className='text-black'>Engagement Level</p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {fields_attendees.length ? (
                  <div>
                    {fields_attendees.map((goal, index) => (
                      <Row gutter={20} className='mt-10' key={goal.id}>
                        <Col span={12}>
                          <div className='flex vertical'>
                            <div
                              className='close-icon mr-5'
                              onClick={() => remove_attendees(index)}>
                              -
                            </div>
                            <Controller
                              name={`array_attendees.${index}.associated_users`}
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className={`select-class-multiple ${errors[`goals.${index}.associated_users`]
                                      ? 'border-error'
                                      : ''
                                    }`}
                                  size='default'
                                  placeholder='Select attendees'
                                  bordered={false}
                                  onChange={(net) => {
                                    field.onChange(net);
                                  }}
                                  options={members.map((member) => ({
                                    value: member.id,
                                    label: member.email || member.name,
                                  }))}
                                />
                              )}
                            />
                          </div>
                        </Col>
                        <Col span={12}>
                          <Controller
                            name={`array_attendees.${index}.attendee_type`}
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                className={`select-class-multiple ${errors[`goals.${index}.attendee_type`]
                                    ? 'border-error'
                                    : ''
                                  }`}
                                size='default'
                                bordered={false}
                                onChange={(net) => {
                                  field.onChange(net);
                                }}
                                options={[
                                  { label: 'Unsure', value: 'UNSURE' },
                                  { label: 'Not Engaged', value: 'NOT_ENGAGED' },
                                  { label: 'Low', value: 'LOW' },
                                  { label: 'Medium', value: 'MEDIUM' },
                                  { label: 'High', value: 'HIGH' },
                                ]}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    ))}
                  </div>
                ) : (
                  <div className='text-center'>
                    <p>No member added yet</p>
                  </div>
                )}
                <div
                  className='add-button-container'
                  onClick={() => append_attendees({ attendee_type: 'UNSURE' })}>
                  <p className='text-default-blue'>Add New Attendee</p>
                </div>
              </Col>
            </Row>
          </Panel>
          <Panel header='Goals for Meeting' key='2'>
            <Row>
              <Col span={24}>
                {fields_goals_meet.length ? (
                  <div>
                    {fields_goals_meet.map((goal, index) => (
                      <Row gutter={24} className='mt-15' key={goal.id}>
                        <Col span={24}>
                          <label className='mr-5'>Goal</label>
                          <div className='flex vertical'>
                            <div
                              className='close-icon mr-5'
                              onClick={() => deleteActionGM(index, goal)}>
                              -
                            </div>
                            <input
                              className={`input-class ${errors[`goals_meet.${index}.name`]
                                  ? 'border-error'
                                  : ''
                                }`}
                              {...register(`goals_meet.${index}.name`, {
                                required: true,
                              })}
                              placeholder='Describe Goal'
                            />
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </div>
                ) : (
                  <div className='text-center'>
                    <p>No goal added yet</p>
                  </div>
                )}
                <div
                  className='add-button-container'
                  onClick={() => append_goals_meet({ name: '' })}>
                  <p className='text-default-blue'>Add Goal</p>
                </div>
              </Col>
            </Row>
          </Panel>
          <Panel header='Goals for Facilitators' key='3'>
            <Row>
              <Col span={24}>
                {controlledFields.length ? (
                  <div>
                    {controlledFields.map((goal, index) => (
                      <Row gutter={20} className='mt-5' key={goal.id}>
                        <Col span={24}>
                          <label>Goal</label>
                          <div className='flex vertical'>
                            <div
                              className='close-icon'
                              onClick={() => deleteActionGF(index, goal)}>
                              -
                            </div>
                            <input
                              className={`input-class ${errors[`goals.${index}.name`]
                                  ? 'border-error'
                                  : ''
                                }`}
                              {...register(`goals.${index}.name`, {
                                required: true,
                              })}
                              placeholder='Describe Goal'
                            />
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </div>
                ) : (
                  <div className='text-center'>
                    <p>No goal added yet</p>
                  </div>
                )}
                <div
                  className='add-button-container'
                  onClick={() => append({ name: '' })}>
                  <p className='text-default-blue'>Add Goal</p>
                </div>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default MeetingsPreparing;
