import {ApiBase} from '../../../utils/apiBase';
import {FETCH_ORG_DETAILS, LIST_ORGANIZATION_GROUP} from '../../Api.constants';
import {
  FETCH_GROUP_DETAILS_FAILED,
  FETCH_GROUP_DETAILS_STARTED,
  FETCH_GROUP_DETAILS_SUCCESS,
} from '../../Redux.constants';
import * as yup from 'yup';
import {getNotification} from '../../../Components/GetNotification';

export function FetchGroupDetailAction(input, name) {
  return async (dispatch) => {
    dispatch({
      //   type: FETCH_ORG_DETAILS_STARTED,
      type: FETCH_GROUP_DETAILS_STARTED,
      payload: {},
    });
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.get(
        `organization-group/?group_id=${input}`
      );
      let grps = response.data;
      let grp = grps.filter((grp) => grp.id == input);
      dispatch({
        // type: FETCH_ORG_DETAILS_SUCCESS,
        type: FETCH_GROUP_DETAILS_SUCCESS,
        payload: {
          orgDetails: grp[0],
          orgList: grps,
        },
      });
    } catch (error) {
      // let validationErrors = await fetchValidationErrors(input);
      dispatch({
        type: FETCH_GROUP_DETAILS_FAILED,
        payload: {error},
      });
      // getNotification('error', {
      //     header: 'Failed!',
      //     body: 'Failed to fetch network details'
      // });
    }
  };
}
