import axios from "axios"
import { API_BASE_URL } from "../../config/constants"

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    },
  }
}

export const postWorkflow = (data) => {
  return {
    call: axios.post(`${API_BASE_URL}practice-workflows/`, data, headers()),
  }
}

export const patchWorkflow = (data) => {
  return {
    call: axios.patch(`${API_BASE_URL}practice-workflows/${data.id}/`, data, headers()),
  }
}

export const getWorkflow = (id, page, pageSize, q) => {
  const controller = new AbortController()
  return {
    call: axios.get(`${API_BASE_URL}practice-workflows/?organization=${id}&page=${page}&page_size=${pageSize}${q !== "" ? `&q=${q}` : ""}`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  }
}

export const deleteWorkflow = (id) => {
  return {
    call: axios.delete(`${API_BASE_URL}practice-workflows/${id}/`, headers()),
  }
}

export const postWorkflowSteps = (data) => {
  return {
    call: axios.post(`${API_BASE_URL}workflows-steps/`, data, headers()),
  }
}

export const patchWorkflowSteps = (data) => {
  return {
    call: axios.patch(`${API_BASE_URL}workflows-steps/${data.id}/`, data, headers()),
  }
}

export const deleteWorkflowSteps = (id) => {
  return {
    call: axios.delete(`${API_BASE_URL}workflows-steps/${id}/`, headers()),
  }
}