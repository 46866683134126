import React, { useState } from "react"
import { Input, Radio, Space } from "antd"

import { FilesTable } from "../../../Routes/MeetingsComments/components/FilesTable"

import { useTranslation } from "react-i18next"


/**
 * Renders the appropriate step 3 content based on the value of `step1Value`.
 *
 * @param {object} step1Value - The value of step 1.
 * @param {string} step3BugValue - The bug value for step 3.
 * @param {function} setStep3BugValue - A function to set the bug value for step 3.
 * @param {string} step3FeatureValue - The feature value for step 3.
 * @param {function} setStep3FeatureValue - A function to set the feature value for step 3.
 * @param {string} step3RequestValue - The request value for step 3.
 * @param {function} setStep3RequestValue - A function to set the request value for step 3.
 * @return {JSX.Element} The rendered step 3 content.
 */
const FeedbackStep3 = ({
  step1Value,
  step3BugValue,
  setStep3BugValue,
  step3FeatureValue,
  setStep3FeatureValue,
  step3RequestValue,
  setStep3RequestValue,
}) => {

  const [t] = useTranslation()
  
  return (
    <>
      {step1Value === "bug" && (
        <>
          <h2 className="mb-10">{t('Feedback_Modal.Step3.Bug_header')}</h2>
          <p className="mb-10">{t('Feedback_Modal.Step3.Bug_message')}</p>
          <FilesTable
            id={0}
            field=""
            urlFiles="/"
            postUrlFile="/"
            getSufix=""
            asDrop={true}
            multiple={false}
            dragDropTitle="Click or drag file to this area to upload here"
            getFileCallback={(fileUploaded) =>
              setStep3BugValue(fileUploaded)}
          />
          {step3BugValue?.name || ""}
        </>
      )}
      {step1Value === "feature" && (
        <>
          <h2 className="mb-10">{t('Feedback_Modal.Step3.Feature_header')}</h2>
          <p className="mb-10">{t('Feedback_Modal.Step3.Feature_message')}</p>
          <Input allowClear required size="small" value={step3FeatureValue} onChange={(e) => setStep3FeatureValue(e.target.value)} />
        </>
      )}
      {step1Value === "request" && (
        <>
          <h2 className="mb-10">{t('Feedback_Modal.Step3.Request_header')}</h2>
          <p className="mb-10">{t('Feedback_Modal.Step3.Request_message')}</p>
          <Radio.Group value={step3RequestValue} onChange={(e) => setStep3RequestValue(e.target.value)}>
            <Space direction="vertical">
              <Radio value="GENERAL">{t('Feedback_Modal.Step3.General')}</Radio>
              <Radio value="SPECIFIC">{t('Feedback_Modal.Step3.Specific')}</Radio>
            </Space>
          </Radio.Group>
        </>
      )}
    </>
  )
}

export default FeedbackStep3