import {ApiBase} from '../../../utils/apiBase';
export const addDesignationAction = (payload, callback) => {
  return async (dispatch) => {
    const apiBaseInstance = new ApiBase();
    apiBaseInstance.instance
      .post('/designation/', payload)
      .then((response) => {
        callback && callback(true);
      })
      .catch((error) => {
        callback && callback();
      });
  };
};
