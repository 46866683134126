import {combineReducers} from 'redux'

import { GetAllPopulateToolboxDataReducer } from './Toolbox';
import {GetAllPopulateAllTestDataReducer} from './AllTest';
import {GetDraftPopulateAllTestDataReducer} from './draftReducer'
import {GetAllDraftDataReducer} from './DraftData'

const AllReducers = combineReducers({
    GetAllPopulateToolboxDataReducer : GetAllPopulateToolboxDataReducer,
    GetAllPopulateAllTestDataReducer : GetAllPopulateAllTestDataReducer,
    GetDraftPopulateAllTestDataReducer:GetDraftPopulateAllTestDataReducer,
    GetAllDraftDataReducer:GetAllDraftDataReducer
})

export default AllReducers;