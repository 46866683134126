import React from 'react';
import {Row, Col} from 'antd';
import ProjectSprintItem from './ProjectSprintItem';
import {PerformanceMission} from 'types/Performance';

/**
 * Renders the project sprints component.
 *
 * @param {object} project - The project object containing sprints and id.
 * @return {ReactElement} - The JSX element representing the project sprints.
 */
const ProjectSprints: React.FC<{
  project: PerformanceMission;
}> = ({project}) => {
  const {sprints, id} = project;

  if (!sprints) return <></>;
  return (
    <>
      <Row gutter={12} className='row-primary mb-15 ml-5 mr-5'>
        <Col span={13}>Sprints</Col>
        <Col span={3} className='text-center'>
          Current State
        </Col>
        <Col span={2} className='text-center'>
          Barriers
        </Col>
        <Col span={2} className='text-center'>
          Successes
        </Col>
        <Col span={3} className='text-center'>
          Lessons Learned
        </Col>
        <Col span={1} />
      </Row>
      {sprints?.map((sprint, index) => (
        <ProjectSprintItem mission_id={id} sprint={sprint} index={index} />
      ))}
    </>
  );
};

export default ProjectSprints;
