import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DaysCard } from '../DaysCard';
import './styles/CalendarHeader.scss';
import arrow from '../../../../../Assets/arrow.png';

/**
 * Renders the header component of the calendar.
 *
 * @param {object} calendarRef - The reference to the calendar component.
 * @param {function} getMeetingApi - The function to get meeting API.
 * @return {JSX.Element} The rendered calendar header component.
 */
const CalendarHeader = ({ calendarRef, getMeetingApi }) => {

  const [dates, setDates] = useState([]);

  useEffect(() => {
    const calApi = calendarRef.current?.getApi();

    createHaderDays(calApi.currentDataManager.data.dateProfile.currentRange.start)

  }, [calendarRef]);

  const handleDateChange = (direction) => {
    const calApi = calendarRef.current?.getApi();

    if (calApi) {
      if (direction === 'prev') {
        calApi.prev();
      } else if (direction === 'next') {
        calApi.next();
      } else {
        calApi.today();
      }
    }

    getMeetingApi(calApi.currentDataManager.data.dateProfile.currentRange.start);
    createHaderDays(calApi.currentDataManager.data.dateProfile.currentRange.start)
  };

  /**
   * Creates an array of header days starting from the given start day.
   *
   * @param {type} startDay - the start day for creating the header days
   * @return {type} undefined
   */
  const createHaderDays = (startDay) => {
    let day = moment(startDay);
    let datesHeader = [];

    for (let index = 0; index < 7; index++) {
      day = moment(day).add(1, 'day');
      datesHeader.push({
        month: moment(day).format('MMM'),
        dayNumber: moment(day).format('DD'),
        dayText: moment(day).format('ddd'),
        isToday: day.isSame(moment(), "days")
      })

    }

    setDates(datesHeader);
  }

  return (
    <div className="calendarheader">
      <div onClick={() => handleDateChange('prev')} className="prev">
        <img src={arrow} alt="" />
      </div>
      <DaysCard days={dates} />
      <div onClick={() => handleDateChange('next')} className="next">
        <img src={arrow} alt="" />
      </div>
    </div>
  );
};

export default CalendarHeader;
