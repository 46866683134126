import {ApiBase} from '../../../utils/apiBase';
import {LIST_MISSIONS} from '../../Api.constants';
import {
  LIST_MISSIONS_FAILED,
  LIST_MISSIONS_STARTED,
  LIST_MISSIONS_SUCCESS,
} from '../../Redux.constants';
import * as yup from 'yup';
import {getNotification} from '../../../Components/GetNotification';
import axios from 'axios';
import {API_BASE_URL} from '../../../../config/constants';

// const GET_LIST_MISSIONS_SCHEMA = yup.object().shape({
//     q: yup.string(),
//     limit: yup.string(),
//     offset: yup.string()
// });

// function fetchValidationErrors(input) {
//     return new Promise((resolve, reject) => {
//         GET_LIST_MISSIONS_SCHEMA.validate(input, { abortEarly: false }).catch(
//             (error) => {
//                 let validationList = error.inner.map((item) => {
//                     return {
//                         path: item.path,
//                         message: item.message,
//                     };
//                 });
//                 resolve(validationList);
//             }
//         );
//     });
// }
const failedNotification = () => {
  return getNotification('error', {
    header: 'Failed!',
    body: 'Failed to fetch project list',
  });
};
function listMissionStarted(dispatch) {
  dispatch({
    type: LIST_MISSIONS_STARTED,
    payload: {},
  });
}
function listMissionFailed(dispatch, error) {
  dispatch({
    type: LIST_MISSIONS_FAILED,
    payload: {error},
  });
}
export function listMissionsAction(params, callback) {
  return async (dispatch) => {
    listMissionStarted(dispatch);
    try {
      const apiBaseInstance = new ApiBase();
      let holdOn = 'False';
      if (params.is_on_hold) {
        holdOn = 'True';
      }
      if (!params.q) {
        params.q = '';
      }
      let response = {data: {}};
      if (params.listAllMissions && params.id !== undefined) {
        let formdata = new FormData();
        formdata.append('org_id', params.id);
        axios({
          url: `${API_BASE_URL}mission-org-bridge/mission-organization/`,
          method: 'post',
          data: formdata,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
            'content-Type': 'multipart/form-data',
          },
        }).then(
          (res) => {
            response.data = {
              count: res.data.length,
              next: null,
              previous: null,
              results: res.data,
            };
            dispatch({
              type: LIST_MISSIONS_SUCCESS,
              payload: {
                listMissionResponse: response.data,
                listMissionCount: response.data.count,
                totalMissionCount: response.data.mission_count,
              },
            });
            callback && callback(true);
          },
          (err) => {
            console.log(err);
            // getNotification('error',{
            //   header:'Failed',
            //   message:'Failed to get mission list'
            // })
          }
        );
      } else {
        const url = getListMissionUrl(params, holdOn);
        response = await apiBaseInstance.instance.get(url);
        dispatch({
          type: LIST_MISSIONS_SUCCESS,
          payload: {
            listMissionResponse: response.data,
            listMissionCount: response.data.count,
            totalMissionCount: response.data.mission_count,
          },
        });
        callback && callback(true);
      }
    } catch (error) {
      listMissionFailed(dispatch, error);
      failedNotification();
      callback && callback(false);
    }
  };
}

const getListMissionUrl = (params, holdOn) => {
  if (!params.listAllMissions && params.id !== undefined) {
    return (
      LIST_MISSIONS +
      `?organization=${params.id}&limit=${params.limit}&offset=${params.offset}&is_on_hold=${holdOn}&q=${params.q}`
    );
  } else {
    return (
      LIST_MISSIONS +
      `?limit=${params.limit}&offset=${params.offset}&is_on_hold=${holdOn}&q=${params.q}`
    );
  }
};
