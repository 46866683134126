import React, { useState } from 'react';
import { Button, Col, Divider, Input, Radio, Row, Select } from 'antd';
import { Controller } from 'react-hook-form';
import { PlusOutlined } from '@ant-design/icons';

import { PersonaData } from '../Team/components';

import './ModalPersona.scss';

/**
 * Renders a modal for managing a persona.
 *
 * @param {Object} attendees - The list of attendees.
 * @param {Object} control - The control object from react-hook-form.
 * @param {Object} errors - The errors object from react-hook-form.
 * @param {Function} getValues - The getValues function from react-hook-form.
 * @param {Function} register - The register function from react-hook-form.
 * @param {String} watchPersonaId - The watched persona ID.
 * @param {String} watchIsProblemField - The watched is problem field.
 * @param {Function} setVisibleModal - The function to set the visible modal.
 * @param {Function} onSave - The onSave function.
 * @param {Function} addPersona - The addPersona function.
 * @param {String} namePersona - The name of the persona.
 * @param {Function} setNamePersona - The function to set the name of the persona.
 * @param {Function} updatePersonaData - The function to update persona data.
 * @param {any} onTop - The onTop value.
 * @return {JSX.Element} The rendered modal persona component.
 */
const ModalPersona = ({
  attendees,
  control,
  errors,
  getValues,
  register,
  watchPersonaId,
  watchIsProblemField,
  setVisibleModal,
  onSave,
  addPersona,
  namePersona,
  setNamePersona,
  updatePersonaData,
  onTop,
}) => {
  const [currentId, setCurrentId] = useState(getValues('id') || 0);

  return (
    <div className='ModalPersona'>
      <Row gutter={10}>
        <Col span={24} className='mb-10'>
          <p>Tag the person</p>
          <Controller
            name='persona'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                className={`staff-select-class ${errors.persona ? 'border-error' : ''
                  }`}
                placeholder='Select persona'
                optionFilterProp='children'
                bordered={false}
                onChange={(net) => {
                  field.onChange(net);
                  setCurrentId(net);
                }}
                options={attendees}
                disabled={getValues('id')}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <div className='flex'>
                      <Input
                        className='input-class ml-10'
                        value={namePersona}
                        onChange={(e) =>
                          setNamePersona && setNamePersona(e.target.value)
                        }
                      />
                      <Button
                        type='text'
                        icon={<PlusOutlined />}
                        onClick={() => addPersona && addPersona()}>
                        Add new
                      </Button>
                    </div>
                  </>
                )}
              />
            )}
          />
        </Col>
        <Col span={24} className='mb-10'>
          <PersonaData
            personaId={watchPersonaId}
            updatePersonaData={(key, data) =>
              updatePersonaData && updatePersonaData(key, data)
            }
          />
        </Col>
        {onTop && (
          <Col span={24} className='mb-10'>
            {onTop}
          </Col>
        )}
        <Col span={24} className={`mb-10`}>
          <p className='mb-15'>Are they the right influencer?</p>
          <Controller
            name='right_influencer'
            control={control}
            render={({ field }) => (
              <Radio.Group {...field}>
                <Radio value='1'>Yes</Radio>
                <Radio value='2'>No</Radio>
              </Radio.Group>
            )}
          />
        </Col>
        <Col span={24} className='my-10'>
          <p className='mr-5'>
            Does this individual think this problem is a problem for them?
          </p>
          <Controller
            name={`is_problem`}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Radio.Group
                onChange={(e) => field.onChange(e)}
                value={field.value}>
                <Radio.Button value='yes'>Yes</Radio.Button>
                <Radio.Button value='no'>No</Radio.Button>
              </Radio.Group>
            )}
          />
        </Col>
        <Col
          span={24}
          className={`mb-10 ${watchIsProblemField == 'yes' ? 'block' : 'hidden'
            }`}>
          <p className='my-15'>
            How bad or serious do they think the problem is?
          </p>
          <Controller
            name='how_serious'
            control={control}
            render={({ field }) => (
              <Radio.Group {...field} className='flex '>
                <Radio value='1' className='grid'>
                  1
                </Radio>
                <Radio value='2' className='grid'>
                  2
                </Radio>
                <Radio value='3' className='grid'>
                  3
                </Radio>
                <Radio value='4' className='grid'>
                  4
                </Radio>
                <Radio value='5' className='grid'>
                  5
                </Radio>
              </Radio.Group>
            )}
          />
        </Col>
        <Col
          span={24}
          className={`mb-10 ${watchIsProblemField == 'yes' ? 'block' : 'hidden'
            }`}>
          <p>Why do they think this way?</p>
          <textarea className='textarea' {...register('why')} />
        </Col>
        <Col
          span={24}
          className={`mb-10 ${watchIsProblemField == 'yes' ? 'block' : 'hidden'
            }`}>
          <p className='my-15'>
            How difficult do they think it will be to solve this problem?
          </p>
          <Controller
            name='difficulty'
            control={control}
            render={({ field }) => (
              <Radio.Group {...field} className='flex'>
                <Radio value='1' className='grid'>
                  1
                </Radio>
                <Radio value='2' className='grid'>
                  2
                </Radio>
                <Radio value='3' className='grid'>
                  3
                </Radio>
                <Radio value='4' className='grid'>
                  4
                </Radio>
                <Radio value='5' className='grid'>
                  5
                </Radio>
              </Radio.Group>
            )}
          />
        </Col>
        <Col
          span={24}
          className={`mb-10 ${watchIsProblemField == 'yes' ? 'block' : 'hidden'
            }`}>
          <p>What is their reasoning behind the answer above?</p>
          <textarea className='textarea' {...register('difficulty_reason')} />
        </Col>
        <Col
          span={24}
          className={`mb-10 ${watchIsProblemField == 'no' ? 'block' : 'hidden'
            }`}>
          <p>Why?</p>
          <textarea className='textarea' {...register('why')} />
        </Col>
        {/*       <Col span={24} className={`mb-10`}>
        <p>Primary Job Roles & Responsabilities</p>
        <input
          type='text'
          className='input-class'
          {...register('job_and_resp')}
        />
      </Col> */}
        <Col span={24} className={`mb-10`}>
          <p className='my-15'>Demand for Problem Score</p>
          <Controller
            name='demand_prob_score'
            control={control}
            render={({ field }) => (
              <Radio.Group {...field} className='flex '>
                <Radio value='1' className='grid'>
                  1
                </Radio>
                <Radio value='2' className='grid'>
                  2
                </Radio>
                <Radio value='3' className='grid'>
                  3
                </Radio>
                <Radio value='4' className='grid'>
                  4
                </Radio>
                <Radio value='5' className='grid'>
                  5
                </Radio>
                <Radio value='6' className='grid'>
                  6
                </Radio>
                <Radio value='7' className='grid'>
                  7
                </Radio>
                <Radio value='8' className='grid'>
                  8
                </Radio>
                <Radio value='9' className='grid'>
                  9
                </Radio>
                <Radio value='10' className='grid'>
                  10
                </Radio>
              </Radio.Group>
            )}
          />
        </Col>
        <Col span={24} className={`mb-10`}>
          <p className='my-15'>Trust from Other Score</p>
          <Controller
            name='trust_other_score'
            control={control}
            render={({ field }) => (
              <Radio.Group {...field} className='flex'>
                <Radio value='1' className='grid'>
                  1
                </Radio>
                <Radio value='2' className='grid'>
                  2
                </Radio>
                <Radio value='3' className='grid'>
                  3
                </Radio>
                <Radio value='4' className='grid'>
                  4
                </Radio>
                <Radio value='5' className='grid'>
                  5
                </Radio>
                <Radio value='6' className='grid'>
                  6
                </Radio>
                <Radio value='7' className='grid'>
                  7
                </Radio>
                <Radio value='8' className='grid'>
                  8
                </Radio>
                <Radio value='9' className='grid'>
                  9
                </Radio>
                <Radio value='10' className='grid'>
                  10
                </Radio>
              </Radio.Group>
            )}
          />
        </Col>
        <Col span={24} className='text-center mt-15'>
          <button
            type='button'
            className='modal-delete-btn'
            onClick={() => setVisibleModal(false)}>
            Cancel
          </button>
          <button
            type='submit'
            className='modal-dont-delete ml-10'
            onClick={() => onSave && onSave(currentId)}>
            Save
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default ModalPersona;
