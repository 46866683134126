import React from 'react';
import FormBlock from '../../../Components/FormBlock';
import { Card, CardHeader, CardBody, CardFooter } from '../../../Components/Card';
import { ButtonElement } from '../../../Components/ButtonElement';
import style from '../style.module.scss';
import { NAV_TAB } from '../UserProfile.constants';

class ResetPassword extends React.Component {
  changePassword = () =>
    this.props.changePassword(this.props.formConfig, () => {
      this.props.setActiveTab(NAV_TAB[0].key);
    });
  passwordFormBlock = (formField, index) => (
    <FormBlock
      elementType='password'
      placeholder={formField.placeholder}
      value={formField.value}
      error={formField.error}
      maxLength={500}
      onChange={(event) =>
        this.props.updateChangePasswordForm({
          key: formField.key,
          value: event.target.value,
          index,
        })
      }
    />
  );
  render() {
    return (
      <>
        <h3>{this.props.tabDetails.title}</h3>
        <p>Please choose a new password</p>
        <hr />
        <ul className={style.password__list}>
          {this.props.formConfig.map((formField, index) => {
            return (
              <>
                <li>
                  <label>{formField.label}</label>
                  <span>
                    {this.passwordFormBlock(formField, index)}
                    <p>{formField.notes}</p>
                  </span>
                </li>
                <hr />
              </>
            )
          })}
        </ul>
        <div className={style.userDetails__password}>
          <ButtonElement
            onClick={this.changePassword}
            className={style.userDetails__password__btn}
            disabled={this.props.changePasswordApi.loading}
            loading={this.props.changePasswordApi.loading}
            type='primary'>
            Update Password
          </ButtonElement>
        </div>
      </>
    );
  }
}
export default ResetPassword;
