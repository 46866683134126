import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_BASE_URL } from '../../config/constants';

const headers = () => {
  return {
    headers: {},
  };
};

type RefreshToken = {
  refresh: string;
}

/**
 * @todo Remove `any` type.
 */
export function postRefresh(refresh: string): {
  call: Promise<AxiosResponse<RefreshToken>>;
} {
  return {
    call: axios.post(
      `${API_BASE_URL}token/refresh/`,
      {
        refresh,
      }, headers() as AxiosRequestConfig),
  };
}
