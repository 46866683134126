import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: [],
}

export const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        addTeamMember: (state, action) => {
            state.value = action.payload;
        },
        cleanTeamMember: (state) => {
            state.value = [];
        },
        updatedTeamMember: (state, action) => {
            state.value.forEach(member => {
                if (member.id === action.payload.id) {
                    member.attendee_type = action.payload.type;
                }
            });
        },
        loadTeamMember: (state, action) => {
            state.value = action.payload;
        },
        removeTeamMember: (state, action) => {
            state.value = state.value.filter(member => member.id !== action.payload);
        }
    }
});

export const { addTeamMember, removeTeamMember, updatedTeamMember, cleanTeamMember, loadTeamMember } = memberSlice.actions;

