import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';

import { getNotification } from '../../../Components/GetNotification';
import { SwitchContext } from '../../../Components/new/Context/ComponentSwitchContext';
import { AnswerStorageContext } from '../../../Components/new/Context/AnswerStorageContext';

import { getallpopulatetoolbox, getdraftDataById } from '../Actions/Toolbox';
import { useSelector, useDispatch } from 'react-redux';
import { API_BASE_URL } from '../../../../config/constants';

const Takeassessment = () => {
  const { changecomponent, component } = React.useContext(SwitchContext);
  const { addanswer, answer, add_quiz_id, removeanswer, resetAnswer, quizid: quiz_id } =
    React.useContext(AnswerStorageContext);

  const [questionsdata, setquestionsdata] = React.useState();
  const [questionscount, setquestionscount] = React.useState(0);
  const [showsubmitbutton, setshowsubmitbutton] = React.useState(false);
  const [quizid, setquizid] = React.useState({});
  const [selectedOption, setselectedOption] = React.useState([])
  const [draftId, setDraftId] = React.useState(null)

  const inputRef = useRef(null);
  const [inputVisible, setInputVisible] = useState(true);
  const [text, setText] = useState("");

  let dispatch = useDispatch();
  let data = useSelector((state) => state.GetAllPopulateToolboxDataReducer);
  let draftData = useSelector((state) => state.GetAllDraftDataReducer)

  function onClickOutSide(e) {
    // Check if user is clicking outside of <input>
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setInputVisible(false); // Disable text input
    }
  }

  useEffect(() => {
    // Handle outside clicks on mounted state
    if (inputVisible) {
      document.addEventListener("mousedown", onClickOutSide);
    }

    // This is a necessary step to "dismount" unnecessary events when we destroy the component
    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    };
  });

  React.useEffect(() => {
    dispatch(getallpopulatetoolbox());
  }, [dispatch]);

  React.useEffect(() => {
    if (data !== undefined) {
      setquestionsdata(data.data.data);
    }
  }, [data]);

  React.useEffect(() => {
    if (component === "draft assessment") {
      dispatch(getdraftDataById(quiz_id))
    }
  }, [component, quiz_id])

  React.useEffect(() => {
    if (questionsdata !== undefined) {
      if (questionscount === questionsdata.count - 1) {
        setshowsubmitbutton(true);
      }
    }
  }, [questionscount, questionsdata]);


  React.useEffect(() => {
    if (draftData.data?.data && component === "draft assessment") {
      setDraftId(draftData.data?.data.id)
      var string = draftData.data?.data.user_answers
      string = JSON.parse(string.replaceAll("'", '"'))
      setquizid({ ...string })
    }
  }, [draftData])

  const submitButton = (key = null) => {
    let formdata = new FormData();

    answer.forEach((val, index) => {
      formdata.append(`answer_${index}`, val);
    });

    let url = key === "save_to_draft" ? `${API_BASE_URL}toolbox/drafts/` : `${API_BASE_URL}toolbox/results/`
    if (draftId && key) {
      axios({
        url: `${API_BASE_URL}toolbox/drafts/?patch_id=${draftId}`,
        method: 'patch',
        data: formdata,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
        },
      }).then(
        (res) => {
          add_quiz_id(res.data.id);
          resetAnswer()
          setDraftId(null)
          setquestionscount(0)
          changecomponent('draft')
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      axios({
        url,
        method: 'post',
        data: formdata,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
        },
      }).then(
        (res) => {
          add_quiz_id(res.data.id);
          resetAnswer()
          setDraftId(null)
          setquestionscount(0)
          key ? changecomponent('draft') : changecomponent('finish assessment');
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  React.useEffect(() => {
    if (questionsdata !== undefined) {
      if (questionsdata.count === answer.length) {
        submitButton();
      }
    }
  }, [answer]);

  const scoreRecorder = (position) => {
    let val = 0;
    if (document.getElementById('option_a_points').checked === true) {
      val = document.getElementById('option_a_points').value;
    }
    if (document.getElementById('option_b_points').checked === true) {
      val = document.getElementById('option_b_points').value;
    }
    if (document.getElementById('option_c_points').checked === true) {
      val = document.getElementById('option_c_points').value;
    }
    if (document.getElementById('option_d_points').checked === true) {
      val = document.getElementById('option_d_points').value;
    }
    quizid[`answer_${questionscount}`] = val;

    setquizid({ ...quizid })

    if (position === 'next') {
      if (val !== 0) {
        setquestionscount(questionscount + 1);
        addanswer(val);
        setselectedOption([...answer])
      } else {
        showError('Please select an option!');
      }
    } else if (position === 'prev') {
      setquestionscount(questionscount - 1);
      setselectedOption([...answer])
      removeanswer(questionscount);
    } else {
      if (val !== 0) {
        addanswer(val);
      } else {
        showError('Please select an option!');
      }
    }
    setquizid({ ...quizid })


    document.getElementById('option_a_points').checked = false;
    document.getElementById('option_b_points').checked = false;
    document.getElementById('option_c_points').checked = false;
    document.getElementById('option_d_points').checked = false;
  };
  const showError = (message) => {
    getNotification('error', {
      header: 'Error!',
      body: message,
    });
  };
  return (
    <div className='AssessmentHome'>
      <div className='row m-0 p-0'>
        <div className='col-12'>
          <span className='BlackForeground FontSize20'>
            New Primary Care Practice Assessment
          </span>
          <div>
            {inputVisible ? (
              <input
                ref={inputRef} // Set the Ref
                value={text} // Now input value uses local state
                onChange={e => {
                  setText(e.target.value);
                }}
              />
            ) : (
              <span onClick={() => setInputVisible(true)}>{text}</span>
            )}
          </div>
          <span className='FloatRight'>
            <button
              style={{ marginRight: '10px', cursor: 'pointer' }}
              className='NewButton'
              onClick={() => changecomponent('new assessment')}>
              Back
            </button>
            <button
              className='NewButton'
              style={{ cursor: 'pointer' }}
              onClick={() => submitButton('save_to_draft')}>
              + Save Draft
            </button>
          </span>
        </div>

        <div className='col-12'>
          <hr className='hr1' />
        </div>

        <div className='col-12'>
          <div className='TakeAssessmentDiv'>
            <div className='TakeAssessmentDiv-header dashboarddivgradient1 WhiteForeground'>
              <span className='TakeAssessmentDiv-tag FontSize12'>
                {`Milestone - ${questionscount + 1}`}
              </span>
              <span className='FloatRight FontSize16'>
                {' '}
                {questionsdata !== undefined
                  ? questionsdata.results[questionscount].milestone
                  : 'loading...'}
              </span>
            </div>
            <div className='TakeAssessmentDiv-maindiv'>
              {questionsdata !== undefined
                ? questionsdata.results[questionscount].question
                : 'loading...'}

              <div className='row m-0 p-0 mt-3' style={{ cursor: 'pointer' }}>
                <div className='col-md-3 '>
                  <div
                    onClick={(e) => {
                      if (e.target.children.length > 0) {
                        e.target.children[0].checked = true;
                      }
                    }}
                    className={`MCQDiv FontSize12 ${questionsdata !== undefined
                      ? questionsdata.results[questionscount].option_a_color
                      : 'loading...'
                      }`}>
                    <input
                      type='radio'
                      id='option_a_points'
                      name='radAnswer'
                      value={1}
                      checked={quizid[`answer_${questionscount}`] === "1" ? true : ""}
                    />
                    <br />
                    {questionsdata !== undefined
                      ? questionsdata.results[questionscount].option_a
                      : 'loading...'}
                  </div>
                </div>
                <div className='col-md-3 ' style={{ cursor: 'pointer' }}>
                  <div
                    onClick={(e) => {
                      if (e.target.children.length > 0) {
                        e.target.children[0].checked = true;
                      }
                    }}
                    className={`MCQDiv FontSize12 ${questionsdata !== undefined
                      ? questionsdata.results[questionscount].option_b_color
                      : 'loading...'
                      }`}>
                    <input
                      type='radio'
                      id='option_b_points'
                      name='radAnswer'
                      value={2}
                      checked={quizid[`answer_${questionscount}`] === "2" ? true : ""}
                    />
                    <br />
                    {questionsdata !== undefined
                      ? questionsdata.results[questionscount].option_b
                      : 'loading...'}
                  </div>
                </div>
                <div className='col-md-3 ' style={{ cursor: 'pointer' }}>
                  <div
                    onClick={(e) => {
                      if (e.target.children.length > 0) {
                        e.target.children[0].checked = true;
                      }
                    }}
                    className={`MCQDiv FontSize12 ${questionsdata !== undefined
                      ? questionsdata.results[questionscount].option_c_color
                      : 'loading...'
                      }`}>
                    <input
                      type='radio'
                      id='option_c_points'
                      name='radAnswer'
                      value={3}
                      checked={quizid[`answer_${questionscount}`] === "3" ? true : ""}
                    />
                    <br />
                    {questionsdata !== undefined
                      ? questionsdata.results[questionscount].option_c
                      : 'loading...'}
                  </div>
                </div>
                <div className='col-md-3 ' style={{ cursor: 'pointer' }}>
                  <div
                    onClick={(e) => {
                      if (e.target.children.length > 0) {
                        e.target.children[0].checked = true;
                      }
                    }}
                    className={`MCQDiv FontSize12 ${questionsdata !== undefined
                      ? questionsdata.results[questionscount].option_d_color
                      : 'loading...'
                      }`}>
                    <input
                      type='radio'
                      id='option_d_points'
                      name='radAnswer'
                      value={4}
                      checked={quizid[`answer_${questionscount}`] === "4" ? true : ""}
                    />
                    <br />
                    {questionsdata !== undefined
                      ? questionsdata.results[questionscount].option_d
                      : 'loading...'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-12 mt-2'>
          <div className='FloatRight'>
            {showsubmitbutton ? (
              <span className='MarginRight10'>
                <button
                  className='RedButton'
                  onClick={() => changecomponent('new assessment')}>
                  {'Cancel'}
                </button>
              </span>
            ) : (
              <></>
            )}
            {showsubmitbutton ? (
              <></>
            ) : (
              <div>
                {questionscount !== 0 ? (
                  <span className='MarginRight10'>
                    <button
                      className='RedButton'
                      onClick={() => {
                        scoreRecorder('prev');
                      }}>
                      {'Prev <'}
                    </button>
                  </span>
                ) : (
                  <></>
                )}
                <span className='MarginRight10'>
                  <button
                    className='RedButton'
                    onClick={() => {
                      scoreRecorder('next');
                    }}>
                    {'Next >'}
                  </button>
                </span>
              </div>
            )}
            {showsubmitbutton ? (
              <span>
                <button
                  style={{ cursor: 'pointer' }}
                  className='GreenButton'
                  onClick={() => {
                    scoreRecorder('final');
                  }}>
                  Save
                </button>
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Takeassessment;
