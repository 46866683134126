import React from 'react';
import { Spin } from 'antd';

const FullScreenLoader = () => {
  return (
    <div style={{
      position: 'fixed', // Full-screen
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.5)', // White background with opacity
      zIndex: 1000, // Ensure it's above other content
    }}>
      <Spin size="large" />
    </div>
  );
};

export default FullScreenLoader