import React, { useState } from 'react';
import './Milestones.scss';
import { Collapse } from 'antd';

const { Panel } = Collapse;

/**
 * Renders the Milestones component.
 *
 * @param {function} openTctModal - function to open TctModal
 * @param {object} allMilestones - object containing all milestones
 * @param {function} openAboutModal - function to open AboutModal
 * @return {JSX.Element} The rendered Milestones component
 */
const Milestones = ({ openTctModal, allMilestones, openAboutModal }) => {
  const [step, setStep] = useState(0);

  const prevStep = () => {
    if (step != 0) {
      setStep(step - 1);
    }
  };

  const nextStep = () => {
    if (step != allMilestones?.filtered_competencies?.length - 1) {
      setStep(step + 1);
    }
  };

  return (
    <Collapse expandIconPosition='end'>
      <Panel
        header={
          <div className='flex'>
            <p className='text-white m-auto'>
              Goals to reach
              <span
                style={{
                  backgroundColor: allMilestones?.target_phase?.color,
                  padding: '5px',
                  marginLeft: '10px',
                }}
                className='text-black'>
                {allMilestones?.target_phase?.name}
              </span>
            </p>
          </div>
        }>
        <div className='Milestones'>
          <div>
            <div className='flex items-center'>
              <h2>
                Milestone {allMilestones?.filtered_competencies[step]?.competency?.id}
              </h2>
              <span
                className='hoverIcon ml-10'
                onClick={() =>
                  openAboutModal(
                    allMilestones?.filtered_competencies[step]?.competency?.id
                  )
                }
              />
            </div>
            <p className='text-black'>
              {
                allMilestones?.filtered_competencies[step]?.competency
                  ?.description
              }
            </p>
            <div className='flex-vertical mt-15'>
              <div className='Milestones-card b-rounded-10 bg-yellow'>
                <p className='text-black'>
                  <b>Current State</b>
                </p>
                {
                  allMilestones?.filtered_competencies[step]?.current_option
                    ?.description
                }
              </div>
              <p className='mx-15 text-xxl'>{'>'}</p>
              <div className='Milestones-card b-rounded-10'>
                <p className='text-black'>
                  <b>Target State</b>
                </p>
                {
                  allMilestones?.filtered_competencies[step]?.target_option
                    ?.description
                }
              </div>
            </div>
            <div className='flex justify-center mt-15'>
              <div className='mt-15'>
                <button
                  type='button'
                  className='modal-dont-delete b-rounded-10'
                  onClick={() =>
                    openTctModal(
                      allMilestones?.filtered_competencies[step]?.competency?.id
                    )
                  }>
                  Improvement Tactics
                </button>
              </div>
            </div>
            <div className='flex justify-center mt-15'>
              <div className='flex items-center'>
                <button
                  className='p-5 pointer Milestones-btn b-rounded-5'
                  onClick={prevStep}>
                  Previous
                </button>
                <p className='font-18 mx-15 text-black'>
                  {step + 1} / {allMilestones?.filtered_competencies?.length}
                </p>
                <button
                  className='p-5 pointer Milestones-btn b-rounded-5'
                  onClick={nextStep}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </Collapse>
  );
};

export default Milestones;
