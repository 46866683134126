import React, {useEffect, useState} from 'react';
import {
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  PlusSquareFilled,
  SearchOutlined,
} from '@ant-design/icons';
import {Input, Table, Modal} from 'antd';
import {useForm, Controller} from 'react-hook-form';
import {InputWithLabel} from 'Components/atoms/form/InputWithLabel';
import moment from 'moment';
import DeleteConfirmationModal from '../../../../Components/DeleteConfirmationModal/DeleteConfirmationModal';
import {deletePFTList, patchPlan } from '../../../../Services/Pft.service';
import {Pftsteps} from './components';
import {getNotification} from 'Components/GetNotification';
// import './styles/PlanTransformation.scss';
import { useDispatch, useSelector } from 'react-redux';
import { 
  clearListView,
  fetchItems, 
  selectListViewPage, 
  selectListViewSearch, 
  setListViewType, 
  setListViewIsSearching 
} from 'ReduxV2/state/listView';
import { SearchAdd } from 'Components/molecules/form';

const {Column} = Table;

/**
 * Renders the PlanTransformation component.
 *
 * @return {JSX.Element} The rendered component.
 */
const PlanTransformation = ({ activeKey }) => {
  const [pftId, setPftId] = useState(0);
  const [createPft, setCreatePft] = useState(false);
  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [selectDeleteIdPat, setSelectDeleteIdPat] = useState();
  const [visibleModal, setVisibleModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModal, setEditModal] = useState({});
  const [pftPlanTitle, setPlanTitle] = useState('');
  const [searchTimer, setSearchTimer] = useState(null);

  const dispatch = useDispatch();

  const network = useSelector((state) => state.network.value);
  const listItems = useSelector((state) => state.listView.items);
  const totalItems = useSelector((state) => state.listView.count);
  const itemsPerPage = useSelector((state) => state.listView.perPage);
  const currentPage = useSelector((state) => state.listView.page);
  const itemsSearch = useSelector((state) => state.listView.search);
  const isSearching = useSelector((state) => state.listView.isSearching);

  //  TOdo: update these with action Plan stuff
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: {errors},
    watch,
    getValues,
  } = useForm({defaultValues: {why_sprinting: 'TO_CREATE_DEMAND'}});

  // const pftListResult = pftList?.results?.filter(
  //   ({title}) =>
  //     title && title.toLowerCase().includes(queryString.toLowerCase())
  // );

  const loadInspect = async (id) => {
    setPftId(id);
    setCreatePft(true);
  };

  const deletePFT = async () => {
    try {
      await deletePFTList(selectDeleteIdPat).call;
      dispatch(fetchItems());
      setConfirmationDelete(false);
    } catch (error) {}
  };

  const onCancel = () => {
    setPftId(0);
    setCreatePft(false);
  };

  const closeModal = () => {
    setVisibleModal(false);
    setPlanTitle('');
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setPlanTitle('');
  };

  /**
   * Handles the 'Ok' action.
   *
   * @param {Object} params - The parameters for the function.
   * @return {Promise} The result of the function execution.
   */
  const handleOk = async ()=>{

    const dataPlan = {
      title: editModal.title,
      created: moment(editModal.created),
      id: editModal.id
    }

    try {
      const resPlan = await patchPlan(dataPlan);

      dispatch(fetchItems());

      getNotification('success', {
        header: 'Success',
        body: 'Title changed.',
      });
      
    } catch (error) {
      getNotification('error', {
        header: 'Error',
        body: 'Something went wrong when saving the changes.',
      });
    }
  }

  useEffect(() => {
    dispatch(clearListView());
  }, []);

  // only load items if the ma action plans tab is active
  useEffect(() => {
    if(activeKey === 'ma-action-plans') {
      dispatch(clearListView());
      dispatch(setListViewType('ma_action_plans'));
      dispatch(fetchItems());
    }
  }, [network, activeKey]);

  useEffect(() => {
    dispatch(fetchItems());
  }, [createPft, currentPage]);

  useEffect(() => {
    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    const newTimer = setTimeout(() => {
      dispatch(setListViewIsSearching(true));
      dispatch(fetchItems());
    }, 1000);
    setSearchTimer(newTimer);
  }, [itemsSearch]);

  return (
    <>
      <div className='plantransformation'>
        {createPft ? (
          <Pftsteps
            onCancel={onCancel}
            pftId={pftId}
            pftPlanTitle={pftPlanTitle}
            setPlanTitle={setPlanTitle}
          />
        ) : (
          <div className="grid gap-8">
            <div className='flex justify-end'>
              <SearchAdd 
                isSearching={isSearching}
                onSearchChange={(value) => {
                  dispatch(selectListViewSearch(value));
                }}
                onAdd={() => {
                  setVisibleModal(true);
                }}/>
            </div>            
            <Table
              dataSource={listItems}
              pagination={{
                defaultPageSize: itemsPerPage,
                total: totalItems,
                onChange: (page) => {
                  dispatch(selectListViewPage(page));
                }
              }}>
              <Column
                className='pointer'
                title='Plan'
                dataIndex='title'
                key='title'
                render={(text, record) => text && text.trim() !== '' ? text : 'Untitled'}
                onCell={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      loadInspect(record.id);
                    },
                  };
                }}
              />
              <Column
                className='pointer'
                title='Date Created'
                dataIndex='created'
                key='created'
                render={(text) => <p>{moment(text).format('L')}</p>}
                onCell={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      loadInspect(record.id);
                    },
                  };
                }}
              />
              <Column
                className='pointer'
                title='Last Update'
                dataIndex='updated'
                key='updated'
                render={(text) => <p>{moment(text).format('L')}</p>}
                onCell={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      loadInspect(record.id);
                    },
                  };
                }}
              />
              <Column
                className='pointer'
                title='Status'
                dataIndex='status'
                key='status'
                onCell={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      loadInspect(record.id);
                    },
                  };
                }}
              />
              <Column
                className='pointer'
                title='Inspect'
                dataIndex='action'
                key='action'
                render={(_, record) => (
                  <div>
                    <a
                      data-testid='editPatButton'
                      className='ml-10'
                      onClick={() => {
                        console.log(record);
                        setEditModal(record)
                        setShowEditModal(true)
                      }}>
                      <EditOutlined style={{ color: '#888895' }} />
                    </a>

                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      className='ml-15'
                      onClick={() => loadInspect(record.id)}>
                      <EyeOutlined />
                    </a>

                    <a
                      className='ml-10'
                      onClick={() => {
                        setSelectDeleteIdPat(record?.id);
                        setConfirmationDelete(true);
                      }}>
                      <DeleteOutlined style={{color: '#F46767'}} />
                    </a>
                  </div>
                )}
              />
            </Table>
          </div>
        )}
        <DeleteConfirmationModal
          visible={confirmationDelete}
          cancel={() => setConfirmationDelete(false)}
          save={deletePFT}
        />
      </div>
      {/* TODO Separate modals in different components */}
      <Modal
      visible={showEditModal}
      onCancel={closeEditModal}
      footer={false}
      header={false}
      className='mission-modal'
      >
        <form onSubmit={handleSubmit(handleOk)}>
          <div className='flex flex-col'>
            <h1 className='text-center'>Update Action Plan Title</h1>
            <Controller
            name={`title`}
            control={control}
            render={({field}) => (
              <div className='w-6/12 mt-4'>
                <InputWithLabel
                  {...field}
                  label={'Title'}
                  value={editModal.title}
                  onChange={(e) => setEditModal({...editModal, title:e.target.value})}
                  required
                />
              </div>
            )}
            />
            <p>
              <span className='text-error-500'>*</span>These fields are required
            </p>
            <div className='flex flex-row justify-center gap-10 space-around mt-4'>
              <button
                type='submit'
                className='bg-cyan-500 p-2 rounded text-white'
                onClick={() => {
                  setShowEditModal(false);
                }}>
                Create
              </button>
              <button type='button' className='p-2' onClick={closeEditModal}>
                Cancel
              </button>
            </div>
          </div>
        </form>
      </Modal>
      <Modal
        visible={visibleModal}
        onCancel={closeModal}
        footer={false}
        header={false}
        className='mission-modal'>
        <form onSubmit={handleSubmit(() => {})}>
          <div className='flex flex-col'>
            <h1 className='text-center'>Create Action Plan</h1>
            <div className='w-6/12 mt-4'>
              <InputWithLabel
                label={'Title'}
                value={pftPlanTitle}
                onChange={(e) => setPlanTitle(e.target.value)}
                required
              />
            </div>
            <p>
              <span className='text-error-500'>*</span>These fields are required
            </p>
            <div className='flex flex-row justify-center gap-10 space-around mt-4'>
              <button
                type='submit'
                className='bg-cyan-500 p-2 rounded text-white'
                onClick={() => {
                  setCreatePft(true);
                  setVisibleModal(false);
                }}>
                Create
              </button>
              <button type='button' className='p-2' onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default PlanTransformation;
