/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'apiConfig';
import { AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import { AbortableRequestConfig } from 'types/Request';
import { API_BASE_URL } from "config/constants";
import { 
    PaginatedMeeting, 
    MeetingDetail, 
    MeetingGoal, 
    PostMeetingGoal 
} from "types/Meeting";

const headers = () => {
    return {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('idToken')}`,
        }
    }
}

export function getMeetings(start_date: Moment, orgId: number): {
    call: Promise<AxiosResponse<PaginatedMeeting>>;
    controller: AbortController;
} {
    const controller = new AbortController();
    let createMeetingUrl = `${API_BASE_URL}meetings/?page_size=100`;

    if (orgId) createMeetingUrl = createMeetingUrl + `&organizations=${orgId}`;
    if (start_date) createMeetingUrl = createMeetingUrl + `&start__gte=${moment(start_date).format('YYYY-MM-DDTHH:mm:ss')}`;

    return {
        call: axios.get(createMeetingUrl, {
            signal: controller.signal,
            ...headers()
        } as AbortableRequestConfig),
        controller
    }
}

export function getPastMeetings(start_date: Moment, orgId: number): {
    call: Promise<AxiosResponse<PaginatedMeeting>>;
    controller: AbortController;
} {
    const controller = new AbortController();
    let createMeetingUrl = `${API_BASE_URL}meetings/?page_size=100`;

    if (orgId) createMeetingUrl = createMeetingUrl + `&organizations=${orgId}`;
    if (start_date) createMeetingUrl = createMeetingUrl + `&start__lt=${moment(start_date).format('YYYY-MM-DDTHH:mm:ss')}`;

    return {
        call: axios.get(createMeetingUrl, {
            signal: controller.signal,
            ...headers()
        } as AbortableRequestConfig),
        controller
    }
}

export function getMeetingsByStatus(status: string): {
    call: Promise<AxiosResponse<PaginatedMeeting>>;
    controller: AbortController;
} {
    const controller = new AbortController();
    let createMeetingUrl = `${API_BASE_URL}meetings/?page_size=100&status__code=${status}`;

    return {
        call: axios.get(createMeetingUrl, {
            signal: controller.signal,
            ...headers()
        } as AbortableRequestConfig),
        controller
    }
}

export function getMeetingsByNetwork(id: number): {
    call: Promise<AxiosResponse<PaginatedMeeting>>;
    controller: AbortController;
} {
    const controller = new AbortController();
    let createMeetingUrl = `${API_BASE_URL}meetings/?organizations=${id}`;

    return {
        call: axios.get(createMeetingUrl, {
            signal: controller.signal,
            ...headers()
        } as AbortableRequestConfig),
        controller
    }
}

export function getMeetingsDatails(id: number):{
    call: Promise<AxiosResponse<MeetingDetail>>;
    controller: AbortController;
} {
    const controller = new AbortController();
    return {
        call: axios.get(`${API_BASE_URL}meetings/${id}/`, {
            signal: controller.signal,
            ...headers()
        } as AbortableRequestConfig),
        controller
    }
}

export function createMeeting(meet: any): {
    call: Promise<AxiosResponse<any>>;
} {
    return {
        call: axios.post(`${API_BASE_URL}meetings/`, meet, headers())
    }
}

export function deleteMeeting(id: number):{
    call: Promise<AxiosResponse<MeetingDetail>>;
    controller: AbortController;
} {
    const controller = new AbortController();
    return {
        call: axios.delete(`${API_BASE_URL}meetings/${id}/`, {
            signal: controller.signal,
            ...headers()
        } as AbortableRequestConfig),
        controller
    }
}

export async function saveComments(meetId: any, { note, observation, barriers, actions, feedback, action }: any) {
    const fetchNote = axios.patch(`${API_BASE_URL}meetings/${meetId}/notes/`, note, headers());
    const fetchObservation = axios.patch(`${API_BASE_URL}meetings/${meetId}/observation/`, observation, headers());
    const fetchBarries = axios.patch(`${API_BASE_URL}meetings/${meetId}/barriers/`, barriers, headers());
    const fetchActionChecks = axios.patch(`${API_BASE_URL}meetings/${meetId}/action-items-checks/`, action, headers());
    const fetchActionItems = actions.filter((action: any) => { 
        if (!action.actionId) axios.post(`${API_BASE_URL}meeting-action-items/`, { ...action, due_date: moment(action.due_date).format('YYYY-MM-DD') }, headers()) 
    });
    const patchActionItems = actions.filter((action: any) => { 
        if (action.actionId) axios.patch(`${API_BASE_URL}meeting-action-items/${action.actionId}/`, { ...action, due_date: moment(action.due_date).format('YYYY-MM-DD') }, headers()) 
    });
    const fetchFeedback = axios.patch(`${API_BASE_URL}meetings/${meetId}/feedback/`, feedback, headers());

    await Promise.all([
        fetchNote,
        fetchObservation,
        fetchBarries,
        ...fetchActionItems,
        ...patchActionItems,
        fetchFeedback,
        fetchActionChecks
    ]);
}

/**
 * @todo check double promise here
 */
export async function deleteAction(actionId: number): Promise<{
    call: Promise<AxiosResponse<void>>;
}> {
    return {
        call: axios.delete(`${API_BASE_URL}meeting-action-items/${actionId}/`, {
            ...headers()
        }),
    }
}

export function getMeetingGoals(id: number): {
    call: Promise<AxiosResponse<void>>;
    controller: AbortController;
} {

    const controller = new AbortController();
    return {
        call: axios.get(`${API_BASE_URL}meeting-goals/?meeting=${id}`, {
            signal: controller.signal,
            ...headers()
        } as AbortableRequestConfig),
        controller
    }
}

/**
 * @todo duplicate functionality with `patchMeetingGoal`
 */
export function updateMeetingGoals(id: number, data: MeetingGoal): {
    call: Promise<AxiosResponse<MeetingGoal>>;
} {
    return {
        call: axios.patch(`${API_BASE_URL}meeting-goals/${id}/`, data, headers())
    }
}


export function saveMeetingReflection(id: number, data: any): {
    call: Promise<AxiosResponse<any>>;
} {
    return {
        call: axios.patch(`${API_BASE_URL}meetings/${id}/`, data, headers())
    }
}

export function getAttendees(id: number): {
    call: Promise<AxiosResponse<any>>;
    controller: AbortController;
} {
    const controller = new AbortController();
    return {
        call: axios.get(`${API_BASE_URL}attendees/?meeting=${id}`, {
            signal: controller.signal,
            ...headers()
        } as AbortableRequestConfig),
        controller
    }
}

export function uploadCommentsFiles(meetId: any, file: any, url: any, field: any): {
    call: Promise<AxiosResponse<any>>;
} {
    let data = new FormData();
    data.append("file", file);
    data.append(field, meetId)
    return {
        call: axios.post(`${API_BASE_URL}${url}`, data, headers())
    }
}

export function getFiles(id: number, url: string): {
    call: Promise<AxiosResponse<any>>;
    controller: AbortController;
} {
    const controller = new AbortController();
    return {
        call: axios.get(`${API_BASE_URL}${url}${id}`, {
            signal: controller.signal,
            ...headers()
        } as AbortableRequestConfig),
        controller
    }
}

export function deleteFile(id: number, url: string) {
    return {
        call: axios.delete(`${API_BASE_URL}${url}${id}/`, {
            ...headers()
        }),
    }
}

export function getMeetingGoal(id: number, category: string): {
    call: Promise<AxiosResponse<any>>;
    controller: AbortController;
} {
    const controller = new AbortController();

    return {
        call: axios.get(`${API_BASE_URL}meeting-goals/?meeting=${id}&category=${category}`, {
            signal: controller.signal,
            ...headers()
        } as AbortableRequestConfig),
        controller
    }
}

export function postMeetingGoal(data: PostMeetingGoal): {
    call: Promise<AxiosResponse<MeetingGoal>>;
} {
    return {
        call: axios.post(`${API_BASE_URL}meeting-goals/`, data, headers())
    }
}

export function patchMeetingGoal(data: MeetingGoal): {
    call: Promise<AxiosResponse<MeetingGoal>>;
} {
    return {
        call: axios.patch(`${API_BASE_URL}meeting-goals/${data.id}/`, data, headers())
    }
}

export function deleteMeetingGoal(id: number): {
    call: Promise<AxiosResponse<void>>;
} {
    return {
        call: axios.delete(`${API_BASE_URL}meeting-goals/${id}/`, {
            ...headers()
        }),
    }
}