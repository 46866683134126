export function departmentData(state, payload) {
  return {
    ...state,
    departmentdata: {
      ispending: true,
      completed: false,
      failed: false,
      data: [],
    },
  };
}
export function departmentDataSuccess(state, payload) {
  return {
    ...state,
    departmentdata: {
      ispending: false,
      completed: true,
      failed: false,
      data: payload.department.results,
    },
  };
}
export function departmentDataFailed(state, payload) {
  return {
    ...state,
    departmentdata: {
      ispending: false,
      completed: false,
      failed: true,
      data: [],
    },
  };
}

export function createOrganisationGroupData(state, payload) {
  return {
    ...state,
    organisationgroupdata: {
      ispending: true,
      completed: false,
      failed: false,
      data: [],
    },
    isProcessing: true,
  };
}

export function createOrganisationGroupDataSuccess(state, payload) {
  return {
    ...state,
    organisationgroupdata: {
      ispending: false,
      completed: true,
      failed: false,
      data: payload.organisation,
    },
    isProcessing: false,
  };
}

export function createOrganisationGroupDataFailed(state, payload) {
  let errorMessage = {nameError: '', websiteError: ''};
  if (payload.createOrgnisationError) {
    payload.createOrgnisationError.map((item) => {
      if (item === 'Group already exists') {
        errorMessage.nameError = 'Group title already exists';
      }
      // if (item === 'Group with same website already exists') {
      //   errorMessage.websiteError = 'Group with same website already exists';
      // }
    });
  }
  return {
    ...state,
    organisationgroupdata: {
      ispending: false,
      completed: false,
      failed: true,
      data: [],
    },
    isProcessing: false,
    // name: {name: 'name', errorMessage: errorMessage.nameError},
    // website: {name: 'website', errorMessage: errorMessage.websiteError},
  };
}
export function createOrganisationGroupValidationErrors(state, payload) {
  const newState = {...state};
  newState.loading = false;
  for (let item of payload) {
    newState[item.path] = {
      ...newState[item.path],
      errorMessage: item.message,
    };
  }
  return {
    ...state,
    ...newState,
  };
}
export function updateErrorMessage(state, payload) {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      name: payload.name,
      errorMessage: '',
    },
    isProcessing: false,
  };
}
export function getOrganisationGroupListSuccess(state, payload) {
  let archivedOnly = false;
  let emptyResult = false;
  if (
    payload.listOrgResponse.total_count ===
    payload.listOrgResponse.archived_count
  ) {
    archivedOnly = true;
  }
  if (payload.listOrgResponse.count === 0) emptyResult = true;
  // BE updated added .results in payload.listOrgResponse
  return {
    ...state,
    getOrganisations: {
      isProcessing: false,
      success: true,
      error: null,
    },
    // totalOrganisationCount: payload.listOrgResponse.total_count,
    // archiveOrganisationCount: payload.listOrgResponse.archived_count,
    organisationGroupCount: payload.listOrgResponse.count,
    organisationGroupList: payload.listOrgResponse.results.filter((val)=> !val.is_deleted),
    organisationGroupListOffset: payload.offset,
    // archivedOnly: archivedOnly,
    // emptyResult: emptyResult,
    organisationGroupListSelectOptions: payload.listOrgResponse
      ? payload.listOrgResponse.results.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      : [],
  };
}

export function getOrganisationGroupListFailed(state, payload) {
  return {
    ...state,
    getOrganisations: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching list',
    },
  };
}
export function getOrganisationGroupListStarted(state) {
  return {
    ...state,
    organisationgroupdata: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}

export function fetchGroupDetailsStarted(state) {
  return {
    ...state,
    getGroupDetails: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}

export function fetchGroupDetailsSuccess(state, payload) {
  return {
    ...state,
    getGroupDetails: {
      isProcessing: false,
      success: true,
      error: null,
    },
    orgGroupData: payload.orgDetails,
    orgGroupList: payload.orgList,
  };
}
