import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Input } from 'antd';

interface IMilestones {
  competencies: {
    current_option: string,
    description: string,
    target_option: string,
    title: string,
    id: number,
    notes: string
  }[],
  title: string,
  user_pat: number
}

type PercentageCardDetailProps = {
  data: IMilestones;
  handleClose?: ()=> void
}


const { TextArea } = Input;

type MilestoneOptionsProp = {
  title: string;
  onClick: MouseEventHandler<HTMLDivElement>;
}

const MilestoneOptions:React.FC<MilestoneOptionsProp> = ({title, onClick}) => {
  const [milestoneId, milestoneTitle] = title.split('-')

  return <div className='flex hover:bg-black hover:text-white cursor-pointer rounded p-2 mx-12 my-2 w-80 text-base' data-value={milestoneId} onClick={onClick}>
    <div className='wfull flex'>{milestoneId} - {milestoneTitle}</div>
  </div>
}


const PercentageCardDetail: React.FC<PercentageCardDetailProps> = ({ data, handleClose }) => {

  let debounceTimer: any;

  const [competency, setCompetency] = useState({
    current_option: '',
    description: '',
    target_option: '',
    title: '',
    id: 0,
    notes: ''
  })

  useEffect(() => {
    setCompetency(data?.competencies[0])
  }, [data])

  const handleCompetencyClick = (e: React.MouseEvent<HTMLElement>) => {
    const milestone = e.currentTarget.getAttribute('data-value')
    const hasMilestoneInTitle = milestone?.includes('Milestone #')
    const newCompetency = hasMilestoneInTitle ? data.competencies.filter((el) => el.title.includes(milestone as string))[0] : data.competencies.filter((el) => el.id.toString() == milestone)[0] 
    setCompetency(newCompetency)
  }

  return (
    <div className='PercentageCardDetail pb-4 w-full min-h-[680px] bg-white flex-col rounded-lg '>
      <section className='p-2 mx-6'>
        <div className='flex items-center my-4'>
          <img
            className='arrow-back cursor-pointer'
            src='/img/arrowBack.png'
            alt='back'
            onClick={handleClose}
          />
          <div className='ml-4'>Return to summary</div>
        </div>
        <div className='text-lg font-semibold'>{data.title}</div>
      </section>
      <div className='flex h-100'>
        <section className='w-40 flex-col'>
          {data?.competencies?.map((val) => (
                val?.title?.includes('Milestone #') ? (
                      <MilestoneOptions title={val?.title} onClick={handleCompetencyClick}/>
                  )
                  : (
                    <div className='flex hover:bg-black hover:text-white cursor-pointer rounded p-2 mx-12 my-2 w-80 text-base' data-value={val.id} onClick={handleCompetencyClick}>
                      <div className='w-50 flex'>Milestone #{val.id}<div className='ml-2'>-</div></div><div className='w-70'> {val.title}</div>
                    </div>
                  )
          ))}
        </section>
        <section className='w-70 h-100 flex flex-col border-left px-8'>
            {competency && competency?.title?.includes('Milestone #') ? (
                <div className='w-100 flex mt-2 mb-3'>{competency?.title}</div>
              )
              : (
                <div className='w-100 flex mt-2 mb-3'>Milestone #{competency?.id} <div className='px-2'>- {competency?.title}</div></div>
              )
            }
          <div className='mb-60 min-h-[120px]'>{competency?.description}</div>
          <section className='h-30'>
            <div className='text-red-600 my-4'>Answer Selected:</div>
            <div>{competency?.current_option}</div>
            <div className='text-green-600 my-4'>Target State:</div>
            <div>{competency?.target_option}</div>
          </section>
          <section className='pb-4'>
            <div className='my-4'>Notes:</div>
            <TextArea
              rows={4}
              placeholder={competency?.notes}
              defaultValue={competency?.notes}
              disabled
            />
          </section>
        </section>
      </div>
    </div>
  );
};

export default PercentageCardDetail;
