import {
  FETCH_MSOP_REPORT_START,
  FETCH_MSOP_REPORT_FAILED,
  FETCH_MSOP_REPORT_SUCCESS,
} from '../../Redux.constants';
import {Default} from './InitialState';
import {fetchMsopStarted, fetchMsopFailed, fetchMsopSuccess} from './Helper';

const FetchMsopReducer = (state = Default(), action) => {
  switch (action.type) {
    case FETCH_MSOP_REPORT_START:
      return fetchMsopStarted(state, action);
    case FETCH_MSOP_REPORT_FAILED:
      return fetchMsopFailed(state, action);
    case FETCH_MSOP_REPORT_SUCCESS:
      return fetchMsopSuccess(state, action.payload);
    default:
      return state;
  }
};
export default FetchMsopReducer;
