import React, {useEffect, useState} from 'react';
import {Modal, Input} from 'antd';
import {useSelector} from 'react-redux';
import {getPatCategories, getUsersPatDetail, PatTypes} from 'Services/UserPat.service';
import {getNotification} from 'Components/GetNotification';
import {updatePatQuestionsAndNotes} from 'Services/PftJourney.service';
import { useTranslation } from "react-i18next"
import { PatOption } from 'types/Pat';

const {TextArea} = Input;

type CompleteMilestoneModalProps = {
  setSelectedOption: React.Dispatch<React.SetStateAction<PatOption | undefined>>;
  competency: any;
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
};

type OptionCardProps = {
  isDisabled: boolean;
  competencyId: number;
  description: string;
  points: number;
  onClick: () => void;
  isPreviousAnswer: boolean;
  isCurrentAnswer: boolean;
};

const OptionCard: React.FC<OptionCardProps> = ({
  isDisabled = false,
  competencyId = 0,
  description = '',
  points = 0,
  isPreviousAnswer = false,
  isCurrentAnswer = false,
  onClick,
}) => {
  let borderClass = 'border-white';
  if (isPreviousAnswer) {
    borderClass = 'border-negative';
  }
  if (isCurrentAnswer) {
    borderClass = 'border-positive';
  }

  const isDisabledClass = isDisabled ? 'cursor-not-allowed bg-gray-600' : ''

  return (
    <button
      disabled={isDisabled}
      className={`rounded-lg flex-1 min-h-[350px] flex flex-col justify-between shadow-xl p-4 transition-colors text-left border-[3px] border-solid ${borderClass} ${isDisabledClass}`}
      onClick={onClick}>
      <div className='top grid gap-2.5'>
        <div className='bg-primary rounded-full w-6 h-6 place-items-center text-center'>
          <span className='text-white text-sm'>{points + 1}</span>
        </div>
        <span className='font-medium text-base'>{description ?? ''}</span>
      </div>
      {isPreviousAnswer && (
        <div className='bottom'>
          <span className='font-bold'>Previous Answer</span>
        </div>
      )}
    </button>
  );
};

const QuestionCard: React.FC<{
  competencyInfo: any;
  question: any;
  previousAnswer: any;
  currentAnswer: any;
  onOptionClick: (option: any) => void;
  initialNotesValue: string | null;
  onNotesChange: (value: string) => void;
}> = ({
  competencyInfo,
  question,
  previousAnswer,
  currentAnswer,
  onOptionClick,
  initialNotesValue,
  onNotesChange,
}) => {
  let debounceTimer: any;

  const handleNotesChange = (e: any) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      onNotesChange(e.target.value);
    }, 1000);
  };

  return (
    <div className='grid gap-6'>
      <span className='block text-lg max-w-[80ch]'>
        {question?.description ?? ''}
      </span>
      <div className='current-question flex gap-10'>
        {question?.pat_options.map((item: any, index: number) => (
          <OptionCard
            isDisabled={item.order < previousAnswer?.order}
            isPreviousAnswer={item.id === previousAnswer?.id}
            isCurrentAnswer={item.id === currentAnswer?.id}
            competencyId={item.competency}
            description={item.description}
            points={item.points}
            key={index}
            onClick={() => {
              onOptionClick(item);
            }}
          />
        ))}
      </div>
      <h2>Notes:</h2>
      {initialNotesValue !== null && (
        <TextArea
          placeholder='Notes'
          defaultValue={initialNotesValue}
          rows={6}
          style={{resize: 'none', borderRadius: '4px'}}
          onChange={handleNotesChange}
        />
      )}
    </div>
  );
};

export const CompleteMilestoneModal: React.FC<CompleteMilestoneModalProps> = ({
  setSelectedOption,
  competency,
  isOpen,
  onCancel,
  onOk,
}) => {
  const [pat, setPat] = useState<any>(null); // used to rebuild the options and notes
  const [showAssessment, setShowAssessment] = useState<boolean>(
    competency?.completed
  );
  const [title, setTitle] = useState<string>('');
  const [AllPatQuestions, setAllPatQuestions] = useState<any>([]);
  const [allPatAnswers, setAllPatAnswers] = useState<any>([]);
  const [thisPatQuestion, setThisPatQuestion] = useState<any>(null);
  const [previousAnswer, setPreviousAnswer] = useState<any>(null);
  const [currentAnswer, setCurrentAnswer] = useState<any>(null);
  const [notes, setNotes] = useState<string | null>(null);
  const [patType, setPatType] = useState<PatTypes | undefined>(undefined);

  // const dispatch = useDispatch();
  const patId = useSelector((state: any) => state.pat.value);
  const patCompetencyId = competency?.pat_competency?.id ?? null;

  const LoadPat = async () => {
    try {
      const res = await getUsersPatDetail(patId).call;

      if (res?.data) {
        setPat(res.data);
        setPatType((res.data.type.code as PatTypes));
        setTitle(res.data.title);

        const options = res.data.pat_options.map(
          (item: any) => item.pat_option
        );
        setAllPatAnswers(options);
      }
    } catch (error) {}
  };

  const LoadAllPatQuestions = async () => {
    try {
      const res = await getPatCategories(patType).call;
      if (res?.data) {
        //@ts-ignore
        const allQuestions = res.data.map((item: any) => item.pat_competencies);
        setAllPatQuestions(allQuestions.flat());
      }
    } catch (error) {}
  };

  const findAndSetPreviousAnswer = () => {
    setPreviousAnswer(0);
    const answer = allPatAnswers.find(
      (item: any) => item.competency === patCompetencyId
    );
    setPreviousAnswer(answer);
  };

  const findAndSetPreviousNotes = () => {
    if (pat) {
      const previousNotesIndex = pat.competency_notes.findIndex(
        (item: any) => item.pat_competency === previousAnswer?.competency
      );

      if (previousNotesIndex > -1) {
        setNotes(pat.competency_notes[previousNotesIndex].notes);
      } else {
        setNotes('');
      }
    }
  };

  const findAndSetPatQuestion = () => {
    const thisQuestion = AllPatQuestions.find(
      (item: any) => item.id === patCompetencyId
    );
    setThisPatQuestion(thisQuestion);
  };

  const buildAndSavePatAnswersAndNotes = (
    pat_option: number,
    notes: string
  ) => {
    if (pat) {
      let answerPayload = pat.pat_options.map((item: any) => {
        return {
          pat_option: item.pat_option.id,
        };
      });

      // Update the current answer
      const previousAnswerIndex = answerPayload.findIndex(
        (item: any) => item.pat_option === previousAnswer?.id
      );

      answerPayload[previousAnswerIndex] = {
        pat_option: pat_option,
      };

      // Shape the payload
      const patchPayload = {
        pat_options: answerPayload,
        competency_notes: pat.competency_notes.map((item: any) => {
          return {
            pat_competency: item.pat_competency,
            notes: item.notes,
          };
        }),
      };

      // Update the current note
      const previousNotesIndex = pat.competency_notes.findIndex(
        (item: any) => item.pat_competency === previousAnswer?.competency
      );

      if (previousNotesIndex > -1) {
        patchPayload.competency_notes[previousNotesIndex] = {
          pat_competency: previousAnswer?.competency,
          notes: notes,
        };
      } else {
        patchPayload.competency_notes.push({
          pat_competency: previousAnswer?.competency,
          notes: notes,
        });
      }

      updatePatQuestionsAndNotes(patId, patchPayload);
    }
  };

  useEffect(() => {
    LoadPat();
    findAndSetPatQuestion();
    findAndSetPreviousAnswer();
  }, []);

  useEffect(() => {
    if(patType) LoadAllPatQuestions();
  }, [patType])

  useEffect(() => {
    findAndSetPatQuestion();
    findAndSetPreviousAnswer();
    if (competency?.completed) {
      setShowAssessment(true);
    }
  }, [isOpen]);

  useEffect(() => {
    findAndSetPreviousNotes();
  }, [previousAnswer]);

  useEffect(() => {
    setSelectedOption(currentAnswer)
  }, [currentAnswer])

  // i18n translation hook
  const [t] = useTranslation()

  return (
    <Modal
      className='complete-milestone-modal'
      wrapClassName='rounded-2xl'
      open={isOpen}
      width={showAssessment ? '80%' : 520}
      bodyStyle={{maxHeight: '90vh', overflow: 'auto', borderRadius: '16px'}}
      onCancel={onCancel}
      afterClose={() => {
        setShowAssessment(false);
      }}
      footer={false}>
      <div className='mt-4 px-8'>
        {!showAssessment && (
          <div className='grid gap-4'>
            <h1 className='text-4xl leading-tight text-center'>
              {t("Complete_Milestone.Complete_Milestone")}?
            </h1>
            <span className='font-normal text-base text-center'>
              {t("Complete_Milestone.Completed_tactis")}?
            </span>
            <div className='flex justify-center align-center gap-2'>
              <button
                className='text-base font-medium px-4 py-2 text-white rounded bg-primary hover:bg-primary-hover'
                onClick={() => {
                  setShowAssessment(true);
                }}>
                {t("Button.Complete_milestone")}.
              </button>
              <button
                className='text-base font-medium px-4 py-2'
                onClick={onCancel}>
                {t("Button.No_complete_milestone")}.
              </button>
            </div>
          </div>
        )}
        {showAssessment && (
          <div className='grid gap-6'>
            <div className='header'>
              <div className='flex justify-between gap-4'>
                <div className='grid gap-2'>
                  <h1 className='text-4xl'>{title}</h1>
                    {competency?.pat_competency?.title?.includes('Milestone #') ? (
                        <h2>{competency.pat_competency.title}</h2>
                      )
                      : (
                        <h2>{`Milestone #${competency.pat_competency.ref_id} - ${competency.pat_competency.title}`}</h2>
                      )
                    }
                </div>
                <div className='flex gap-4 items-start'>
                  <button
                    className='px-6 py-3 rounded border border-solid transition-colors border-primary hover:border-primary-hover'
                    onClick={onCancel}>
                    {t("Button.Cancel")}
                  </button>
                  <button
                    className='px-6 py-3 rounded text-white font-medium text-base transition-colors bg-primary hover:bg-primary-hover'
                    onClick={() => {
                      onOk();
                      LoadPat();
                      //TODO: Add loader
                    }}>
                    {t("Button.Complete")}
                  </button>
                </div>
              </div>
            </div>
            <div className='w-full'>
              <QuestionCard
                competencyInfo={competency}
                question={thisPatQuestion}
                previousAnswer={previousAnswer}
                currentAnswer={currentAnswer}
                initialNotesValue={notes}
                onNotesChange={(value: string) => {
                  setNotes(value);
                  buildAndSavePatAnswersAndNotes(
                    currentAnswer?.id ?? previousAnswer?.id,
                    value ?? ''
                  );
                  getNotification('success', {
                    header: 'Success',
                    body: 'Note updated.',
                  });
                }}
                onOptionClick={(option: any) => {
                  if (pat) {
                    setCurrentAnswer(option);
                    buildAndSavePatAnswersAndNotes(option.id, notes ?? '');
                    getNotification('success', {
                      header: 'Success',
                      body: 'Answer updated.',
                    });
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
