import React, {useState} from 'react';
import {ProgressBar} from './ProgressBar';

import { useTranslation } from "react-i18next"

type Props = {
  milestoneData: {
    competency: string;
    category: string;
    score: {
      name: string;
      count: number;
    }[];
  }[];
};

export const MilestoneOverviewTable = ({milestoneData}: Props) => {
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const handleSort = (field: string) => {
    if (sortBy === field) {
      // If already sorting by the selected field, toggle the order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // If sorting by a new field, set the new field and order
      setSortBy(field);
      setSortOrder('asc');
    }
  };

  const sortedData = [...milestoneData].sort((a, b) => {
    const aValue = sortBy === 'competency' ? a.competency : a.category;
    const bValue = sortBy === 'competency' ? b.competency : b.category;

    // Compare values based on the sorting order
    return sortOrder === 'asc'
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue);
  });

  // i18n translation hook
  const [t] = useTranslation()

  return (
    <div style={{maxHeight: '400px', overflowY: 'auto'}}>
      <table className='w-full'>
        <thead
          className='bg-gray-300'
          style={{position: 'sticky', top: 0, zIndex: 1}}>
          <tr>
            <th
              className='cursor-pointer'
              onClick={() => handleSort('competency')}>
              {t("Milestones")}
            </th>
            <th
              className='cursor-pointer'
              onClick={() => handleSort('category')}>
              {t("Category")}
            </th>
            <th>{t("Score_Distribution")}</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((milestone, index) => (
            <tr key={index}>
              <td className='py-2'>{milestone.competency}</td>
              <td>{milestone.category}</td>
              <td>
                <ProgressBar
                  phases={milestone.score}
                  progressType='MilestoneOverview'
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MilestoneOverviewTable;
