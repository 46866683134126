import React, {useState} from 'react';
import './CategoriesDropdowns.scss';
import {Col, Row} from 'antd';
import {OverviewCard} from '../OverviewCard';
import {ProjectsLIst} from '../ProjectsLIst';
import {PerformanceCategory, PerformanceCategoryMap} from 'types/Performance';

type AuxDataMap = {
  [key: string]: {
    title: string;
    color: string;
  };
};

const initialAuxData: AuxDataMap = {
  quality: {
    title: 'Quality',
    color: '#124E86',
  },
  patient_safety: {
    title: 'Patient Safety',
    color: '#0D7D47',
  },
  patient_experience: {
    title: 'Patient Experience',
    color: '#D15800',
  },
  cost_of_care: {
    title: 'Cost of Care',
    color: '#64C262',
  },
  organizational_reputation: {
    title: 'Organizational Reputation',
    color: '#4C2B6C',
  },
  star_rating: {
    title: 'STAR Rating',
    color: '#EADC5E',
  },
  provider_revenue: {
    title: 'Provider Revenue',
    color: '#56D1EC',
  },
  other: {
    title: 'Other',
    color: '#8E1F3A',
  },
};

const CategoryDropdown: React.FC<{
  title: string;
  backgroundColor: string;
  projects: PerformanceCategory[];
}> = ({title, backgroundColor, projects}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className={`CategoriesDropdowns-container ${
          isOpen && 'border-top-none'
        }`}
        style={{
          backgroundColor: backgroundColor,
        }}>
        <p className='text-white'>{title}</p>
        <hr className='hr-white' />
        <Row gutter={5} className='mt-10'>
          <Col span={5}>
            <OverviewCard
              isSmallText
              quantity={projects.length}
              key_card='projects'
            />
          </Col>
          {title == 'Quality' ? (
            <Col span={5}>
              <OverviewCard
                isSmallText
                quantity={projects?.reduce(
                  (total, item) => total + item?.early_wins,
                  0
                )}
                key_card='early_wins'
              />
            </Col>
          ) : (
            <Col span={5}>
              <OverviewCard
                isSmallText
                quantity={projects?.reduce(
                  (total, item) => total + item?.sprints,
                  0
                )}
                key_card='sprints'
              />
            </Col>
          )}
          <Col span={5}>
            <OverviewCard
              isSmallText
              quantity={projects?.reduce(
                (total, item) => total + (item?.status == 'COMPLETED' ? 1 : 0),
                0
              )}
              key_card='completions'
            />
          </Col>
          <Col span={4}>
            <OverviewCard
              isSmallText
              quantity={projects?.reduce(
                (total, item) => total + item?.barriers,
                0
              )}
              key_card='barriers'
            />
          </Col>
          <Col span={5}>
            <OverviewCard
              isSmallText
              quantity={projects?.reduce(
                (total, item) => total + item?.lessons_learned,
                0
              )}
              key_card='lessons_learned'
            />
          </Col>
        </Row>
        <div
          className={`text-end mt-15 ${projects.length ? 'block' : 'd-none'}`}>
          <p
            className={`text-white pointer`}
            onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? 'Hide details' : 'Show details'}
          </p>
        </div>
      </div>
      <div className={`${isOpen ? 'block' : 'hidden'}`}>
        <ProjectsLIst projects={projects} />
      </div>
    </>
  );
};

/**
 * Render dropdown menus for categories.
 *
 * @param {PerformanceCategoryMap} categories - The categories data.
 * @return {JSX.Element} - The rendered dropdown menu.
 */
const CategoriesDropdowns: React.FC<{
  categories: PerformanceCategoryMap;
}> = ({categories}) => {
  const categoryNames = Array.from(Object.keys(categories));
  const categoriesAuxData = initialAuxData;

  return (
    <div className='CategoriesDropdowns'>
      {categories &&
        categoryNames.map((item_key, index) => {
          const auxData = categoriesAuxData[item_key];
          const projects = categories[item_key];
          return (
            <div key={index}>
              <CategoryDropdown
                title={auxData?.title}
                backgroundColor={auxData?.color}
                projects={projects}
              />
            </div>
          );
        })}
    </div>
  );
};

export default CategoriesDropdowns;
