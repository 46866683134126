import {
  GET_MISSION_PROBLEM_TYPE_FAILED,
  GET_MISSION_PROBLEM_TYPE_START,
  GET_MISSION_PROBLEM_TYPE_SUCCESS,
  SAVE_MISSION_FAILED,
  SAVE_MISSION_START,
  SAVE_MISSION_SUCCESS,
  ON_CHANGE_OBJECTIVE,
  ON_CHANGE_MISSION_FORM,
  FETCH_QUESTIONS_STEP2_START,
  FETCH_QUESTIONS_STEP2_SUCCESS,
  FETCH_QUESTIONS_STEP2_FAILED,
  FETCH_QUESTIONS_STEP2_ONCHANGE,
  FETCH_QUESTIONS_STEP2_SELECTED,
  UPDATE_QUEST_ANSWER,
} from '../../Redux.constants';
import {
  // Common
  onChangeMissionForm,

  // Step1
  getMissionProblemTypeFailed,
  getMissionProblemTypeStart,
  getMissionProblemTypeSuccess,
  createMissionFailed,
  createMissionStart,
  createMissionSuccess,
  onChangeObjective,

  // Step2
  fetchQuestionStart,
  fetchQuestionSuccess,
  fetchQuestionFailed,
  questQuestionSelected,
  questAnswerUpdate,
} from './helper';

import { INITIAL_STATE } from './initialState';

// eslint-disable-next-line max-lines-per-function
export default function CreateMissionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MISSION_PROBLEM_TYPE_START:
      return getMissionProblemTypeStart(state, action.payload);
    case GET_MISSION_PROBLEM_TYPE_SUCCESS:
      return getMissionProblemTypeSuccess(state, action.payload);
    case GET_MISSION_PROBLEM_TYPE_FAILED:
      return getMissionProblemTypeFailed(state, action.payload);
    case SAVE_MISSION_START:
      return createMissionStart(state, action.payload);
    case SAVE_MISSION_SUCCESS:
      return createMissionSuccess(state, action.payload);
    case SAVE_MISSION_FAILED:
      return createMissionFailed(state, action.payload);
    case ON_CHANGE_OBJECTIVE:
      return onChangeObjective(state, action.payload);
    case ON_CHANGE_MISSION_FORM:
      return onChangeMissionForm(state, action.payload);
    case FETCH_QUESTIONS_STEP2_START:
      return fetchQuestionStart(state, action.payload);
    case FETCH_QUESTIONS_STEP2_SUCCESS:
      return fetchQuestionSuccess(state, action.payload);
    case FETCH_QUESTIONS_STEP2_FAILED:
      return fetchQuestionFailed(state, action.payload);
    case FETCH_QUESTIONS_STEP2_ONCHANGE:
      return onChangeMissionForm(state, action.payload);
    case FETCH_QUESTIONS_STEP2_SELECTED:
      return questQuestionSelected(state, action.payload);
    case UPDATE_QUEST_ANSWER:
      return questAnswerUpdate(state, action.payload);
    default:
      return { ...state };
  }
}
