import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Col, Row, Modal, Alert } from 'antd';
import { useParams } from 'react-router-dom';

import { deleteSprintEngagged, getSprintEngagedById, patchSprintEngagged, postSprintEngagged } from '../../../../../../../../../../Services/SprintTeams.service';
import { getNotification } from '../../../../../../../../../../Components/GetNotification';
import { getMemberList, postTeamPersona, updateMember } from '../../../../../../../../../../Services/Teams.service';
import {
  getSprint,
} from '../../../../../../../../../../Services/Sprints.service';
import TeamCard from '../../../../../Overview/components/TeamCard/TeamCard';
import ModalPersona from '../../../../../ModalPersona/ModalPersona';

import './styles/Personas.scss';

import { useTranslation } from "react-i18next"

/**
 * Renders the Personas component.
 *
 * @return {JSX.Element} The rendered component.
 */
const Personas = () => {
  const refForm = useRef();
  const [modalTitle, setModalTitle] = useState('Add Team Member');
  const [namePersona, setNamePersona] = useState('');
  const [selectedPersona, setSelectedPersona] = useState({});
  const [allStaff, setAllStaff] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const { register, handleSubmit, control, setValue, getValues, reset, formState: { errors }, watch } = useForm({
    defaultValues: {
      demand_prob_score: "1",
      trust_other_score: "1",
      how_serious: "1",
      difficulty: "1",
      is_problem: "yes",
      persona: null,
      right_influencer: "2",
    },
  });
  const { id } = useParams();

  const [t] = useTranslation()

  const sprint = useSelector((state) => {
    return state.sprint.value;
  });

  const loadAttendees = async () => {
    try {
      const res = await getMemberList(id).call;
      setAttendees(res.data?.results?.map(att => ({ value: att.id, label: att.name })));
    } catch (error) { }
  };

  const loadSprint = async () => {
    if (sprint) {
      try {

        const res = await getSprint(sprint).call;
        setAllStaff(res.data?.engaged_team.map((team) => {
          return team || {};
        }) || []);
      } catch (error) { }
    }
  }

  /**
   * Saves personas information.
   *
   * @param {Object} params - The parameters for saving personas information.
   * @return {Promise<void>} A promise that resolves when the information is saved.
   */
  const savePersonasInformation = async (params) => {
    if (!params?.persona) return
    const data = {
      ...params,
      sprint,
      is_problem: params?.is_problem == 'yes' ? true : false,
      right_influencer: params?.right_influencer == '1' ? true : false,
    };

    try {
      if (params?.id) {
        await patchSprintEngagged(data).call;
      } else {
        await postSprintEngagged(data).call;
      }
      setVisibleModal(false);
      loadSprint();
    } catch (error) {
      getNotification('error', {
        header: 'Error',
        body: error.response.data?.non_field_errors?.join('.'),
      });
    }
  }

  /**
   * Deletes a team.
   *
   * @param {type} id - the ID of the team to delete
   * @return {type} undefined
   */
  const deleteTeam = async (id) => {
    try {
      await deleteSprintEngagged(id).call;
    } catch (error) { }
    loadSprint();
  };

  /**
   * Edits a team member in the sprint.
   *
   * @param {type} id - The ID of the team member to edit.
   * @return {type} A Promise that resolves to the edited team member.
   */
  const editTeam = async (id) => {
    reset();
    try {
      const res = await getSprintEngagedById(id).call;
      Object.keys(res?.data).map((key) => {
        if (key == 'is_problem') {
          setValue(key, res?.data[key] ? 'yes' : 'no');
        } else if (key == 'persona') {
          setValue(key, res?.data[key].id);
        } else if (key == 'right_influencer') {
          setValue(key, res?.data[key] ? '1' : '2');
        } else {
          setValue(key, String(res?.data[key]));
        }
      });
      setVisibleModal(true);
      setModalTitle(`Edit ${t('commons.intervention')}-Specific Team Member`);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Updates the team with the provided data.
   *
   * @param {Object} data - The data to update the team with.
   * @param {string} itemId - The ID of the item.
   * @return {Promise<void>} A promise that resolves when the team is updated.
   */
  const updateTeam = async (data, itemId) => {
    const personaId = allStaff.find((team) => team.id == getValues('id'))?.persona?.id || itemId || 0;
    try {
      await updateMember({
        id: personaId,
        ...data
      }).call;
      savePersonasInformation({
        id: getValues('id'),
        ...data,
      });
      reset();
    } catch (error) {
      console.log(error)
    }
    loadSprint();
  };

  /**
   * Updates the persona data with the given key-value pair.
   *
   * @param {string} key - The key of the data to be updated.
   * @param {any} data - The new value for the specified key.
   * @return {void} - This function does not return anything.
   */
  const updatePersonaData = (key, data) => {
    setSelectedPersona({ ...selectedPersona, [key]: data });
    loadAttendees();
  };

  const addPersona = async () => {
    try {
      await postTeamPersona([
        {
          name: namePersona,
          email: '',
          user: '',
          organization: id,
        },
      ]).call;
      setNamePersona("");
      loadAttendees();
    } catch (error) { }
  };

  const watchIsProblemField = watch('is_problem');
  const watchPersonaId = watch('persona');

  useEffect(() => {
    loadAttendees();
    loadSprint();
  }, [setVisibleModal])

  return (
    <div className='personas'>
      <form onSubmit={handleSubmit(savePersonasInformation)}>
        <div>
          <div className="flex justify-end font-18">
            <div>
              <div>
                <button type="submit" className="btn-edit d-none" ref={e => refForm.current = e}>Save</button>
              </div>
            </div>
          </div>
        </div>
        <Row gutter={10} className={"p-10"}>
          <Col xs={24}>
            <p className="float-left">Who needs to be engaged in this {t('commons.intervention')}?</p>
            <div className="float-right">
              <button type="button" className="ant-btn ant-btn-primary" onClick={() => {
                setModalTitle('Add Team Member');
                setVisibleModal(true);
                reset();
              }}>
                Add Team Member
                <span className="pl-5">+</span>
              </button>
            </div>
          </Col>
        </Row>
        <Row gutter={10} className={"p-10"}>
          <Col xs={24}>
            <div className="row-container">
              <Row gutter={10}>
                {(allStaff?.length || 0) === 0 && (
                  <Col xs={24}>
                    <div className="no-data">
                      <center>
                        No members have been added yet. Start create your<br />
                        sprint’s team clicking the “Add” button.
                      </center>
                    </div>
                  </Col>
                )}
                {allStaff?.map((item, index) => (
                  <Col xs={24} sm={24} md={12} lg={6} key={index}>
                    <TeamCard
                      item={item}
                      deleteMember={deleteTeam}
                      editMember={editTeam}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
        </Row>
      </form>
      <Modal
        title={modalTitle}
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        footer={false}
        className='mission-modal'
        style={{ height: 'calc(100vh - 100px)' }}
        bodyStyle={{ overflowY: 'scroll' }}>
        <form onSubmit={handleSubmit(savePersonasInformation)}>
          <ModalPersona
            attendees={attendees}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            register={register}
            watchPersonaId={watchPersonaId}
            watchIsProblemField={watchIsProblemField}
            setVisibleModal={setVisibleModal}
            addPersona={addPersona}
            namePersona={namePersona}
            setNamePersona={setNamePersona}
            updatePersonaData={updatePersonaData}
            onSave={(itemId) => {
              updateTeam(selectedPersona, itemId);
              setVisibleModal(false);
            }}
            onTop={<>
              <Alert message={`The following fields are ${t('commons.intervention').toLowerCase()}-specific. Edits made here will not impact the mission-level team member cards.`} type="info" />
            </>}
          />
        </form>
      </Modal>
    </div>
  )
};

export default Personas;
