import React, {useState} from 'react';
import {Badge, Button, Col, Row} from 'antd';
import {
  RightCircleFilled,
  UpCircleFilled,
  RightOutlined,
} from '@ant-design/icons';
import {useHistory, useParams} from 'react-router-dom';
import {PerformanceMissionSprint} from 'types/Performance';

import { useTranslation } from "react-i18next"

type ProjectSprintItemRouteParams = {
  id: string;
};

/**
 * Renders a ProjectSprintItem component.
 *
 * @param {Object} props - The props object containing mission_id, sprint, and index.
 * @return {JSX.Element} The rendered ProjectSprintItem component.
 */
const ProjectSprintItem: React.FC<{
  mission_id: number;
  sprint: PerformanceMissionSprint;
  index: number;
}> = ({mission_id, sprint, index}) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const [t] = useTranslation()

  const {id} = useParams<ProjectSprintItemRouteParams>();

  const toggleOpen = () => setIsOpen(!isOpen);

  const sprint_id: number = sprint?.id ?? 0;

  return (
    <>
      <Row
        gutter={12}
        key={`sprint-${index}`}
        className={`ProjectsLIst-SprintItem ${
          isOpen && 'row-white'
        } mb-15 ml-5 mr-5`}>
        {!isOpen && (
          <>
            <Col span={13}>
              <div className='ProjectsLIst-container-data flex-left bordered'>
                {sprint?.name}
              </div>
            </Col>
            <Col span={3}>
              <div className='ProjectsLIst-container-data text-center capitalized bordered'>
                {sprint?.status?.toString().replace('_', ' ').toLowerCase() ||
                  ''}
              </div>
            </Col>
            <Col span={2}>
              <div className='ProjectsLIst-container-data text-center bordered'>
                {sprint?.barriers || 0}
              </div>
            </Col>
            <Col span={2}>
              <div className='ProjectsLIst-container-data text-center bordered'>
                {sprint?.successes || 0}
              </div>
            </Col>
            <Col span={3}>
              <div className='ProjectsLIst-container-data text-center bordered'>
                {sprint?.lessons_learned}
              </div>
            </Col>
          </>
        )}
        {isOpen && (
          <>
            <Col span={23} className='header'>
              <b>{sprint?.name}</b>{' '}
              <span className='subtitle'>{t('commons.intervention')} Details</span>
            </Col>
          </>
        )}
        <Col span={1}>
          <div className=''>
            <Button type='primary' onClick={toggleOpen}>
              {isOpen ? <UpCircleFilled /> : <RightCircleFilled />}
            </Button>
          </div>
        </Col>
        {isOpen && (
          <Col span={24} className='mb-10 ml-5 mr-5'>
            <Row gutter={12}>
              <Col span={12}>
                <b className={`badge-${sprint?.status}`}>
                  {sprint?.status?.toString().replace('_', ' ').toLowerCase()}
                </b>
                <br />
                <b className='mt-10'>
                  What are you truing to accomplish in this {t('commons.intervention').toLowerCase()}?
                </b>
                <p>
                  {sprint?.trying_to_accomplish || (
                    <Badge status='warning' text='Not information provided.' />
                  )}
                </p>
              </Col>
              <Col span={12}>
                <Row gutter={12} className='mr-10'>
                  <Col span={8} className='text-center'>
                    Barriers
                  </Col>
                  <Col span={8} className='text-center'>
                    Successes
                  </Col>
                  <Col span={8} className='text-center'>
                    Lessons Learned
                  </Col>
                  <Col span={1} />
                </Row>
                <Row gutter={12} className='mr-10'>
                  <Col span={8}>
                    <div className='ProjectsLIst-container-data text-center bordered'>
                      {sprint?.barriers || 0}
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className='ProjectsLIst-container-data text-center bordered'>
                      {sprint?.successes || 0}
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className='ProjectsLIst-container-data text-center bordered'>
                      {sprint?.lessons_learned}
                    </div>
                  </Col>
                </Row>
                <Button
                  className='button primary float-right mt-10 mr-15'
                  onClick={() => {
                    localStorage.setItem('mission_id', `${mission_id}`);
                    localStorage.setItem('sprint_id', `${sprint_id}`);
                    history.push(`/${id}/organisation-insights/?tab=9`);
                  }}
                  size='small'>
                  Go to {t('commons.intervention')}
                  <RightOutlined />
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export default ProjectSprintItem;
