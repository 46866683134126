import {
  GET_MEASUREMENTS_FAILED,
  GET_MEASUREMENTS_START,
  GET_MEASUREMENTS_SUCCESS,
  GET_CADENCE_FAILED,
  GET_CADENCE_START,
  GET_CADENCE_SUCCESS,
  ADD_MEASUREMENTS_FAILED,
  ADD_MEASUREMENTS_START,
  ADD_MEASUREMENTS_SUCCESS,
  ADD_CADENCE_FAILED,
  ADD_CADENCE_START,
  ADD_CADENCE_SUCCESS,
  GET_SPRINT_TEAM_FAILED,
  GET_SPRINT_TEAM_START,
  GET_SPRINT_TEAM_SUCCESS,
  ADD_SPRINT_TEAM_START,
  ADD_SPRINT_TEAM_SUCCESS,
  ADD_SPRINT_TEAM_FAILED,
  GET_MISSION_TEAM_FAILED,
  GET_MISSION_TEAM_START,
  GET_MISSION_TEAM_SUCCESS,
  HYPOTHESIS_HEADING_START,
  HYPOTHESIS_HEADING_SUCCESS,
  HYPOTHESIS_HEADING_ERROR,
  GET_ORGANIZATION_SPRINT_LEARNING,
  ADD_ORGANIZATION_SPRINT_LEARNING,
  ADD_ORGANIZATION_SPRINT_LEARNING_SUCCESS,
  GET_ORGANIZATION_SPRINT_LEARNING_SUCCESS,
  GET_SPRINT_HUDDLE_SUCCESS,
  GET_ORGANIZATION_SPRINT_LEARNING_FAILED,
  ADD_ORGANIZATION_SPRINT_LEARNING_FAILED,
  ADD_ORGANIZATION_SPRINT_LEARNING_TABLE,
  ADD_ORGANIZATION_SPRINT_LEARNING_TABLE_SUCCESS,
  ADD_ORGANIZATION_SPRINT_LEARNING_TABLE_FAILED,
  ADD_ORGANIZATION_SPRINT_LEARNING_TOPICS_TABLE_SUCCESS,
  ADD_ORGANIZATION_SPRINT_LEARNING_TOPICS_TABLE,
  ADD_SPRINT_HUDDLE,
  ADD_SPRINT_HUDDLE_SUCCESS,
  ADD_SPRINT_HUDDLE_FAILED,
  GET_ORGANIZATION_SPRINT_LEARNING_TABLE_SUCCESS,
  GET_SPRINT_TABLE_TOPICS_VALUES_SUCCESS,
  GET_ORGANIZATION_SPRINT_TABLE_TOPICS_VALUES_SUCCESS,
  GET_ORGANIZATION_SPRINT_LEARNING_TABLE_FAILED,
  GET_ORGANIZATION_SPRINT_LEARNING_TOPICS_TABLE_SUCCESS,
} from '../../Redux.constants';
import {
  getMeasurementsFailed,
  getMeasurementsStart,
  getMeasurementsSuccess,
  getCadenceFailed,
  getCadenceStart,
  getCadenceSuccess,
  addCadenceFailed,
  addCadenceStart,
  addCadenceSuccess,
  addMeasurementsStart,
  addMeasurementsSuccess,
  addMeasurementsFailed,
  getMissionTeamFailed,
  getMissionTeamStart,
  getMissionTeamSuccess,
  addSprintTeamFailed,
  addSprintTeamStart,
  addSprintTeamSuccess,
  getSprintTeamFailed,
  getSprintTeamStart,
  getSprintTeamSuccess,
  hypothesisHeadingStarted,
  hypothesisHeadingSuccess,
  hypothesisHeadingError,
  getSprintLearningSuccess,
  updateSprintLearningStart,
  updateSprintLearningFailed,
  updateSprintLearningSuccess,
  getSprintLearningTableSuccess,
  getSprintHuddleSuccess,
  updateSprintTopicValueSuccess,
  updateSprintTopicValueStart,
  updateSprintHuddleStart,
  updateSprintHuddleFailed,
  updateSprintHuddleSuccess,
  getSprintLearningValueSuccess,
} from './Helper';

import * as constants from './constants';

const INITIAL_STATE = {
  getMeasurementsApi: {
    isProcessing: null,
    success: null,
    error: null,
  },
  updateSprintLHuddleApi: {
    isProcessing: false,
    success: true,
    error: null,
  },
  updateSprintLearningApi: {
    isProcessing: true,
    success: false,
    error: false,
    data: null,
  },
  getSprintLHuddleApi: {
    isProcessing: false,
    success: true,
    error: null,
  },
  updateSprintLearningTableApi: {
    isProcessing: true,
    success: false,
    error: false,
    data: null,
  },
  addMeasurementsApi: {
    isProcessing: null,
    success: null,
    error: null,
  },
  getCadenceApi: {
    isProcessing: null,
    success: null,
    error: null,
  },
  addCadenceApi: {
    isProcessing: null,
    success: null,
    error: null,
  },

  cadenceList: [],
  measurementsList: [],
  ...constants.TEAM_BUILDER_STATE,
};

/*eslint-disable*/
export default function OrganizationSprintDetailsReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case GET_MEASUREMENTS_START:
      return getMeasurementsStart(state);
    case GET_MEASUREMENTS_SUCCESS:
      return getMeasurementsSuccess(state, action.payload);
    case GET_MEASUREMENTS_FAILED:
      return getMeasurementsFailed(state, action.payload);
    case ADD_MEASUREMENTS_START:
      return addMeasurementsStart(state);
    case ADD_MEASUREMENTS_SUCCESS:
      return addMeasurementsSuccess(state, action.payload);
    case ADD_MEASUREMENTS_FAILED:
      return addMeasurementsFailed(state, action.payload);
    case GET_CADENCE_START:
      return getCadenceStart(state);
    case GET_CADENCE_SUCCESS:
      return getCadenceSuccess(state, action.payload);
    case GET_CADENCE_FAILED:
      return getCadenceFailed(state, action.payload);
    case ADD_CADENCE_START:
      return addCadenceStart(state);
    case ADD_CADENCE_SUCCESS:
      return addCadenceSuccess(state, action.payload);
    case ADD_CADENCE_FAILED:
      return addCadenceFailed(state, action.payload);
    case GET_SPRINT_TEAM_START:
      return getSprintTeamStart(state);
    case GET_SPRINT_TEAM_SUCCESS:
      return getSprintTeamSuccess(state, action.payload);
    case GET_SPRINT_TEAM_FAILED:
      return getSprintTeamFailed(state, action.payload);
    case ADD_SPRINT_TEAM_START:
      return addSprintTeamStart(state);
    case ADD_SPRINT_TEAM_SUCCESS:
      return addSprintTeamSuccess(state, action.payload);
    case ADD_SPRINT_TEAM_FAILED:
      return addSprintTeamFailed(state, action.payload);
    case GET_MISSION_TEAM_START:
      return getMissionTeamStart(state);
    case GET_MISSION_TEAM_SUCCESS:
      return getMissionTeamSuccess(state, action.payload);
    case GET_MISSION_TEAM_FAILED:
      return getMissionTeamFailed(state, action.payload);
    case HYPOTHESIS_HEADING_START:
      return hypothesisHeadingStarted(state, action.payload);
    case HYPOTHESIS_HEADING_SUCCESS:
      return hypothesisHeadingSuccess(state, action.payload);
    case HYPOTHESIS_HEADING_ERROR:
      return hypothesisHeadingError(state, action.payload);
    case GET_ORGANIZATION_SPRINT_LEARNING_SUCCESS:
      return getSprintLearningSuccess(state, action.payload);
    case ADD_ORGANIZATION_SPRINT_LEARNING:
      return updateSprintLearningStart(state, action.payload);
    case ADD_ORGANIZATION_SPRINT_LEARNING_SUCCESS:
      return updateSprintLearningSuccess(state, action.payload);
    case ADD_ORGANIZATION_SPRINT_LEARNING_FAILED:
      return updateSprintLearningFailed(state, action.payload);
    case GET_ORGANIZATION_SPRINT_LEARNING_TABLE_SUCCESS:
      return getSprintLearningTableSuccess(state, action.payload);
    case GET_ORGANIZATION_SPRINT_LEARNING_TOPICS_TABLE_SUCCESS:
      return getSprintLearningTableSuccess(state, action.payload);
    case GET_ORGANIZATION_SPRINT_TABLE_TOPICS_VALUES_SUCCESS:
      return getSprintLearningValueSuccess(state, action.payload);
    case GET_SPRINT_HUDDLE_SUCCESS:
      return getSprintHuddleSuccess(state, action.payload);
    case ADD_SPRINT_HUDDLE:
      return updateSprintHuddleStart(state, action.payload);
    case ADD_SPRINT_HUDDLE_FAILED:
      return updateSprintHuddleFailed(state, action.payload);
    case ADD_SPRINT_HUDDLE_SUCCESS:
      return updateSprintHuddleSuccess(state, action.payload);
    case ADD_ORGANIZATION_SPRINT_LEARNING_TOPICS_TABLE:
      return updateSprintTopicValueStart(state, action.payload);
    case ADD_ORGANIZATION_SPRINT_LEARNING_TOPICS_TABLE_SUCCESS:
      return updateSprintTopicValueSuccess(state, action.payload);
    default:
      return {...state};
  }
}
