export const INITIAL_STATE = {
  imageUrl: '',
  integratedUrl: '',
  uploadingImage : false,
  imageApi: {
    loading: null,
    error: null,
    error: null,
  },
  integrateApi: {
    loading: null,
    error: null,
    error: null,
  },
};
