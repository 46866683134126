export const getConnectedNetwrokStarted = (state) => {
  return {
    ...state,
    connectedNetworkList: [],
    getConnectedNetwork: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
};

export const getConnectedNetwrokSuccess = (state, action) => {
  let {connectedNetworkResponse} = action.payload;
  return {
    ...state,
    getConnectedNetwork: {
      isProcessing: false,
      success: true,
      error: false,
    },
    connectedNetworkList: connectedNetworkResponse.organization,
    connectedNetworkMissionDetail: connectedNetworkResponse.mission,
    connectNetworkTotal: connectedNetworkResponse.count,
  };
};

export const getConnectedNetwrokFailed = (state) => {
  return {
    ...state,
    getConnectedNetwork: {
      isProcessing: false,
      success: false,
      error: true,
    },
  };
};

export const addConnectedNetwrokStarted = (state) => {
  return {
    ...state,
    addConnectedNetwork: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
};

export const addConnectedNetwrokSuccess = (state, action) => {
  return {
    ...state,
    addConnectedNetwork: {
      isProcessing: false,
      success: true,
      error: false,
    },
  };
};

export const addConnectedNetwrokFailed = (state) => {
  return {
    ...state,
    addConnectedNetwork: {
      isProcessing: false,
      success: false,
      error: true,
    },
  };
};
export const deleteConnectedNetwrokStarted = (state) => {
  return {
    ...state,
    deleteConnectedNetwork: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
};

export const deleteConnectedNetwrokSuccess = (state, action) => {
  return {
    ...state,
    deleteConnectedNetwork: {
      isProcessing: false,
      success: true,
      error: false,
    },
  };
};

export const deleteConnectedNetwrokFailed = (state) => {
  return {
    ...state,
    deleteConnectedNetwork: {
      isProcessing: false,
      success: false,
      error: true,
    },
  };
};
