import React, {useCallback, useEffect, useState} from 'react';
import {Button, Select, Tag} from 'antd';
import debounce from 'lodash.debounce';
import {getInsuranceList} from 'Services/demographic.service';
import {
  deleteInsuranceTypes,
  getInsuranceTypes,
  patchInsuranceTypes,
  postInsuranceTypes,
} from 'Services/InsuranceType.service';
import './styles/InsuranceTypes.scss';
import DebounceLoading, {
  dialogStartSubject$,
  dialogStopSubject$,
} from 'Components/DebounceLoading/DebounceLoading';
import {InsuranceType, InsuranceTypeDetail} from 'types/Demographic';
const {Option} = Select;

/**
 * Renders the InsuranceTypes component.
 *
 * @param {Object} props - The props object that contains the demographicId.
 * @return {ReactElement} The rendered InsuranceTypes component.
 */
const InsuranceTypes: React.FC<{
  demographicId: number;
}> = ({demographicId}) => {
  const [types, setTypes] = useState<InsuranceType[]>([]);
  const [selectType, setSelectType] = useState<number | null>();
  const [allInsurance, setAllInsurance] = useState<InsuranceTypeDetail[]>([]);

  const loadTypes = async () => {
    try {
      const res = await getInsuranceList().call;
      setTypes(res?.data?.results);
    } catch (error) {}
  };

  const loadInsuranceTypes = async () => {
    try {
      const res = await getInsuranceTypes(demographicId).call;
      if (res?.data?.results) {
        // @todo: the following has a type error
        //@ts-ignore
        setAllInsurance(res.data.results);
      }
    } catch (error) {}
  };

  const addInsuranceTypes = async () => {
    try {
      if (!selectType) return;
      await postInsuranceTypes({
        demographics: demographicId,
        insurance: selectType,
      }).call;
      setSelectType(null);
      loadInsuranceTypes();
    } catch (error) {}
  };

  const removeInsuranceTypes = async (id: number) => {
    try {
      await deleteInsuranceTypes(id).call;
      loadInsuranceTypes();
    } catch (error) {}
  };

  const updatePatientLenguage = async (data: any) => {
    dialogStartSubject$.setSubject = true;
    try {
      await patchInsuranceTypes(data).call;
    } catch (error) {}
    dialogStopSubject$.setSubject = false;
  };

  const debounceInsurance = useCallback(
    debounce(updatePatientLenguage, 625),
    []
  );

  useEffect(() => {
    if (demographicId) {
      loadInsuranceTypes();
      loadTypes();
    }
  }, [demographicId]);

  return (
    <div>
      <div className='mt-15'>
        <span>Insurance Type:</span>
        <hr />
        <div className='flex'>
          <Select
            className='select-class'
            placeholder='Add Insurance Type'
            optionFilterProp='children'
            bordered={false}
            value={selectType}
            onChange={(net) => {
              setSelectType(net);
            }}>
            {types.map((item) => (
              <Option key={item.id}>{item.name}</Option>
            ))}
          </Select>
          <Button type='primary' onClick={addInsuranceTypes}>
            Add
          </Button>
        </div>
      </div>
      <div className='mt-5'>
        {allInsurance?.map((item, index) => (
          <div key={index} className='block mt-5'>
            <Tag
              closable
              onClose={() => removeInsuranceTypes(item?.id)}
              color='#102431'>
              {item?.insurance?.name}
            </Tag>
            <input
              className='input-class'
              defaultValue={item?.percentage ?? ''}
              type='text'
              placeholder='Percentage'
              onChange={(e) =>
                debounceInsurance({id: item?.id, percentage: e.target.value})
              }
            />
          </div>
        ))}
      </div>
      <DebounceLoading />
    </div>
  );
};

export default InsuranceTypes;
