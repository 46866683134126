import {BaseIcon, sizeType} from './BaseIcon';
import React from 'react';

const path = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g id='Icon'>
      <path
        id='Union'
        fillRule='evenodd'
        clipRule='evenodd'
        // eslint-disable-next-line max-len
        d='M3.74957 7.76852C3.79144 6.55086 4.55992 5.39616 5.61803 4.94953C6.59683 4.53638 8.03165 4.65635 9.59416 6.42586C9.73655 6.58712 9.94134 6.67947 10.1565 6.67943C10.3716 6.6794 10.5764 6.58698 10.7187 6.42567C11.7569 5.24918 12.7373 4.81288 13.5308 4.75657C14.3242 4.70027 15.0444 5.01478 15.5937 5.56273C16.725 6.69117 16.9878 8.61534 15.7135 9.90182L10.1563 14.9838L6.34441 11.4985C6.03872 11.2189 5.56432 11.2402 5.28481 11.5459C5.00531 11.8516 5.02654 12.326 5.33223 12.6055L9.65026 16.5536C9.93681 16.8156 10.376 16.8156 10.6625 16.5535L16.7388 10.9969C16.747 10.9894 16.755 10.9817 16.7629 10.9739C18.758 8.97943 18.245 6.08869 16.653 4.50073C15.8391 3.68883 14.7078 3.16928 13.4246 3.26034C12.3354 3.33763 11.2185 3.84829 10.156 4.85068C8.43061 3.22643 6.57897 2.91577 5.03471 3.5676C3.40038 4.25745 2.31096 5.95758 2.25045 7.71696C2.23621 8.13093 2.56026 8.47806 2.97423 8.4923C3.3882 8.50654 3.73533 8.18249 3.74957 7.76852ZM8.09218 7.4473C7.97714 7.17887 7.71676 7.00164 7.42485 6.99306C7.13294 6.98448 6.8626 7.14612 6.732 7.40732L5.83832 9.19469H4.09989C3.68568 9.19469 3.34989 9.53047 3.34989 9.94469C3.34989 10.3589 3.68568 10.6947 4.09989 10.6947H6.30184C6.58592 10.6947 6.84562 10.5342 6.97266 10.2801L7.35046 9.52451L8.36492 11.8916C8.47041 12.1377 8.69913 12.3089 8.96502 12.3408C9.2309 12.3727 9.49361 12.2604 9.65428 12.0462L11.0807 10.1442H12.3572C12.7714 10.1442 13.1072 9.80841 13.1072 9.3942C13.1072 8.97998 12.7714 8.6442 12.3572 8.6442H10.7057C10.4697 8.6442 10.2474 8.75534 10.1057 8.9442L9.23263 10.1084L8.09218 7.4473Z'
        strokeWidth='.5'
      />
    </g>
  </svg>
);
const ProjectsIcon: React.FC<sizeType> = ({size}) => (
  <BaseIcon size={size}>{path}</BaseIcon>
);

const ProjectsIconWithBackground = () => <BaseIcon background>{path}</BaseIcon>;

export {ProjectsIcon, ProjectsIconWithBackground};
