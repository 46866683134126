import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { Carousel, Modal } from 'antd';
import { useForm } from 'react-hook-form';
import { PrimaryDrivers } from './components';

import {
  getKeyDriversMVG,
  getKeyDriversPD,
  patchKeyDriverMVG,
  postKeyDriverMVG,
} from 'Services/KeyDrivers.service';
import {
  getTemplateMVGs,
  copyTemplateMVG,
} from 'Services/KeyDriversTemplates.service';
import { ArrowLeft, ArrowRight } from 'Assets/icons';
import {LinkButton} from 'Components/atoms';
import './styles/KeyDrivers.scss';

const arrowClasses = "w-8 h-8 absolute flex items-center justify-center z-10 top-24 transform -translate-y-1/2";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      aria-label="Next"
      className={`${arrowClasses} right-0`}
      onClick={onClick}
    >
      <ArrowRight />
    </button>
  );
}

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      aria-label="Previous"
      className={`${arrowClasses} left-0`}
      onClick={onClick}
    >
      <ArrowLeft />
    </button>
  );
}

const CopyTemplateModal = ({isOpen, mission, onClick, onClose, templates}) => {
  const links = Array.from(templates);
  const [currentSlide, setCurrentSlide] = useState(0);

  const buttonStyle = "m-0 border-none !normal-case text-base !font-medium";

  return (
    <Modal 
      className="keydriver-template-modal"
      wrapClassName="rounded-2xl"
      open={isOpen}
      okText="Import"
      cancelButtonProps={{className: `${buttonStyle} order-1 hover:text-primary-text-disabled`}}
      okButtonProps={{className: `${buttonStyle} !bg-primary !rounded order-0 hover:!bg-primary-hover`}}
      onCancel={onClose}
      onOk={() => {
        onClick(mission, links[currentSlide].id);
        onClose();
      }}>
        <div className="pt-7">
        <Carousel 
          arrows={true} 
          dots={false}
          swipeToSlide={true}
          draggable={true}
          className='keydriver-template-carousel'
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
          afterChange={(current) => setCurrentSlide(current)}
        >
        {links.map((link, idx) => (
          <div>
            <div className="grid gap-4 px-6">
              <div className="aspect-[434/194] relative">
                {link.preview_image && (
                  <img 
                    src={link.preview_image}
                    className="absolute w-full h-full object-contain"/>
                )}
              </div>
              <div className="text-center grid gap-4 text-gray-900">
                <h2 className="text-4xl">Import Template</h2>
                <p className="text-gray-900 text-base">{`Example ${idx + 1}: ${link.description}`}</p>
              </div>
            </div>
          </div>
        ))}
        </Carousel>
        </div>
    </Modal>
  );
}

/**
 * Renders the KeyDrivers component.
 *
 * @return {JSX.Element} The rendered KeyDrivers component.
 */
const KeyDrivers = () => {
  const [visibleCopyTemplateModal, setVisibleCopyTemplateModal] = useState(false);
  const [visibleGoalModal, setVisibleGoalModal] = useState(false);
  const [goalData, setGoalData] = useState([]);
  const [templateMVGs, setTemplateMVGs] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
    getValues,
  } = useForm();

  const mission = useSelector((state) => {
    return state.mission.value.id;
  });

  const loadSD = async () => {
    try {
    } catch (error) { }
  };

  /**
   * Loads the PD with the given ID.
   *
   * @param {type} id - The ID of the PD.
   * @return {type} No return value.
   */
  const loadPD = async (id) => {
    try {
      const res = await getKeyDriversPD(id).call;
      loadSD();
    } catch (error) { }
  };

  const loadMVG = async () => {
    if (mission) {
      try {
        const res = await getKeyDriversMVG(mission).call;
        setGoalData(res?.data?.results);
        loadPD(res?.data?.results[0].id);
      } catch (error) { }
    }
  };

  const copyTemplate = async (mission, id) => {
    try {
      const res = await copyTemplateMVG(mission, id).call;
      loadMVG();
    } catch (error) {}
  };

  const loadTemplateMVGs = async () => {
    try {
      const res = await getTemplateMVGs().call;
      setTemplateMVGs(res?.data);
    } catch (error) {}
  };

  /**
   * Saves MVG data.
   *
   * @param {Object} params - The parameters for saving the MVG data.
   * @return {Promise<void>} A promise that resolves when the MVG data is saved.
   */
  const saveMVG = async (params) => {
    try {
      const data = {
        mission: mission,
        ...params,
      };

      if (data?.id) {
        await patchKeyDriverMVG(data).call;
      } else {
        await postKeyDriverMVG(data).call;
      }
      setVisibleGoalModal(false);
      loadMVG();
    } catch (error) { }
  };

  /**
   * Opens the goal modal.
   *
   * @param {type} id - the ID of the goal (optional)
   * @return {Promise} a promise that resolves to nothing
   */
  const openGoalModal = async (id = null) => {
    reset();
    if (id) {
      setValue('id', id);
      if (goalData[0]) {
        setValue('description', goalData[0].description);
      }
    }
    setVisibleGoalModal(true);
  };

  useEffect(() => {
    loadMVG();
  }, [mission]);

  useEffect(() => {
    loadTemplateMVGs();
  }, []);

  return (
    <div className='keydrivers'>
      <div className='flex justify-beetween my-15'>
        <p>
          To get started, {0 < templateMVGs.length && 'use an existing template or '}add your own Key Drivers. <br />
          <a 
            href="https://static.blueagilis.com/key_drivers/key_drivers_deck.pdf" 
            target="_blank" 
            rel="noopener noreferrer">See template details</a>
        </p>
        {0 < templateMVGs.length && (
          <LinkButton
            onClick={() => {setVisibleCopyTemplateModal(true)}}>
            Import Template
          </LinkButton>
        )}
      </div>
      <div className='keydrivers-container'>
        <div className='w-33 h-100 grid grid-vertical relative'>
          <p className='text-center absolute top-15'>
            <b>Most Valuable Goal</b>
          </p>
          {goalData.length ? (
            <div
              className='keydrivers-card bg-orange pointer'
              onClick={() => openGoalModal(goalData[0].id)}>
              <p className='text-white'>
                {' '}
                <b> {goalData[0].description} </b>
              </p>
            </div>
          ) : (
            <div
              className='keydrivers-card bg-gray pointer'
              onClick={() => openGoalModal()}>
              <p>
                {' '}
                <b> Add Goal + </b>
              </p>
            </div>
          )}
        </div>
        <div className='w-67 flex overflow-y-scroll'>
          {!!goalData.length && <PrimaryDrivers mvgoalId={goalData[0]?.id} />}
        </div>
      </div>
      <Modal
        title='Goal'
        visible={visibleGoalModal}
        onCancel={() => setVisibleGoalModal(false)}
        footer={false}
        className='mission-modal'>
        <form onSubmit={handleSubmit(saveMVG)}>
          <p>Goal</p>
          <input
            type='text'
            className='input-class'
            {...register('description')}
          />
          <div className='mt-15 text-end'>
            <button
              type='button'
              className='modal-delete-btn'
              onClick={() => setVisibleGoalModal(false)}>
              Cancel
            </button>
            <button type='submit' className='modal-dont-delete ml-10'>
              Save
            </button>
          </div>
        </form>
      </Modal>
      <CopyTemplateModal 
        isOpen={visibleCopyTemplateModal} 
        onClick={copyTemplate}
        onClose={() => setVisibleCopyTemplateModal(false)}
        mission={mission}
        templates={templateMVGs} />
    </div>
  );
};

export default KeyDrivers;
