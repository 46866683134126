import {
  SIGN_UP_FORM_CHANGE,
  SIGN_UP_STARTED,
  SIGN_UP_SUCCESS,
  SIGN_UP_VALIDATION_ERRORS,
  SIGN_UP_ERROR,
} from '../../Redux.constants';
import {ApiBase} from '../../../utils/apiBase';
import {getNotification} from '../../../Components/GetNotification';
import * as yup from 'yup';
import {ClearLocalStorage, GetLocalStorage} from '../../../utils/localStorageOperations';

export function signUpFormChange(params) {
  return (dispatch) => {
    dispatch({
      type: SIGN_UP_FORM_CHANGE,
      payload: params,
    });
  };
}

const SIGN_UP_FORM_SCHEMA = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[A-Za-z]+$/, ' Firstname should only have Alphabets.')
    .required(' First name required'),
  lastName: yup
    .string()
    .matches(/^[A-Za-z]+$/, ' Lastname should only have Alphabets.')
    .required(' Last Name required'),
  email: yup
    .string()
    .email('Please enter a valid Email Id. (Eg: name@gmail.com)')
    .required(' Email is required'),
  password: yup
    .string()
    .matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
      'The password is invalid. Please match the required format.'
    )
    .min(8, 'Password is too short, must be atleast 8 charaters long')
    .required(' Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], ' Passwords must match')
    .required(' Confirm Password is required'),
});

function fetchValidationErrors(input) {
  return new Promise((resolve, reject) => {
    SIGN_UP_FORM_SCHEMA.validate(input, {abortEarly: false}).catch((error) => {
      let validationList = error.inner.map((item) => {
        return {
          path: item.path,
          message: item.message,
        };
      });
      resolve(validationList);
    });
  });
}
function signUpSuccess(dispatch, response) {
  dispatch({
    type: SIGN_UP_SUCCESS,
    payload: {
      signUpResponse: response.data,
    },
  });
  ClearLocalStorage();
}
function signUpError(dispatch, error) {
  dispatch({
    type: SIGN_UP_ERROR,
    payload: {error: 'Sign Up Failed'},
  });
}
function signUpValidatingErrors(validationErrors, dispatch) {
  dispatch({
    type: SIGN_UP_VALIDATION_ERRORS,
    payload: validationErrors,
  });
}
export function signUp(input, callback) {
  return async (dispatch) => {
    try {
      await SIGN_UP_FORM_SCHEMA.validate(input);
      const apiBaseInstance = new ApiBase();
      dispatch({type: SIGN_UP_STARTED, payload: {}});
      apiBaseInstance.instance
        .post('/users/', {
          email: input.email,
          first_name: input.firstName,
          last_name: input.lastName,
          roles: [1],
          password: input.password,
          client_code: GetLocalStorage('clientName')
        })
        .then((response) => {
          successNotification();
          callback();
          signUpSuccess(dispatch, response);
        })
        .catch((error) => {
          failedNotification();
          signUpError(dispatch, error);
        });
    } catch (error) {
      let validationErrors = await fetchValidationErrors(input);
      signUpValidatingErrors(validationErrors, dispatch);
    }
  };
}

const successNotification = () => {
  return getNotification('success', {
    header: 'Success',
    body: 'SignUp Success, Please check email to verify your account.',
  });
};

const failedNotification = () => {
  return getNotification('error', {
    header: 'Sorry',
    body: 'Email already exist. Please try with another email ',
  });
};
