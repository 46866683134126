import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getIntelDocuments } from 'Services/IntelDocument.service';
import { getPFTList, deletePFTList } from 'Services/Pft.service';
import { getUsersPat } from 'Services/UserPat.service';
import { selectNetworkId } from './networks';

type Messages = {
  [key: string]: {
    [key: string]: string;
  };
};

const MESSAGES: Messages = {
  'documents': {
    ITEM_DELETED: 'Document deleted.',
  },
  'ma_action_plans': {
    ITEM_DELETED: 'Plan deleted.',
  },
};

interface ListViewState<T> {
  items: T[];
  type: string;
  count: number;
  status: 'idle' | 'loading' | 'success' | 'failed';
  statusMessage: string;
  page: number;
  perPage: number;
  search: string;
  isSearching: boolean;
}

const initialState: ListViewState<any> = {
  items: [],
  type: '',
  count: 0,
  status: 'idle',
  statusMessage: '',
  page: 1,
  perPage: 10,
  search: '',
  isSearching: false,
};

const getData = async ({
  datatype, 
  networkId,
  page,
  perPage,
  search,
}: {
  datatype: string, 
  networkId: number,
  page: number,
  perPage: number,
  search?: string,
}) => {
  switch (datatype) {
    case 'documents':
      return await getIntelDocuments(networkId, page, perPage, search).call;
    case 'pats':
      return await getUsersPat(networkId, page, perPage, search).call;
    case 'ma_action_plans':
      return await getPFTList(networkId, page, perPage, search).call;
    default:
      break;
  }
}

const deleteDataItem = async ({
  datatype,
  id,
}: {
  datatype: string,
  id: number,
}) => {
  switch (datatype) {
    case 'ma_action_plans':
      return await deletePFTList(id).call;
    default:
      break;
  }
}

export const fetchItems = createAsyncThunk(
  'listView/fetchItems',
  async (__, {getState}) => {
    try {
      const state = getState();
      const networkId = Number(selectNetworkId(state));
      if(networkId > 0){
        //@ts-ignore
        const {type, page, perPage, search} = state.listView;
        const res = await getData({
          datatype: type,
          networkId,
          page,
          perPage,
          search,
        });
        if(res?.data){
          return res.data;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
);

export const deleteItem = createAsyncThunk(
  'listView/deleteItem',
  async ({id}: {id: number}, {getState}) => {
    try {
      const state = getState();
      const networkId = Number(selectNetworkId(state));
      if(networkId > 0){
        //@ts-ignore
        const {type} = state.listView;
        await deleteDataItem({
          datatype: type,
          id,
        });
        return id;
      }
    } catch (error) {
      console.error(error);
    }
  }
);


export const listViewSlice = createSlice({
  name: 'listView',
  initialState,
  reducers: {
    selectListViewPage: (state, {payload}) => {
      state.page = payload;
    },
    selectListViewSearch: (state, {payload}) => {
      state.search = payload;
    },
    setListViewType: (state, {payload}) => {
      // Type can only be set once. To alter type, fully reset the state.
      if(state.type === ''){
        state.type = payload;
      } else {
        console.error('Cannot set type more than once.');
      }
    },
    setListViewIsSearching: (state, {payload}) => {
      state.isSearching = payload;
    },
    clearListView: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchItems.fulfilled, (state, {payload}) => {
        if(payload) {
          return {
            ...state,
            status: 'idle',
            isSearching: false,
            items: payload.results,
            count: payload.count,

          }
        }
      });

    builder
      .addCase(deleteItem.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteItem.fulfilled, (state, {payload}) => {
        if(payload) {
          return {
            ...state,
            status: 'idle',
            statusMessage: MESSAGES[state.type]?.ITEM_DELETED || '',
            items: state.items.filter(item => item.id !== payload),
          }
        }
      });
  }
});

export const { clearListView, selectListViewPage, selectListViewSearch, setListViewIsSearching, setListViewType } = listViewSlice.actions;