/* eslint-disable */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Alert, Input} from 'antd';
import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';
const characterLimit = 50;
export const InputPassword = (props) => {
  const [isLimitexceeded, setIsLimitexceeded] = useState(false);

  const inputChange = (event) => {
    let limit = props.maxLength || characterLimit;
    if (event.target.value.length >= limit) setIsLimitexceeded(true);
    else {
      setIsLimitexceeded(false);
    }
    props.onChange && props.onChange(event);
  };

  var clear = true;
  function clear(obj) {
    if (clear) {
      obj.value = '';
      clear = false;
    }
  }
  return (
    <>
      <Input.Password
        onChange={inputChange}
        disabled={props.disabled}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        allowClear={props.allowClear}
        value={props.value}
        type={props.htmlType}
        name={props.name}
        autoFocus={props.autoFocus}
        key={props.key}
        onKeyDown={props.onKeyDown}
        onPressEnter={props.onPressEnter}
        onClick={props.onClick}
        id={props.id}
        onfocus='clear(this);'
        max={props.max}
        min={props.min}
        maxLength={props.maxLength || characterLimit} // maxLength is used to limit the maximum Length
        onBlur={props.onBlur}
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
        style={props.style}
        className={`${props.className || ''} ${
          !props.isValid ? ' error-field' : ''
        }`}
      />
      {isLimitexceeded && (
        <Alert
          message={`Character limit (maximum : ${
            props.maxLength || characterLimit
          }) reached!`}
          banner
        />
      )}
    </>
  );
};
InputPassword.propTypes = {
  handleChange: PropTypes.func.isRequired,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  placeHolder: PropTypes.string,
  value: PropTypes.any,
  htmlType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
};
