import {getNotification} from '../../../Components/GetNotification';
import {ApiBase} from '../../../utils/apiBase';
import {RESET_PASSWORD} from '../../Api.constants';
import {
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_VALIDATION_ERROR,
} from '../../Redux.constants';
import * as yup from 'yup';

const RESET_PASSWORD_SCHEMA = yup.object().shape({
  password: yup
    .string()
    .matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
      'The password must have at least an uppercase letter, a lowercase letter, a digit & a special character. '
    )
    .min(8, ' Password is too short, must be atleast 8 charaters long')
    .required('Password is required'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password'), null], ' Passwords must match')
    .required('Confirm Password is required'),
});
function fetchValidationErrors(params) {
  return new Promise((resolve, reject) => {
    RESET_PASSWORD_SCHEMA.validate(params, {abortEarly: false}).catch(
      (error) => {
        let validationList = error.inner.map((item) => {
          return {
            path: item.path,
            message: item.message,
          };
        });
        resolve(validationList);
      }
    );
  });
}
export function resetPasswordAction(params, callback) {
  return async (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_START,
    });
    try {
      await RESET_PASSWORD_SCHEMA.validate(params);
      await resetPassword({dispatch, params, callback});
    } catch (error) {
      let ValidationErrors = await fetchValidationErrors(params);
      dispatch({
        type: RESET_PASSWORD_VALIDATION_ERROR,
        payload: ValidationErrors,
      });
    }
  };
}
async function resetPassword({dispatch, params, callback}) {
  try {
    const apiBaseInstance = new ApiBase();
    let response = await apiBaseInstance.instance.post(RESET_PASSWORD, params);
    dispatch({
      type: RESET_PASSWORD_SUCCESS,
    });
    getNotification('success', {
      header: 'Success!',
      body: response.data,
    });
    callback && callback();
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAILED,
    });
    getNotification('error', {
      header: 'Failed!',
      body: error?.error?.email?.length
        ? error.email[0]
        : 'Failed to reset password.',
    });
  }
}
