import {createSelector} from 'reselect';

const UserProfileReducer = (state) => state.UserProfileReducer;

const mergeSelectedReducers = (state) => {
  return {
    formConfig: state.changePasswordFormConfig,
    changePasswordApi: state.changePasswordApi,
  };
};

export const SelectState = createSelector(
  [UserProfileReducer],
  mergeSelectedReducers
);
