import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import './styles/DateDebounce.scss';

/**
 * Renders a debounced DatePicker component wrapped in a Controller.
 *
 * @param {Object} props - The props object.
 * @param {string} props.name - The name of the DatePicker.
 * @param {Object} props.control - The control object.
 * @param {function} [props.debounceAction=()=>{}] - The debounce action function.
 * @param {string} [props.className=' '] - The CSS class name.
 * @return {JSX.Element} The rendered DatePicker component.
 */
const DateDebounce = ({ name, control, debounceAction = () => { }, className = ' ' }) => {
  return <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <DatePicker
        {...field}
        className={className}
        placeholderText='Select date'
        onChange={(date) => {
          field.onChange(date)
          debounceAction()
        }}
        value={field.value ? moment(field.value) : ''}
        format="MM-DD-YYYY"
        allowClear={false}
      />
    )}
  />;
};

export default DateDebounce;
