import React, {useState} from 'react';

export const AnswerStorageContext = React.createContext();

const AnswerStorageContextProvider = (props) => {
  const [answer, setanswer] = useState([]);

  const [quizid, setquizid] = useState();

  const add_quiz_id = (val) => {
    setquizid(val);
  };

  const addanswer = (val) => {
    setanswer([...answer, val]);
  };
  const removeanswer = (moduleId) => {
    setanswer(answer.filter((answer, index) => index !== moduleId - 1));
  };
  const resetAnswer=()=>{
    setanswer([])
  }
  return (
    <AnswerStorageContext.Provider
      value={{answer, addanswer, quizid, add_quiz_id, removeanswer,resetAnswer}}>
      {props.children}
    </AnswerStorageContext.Provider>
  );
};

export default AnswerStorageContextProvider;
