import React, { useState } from 'react';
import Dashboardhome from '../DashboardHome/Dashboardhome';
import './App.css';
import { SwitchContext } from '../../../Components/new/Context/ComponentSwitchContext';
import Newassessment from '../Assessment/Newassessment';
import Takeassessment from '../Assessment/Takeassessment';
import Finishassessment from '../Assessment/Finishassessment';
import Draftassessment from '../Assessment/Draftassessment';

import { createStore, applyMiddleware, compose } from 'redux';
import AllReducers from '../Reducer/allreducer';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
const composeEnhancers =
  (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));
const store = createStore(AllReducers, enhancer);

const ToolBox = (props) => {
  const { component, changecomponent } = React.useContext(SwitchContext);
  useState(() => {
    changecomponent('dashboard');
  }, []);
  return (
    <>
      <Provider store={store}>
        <div>
          <div className='row m-0 p-0' style={{ width: '100%' }}>
            <div className='col-12 m-0 p-0'>
              {component === 'dashboard' ? (
                <Dashboardhome />
              ) : component === 'new assessment' ? (
                <Newassessment />
              ) : component === 'take assessment' ? (
                <Takeassessment />
              ) : component === 'finish assessment' ? (
                <Finishassessment />
              ) : (
                component === "draft" ?
                  <Draftassessment /> :
                  component === "draft assessment" ?
                    <Takeassessment /> :
                    <>Page Not Found</>
              )}
            </div>
          </div>
        </div>
      </Provider>
    </>
  );
};

export default ToolBox;
