export function onChangeObjective(state, payload) {
  return {
    ...state,
    ...handleObjectiveChange(state, payload),
  };
}

const handleObjectiveChange = (state, payload) => {
  const createMissionForm = JSON.parse(JSON.stringify(state.createMissionForm));
  const objectiveList = createMissionForm.step1.objectives;
  if (payload.add) {
    objectiveList.push({ objective: '', eta: '' });
  }
  if (payload.delete) {
    objectiveList.pop();
  }
  if (payload.field === 'objective')
    objectiveList[payload.index].objective = payload.value;
  if (payload.field === 'eta') objectiveList[payload.index].eta = payload.value;
  return { createMissionForm: createMissionForm };
};
