import {ApiBase} from '../../../utils/apiBase';
import {LIST_ORGANISATIONS} from '../../Api.constants';
import {
  LIST_ORGANISATIONS_FAILED,
  LIST_ORGANISATIONS_STARTED,
  LIST_ORGANISATIONS_SUCCESS,
} from '../../Redux.constants';
import * as yup from 'yup';
import {getNotification} from '../../../Components/GetNotification';

// const GET_LIST_ORGANISATION_SCHEMA = yup.object().shape({
//     // organization: yup.string(),
//     q: yup.string(),
//     limit: yup.string(),
//     offset: yup.string()
// });

// function fetchValidationErrors(input) {
//     return new Promise((resolve, reject) => {
//         GET_LIST_ORGANISATION_SCHEMA.validate(input, { abortEarly: false }).catch(
//             (error) => {
//                 let validationList = error.inner.map((item) => {
//                     return {
//                         path: item.path,
//                         message: item.message,
//                     };
//                 });
//                 resolve(validationList);
//             }
//         );
//     });
// }
function listOrganisationStarted(dispatch) {
  dispatch({
    type: LIST_ORGANISATIONS_STARTED,
    payload: {},
  });
}
const failedNotification = () => {
  return getNotification('error', {
    header: 'Failed',
    body: 'Failed to fetch networks',
  });
};
export function listOrganisationAction(payload, callback) {
  return async (dispatch) => {
    listOrganisationStarted(dispatch);
    try {
      const apiBaseInstance = new ApiBase();
      let queryParams = '';
      let groupParams = '';
      let holdOn = 'False';
      if (payload.is_on_hold) holdOn = 'True';
      if (payload.q) queryParams = payload.q;
      if (payload.groupId) groupParams = payload.groupId;
      let response = await apiBaseInstance.instance.get(
        `${LIST_ORGANISATIONS}?limit=${payload.limit}&offset=${payload.offset}&is_on_hold=${holdOn}&q=${queryParams}&group_id=${groupParams}`
      );

      dispatch({
        type: LIST_ORGANISATIONS_SUCCESS,
        payload: {
          listOrgResponse: response.data,
          offset: payload.offset,
        },
      });
      callback && callback(true);
    } catch (error) {
      failedNotification();
      dispatch({
        type: LIST_ORGANISATIONS_FAILED,
        payload: {error},
      });
      callback && callback(false);
    }
  };
}
