import axios, { AxiosResponse } from "axios";
import { API_BASE_URL } from "../../config/constants";
import { AbortableRequestConfig } from 'types/Request';
import { ImprovementTactic } from "types/ImprovementTactic";

const headers = () => {
    return {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('idToken')}`,
        }
    }
}

export function getImprovementTacticInfo(improvement_tactic: number): {
    call: Promise<AxiosResponse<ImprovementTactic[]>>;
    controller: AbortController;
} {
    const controller = new AbortController();
    return {
        call: axios.get(`${API_BASE_URL}improvement-tactic-info/?improvement_tactic=${improvement_tactic}`, {
            signal: controller.signal,
            ...headers()
        } as AbortableRequestConfig),
        controller
    }
}
