import React, { useEffect, useState } from 'react';

import DeleteConfirmationModal from 'Components/DeleteConfirmationModal/DeleteConfirmationModal';
import { SearchAdd } from 'Components/molecules/form';
import {
  cleanPat,
  createOrUpdatePat,
  fetchCollaboratorsByPatId,
  fetchPatById,
  fetchPatCategories,
  fetchPats,
  selectPat,
  selectPatsSearch,
  setIsSearching
} from 'ReduxV2/state';
import PatData from 'Routes/PatData/PatData';
import {
  deleteUsersPat,
  getCollaborators,
  getUsersPat,
  getUsersPatDetail,
} from 'Services/UserPat.service';
import { useDispatch, useSelector } from 'react-redux';
import { PatStatus } from 'types/Pat';
import { CollaboratorsModal } from '../../../PatData/components';
import AssessmentModal from './Components/AssessmentModal';
import CompleteConfirmationModal from './Components/CompleteConfirmationModal';
import PatTable from './Components/PatTable';
import ShareModal from './Components/ShareModal/ShareModal';
import './styles/AssesmentTool.scss';

/**
 * Renders the Intel component.
 *
 * @return {JSX.Element} The rendered Intel component.
 */
const AssesmentTool = ({onClickNext}) => {
  const [visible, setVisible] = useState(false);
  const [visibleConfirmation, setVisibleConfirmation] = useState(false);
  const [isEditingCompletedPat, setIsEditingCompletedPat] = useState(false)
  const [isEdit, setIsEdit] = useState({});
  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [shareId, setShareId] = useState(0);
  const [selectDeleteIdPat, setSelectDeleteIdPat] = useState();
  const [collaboratorId, setCollaboratorId] = useState(0);
  const [visibleCollaboratorModal, setVisibleCollaboratorModal] =
    useState(false);
  const [searchTimer, setSearchTimer] = useState(null);

  const dispatch = useDispatch();

  const network = useSelector((state) => state.network.value);
  const patReduxId = useSelector((state) => state.pat.value);
  const patsPage = useSelector((state) => state.pats.page);
  const patsSearch = useSelector((state) => state.pats.search);
  const isSearching = useSelector((state) => state.pats.isSearching);

  const deletePAT = async () => {
    try {
      await deleteUsersPat(selectDeleteIdPat).call;
      setConfirmationDelete(false);
      dispatch(fetchPats());
      loadPats();
    } catch (error) {}
  };

  const sharePat = async () => {};

  const togglePatModalVisibility = () => {
    setVisible((prev) => !prev);
  };

  /**
   * Loads a PAT to be updated.
   *
   * @param {any} id - the ID of the PAT to be loaded
   * @return {Promise<void>} a promise that resolves when the PAT is loaded
   */
  const loadPatToUpdate = async (id) => {
    try {
      const res = await getUsersPatDetail(id).call;
      setIsEdit({id: res?.data?.id});
    } catch (error) {}
  };


  /**
   * Saves the option with the given status.
   *
   * @param {string} status - The status of the option. Defaults to 'IN_PROGRESS'.
   * @param {boolean} verifyAnswers - Whether to verify the answers. Defaults to false.
   * @param {boolean} refreshModalData - Whether to refresh the modal data. Defaults to true.
   * @param {boolean} activeNotificationSave - Whether to activate the save notification. Defaults to true.
   * @param {boolean} showAllNotification - Whether to show all notifications. Defaults to true.
   * @return {void}
   */
  const loadPats = async () => {
    try {
      if (network) {
        await getUsersPat(network).call;
      }
    } catch (error) {}
  };

  /**
   * Identifies the modal based on the given item.
   *
   * @param {Object} item - The item to be used for identification.
   */
  const identifyModal = async (item, editMode = false) => {
    try {
      if (item?.id) {
        dispatch(cleanPat());
        if (item.status == PatStatus.IN_PROGRESS || editMode) {
          /**
           * disabling the below disables collaborator functionality.
           * @todo port to redux slice
           */
          loadPatToUpdate(item.id);
          const dispatches = [
            dispatch(fetchPatCategories()),
            dispatch(fetchCollaboratorsByPatId(item.id)),
            dispatch(fetchPatById(item.id)),
          ];
          Promise.all(dispatches).then(() => setVisible(true));
        } else {
          /**
           * Go to PFT.
           * @todo change to a route  
           */ 
          dispatch(selectPat(item.id));          
        }
      }
    } catch (error) {}
  };

  const loadCollaborators = async (patIdTest) => {
    try {
      await getCollaborators(patIdTest).call;
    } catch (error) {}
  };

  const editCollaborator = (collaborator_Id) => {
    setCollaboratorId(collaborator_Id);
    setVisibleCollaboratorModal(true);
  };

  useEffect(() => {
    dispatch(fetchPats());
  }, [network, visible, patsPage]);

  useEffect(() => {
    if (isEdit?.id) {
      loadCollaborators(isEdit.id);
      dispatch(fetchCollaboratorsByPatId(isEdit.id));
    }
  }, [visibleCollaboratorModal]);

  useEffect(() => {
    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    const newTimer = setTimeout(() => {
      dispatch(setIsSearching(true));
      dispatch(fetchPats());
    }, 1000);
    setSearchTimer(newTimer);
  }, [patsSearch]);

  useEffect(() => {
    if(isEditingCompletedPat && patReduxId) {
      identifyModal({id:patReduxId}, true);
    }
  }, [isEditingCompletedPat])

  return (
    <div className='assesmenttool'>
      {(!patReduxId || isEditingCompletedPat)? (
        <div className="grid gap-8">
          <div className='flex flex-row justify-end'>
            <SearchAdd 
              isSearching={isSearching}
              onSearchChange={(value) => {
                dispatch(selectPatsSearch(value));
              }}
              onAdd={() => {
                dispatch(cleanPat());
                dispatch(fetchPatCategories());
                togglePatModalVisibility();
              }}/>
          </div>
          <PatTable
            identifyModal={identifyModal}
            setShareId={setShareId}
            setShareModal={setShareModal}
            setIsEditingCompletedPat={setIsEditingCompletedPat}
            setSelectDeleteIdPat={setSelectDeleteIdPat}
            setConfirmationDelete={setConfirmationDelete}
          />
          <AssessmentModal 
            setIsEditingCompletedPat={setIsEditingCompletedPat}
            isEditingCompletedPat={isEditingCompletedPat}
            visible={visible} 
            togglePatModalVisibility={togglePatModalVisibility}
            editCollaborator={editCollaborator}
            setVisibleCollaboratorModal={setVisibleCollaboratorModal}
            loadPats={loadPats}
            setVisibleConfirmation={setVisibleConfirmation}/>

          <CompleteConfirmationModal
              visibleConfirmation={visibleConfirmation}
              setVisibleConfirmation={setVisibleConfirmation}
              createOrUpdatePat={createOrUpdatePat}
              loadPats={loadPats}
              setVisible={setVisible}
          />
          <DeleteConfirmationModal
            visible={confirmationDelete}
            cancel={() => setConfirmationDelete(false)}
            save={deletePAT}
          />
          <ShareModal
            id={shareId}
            visible={shareModal}
            cancel={() => setShareModal(false)}
            share={sharePat}
          />
          <CollaboratorsModal
            visibleModal={visibleCollaboratorModal}
            closeVisibleModal={() => {
              setVisibleCollaboratorModal(false);
              setCollaboratorId(0);
            }}
            patId={isEdit?.id}
            collaboratorId={collaboratorId}
          />
        </div>
      ) : (
        <>
          <div>
            <PatData onClickNext={onClickNext} />
          </div>
        </>
      )}
    </div>
  );
};

export default AssesmentTool;