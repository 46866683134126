/* eslint-disable */
import React, {Component} from 'react';
import {ROUTES} from '../../Routes.constants';
import {Link, withRouter} from 'react-router-dom';
import style from './style.module.scss';
import {GetLocalStorage} from '../../utils/localStorageOperations';
import ModalWindow from '../ModalWindow';
import {
  FIRST_NAME,
  LAST_NAME,
  USER_DESIGNATION,
} from '../../Redux/Redux.constants';
import ConfirmationMessage from '../ConfirmationMessage';
class ProfileElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      clientName: GetLocalStorage('clientName'),
    };
  }

  onCancelLogout = () => {
    this.setState({visible: !this.state.visible});
    this.props.hideDropDown();
  };

  setModalWindow = () => {
    this.setState({visible: true});
    this.props.hideDropDown();
  };
  getUserNameFromLocalStorage = () => {
    return `${GetLocalStorage(FIRST_NAME)} ${GetLocalStorage(LAST_NAME)}`;
  };
  getUserDesignation = () => {
    return GetLocalStorage(USER_DESIGNATION) || 'Unavailable';
  };

  render() {
    return (
      <>
        <div className={style.profileBlock}>
          <div className={style.profileBlock__image}>
            <span>
              <img
                src={
                  localStorage.getItem('profileImage') !== 'null'
                    ? localStorage.getItem('profileImage')
                    : '/img/avatar.png'
                }
                alt='logo'
              />
            </span>
            <Link
              to={ROUTES.PROFILE}
              onClick={() => {
                this.props.hideDropDown();
              }}>
              <span className={style.editOverlay}>edit</span>
            </Link>
          </div>
          <h3 className={style.profileBlock__name}>
            {this.getUserNameFromLocalStorage()}
          </h3>
          <span className={style.profileBlock__designation}>
            {this.getUserDesignation()}
          </span>
        </div>
        <ul className={style.userProfile}>
          <li>
            <Link
              to={ROUTES.COACH_DASHBOARD}
              onClick={() => {
                this.props.hideDropDown();
              }}>
              <span>
                <svg viewBox='0 0 477.119 512'>
                  <path
                    id='Path_264'
                    data-name='Path 264'
                    d='M18.686,512A18.685,18.685,0,0,1,0,493.314c0-132.15,84.723-204.926,238.56-204.926S477.12,361.172,477.119,493.314A18.685,18.685,0,0,1,458.433,512Zm19.366-37.372H439.075c-7.356-98.806-74.72-148.865-200.515-148.865S45.4,375.822,38.052,474.628Zm76.557-348.19C114.609,54.358,167.9,0,238.56,0S362.511,54.358,362.511,126.443c0,74.184-55.6,134.534-123.951,134.534S114.609,200.627,114.609,126.437Zm37.372.005c0,53.579,38.836,97.169,86.579,97.169s86.579-43.59,86.579-97.169c0-50.783-37.217-89.071-86.579-89.071C188.394,37.372,151.981,74.832,151.981,126.443Z'
                  />
                </svg>
              </span>
              <span>{'Switch to Coach'}</span>
            </Link>
          </li>
          <li>
            <Link
              to={ROUTES.PROFILE}
              onClick={() => {
                this.props.hideDropDown();
              }}>
              <span>
                <svg viewBox='0 0 477.119 512'>
                  <path
                    id='Path_264'
                    data-name='Path 264'
                    d='M18.686,512A18.685,18.685,0,0,1,0,493.314c0-132.15,84.723-204.926,238.56-204.926S477.12,361.172,477.119,493.314A18.685,18.685,0,0,1,458.433,512Zm19.366-37.372H439.075c-7.356-98.806-74.72-148.865-200.515-148.865S45.4,375.822,38.052,474.628Zm76.557-348.19C114.609,54.358,167.9,0,238.56,0S362.511,54.358,362.511,126.443c0,74.184-55.6,134.534-123.951,134.534S114.609,200.627,114.609,126.437Zm37.372.005c0,53.579,38.836,97.169,86.579,97.169s86.579-43.59,86.579-97.169c0-50.783-37.217-89.071-86.579-89.071C188.394,37.372,151.981,74.832,151.981,126.443Z'
                  />
                </svg>
              </span>
              <span> Profile</span>
            </Link>
          </li>
          <li className={style.logoutPane}>
            <a
              className={style.logoutBtn}
              onClick={() => {
                this.setModalWindow();
              }}>
              <span>
                <svg viewBox='0 0 226.529 249.963'>
                  <path
                    data-name='Path 264'
                    d='M90.438,247.662a112.639,112.639,0,0,1-40.5-17.043A113.6,113.6,0,0,1,8.9,180.786a112.723,112.723,0,0,1-6.6-21.261,114.357,114.357,0,0,1,0-45.654,112.636,112.636,0,0,1,17.042-40.5A113.6,113.6,0,0,1,69.177,32.335c1.5-.636,3.042-1.247,4.576-1.818a14.6,14.6,0,0,1,5.3,1.366,9.086,9.086,0,0,1,4.493,4.643c0-.005,0-.008,0-.009s.005.005.015.025a9.831,9.831,0,0,1,.626,3.442,14.035,14.035,0,0,1-.34,3.438,16.38,16.38,0,0,1-1.59,4.2c-1.916.667-3.832,1.4-5.695,2.192a94.17,94.17,0,1,0,73.407,0c-.843-.357-1.7-.706-2.559-1.038a11.954,11.954,0,0,1-2.689-5.124,10.888,10.888,0,0,1,.926-7.126,14.28,14.28,0,0,1,8.234-5.593c1.162.446,2.33.917,3.471,1.4A113.3,113.3,0,0,1,90.438,247.662ZM103.222,93.736V10.043a10.043,10.043,0,1,1,20.086,0V93.736a10.043,10.043,0,1,1-20.086,0Z'
                  />
                </svg>
              </span>
              <span>Logout</span>
            </a>
          </li>
        </ul>
        <ModalWindow
          visible={this.state.visible}
          onCancel={this.onCancelLogout}
          className='conform-login'
          footer={false}>
          <ConfirmationMessage
            heading={`Are you sure you want to logout ?`}
            message={`Clicking on 'Yes' will redirect you to Login Page`}
            onCancel={this.onCancelLogout}
            cancelButtonLabel='No'
            onOk={() => {
              localStorage.clear();
              this.props.history.push(`${this.state.clientName}/login`);
            }}
            confirmButtonLabel='Yes'
          />
        </ModalWindow>
      </>
    );
  }
}
export default withRouter(ProfileElement);
