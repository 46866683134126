import {Icon} from 'Components/atoms';
import React from 'react';
import {Phase} from 'types/TransformationJourney';

const Tag: React.FC<Phase> = ({name, color, current}) => {
  const textColor = current ? 'white' : 'black';
  const backgroundColor = current ? color : 'white';
  return (
    <div
      className='border-2 border-solid rounded py-px px-2 text-3xl whitespace-nowrap'
      style={{
        borderColor: color,
        color: textColor,
        backgroundColor: backgroundColor,
      }}>
      {name}
    </div>
  );
};

export const TagSteps: React.FC<{tags?: Phase[]}> = ({tags}) => {
  const tagArray = tags ?? [];
  return (
    <div className='flex flex-wrap gap-3 items-center'>
      {tagArray.map((tag, index) => {
        return (
          <>
            {!!index && (
              <Icon name='chevron-right' className='w-5 h-5 text-gray-400' />
            )}
            <Tag {...tag} key={index} />
          </>
        );
      })}
    </div>
  );
};
