import {
  ADD_ACCOUNT_INSIGHTS_FAILED,
  ADD_ACCOUNT_INSIGHTS_START,
  ADD_ACCOUNT_INSIGHTS_SUCCESS,
  GET_ACCOUNT_INSIGHTS_FAILED,
  GET_ACCOUNT_INSIGHTS_START,
  GET_ACCOUNT_INSIGHTS_SUCCESS,
  ADD_USER_DEPARTMENT_FAILED,
  ADD_USER_DEPARTMENT_START,
  ADD_USER_DEPARTMENT_SUCCESS,
  GET_USER_DEPARTMENT_START,
  GET_USER_DEPARTMENT_SUCCESS,
  GET_USER_DEPARTMENT_FAILED,
  ADD_USER_DESIGNATION_FAILED,
  ADD_USER_DESIGNATION_START,
  ADD_USER_DESIGNATION_SUCCESS,
  GET_USER_DESIGNATION_START,
  GET_USER_DESIGNATION_SUCCESS,
  GET_USER_DESIGNATION_FAILED,
} from '../../Redux.constants';
import {
  getAccountInsightsFailed,
  getAccountInsightsStart,
  getAccountInsightsSuccess,
  addAccountInsightsFailed,
  addAccountInsightsStart,
  addAccountInsightsSuccess,
  getDepartmentsFailed,
  getDepartmentsStart,
  getDepartmentsSuccess,
  addDepartmentsFailed,
  addDepartmentsStart,
  addDepartmentsSuccess,
  getDesignationsFailed,
  getDesignationsStart,
  getDesignationsSuccess,
  addDesignationFailed,
  addDesignationStart,
  addDesignationSuccess,
} from './Helper';

const INITIAL_STATE = {
  getAccountInsightsApi: {
    isProcessing: null,
    success: null,
    error: null,
  },
  addAccountInsightsApi: {
    isProcessing: null,
    success: null,
    error: null,
  },
  getDepartmentsApi: {
    isProcessing: null,
    success: null,
    error: null,
  },
  addDepartmentApi: {
    isProcessing: null,
    success: null,
    error: null,
  },
  getDesignationsApi: {
    isProcessing: null,
    success: null,
    error: null,
  },
  addDesignationApi: {
    isProcessing: null,
    success: null,
    error: null,
  },
  departmentList: [],
  designationList: [],
  accountInsights: [],
  departmentListCount: '',
  designationListCount: '',
  accountInsightCount: '',
};

export default function AccountInsightsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ACCOUNT_INSIGHTS_START:
      return getAccountInsightsStart(state);
    case GET_ACCOUNT_INSIGHTS_SUCCESS:
      return getAccountInsightsSuccess(state, action.payload);
    case GET_ACCOUNT_INSIGHTS_FAILED:
      return getAccountInsightsFailed(state, action.payload);
    case ADD_ACCOUNT_INSIGHTS_START:
      return addAccountInsightsStart(state);
    case ADD_ACCOUNT_INSIGHTS_SUCCESS:
      return addAccountInsightsSuccess(state, action.payload);
    case ADD_ACCOUNT_INSIGHTS_FAILED:
      return addAccountInsightsFailed(state, action.payload);
    //Departments
    default:
      return departmentInAccountInsight(state, action);
  }
}
function departmentInAccountInsight(state, action) {
  switch (action.type) {
    case GET_USER_DEPARTMENT_START:
      return getDepartmentsStart(state);
    case GET_USER_DEPARTMENT_SUCCESS:
      return getDepartmentsSuccess(state, action.payload);
    case GET_USER_DEPARTMENT_FAILED:
      return getDepartmentsFailed(state, action.payload);
    case ADD_USER_DEPARTMENT_START:
      return addDepartmentsStart(state);
    case ADD_USER_DEPARTMENT_SUCCESS:
      return addDepartmentsSuccess(state, action.payload);
    case ADD_USER_DEPARTMENT_FAILED:
      return addDepartmentsFailed(state, action.payload);
    //Designations
    default:
      return designationInAccountInsight(state, action);
  }
}
function designationInAccountInsight(state, action) {
  switch (action.type) {
    case GET_USER_DESIGNATION_START:
      return getDesignationsStart(state);
    case GET_USER_DESIGNATION_SUCCESS:
      return getDesignationsSuccess(state, action.payload);
    case GET_USER_DESIGNATION_FAILED:
      return getDesignationsFailed(state, action.payload);
    case ADD_USER_DESIGNATION_START:
      return addDesignationStart(state);
    case ADD_USER_DESIGNATION_SUCCESS:
      return addDesignationSuccess(state, action.payload);
    case ADD_USER_DESIGNATION_FAILED:
      return addDesignationFailed(state, action.payload);
    default:
      return {...state};
  }
}
