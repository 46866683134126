import Component from './ResetPassword';
import {connect} from 'react-redux';
import { SelectState } from './Selector';
import { updateChangePasswordForm, changePassword } from '../../../Redux/Actions';
function mapStateToProps(state){
  return {
    ...SelectState(state)
  };
};
function mapDispatchToProps(dispatch){
  return{
    updateChangePasswordForm:payload=>dispatch(updateChangePasswordForm(payload)),
    changePassword:(payload,next)=>dispatch(changePassword(payload,next)),
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(Component);