import React, { Component } from 'react';
import SVGIcons from '../../../../Components/SVGIcons';
import Pagination from '../../../../Components/Pagination';
import { Row, Col, Table } from 'antd';
import {
  Card,
  CardBody,
} from '../../../../Components/Card';
import { InitialCard } from '../../../../Components/InitialCard';
import { EmptyState } from '../../../../Components/EmptyState';
import Loader from '../../../../Components/Loader';
import { Dropdown } from '../../../../Components/Dropdown';
import { ButtonElement } from '../../../../Components/ButtonElement';
import ModalWindow from '../../../../Components/ModalWindow';
import PageLoader from '../../../../Components/PageLoader';
import { withRouter } from 'react-router';
import Container from '../../../../Components/Container';
import style from '../../../Dashboard/style.module.scss';
import globel from '../../../../styles/global/style.module.scss';
import AddOrganisation from './AddOrganisation';
import { parseWebUrl } from '../../../../utils/queryStringUtils';
import axios from 'apiConfig';

import moment from 'moment';
import { API_BASE_URL } from '../../../../../config/constants';
class AddNetwork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showGroupModal: false,
      isEditOrganisation: false,
      selectedOrganisation: {},
      currentPage: 1,
      setMissionPage: 1,
      showDeleteModal: false,
      showArchiveModal: false,
      selectedOrgId: '',
      selectedOrgName: '',
      orgDeleted: false,
      orgUpdated: false,
      showDropDown: false,
      showDropId: null,
      viewArchives: false,
      viewMissionArchives: false,
      saveFavourite: {
        isLoading: false,
        orgId: null,
      },
      searchInProgress: false,
      activeTab: 'networks',
      searchMission: false,
      searchKey: '',
      limit: this.props.limit,
      gridType: 'grid',
      groupId: this.props.groupId,
      test: true,
      success: false,
      isProcessing: false,
      error: false,
    };
    this.setState({ groupId: this.props.groupId });
    this.wrapperRef = React.createRef();
    this.props.history.listen((location) => {
      if (location.search) {
        const parsUrl = parseWebUrl(location.search);

        this.setState({ searchKey: parsUrl.q, searchMission: true });
        parsUrl.is_on_hold = this.state.viewArchives;
        if (parsUrl.q === '') {
          this.setState({ searchInProgress: false });
        } else {
          this.setState({ searchInProgress: true });
        }
        if (this.state.activeTab !== 'missions') {
          this.setState({ searchMission: false });
          this.props.getOrganisationList(parsUrl);
        }
      }
    });
  }

  updatePage = (pageNumber, pageSize) => {
    this.setState({ limit: pageSize });
    this.setState({ currentPage: pageNumber });
    const parsUrl = parseWebUrl(location.search);
    this.props.getOrganisationList(
      {
        q: parsUrl.q ? parsUrl.q : '',
        limit: pageNumber == 1 ? this.state.limit : this.state.limit + 1,
        offset:
          pageNumber === 1
            ? 0
            : pageNumber * this.state.limit - this.state.limit,
        is_on_hold: this.state.viewArchives,
      },
      () => {
        this.setState({
          saveFavourite: { isLoading: false, orgId: null },
        });
      }
    );
  };

  updateOrganisation = (item, type) => {
    let request = {};
    request.name = item.name;
    request.id = item.id;
    if (type === 'hold') {
      request.is_on_hold = !item.is_on_hold;
    } else {
      this.setState({
        saveFavourite: { isLoading: true, orgId: item.id },
      });
      request.is_favourite = !item.is_favourite;
    }
    this.setState({
      orgUpdated: true,
    });
    this.props.updateOrganisation(request);
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('popstate', () => {
      this.setState({
        showDropDown: false,
        showDropId: null,
      });
    });
  }
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.props.organisationList) {
      if (this.props.organisationList.length !== 0) {
        if (
          this.wrapperRef &&
          this.wrapperRef.contains &&
          !this.wrapperRef.contains(event.target)
        ) {
          this.setState({
            showDropDown: false,
            showDropId: null,
          });
        }
      }
    }
  };

  showDropDown = (item) => {
    if (!this.state.showDropDown) {
      this.setState({
        showDropDown: true,
        showDropId: item,
      });
    } else {
      this.setState({
        showDropDown: false,
        showDropId: null,
      });
    }
  };
  componentDidUpdate() {
    if (this.props.updateOrganisations.success && this.state.orgUpdated) {
      this.setState({
        orgUpdated: false,
      });
      this.updatePage(this.state.currentPage, this.state.limit);
    }
  }
  componentWillMount() {
    // this.fetchOrganisationList();
    this.fetchOrganisationWithInGroupList();
    this.props.fetchOrganisationList();
    // this.fetchOrganisationGroupList();
  }

  fetchOrganisationWithInGroupList = (searchText = '') => {
    this.props.getGroupOrganizationList({
      q: searchText,
      id: localStorage.getItem('groupId'),
      limit: this.state.limit,
      offset: 0,
      is_on_hold: this.state.viewArchives,
    });
  };

  deleteOrganisation = () => {
    this.setState({
      orgDeleted: false,
      isProcessing: true,
    });

    axios({
      url: `${API_BASE_URL}organization/${this.state.selectedOrgId}/`,
      data: {
        group_id: null,
      },
      method: 'patch',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
        'content-type': 'application/json',
      },
    }).then(
      (res) => {
        this.setState({
          orgDeleted: true,
          success: true,
          isProcessing: false,
        });
      },
      (err) => {
        console.log(err);
        this.setState({
          orgDeleted: false,
          error: true,
          isProcessing: false,
        });
      }
    );
    this.setState({
      orgDeleted: true,
      isProcessing: false,
      showDeleteModal: false,
    });
    this.fetchOrganisationWithInGroupList();
    this.props.fetchOrganisationList();
  };

  hideDeleteModalWindow = () => {
    // if (!this.props.deleteOrganisations.isProcessing) {
    this.setState({
      showDeleteModal: false,
      selectedOrgId: '',
      selectedOrgName: '',
      orgDeleted: false,
    });
    // }
  };

  finshDelete = () => {
    this.hideDeleteModalWindow();
    this.updatePage(this.state.currentPage, this.state.limit);

    this.props.getGroupOrganizationList({
      id: this.props.groupId,
      limit: 7,
      offset: 0,
      q: '',
    });
    this.props.fetchOrganisationList();
  };
  showDeleteModalWindow = (item) => {
    this.setState({
      showDeleteModal: true,
      selectedOrgId: item.id,
      selectedOrgName: item.name,
      orgDeleted: false,
    });
  };
  showArchiveModalWindow = (item) => {
    this.setState({
      showArchiveModal: true,
      selectedOrganisation: item,
      selectedOrgName: item.name,
    });
  };
  showEditModalWindow = (item) => {
    this.setState({
      selectedOrganisation: item,
      isEditOrganisation: true,
    });
  };

  setSearchResult = () => {
    let dateObject = {};
    if (this.state.activeTab !== 'missions') {
      dateObject.offset = this.props.organisationListOffset;
      dateObject.size = this.state.limit;
      dateObject.total = this.props.organisationCount;
      dateObject.hideSearchArea =
        !this.state.searchInProgress && this.props.emptyResult;
    } else {
      dateObject.offset = (this.state.setMissionPage - 1) * this.state.limit;
      dateObject.size = this.state.limit;
      dateObject.total = this.props.listMissionCount;
      dateObject.hideSearchArea =
        !this.state.searchInProgress && this.props.listMissionIsEmpty;
    }
    return dateObject;
  };

  archiveOnChange = (event) => {
    if (this.state.activeTab !== 'missions') {
      this.setState({
        viewArchives: event.target.checked,
      });
      const parsUrl = parseWebUrl(location.search);
      this.props.getOrganisationList({
        q: parsUrl.q ? parsUrl.q : '',
        limit: this.state.limit,
        offset:
          this.state.currentPage === 1
            ? 0
            : this.state.currentPage * this.state.limit - this.state.limit,
        is_on_hold: event.target.checked,
      });
    } else {
      this.setState({
        viewMissionArchives: event.target.checked,
        setMissionPage: 1,
      });
    }
  };

  renderDeleteModalWindow = () => {
    return (
      <>
        <ModalWindow
          title='Heads Up!!'
          visible={this.state.showDeleteModal}
          footer={false}
          className='conform-login'
          onCancel={() => {
            this.props.deleteOrganisations.success && this.state.orgDeleted
              ? this.finshDelete()
              : this.hideDeleteModalWindow();
          }}>
          {this.props.deleteOrganisations.isProcessing ? (
            <>
              <p className='mr-2'>
                Please wait while we are deleting the report.
              </p>
              <Loader hasWrapper={true} classList={['sm blue']} />
            </>
          ) : (
            <>
              {this.props.deleteOrganisations.error ? (
                <p className='mr-2'>Oops.! Something Went wrong.</p>
              ) : (
                <>
                  {this.props.deleteOrganisations.success &&
                    this.state.orgDeleted ? (
                    <p className='mr-2'>
                      The {this.state.selectedOrgName} deleted successfully.
                    </p>
                  ) : (
                    <p className='mr-2'>
                      Are you sure you want to delete '
                      {this.state.selectedOrgName}' ?
                    </p>
                  )}
                </>
              )}
            </>
          )}
          {!this.props.deleteOrganisations.isProcessing ? (
            <div className='blu-list-submit'>
              {this.props.deleteOrganisations.success &&
                this.state.orgDeleted ? (
                <ButtonElement
                  type=''
                  children='Done'
                  onClick={() => {
                    this.finshDelete();
                  }}
                />
              ) : (
                <>
                  <ButtonElement
                    type=''
                    children='Yes'
                    onClick={() => {
                      this.deleteOrganisation();
                    }}
                  />
                  <ButtonElement
                    type='primary'
                    children='No'
                    onClick={() => {
                      this.hideDeleteModalWindow();
                    }}
                  />
                </>
              )}
            </div>
          ) : (
            ''
          )}
        </ModalWindow>
      </>
    );
  };
  renderArchiveModalWindow = () => {
    return (
      <>
        <ModalWindow
          title='Heads Up !!'
          visible={this.state.showArchiveModal}
          footer={false}
          className='conform-login'
          isNonMaskable={true}
          onCancel={() => {
            this.setState({ showArchiveModal: false });
          }}>
          <p>
            Are you sure that you want to archive the network {''}
            {`"${this.state.selectedOrgName}"`} ?
          </p>
          <div className='blu-list-submit'>
            <ButtonElement
              children='Yes'
              type=''
              onClick={() => {
                this.updateOrganisation(
                  this.state.selectedOrganisation,
                  'hold'
                );
                this.setState({ showArchiveModal: false });
              }}
            />
            <ButtonElement
              children='No'
              type='primary'
              onClick={() => {
                this.setState({ showArchiveModal: false });
              }}
            />
          </div>
        </ModalWindow>
      </>
    );
  };
  renderOrganisationList = (organisationList) => {
    return organisationList.map((item) => {
      return (
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Card>
            <CardBody>
              <div className={globel.cardControls}>
                {/* <div className={style.updateDate}>{item.group_name}</div> */}
                <div className={globel.controlsItem}>
                  {this.state.saveFavourite.isLoading &&
                    this.state.saveFavourite.orgId === item.id ? (
                    <Loader hasWrapper={true} classList={['xs blue']} />
                  ) : (
                    <span
                      className={
                        !item.is_favourite
                          ? globel.favoriteLine
                          : globel.activeFavoriteLine
                      }
                      onClick={() => {
                        !item.is_on_hold &&
                          this.updateOrganisation(item, 'fav');
                      }}>
                      <svg viewBox='0 0 78.227 75.428'>
                        <path
                          data-name='Path 270'
                          d='M733.653,471.435l9.48,23.51,24.552,1.613-19.112,16.451,6.252,24.171-21.172-13.15-21.38,13.15,6.063-24.171-18.892-16.451,24.737-1.613Z'
                          transform='translate(-694.47 -466.085)'
                        />
                      </svg>
                    </span>
                  )}
                </div>
                <div className={globel.controlsItem}>
                  <a
                    onClick={() => this.showDropDown(item.id)}
                    className={globel.dropBox}
                    ref={this.setWrapperRef}>
                    <Dropdown
                      addOnClass={
                        this.state.showDropId === item.id &&
                        this.state.showDropDown
                      }
                      customButton={
                        <span className={globel.dropIcon}>
                          <svg viewBox='0 0 2.118 8.471'>
                            <path
                              data-name='Path 268'
                              d='M0,7.412A1.059,1.059,0,1,1,1.059,8.471,1.059,1.059,0,0,1,0,7.412ZM0,4.236A1.059,1.059,0,1,1,1.059,5.294,1.059,1.059,0,0,1,0,4.236ZM0,1.059A1.059,1.059,0,1,1,1.059,2.118,1.059,1.059,0,0,1,0,1.059Z'
                              fill='#818e9b'
                            />
                          </svg>
                        </span>
                      }>
                      <ul className='card-dropdown'>
                        <li
                          onClick={() => {
                            this.showArchiveModalWindow(item);
                            // this.updateOrganisation(item, 'hold');
                          }}>
                          {!item.is_on_hold ? (
                            <>
                              <span>
                                <SVGIcons type={'SVG-archive'} />
                              </span>
                              <span>Archive</span>
                            </>
                          ) : (
                            <>
                              <span>
                                <SVGIcons type={'SVG-unarchive'} />
                              </span>
                              <span>Unarchive</span>
                            </>
                          )}
                        </li>
                        {!item.is_on_hold && (
                          <>
                            <li
                              onClick={() => {
                                this.showEditModalWindow(item);
                              }}>
                              <span>
                                <SVGIcons type={'SVG-edit'} />
                              </span>
                              <span>Edit</span>
                            </li>
                            <li
                              onClick={() => {
                                this.showDeleteModalWindow(item);
                              }}>
                              <span>
                                <SVGIcons type={'SVG-delete'} />
                              </span>
                              <span> Delete</span>
                            </li>
                          </>
                        )}
                      </ul>
                    </Dropdown>
                  </a>
                </div>
              </div>
              <div
                onClick={() => {
                  if (!item.is_on_hold) {
                    this.props.history.push({
                      pathname: `/${item.id}/organisation-insights/?orgName=${item.name}`,
                      // pathname: `/${item.id}/view-details/?orgName=${item.name}`,
                      organizationName: item.name,
                    });
                  }
                }}
                className={
                  item.is_on_hold
                    ? `${style.blueOrganization} ${globel.archived}`
                    : style.blueOrganization
                }>
                <div className={style.blueOrganization__brand}>
                  <div className={style.brandLogo}>
                    <span>
                      <img src={item.profile_image_url} />
                    </span>
                  </div>
                </div>
                <div className={style.blueOrganization__details}>
                  <h3
                    onClick={() => {
                      if (!item.is_on_hold) {
                        this.props.history.push({
                          pathname: `/${item.id}/view-details/?orgName=${item.name}`,
                          organizationName: item.name,
                        });
                      }
                    }}>
                    {item.name}
                  </h3>
                  <div className={style.updateDate}>
                    <span>Updated on</span>
                    <span>
                      {moment(item.updated_on).format('DD MMMM YYYY')}
                    </span>
                  </div>
                  <ul className={style.missionsCount}>
                    <li>
                      <span>{item.mission_count}</span>
                      <span>Projects</span>
                    </li>
                    <li>
                      <span>{item.department_count}</span>
                      <span>Locations </span>
                    </li>
                    {/* <li>
                      <span>{item.designation_count}</span>
                      <span>Designations </span>
                    </li> */}
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      );
    });
  };
  renderOrganisationGroupList = (organisationGroupList) => {
    return organisationGroupList.map((item) => {
      return (
        <Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={6}>
          <Card>
            <CardBody>
              <div className={globel.cardControls}>
                <div className={globel.controlsItem}>
                  {this.state.saveFavourite.isLoading &&
                    this.state.saveFavourite.orgId === item.id ? (
                    <Loader hasWrapper={true} classList={['xs blue']} />
                  ) : (
                    <span
                      className={
                        !item.is_favourite
                          ? globel.favoriteLine
                          : globel.activeFavoriteLine
                      }
                      onClick={() => {
                        !item.is_on_hold &&
                          this.updateOrganisation(item, 'fav');
                      }}>
                      <svg viewBox='0 0 78.227 75.428'>
                        <path
                          data-name='Path 270'
                          d='M733.653,471.435l9.48,23.51,24.552,1.613-19.112,16.451,6.252,24.171-21.172-13.15-21.38,13.15,6.063-24.171-18.892-16.451,24.737-1.613Z'
                          transform='translate(-694.47 -466.085)'
                        />
                      </svg>
                    </span>
                  )}
                </div>
                {/* <div className={globel.controlsItem}>
                    <a
                      onClick={() => this.showDropDown(item.id)}
                      className={globel.dropBox}
                      ref={this.setWrapperRef}>
                      <Dropdown
                        addOnClass={
                          this.state.showDropId === item.id &&
                          this.state.showDropDown
                        }
                        customButton={
                          <span className={globel.dropIcon}>
                            <svg viewBox='0 0 2.118 8.471'>
                              <path
                                data-name='Path 268'
                                d='M0,7.412A1.059,1.059,0,1,1,1.059,8.471,1.059,1.059,0,0,1,0,7.412ZM0,4.236A1.059,1.059,0,1,1,1.059,5.294,1.059,1.059,0,0,1,0,4.236ZM0,1.059A1.059,1.059,0,1,1,1.059,2.118,1.059,1.059,0,0,1,0,1.059Z'
                                fill='#818e9b'
                              />
                            </svg>
                          </span>
                        }>
                        <ul className='card-dropdown'>
                          <li
                            onClick={() => {
                              this.showArchiveModalWindow(item);
                              // this.updateOrganisation(item, 'hold');
                            }}>
                            {!item.is_on_hold ? (
                              <>
                                <span>
                                  <SVGIcons type={'SVG-archive'} />
                                </span>
                                <span>Archive</span>
                              </>
                            ) : (
                              <>
                                <span>
                                  <SVGIcons type={'SVG-unarchive'} />
                                </span>
                                <span>Unarchive</span>
                              </>
                            )}
                          </li>
                          {!item.is_on_hold && (
                            <>
                              <li
                                onClick={() => {
                                  this.showEditModalWindow(item);
                                }}>
                                <span>
                                  <SVGIcons type={'SVG-edit'} />
                                </span>
                                <span>Edit</span>
                              </li>
                              <li
                                onClick={() => {
                                  this.showDeleteModalWindow(item);
                                }}>
                                <span>
                                  <SVGIcons type={'SVG-delete'} />
                                </span>
                                <span> Delete</span>
                              </li>
                            </>
                          )}
                        </ul>
                      </Dropdown>
                    </a>
                  </div> */}
              </div>
              <div
                onClick={() => {
                  if (!item.is_on_hold) {
                    this.props.history.push({
                      pathname: `/network-group-details/${item.id}`,
                      state: item.name,
                    });
                  }
                }}
                className={
                  item.is_on_hold
                    ? `${style.blueOrganization} ${globel.archived}`
                    : style.blueOrganization
                }>
                <div className={style.blueOrganization__brand}>
                  <div className={style.brandLogo}>
                    <span>
                      <img
                        src={
                          item?.image === null
                            ? 'https://openclipart.org/download/271918/blankYellowFolder.svg'
                            : item.image
                        }
                      />
                    </span>
                  </div>
                </div>
                <div className={style.blueOrganization__details}>
                  <h3
                  // onClick={() => {
                  //   if (!item.is_on_hold) {
                  //     this.props.history.push({
                  //       pathname: `/${item.id}/view-details/?orgName=${item.name}`,
                  //       organizationName: item.name,
                  //     });
                  //   }
                  // }}
                  >
                    {item.name}
                  </h3>
                  <div className={style.updateDate}>
                    <span>Updated on</span>
                    <span>
                      {moment(item.updated_on).format('DD MMMM YYYY')}
                    </span>
                  </div>
                  <ul className={style.missionsCount}>
                    {/* <li>
                        <span>{item.mission_count}</span>
                        <span>Missions </span>
                      </li> */}
                    {/* <li>
                        <span>{item.department_count}</span>
                        <span>Locations </span>
                      </li> */}
                    {/* <li>
                        <span>{item.designation_count}</span>
                        <span>Designations </span>
                      </li> */}
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      );
    });
  };
  renderOrganizationTabel = (organisationList) => {
    const columns = [
      {
        title: 'Network Name',
        dataIndex: 'name',
        key: 'name',
        onCell: (r) => {
          return {
            onClick: () =>
              this.props.history.push({
                pathname: `/${r.id}/organisation-insights/?orgName=${r.name}`,
                organizationName: r.name,
              }),
          };
        },
      },
      {
        title: 'Number of Projects',
        dataIndex: 'mission_count',
        key: 'mission_count',
        onCell: (r) => {
          return {
            onClick: () =>
              this.props.history.push({
                pathname: `/${r.id}/organisation-insights/?orgName=${r.name}`,
                organizationName: r.name,
              }),
          };
        },
      },
      {
        title: 'Actions',
        key: '99',
        render: (item) => {
          return (
            <a
              onClick={() => this.showDropDown(item.id)}
              className={style.dropBox}
              ref={this.setWrapperRef}>
              <Dropdown
                addOnClass={
                  this.state.showDropId === item.id && this.state.showDropDown
                }
                customButton={
                  <span className={style.dropIcon}>
                    <svg viewBox='0 0 2.118 8.471'>
                      <path
                        data-name='Path 268'
                        d='M0,7.412A1.059,1.059,0,1,1,1.059,8.471,1.059,1.059,0,0,1,0,7.412ZM0,4.236A1.059,1.059,0,1,1,1.059,5.294,1.059,1.059,0,0,1,0,4.236ZM0,1.059A1.059,1.059,0,1,1,1.059,2.118,1.059,1.059,0,0,1,0,1.059Z'
                        fill='#818e9b'
                      />
                    </svg>
                  </span>
                }>
                <ul className='card-dropdown'>
                  <li
                    onClick={() => {
                      this.showArchiveModalWindow(item);
                    }}>
                    {!item.is_on_hold ? (
                      <>
                        <span>
                          <SVGIcons type={'SVG-archive'} />
                        </span>
                        <span>Archive</span>
                      </>
                    ) : (
                      <>
                        <span>
                          <SVGIcons type={'SVG-unarchive'} />
                        </span>
                        <span>Unarchive</span>
                      </>
                    )}
                  </li>
                  {!item.is_on_hold && (
                    <>
                      <li
                        onClick={() => {
                          this.showEditModalWindow(item);
                        }}>
                        <span>
                          <SVGIcons type={'SVG-edit'} />
                        </span>
                        <span>Edit</span>
                      </li>
                      <li
                        onClick={() => {
                          this.showDeleteModalWindow(item);
                        }}>
                        <span>
                          <SVGIcons type={'SVG-delete'} />
                        </span>
                        <span> Delete</span>
                      </li>
                    </>
                  )}
                </ul>
              </Dropdown>
            </a>
          );
        },
      },
    ];

    return (
      <>
        <div className='right-pane__header'>
          <h3></h3>

          <a
            onClick={() =>
              this.setState({
                showModal: true,
              })
            }
            className='add-button'>
            <span>
              <SVGIcons type={'SVG-plus'} />
            </span>
            <span>Add Network</span>
          </a>
        </div>
        <Table
          columns={columns}
          dataSource={organisationList}
          // size='middle'
          rowClassName={style.network_tabel}
          pagination={false}
        />
      </>
    );
  };
  renderOrganizationGroupTabel = (organisationGroupList) => {
    const columns = [
      {
        title: 'Group Name',
        dataIndex: 'name',
        key: 'name',
        onCell: (r) => {
          return {
            onClick: () =>
              this.props.history.push({
                pathname: `/network-group-details/${r.id}`,
                state: r.name,
              }),
          };
        },
      },
      {
        title: 'Actions',
        key: '99',
        render: (item) => {
          return (
            <a
              onClick={() => this.showDropDown(item.id)}
              className={style.dropBox}
              ref={this.setWrapperRef}>
              <Dropdown
                addOnClass={
                  this.state.showDropId === item.id && this.state.showDropDown
                }
                customButton={
                  <span className={style.dropIcon}>
                    <svg viewBox='0 0 2.118 8.471'>
                      <path
                        data-name='Path 268'
                        d='M0,7.412A1.059,1.059,0,1,1,1.059,8.471,1.059,1.059,0,0,1,0,7.412ZM0,4.236A1.059,1.059,0,1,1,1.059,5.294,1.059,1.059,0,0,1,0,4.236ZM0,1.059A1.059,1.059,0,1,1,1.059,2.118,1.059,1.059,0,0,1,0,1.059Z'
                        fill='#818e9b'
                      />
                    </svg>
                  </span>
                }>
                <ul className='card-dropdown'>
                  <li
                  // onClick={() => {
                  //   this.showArchiveModalWindow(item);
                  // }}
                  >
                    {!item.is_on_hold ? (
                      <>
                        <span>
                          <SVGIcons type={'SVG-archive'} />
                        </span>
                        <span>Archive</span>
                      </>
                    ) : (
                      <>
                        <span>
                          <SVGIcons type={'SVG-unarchive'} />
                        </span>
                        <span>Unarchive</span>
                      </>
                    )}
                  </li>
                  {!item.is_on_hold && (
                    <>
                      <li
                      // onClick={() => {
                      //   this.showEditModalWindow(item);
                      // }}
                      >
                        <span>
                          <SVGIcons type={'SVG-edit'} />
                        </span>
                        <span>Edit</span>
                      </li>
                      <li
                      // onClick={() => {
                      //   this.showDeleteModalWindow(item);
                      // }}
                      >
                        <span>
                          <SVGIcons type={'SVG-delete'} />
                        </span>
                        <span> Delete</span>
                      </li>
                    </>
                  )}
                </ul>
              </Dropdown>
            </a>
          );
        },
      },
    ];

    return (
      <>
        <div className='right-pane__header'>
          <h3></h3>

          <a
            onClick={() =>
              this.setState({
                showModal: true,
              })
            }
            className='add-button'>
            <span>
              <SVGIcons type={'SVG-plus'} />
            </span>
            <span>Add Group</span>
          </a>
        </div>
        <Table
          columns={columns}
          dataSource={organisationGroupList}
          // size='middle'
          rowClassName={style.network_tabel}
          pagination={false}
        />
      </>
    );
  };

  setActiveTab = (key) => {
    this.setState({
      viewMissionArchives: false,
      viewArchives: false,
    });
    this.setState({
      activeTab: key,
    });
    if (key === 'networks') {
      const parsUrl = parseWebUrl(location.search);
      this.setState({ searchKey: parsUrl.q, searchMission: true });
      let searchText = this.state.searchMission ? this.state.searchKey : '';
      this.props.fetchOrganisationList(searchText);
    }

    if (key === 'team') {
    }
  };

  render() {
    let {
      totalOrganisationCount,
      organisationList,
      organisationGroupList,
      getOrganisations,
      organisationListOffset,
      organisationCount,
      listMissionIsEmpty,
      groupOrganizationList,
    } = this.props;
    const toggleModal = () => {
      this.setState({ showModal: !this.state.showModal });
    };
    return (
      <>
        <section className={style.dashboard}>
          {this.renderArchiveModalWindow()}
          {this.renderDeleteModalWindow()}
          {this.state.showModal || this.state.isEditOrganisation ? (
            <AddOrganisation
              isEditMode={this.state.isEditOrganisation}
              organisationDetails={
                this.state.isEditOrganisation
                  ? this.state.selectedOrganisation
                  : {}
              }
              grpId={this.state.groupId}
              grpList={this.state.groupList}
              showModal={this.state.showModal || this.state.isEditOrganisation}
              onClose={() =>
                this.setState({
                  showModal: false,
                  isEditOrganisation: false,
                })
              }
              getGroupOrganizationList={this.props.getGroupOrganizationList}
              getPresignedUrl={this.props.getPresignedUrl}
              imageUrl={this.props.imageUrl}
              uploadImage={this.props.uploadImage}
              integratedUrl={this.props.integratedUrl}
              nameError={this.props.name?.errorMessage}
              point_of_contactError={this.props.point_of_contact.errorMessage}
              countryError={this.props.country.errorMessage}
              contact_emailError={this.props.contact_email.errorMessage}
              phoneError={this.props.phone.errorMessage}
              websiteError={this.props.website.errorMessage}
              updateErrorMessage={this.props.updateErrorMessage}
              limit={this.state.limit}
              uploadingImage={this.props.uploadingImage}
              isProcessing={
                this.props.isProcessing ||
                this.props.updateOrganisations.isProcessing
              }
            />
          ) : null}

          <Container>
            <div className={style.dashboardInner}>
              {((getOrganisations.isProcessing &&
                !this.state.isEditOrganisation) ||
                (this.props.updateOrganisations.isProcessing &&
                  !this.state.isEditOrganisation)) &&
                !this.state.saveFavourite.orgId ? (
                <PageLoader />
              ) : (
                <>
                  {groupOrganizationList.length !== 0 ? (
                    <>
                      {this.state.gridType === 'grid' && (
                        <Row gutter={8} className={style.dashboardItem}>
                          {!this.state.viewArchives &&
                            this.state.currentPage == 1 ? (
                            <Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={6}>
                              <Card>
                                <div
                                  className={style.addOrganization}
                                  onClick={() => {
                                    this.setState({
                                      showModal: true,
                                    });
                                  }}>
                                  <a
                                    onClick={() => { }}
                                    className={style.addOrganization__btn}>
                                    <span>
                                      <SVGIcons type={'SVG-plus'} />
                                    </span>
                                  </a>
                                  <h3>Add a Network </h3>
                                </div>
                              </Card>
                            </Col>
                          ) : null}
                          {this.renderOrganisationList(groupOrganizationList)}
                        </Row>
                      )}
                      {this.state.gridType === 'tabel' &&
                        this.renderOrganizationTabel(groupOrganizationList)}
                      <Pagination
                        total={this.props.organisationCount}
                        current={this.state.currentPage}
                        pageSize={this.state.limit}
                        onChange={this.updatePage}
                        hideOnSinglePage={false}
                      />
                    </>
                  ) : (
                    <>
                      {this.state.searchInProgress ||
                        this.state.viewArchives ? (
                        <EmptyState customHeight={400} emptyStateMessage={''} />
                      ) : (
                        (totalOrganisationCount !== 0 ||
                          this.props.totalMissionCount !== 0) && (
                          <InitialCard
                            text={`Let's Begin!`}
                            customButton={() => {
                              this.setState({
                                showModal: true,
                              });
                            }}
                            customButtonText='Add Network'>
                            <p>
                              Here is the place where you can see the case
                              studies you created. You may define a case study
                              by creating it as a network. Pin the network as
                              favorite, archive it to denote as network on-hold,
                              and also edit it once you create it. (All the
                              cards that's been archived could be viewed by
                              clicking on the 'View Archives Only' button)
                            </p>
                            <p>
                              Start creating networks by clicking the below
                              button
                            </p>
                          </InitialCard>
                        )
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </Container>
        </section>
      </>
    );
  }
}
export default withRouter(AddNetwork);
