import { PaginatedResponse } from 'types/Request';

export enum TagType {
    NETWORK = 'Network',
    SPRINT = 'Sprint',
    PAT = 'UserPat',
    MISSION ='Project',
}

export type Tag = {
    name: string;
    id: string;
    type?: string;
    created_by?: string;
    created_at?: string;
    updated_by?: number;
    entity_id?: number;
    count: number;
}

export type TagDetailType= {
    id: number;
    name?: string;
    type: string;
    type_id: number;
    tagged_elements: number;
}

export type PaginatedTag = PaginatedResponse<Tag>;