import React from 'react';
import { Row, Col } from 'antd';
import { useForm } from 'react-hook-form';
import PublicLayout from '../../Components/PublicLayout/PublicLayout';
import './styles/ContactUs.scss';
import { postContactUs } from '../../Services/SmartSolution.service';
import { getNotification } from '../../Components/GetNotification';

import { useTranslation } from "react-i18next"

/**
 * Renders the ContactUs component.
 *
 * @return {JSX.Element} The ContactUs component.
 */
const ContactUs = () => {
  const { register, handleSubmit, control, setValue, getValues, reset, formState: { errors }, watch } = useForm();

  /**
   * Saves contact information and displays a success notification.
   *
   * @param {object} params - The parameters for the contact information.
   * @return {undefined} This function does not return a value.
   */
  const saveContact = async (params) => {
    try {
      const res = await postContactUs(params).call;
      reset();
      getNotification('success', {
        header: 'Success',
        body: 'Soon we will be contacting you.'
      })
    } catch (error) {
      getNotification('error', {
        header: 'Apologies',
        body: "An error occurred when trying to contact us, please try again later.",
      });
    }
  }

  // i18n translation hook
  const [t] = useTranslation()

  return (
    <div className='contactus'>
      <div className="mainsearch-bg-black">
        <PublicLayout changeColor={false}>
          <h1 className="text-white text-center">{t("Contact_us.Connect")}!</h1>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <h1 className="text-blue">{t("Contact_us.Taking")}</h1>
              <p className="text-white my-15">{t("Contact_us.Traditional")}.</p>
              <div className="flex items-center">
                <img className="w-40px" src="/img/decrease.png" alt="" />
                <div className="grid ml-15">
                  <p className="text-white"><b>{t("Contact_us.Decrease")}</b></p>
                  <p className="text-white">{t("Contact_us.Accumulate")}</p>
                </div>
              </div>
              <div className="flex items-center mt-15">
                <img className="w-40px" src="/img/increase.png" alt="" />
                <div className="grid ml-15">
                  <p className="text-white"><b>{t("Contact_us.Increase")}</b></p>
                  <p className="text-white">{t("Contact_us.Outperform")}</p>
                </div>
              </div>
              <div className="flex items-center mt-15">
                <img className="w-40px" src="/img/goal.png" alt="" />
                <div className="grid ml-15">
                  <p className="text-white"><b>{t("Contact_us.Localized")}</b></p>
                  <p className="text-white">{t("Contact_us.Consider")}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <form onSubmit={handleSubmit(saveContact)} className="mt-15">
                <div className="mt-10">
                  <p className="text-white"> <span className="text-red">*</span>{t("Contact_us.Name")}</p>
                  <input className={`input-class w-90 ${errors.name ? "border-error" : ""}`} type="text" {...register('name', { required: true })} />
                </div>
                <div className="mt-10">
                  <p className="text-white"> <span className="text-red">*</span>{t("Contact_us.Organization")}</p>
                  <input className={`input-class w-90 ${errors.organization ? "border-error" : ""}`} type="text" {...register('organization', { required: true })} />
                </div>
                <div className="mt-10">
                  <p className="text-white"> <span className="text-red">*</span>{t("Contact_us.Email")}</p>
                  <input className={`input-class w-90 ${errors.email ? "border-error" : ""}`} type="text" {...register('email', { required: true })} />
                </div>
                <div className="mt-10">
                  <p className="text-white">{t("Contact_us.Comments")}</p>
                  <textarea className="textarea w-90" type="text" {...register('comments')} />
                </div>
                <div className="mt-15">
                  <p className="text-white"><span className="text-red">*</span> {t("Contact_us.Required")}.</p>

                </div>
                <div className="text-center">
                  <button type="submit" className="save-btn-contact">{t("Button.Send")}</button>
                </div>
              </form>
            </Col>
          </Row>
        </PublicLayout>
      </div>
    </div >
  );
};

export default ContactUs;
